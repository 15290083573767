/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'

import ChartsTableAudience from 'src/components/pages/Audience/Components/Charts/ChartsTableAudience'
import ChartsTableAudienceCity from 'src/components/pages/Audience/Components/Charts/ChartsTableAudienceCity'
import CreatorsTableAudience from 'src/components/pages/Audience/Components/Creators/CreatorsTableAudience'
import FollowersTableAudience from 'src/components/pages/Audience/Components/Followers/FollowersTableAudience'
import MonthlyListenersTable from 'src/components/pages/Audience/Components/Monthly Listeners/MonthlyListenersTable'
import RepostTableAudience from 'src/components/pages/Audience/Components/Reposts/RepostsTableAudience'
import type { AnalyticsAudienceData } from 'src/models/Analytics'

type Props = {
  audienceData?: AnalyticsAudienceData
  artworkUrl: string
  currentTab: string
}

const AnalyticsAudience = (props: Props) => {
  const [activeTab, setActiveTab] = useState(props.currentTab)

  const monthlyListeners = props.audienceData?.audience?.find(item =>
    item.source === activeTab)?.data.monthly_listeners

  const followers = props.audienceData?.audience?.find(item =>
    item.source === activeTab)?.data.followers

  const cityCharts = props.audienceData?.audience?.find(item =>
    item.source === activeTab)?.data.city_charts

  const countryCharts = props.audienceData?.audience?.find(item =>
    item.source === activeTab)?.data.country_charts

  const creators = props.audienceData?.audience?.find(item =>
    item.source === activeTab)?.data.creators

  const reposts = props.audienceData?.audience?.find(item =>
    item.source === activeTab)?.data.reposts

  useEffect(() => {
    setActiveTab(props.currentTab)
  }, [props.currentTab])

  return (
    <Stack spacing={3} width={1}>
      {props.audienceData?.audience?.map(item =>
        item.source === activeTab &&
        <Stack spacing={4} width={1}>

          {/* Monthly Listeners */}
          {monthlyListeners && monthlyListeners.length > 0 &&
          <MonthlyListenersTable
            artworkUrl={props.artworkUrl}
            currentTab={activeTab}
            listeners={monthlyListeners}
          />}

          {/* Followerss */}
          {followers && followers.length > 0 &&
          <FollowersTableAudience
            artworkUrl={props.artworkUrl}
            currentTab={activeTab}
            followers={followers}
          />}

          {reposts && reposts.length > 0 &&
          <RepostTableAudience
            artworkUrl={props.artworkUrl}
            currentTab={activeTab}
            reposts={reposts}
          />}

          {creators && creators.length > 0 &&
          <CreatorsTableAudience
            artworkUrl={props.artworkUrl}
            creators={creators}
            currentTab={activeTab}
          />}

          {cityCharts && cityCharts.length > 0 &&
          <ChartsTableAudienceCity
            artworkUrl={props.artworkUrl}
            charts={cityCharts}
            country={false}
            currentTab={activeTab}
          />}

          {countryCharts && countryCharts.length > 0 &&
          <ChartsTableAudience
            artworkUrl={props.artworkUrl}
            charts={countryCharts}
            country
            currentTab={activeTab}
          />}

        </Stack>)}
    </Stack>
  )
}
export default AnalyticsAudience
