/* eslint-disable sonarjs/no-nested-template-literals */
import axios from 'axios'
import download from 'js-file-download'

import { AnalyticsActivity, AnalyticsAudienceData, AnalyticsCatalog, AnalyticsCurrentData, AnalyticsHistoricData, AnalyticsProfileData, TopPlaylists } from 'src/models/Analytics'
import type { ArtistItemSingle } from 'src/models/AnalyticsCloud'
import { NewMusicFridayTopGainersnalytics, NewMusicFridayTrackAnalytics, TopArtistAnalytics, TopTracksAnalytics, ViralTracksAnalytics } from 'src/models/AnalyticsCloud'
import { SearchObject } from 'src/models/Distribution'

const PATH = '/analyticsCloud' as const

export const getAnalytics = (isrc: string) =>
  axios.get<AnalyticsHistoricData>(`${PATH}/${isrc}`)
    .then(response => new AnalyticsHistoricData(response.data))

export const getAnalyticsCurrent = (isrc: string) =>
  axios.get<AnalyticsCurrentData>(`${PATH}/current/${isrc}`)
    .then(response => new AnalyticsCurrentData(response.data))

export const getActivities = (isrc: string) =>
  axios.get<AnalyticsActivity>(`${PATH}/activities/${isrc}`)
    .then(response => new AnalyticsActivity(response.data))

export const downloadStatement = (period: string, year: string, month: string) =>
  axios.put<File>(`${PATH}/export/${period}`)
    .then(response => download(response.data, `Statement ${month}-${year}.csv`))

export const getAnalyticsAudienceArtist = () =>
  axios.get<AnalyticsAudienceData>(`${PATH}/artist/audience`)
    .then(response => new AnalyticsAudienceData(response.data))

export const getAnalyticsArtist = () =>
  axios.get<AnalyticsHistoricData>(`${PATH}/artist/historicData`)
    .then(response => new AnalyticsHistoricData(response.data))

export const getAnalyticsProfileArtist = () =>
  axios.get<AnalyticsProfileData>(`${PATH}/artist/info`)
    .then(response => new AnalyticsProfileData(response.data))

export const getAnalyticsCurrentArtist = () =>
  axios.get<AnalyticsCurrentData>(`${PATH}/artist/currentData`)
    .then(response => new AnalyticsCurrentData(response.data))

export const getAnalyticsCatalogArtist = () =>
  axios.get<AnalyticsCatalog>(`${PATH}/artist/catalog`)
    .then(response => new AnalyticsCatalog(response.data))

export const getAnalyticsActivityArtist = () =>
  axios.get<AnalyticsActivity>(`${PATH}/artist/activities`)
    .then(response => new AnalyticsActivity(response.data))

export const getAnalyticsTopPlaylistsArtist = () =>
  axios.get<TopPlaylists>(`${PATH}/artist/topPlaylists`)
    .then(response => new TopPlaylists(response.data))

export const getAnalyticsLabel = () =>
  axios.get<AnalyticsHistoricData>(`${PATH}/label/historicData`)
    .then(response => new AnalyticsHistoricData(response.data))

export const getAnalyticsCurrentLabel = () =>
  axios.get<AnalyticsCurrentData>(`${PATH}/label/currentData`)
    .then(response => new AnalyticsCurrentData(response.data))

export const getAnalyticsCatalogLabel = () =>
  axios.get<AnalyticsCatalog>(`${PATH}/label/catalog`)
    .then(response => new AnalyticsCatalog(response.data))

export const getAnalyticsActivityLabel = () =>
  axios.get<AnalyticsActivity>(`${PATH}/label/activities`)
    .then(response => new AnalyticsActivity(response.data))

export const getAnalyticsAudienceLabel = () =>
  axios.get<AnalyticsAudienceData>(`${PATH}/label/audience`)
    .then(response => new AnalyticsAudienceData(response.data))

export const getAnalyticsProfileLabel = () =>
  axios.get<AnalyticsProfileData>(`${PATH}/label/info`)
    .then(response => new AnalyticsProfileData(response.data))

export const getAnalyticsTopPlaylistsLabel = () =>
  axios.get<TopPlaylists>(`${PATH}/label/topPlaylists`)
    .then(response => new TopPlaylists(response.data))

export const getAnalyticsCloudTrack = (songstatsId: string) =>
  axios.get<AnalyticsHistoricData>(`${PATH}/track/${songstatsId}`)
    .then(response => new AnalyticsHistoricData(response.data))

export const getAnalyticsCloudTrackCurrent = (songstatsId: string) =>
  axios.get<AnalyticsCurrentData>(`${PATH}/trackCurrent/${songstatsId}`)
    .then(response => new AnalyticsCurrentData(response.data))

export const getAnalyticsCloudTrackActivities = (isrc: string) =>
  axios.get<AnalyticsActivity>(`${PATH}/track-activities/${isrc}`)
    .then(response => new AnalyticsActivity(response.data))

export const getAnalyticsArtistFree = () =>
  axios.get<AnalyticsHistoricData>(`${PATH}/artist/historicDataFree`)
    .then(response => new AnalyticsHistoricData(response.data))

export const getAnalyticsCurrentArtistFree = () =>
  axios.get<AnalyticsCurrentData>(`${PATH}/artist/currentDataFree`)
    .then(response => new AnalyticsCurrentData(response.data))

export const getAnalyticsCatalogArtistFree = () =>
  axios.get<AnalyticsCatalog>(`${PATH}/artist/catalogFree`)
    .then(response => new AnalyticsCatalog(response.data))

export const getAnalyticsActivityArtistFree = () =>
  axios.get<AnalyticsActivity>(`${PATH}/artist/activitiesFree`)
    .then(response => new AnalyticsActivity(response.data))

export const getAnalyticsProfileArtistFree = () =>
  axios.get<AnalyticsProfileData>(`${PATH}/artist/infoFree`)
    .then(response => new AnalyticsProfileData(response.data))

export const getAnalyticsAudienceArtistFree = () =>
  axios.get<AnalyticsAudienceData>(`${PATH}/artist/audienceDataFree`)
    .then(response => new AnalyticsAudienceData(response.data))

export const getAnalyticsTopPlaylistsArtistFree = () =>
  axios.get<TopPlaylists>(`${PATH}/artist/topPlaylistsFree`)
    .then(response => new TopPlaylists(response.data))

export const getViralTracks = () =>
  axios.get<ViralTracksAnalytics[]>(`${PATH}/viral-tracks`)
    .then(response => response.data.map(item => new ViralTracksAnalytics(item)))

export const getTopTracks = () =>
  axios.get<TopTracksAnalytics[]>(`${PATH}/top-tracks`)
    .then(response => response.data.map(item => new TopTracksAnalytics(item)))

export const getTopArtists = () =>
  axios.get<TopArtistAnalytics[]>(`${PATH}/top-artists`)
    .then(response => response.data.map(item => new TopArtistAnalytics(item)))

export const getLatestTracks = () =>
  axios.get<NewMusicFridayTrackAnalytics[]>(`${PATH}/latest-tracks`)
    .then(response => response.data.map(item => new NewMusicFridayTrackAnalytics(item)))

export const getLatestTracksTopGains = () =>
  axios.get<NewMusicFridayTopGainersnalytics[]>(`${PATH}/latest-tracks-by-track`)
    .then(response => response.data.map(item => new NewMusicFridayTopGainersnalytics(item)))

export const getViralArtists = () =>
  axios.get<ArtistItemSingle[]>(`${PATH}/viral-artists`)
    .then(response => response.data)

export const getGlobalSearch = (query: string) =>
  axios.put<SearchObject[]>(`${PATH}/search-cloud`, { query })
    .then(response => response.data.map(dto => new SearchObject(dto)))

export const getAnalyticsAudienceArtistSearch = (id: string) =>
  axios.get<AnalyticsAudienceData>(`${PATH}/artist/audience/${id}`)
    .then(response => new AnalyticsAudienceData(response.data))

export const getAnalyticsArtistSearch = (id: string) =>
  axios.get<AnalyticsHistoricData>(`${PATH}/artist/historicData/${id}`)
    .then(response => new AnalyticsHistoricData(response.data))

export const getAnalyticsProfileArtistSearch = (id: string) =>
  axios.get<AnalyticsProfileData>(`${PATH}/artist/info/${id}`)
    .then(response => new AnalyticsProfileData(response.data))

export const getAnalyticsCurrentArtistSearch = (id: string) =>
  axios.get<AnalyticsCurrentData>(`${PATH}/artist/currentData/${id}`)
    .then(response => new AnalyticsCurrentData(response.data))

export const getAnalyticsCatalogArtistSearch = (id: string) =>
  axios.get<AnalyticsCatalog>(`${PATH}/artist/catalog/${id}`)
    .then(response => new AnalyticsCatalog(response.data))

export const getAnalyticsActivityArtistSearch = (id: string) =>
  axios.get<AnalyticsActivity>(`${PATH}/artist/activities/${id}`)
    .then(response => new AnalyticsActivity(response.data))

export const getAnalyticsTopPlaylistsArtistSearch = (id: string) =>
  axios.get<TopPlaylists>(`${PATH}/artist/topPlaylists/${id}`)
    .then(response => new TopPlaylists(response.data))

export const getRecommendedArtists = () =>
  axios.get<ArtistItemSingle[]>(`${PATH}/recommended-artists`)
    .then(response => response.data)
