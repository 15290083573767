import { alpha, Box, Button, Card, CardActionArea, Divider, Stack, Typography,  useTheme } from '@mui/material'

type Props = {
  readonly activeBilling: number
  readonly billingCycle: number
  readonly handleBilling: (step: number) => () => void
  readonly creatorPro: number
  readonly month: number
  readonly disabled: boolean
  readonly seats: number
}

const CreatorProCard = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Card
      sx={{
        borderColor: theme => props.activeBilling === props.creatorPro
          ? `${theme.palette.info.main}!important`
          : null,
        position: 'relative',
        boxShadow: 'inset 0 8px 0 #408AFF !important',
        borderWidth: '2px',
      }}
      variant='outlined'
    >
      <CardActionArea disabled={!props.disabled} onClick={props.handleBilling(props.creatorPro)} sx={{ height: 1 }} >
        {!props.disabled &&
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: 1,
            bgcolor: theme => alpha(theme.palette.action.disabled, 0.25),
            height: 1,
          }}
        />}
        <Typography
          sx={{
            position: 'absolute',
            top: '0px',
            marginX: 'auto',
            width: 'fit-content',
            left: 0,
            right: 0,
            background: theme => theme.palette.info.main,
            borderRadius: '0px 0px 9px 8px',
            paddingBottom: '8px',
            paddingTop: '4px',
            paddingX: '16px',
            color: 'white',
          }}
        >
          &#9733; Most popular
        </Typography>
        <Stack alignItems='flex-start' direction='column' height={1} padding={2} spacing={2}>
          <Stack>
            <Stack alignItems='center' direction='row' spacing={2}>
              <Typography fontWeight='normal' variant='h2'>
                Pro
              </Typography>
            </Stack>
            <Stack alignItems='flex-start' direction='column' spacing={1}>
              <Typography fontFamily='Domaine-Display' lineHeight={1} variant='h1'>
                <span style={{ fontFamily: 'Matter' }}>
                  $
                </span>
                {props.billingCycle === props.month
                  ? <>
                    {((props.billingCycle *
                  props.creatorPro) +
                  Math.max(0, props.billingCycle *
                  (props.seats - 2) *
                  19))}
                  </>
                  : <>
                    {(((props.billingCycle *
                    props.creatorPro) +
                    Math.max(0, props.billingCycle *
                    (props.seats - 2) *
                    19)) / 12).toFixed(2)}
                  </>}
              </Typography>
              {props.billingCycle === props.month
                ? <Typography variant='body1'>
                  / month
                </Typography>
                : <Typography variant='body1'>
                  / month, billed yearly
                </Typography>}
            </Stack>
          </Stack>
          <Typography
            color={themeItem.palette.text.secondary}
            variant='body2'
          >
            The ultimate toolkit for scaling and managing full catalogs.
          </Typography>
          <Button
            color='info'
            disabled={!props.disabled || props.activeBilling === props.creatorPro}
            fullWidth
          >
            {props.activeBilling === props.creatorPro
              ? 'Pro Selected'
              : 'Select Pro'}
          </Button>
          <Stack direction='column'>
            <Typography lineHeight={1.4} variant='h3'>
              Features
            </Typography>
            <Typography color={themeItem.palette.text.secondary} variant='body2'>
              Everything in Essential and...
            </Typography>
          </Stack>
          <Divider sx={{ width: 1 }} />
          <Stack direction='column' spacing={2}>
            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                2 User Logins
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Project Management
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Meta, Google, TikTok & Snap Pixel Integrations
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Bring Your Own Domain Name
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Meta & TikTok Events API
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Export Fan Emails & Data
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Removed Branding on Social Assets
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default CreatorProCard
