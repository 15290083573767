/* eslint-disable sonarjs/no-collapsible-if */
import CloseIcon from '@mui/icons-material/Close'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, Dialog, Divider, IconButton, ListItemText, Rating, Skeleton, Slide, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { addFavoriteArtist, removeFavoriteArtist } from 'src/api/organisation'
import DiscoveryUpgrade from 'src/components/pages/Audience/Components/Modals/DiscoveryUpgrade'
import ViewArtistItem from 'src/components/pages/Audience/Components/Modals/ViewArtistItem'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  close: () => void
  open: boolean
  selectedArtist: string
  loading: boolean
  artwork: string
  title: string
}

const ArtistModalSearch = (props: Props) => {
  const { currentOrganisation, refreshCurrentOrganisation } = useAuth()
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const [loading, setLoading] = useState(false)

  const handleFavorite = async () => {
    if (currentOrganisation && !loading) {
      setLoading(true)
      await (currentOrganisation.favoriteArtistProfiles.some(item => item.spotifyId === props.selectedArtist)
        ? removeFavoriteArtist(props.selectedArtist)
          .then(refreshCurrentOrganisation)
          .finally(() => setLoading(false))
        : addFavoriteArtist(props.selectedArtist, props.artwork, props.title)
          .then(refreshCurrentOrganisation))
        .finally(() => setLoading(false))
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen
      fullWidth
      maxWidth='lg'
      onClose={props.close}
      open={props.open}
      sx={{
        '& .MuiPaper-root': {
          transform: 'none!important',
        },
        '.MuiDialog-paper': {
          height: '100%',
        },
      }}
    >
      <Slide direction='up' in={props.open}>
        <Stack height={1} overflow='hidden' width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            paddingX={2}
            position='sticky'
            width={1}
          >
            <Stack alignItems='center' direction='row' paddingY={2} spacing={2}>
              {props.loading
                ? <>
                  <Skeleton
                    height={64}
                    variant='rounded'
                    width={64}
                  />
                  <Skeleton
                    height={20}
                    variant='text'
                    width={100}
                  />
                </>
                : <>
                  <Avatar
                    src={props.artwork}
                    sx={{
                      width: 64,
                      height: 64,
                    }}
                    variant='rounded'
                  >
                    <MusicNoteRoundedIcon />
                  </Avatar>
                  <ListItemText
                    primary={props.title}
                    secondary='Audience'
                  />
                </>}
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              {!mobile &&
              <Rating
                defaultValue={currentOrganisation?.favoriteArtistProfiles &&
                  currentOrganisation.favoriteArtistProfiles.some(item => item.spotifyId === props.selectedArtist)
                  ? 1
                  : 0}
                max={1}
                onClick={() => void handleFavorite()}
                precision={1}
                value={currentOrganisation?.favoriteArtistProfiles &&
                  currentOrganisation.favoriteArtistProfiles.some(item => item.spotifyId === props.selectedArtist)
                  ? 1
                  : 0}
              />}
              <IconButton onClick={() => props.close()}>
                <CloseIcon color='disabled' />
              </IconButton>
            </Stack>
          </Stack>
          {(currentOrganisation?.membershipTier === 'ENTERPRISE' ||
          currentOrganisation?.membershipTier === 'ENTERPRISE_YEARLY') &&
          currentOrganisation.analyticsExtension
            ? <>
              <Divider />
              <Stack height={1} justifyContent='flex-start' width={1}>
                <ViewArtistItem
                  artistId={props.selectedArtist}
                />
              </Stack>
            </>
            : <DiscoveryUpgrade
              close={props.close}
            />}
        </Stack>
      </Slide>
    </Dialog>
  )
}

export default ArtistModalSearch
