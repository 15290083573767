/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Stack, Typography, useTheme } from '@mui/material'

import TeamAvatar from 'src/components/TeamAvatar'
import type { Collaborator } from 'src/models/Project'

type Props = {
  collaborator: Collaborator
}

const CollaboratorItemDetails = (props: Props) => {
  const themeColors = useTheme()
  return (
    <Stack
      direction='column'
      key={props.collaborator.account.id}
      spacing={2}
      width={1}
    >
      <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={1}>
        <Stack alignItems='center' direction='row' spacing={2} width={1}>
          <TeamAvatar
            organisation={props.collaborator.account}
          />
          <Stack direction='column' overflow='hidden' width={1}>
            <Typography
              overflow='hidden'
              textAlign='start'
              textOverflow='ellipsis'
              variant='body1'
              whiteSpace='nowrap'
            >
              {props.collaborator.account.name}
            </Typography>
            <Typography
              color={themeColors.palette.text.label}
              overflow='hidden'
              textAlign='start'
              textOverflow='ellipsis'
              variant='body2'
              whiteSpace='nowrap'
            >
              {props.collaborator.account.organisationEmail}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CollaboratorItemDetails
