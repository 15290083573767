import { TabContext } from '@mui/lab'
import { Paper, Stack } from '@mui/material'
import { useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import AnalyticsSearch from 'src/components/pages/Audience/Pages/AnalyticsSearch'
import SPACING from 'src/styles/spacing'

const Audience = () => {
  const location = useLocation().pathname
  const [currentTab, _setCurrentTab] = useState(location)
  return (
    <Stack
      sx={{
        paddingBottom: SPACING.RESPONSIVE_CONTOUR,
        paddingRight: SPACING.RESPONSIVE_CONTOUR,
        height: 1,
        width: 1,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          height: 1,
          overflow: 'hidden',
          borderRadius: {
            xl: 1,
            lg: 1,
            md: 1,
            sm: 0,
            xs: 0,
          },
        }}
      >
        <Stack height={1} width={1}>
          <TabContext value={currentTab}>
            <Stack height={1} overflow='hidden'>
              <Routes>
                <Route element={<AnalyticsSearch />} path='/:id' />
              </Routes>
            </Stack>
          </TabContext>
        </Stack>
      </Paper>
    </Stack>
  )
}

export default Audience
