import { Stack } from '@mui/material'

import EarningsStatementArea from 'src/components/pages/Earnings/EarningsDistribution/Overview/EarningsOverviewNewChart'
import TopArtistsChart from 'src/components/pages/Earnings/EarningsDistribution/Overview/TopArtistsChart'
import TopReleasesChart from 'src/components/pages/Earnings/EarningsDistribution/Overview/TopReleasesChart'
import type { AnalyticsMonthlyChart, AnalyticsTopArtists } from 'src/models/Analytics'

type Props = {
  readonly data: AnalyticsMonthlyChart[]
  readonly selectedRecording: string
  readonly topReleases: AnalyticsMonthlyChart[]
  readonly netIncome: AnalyticsMonthlyChart[]
  readonly isOwner: boolean
  readonly topArtists: AnalyticsTopArtists[]
}

const EarningsDistributionOverview = (props: Props) =>
  <Stack spacing={3} width={1}>
    <EarningsStatementArea
      data={props.data}
      isOwner={props.isOwner}
      netIncome={props.netIncome}
    />
    {props.topReleases.length > 0 && props.selectedRecording.length === 0 &&
      <Stack width={1}>
        <TopReleasesChart
          data={props.topReleases}
        />
      </Stack>}
    {props.topReleases.length > 0 && props.selectedRecording.length === 0 && props.topArtists.length > 0 &&
      <Stack width={1}>
        <TopArtistsChart
          artists={props.topArtists}
          data={props.topReleases}
        />
      </Stack>}
  </Stack>

export default EarningsDistributionOverview
