import NotificationAddRoundedIcon from '@mui/icons-material/NotificationAddRounded'
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import type { Key } from 'react'
import { useState } from 'react'
import { v4 } from 'uuid'

import LinkFanItem from 'src/components/pages/Links/ViewLink/ViewLinkComponents/FansComponents/LinkFanItem'
import type { MarketingLinkFan } from 'src/models/Marketing'

type Props = {
  marketingLinkFans: MarketingLinkFan[]
  query?: string
}

type Data = {
  name: string
  email: string
  platform: string
  premium: string
  country: string
  createdAt: Date
}

type Order = 'asc' | 'desc'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (
  order: Order,
  orderBy: keyof Data,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
  ) => number => order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy)

function stableSort<T>(array: readonly Data[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((element, index) => [element, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(element => element[0])
}

type HeadCell = {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'platform',
    numeric: true,
    disablePadding: false,
    label: 'Platform',
  },
  {
    id: 'premium',
    numeric: true,
    disablePadding: false,
    label: 'Premium',
  },
  {
    id: 'country',
    numeric: true,
    disablePadding: false,
    label: 'Country',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Date',
  },
]

type EnhancedTableProps = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy,  onRequestSort } =
    props
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead
      sx={{
        '.MuiTableCell-root': {
          borderBottom: 2,
          borderBottomColor: 'divider',
          color: 'text.secondary',
        },
      }}
    >
      <TableRow>
        {headCells.map((headCell, index) =>
          <TableCell
            align='left'
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              position: index === 0 ? 'sticky' : undefined,
              left: index === 0 ? 0 : undefined,
              zIndex: index === 0 ? 999 : undefined,
              background: theme => theme.palette.background.default,
              borderRight: theme => index === 0 ? `1px solid ${theme.palette.divider}` : 'none',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
                : null}
            </TableSortLabel>
          </TableCell>)}
      </TableRow>
    </TableHead>
  )
}

const ViewLinkFans = (props: Props) => {
  const [page, setPage] = useState(0)
  const queryItem = props.query ?? ''

  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof Data>('createdAt')

  const filterData = props.marketingLinkFans.filter(item =>
    item.name.toLowerCase().includes(queryItem.toLowerCase()))

  const rows = filterData.map(item => ({
    name: item.name,
    email: item.email,
    platform: item.platform,
    premium: item.premium ? 'Premium' : 'Free',
    country: item.country,
    createdAt: item.createdAt,
  }))

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const [rowsPerPage, setRowsPerPage] = useState(25)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Stack alignItems='center' spacing={3} width={1}>
      <Stack
        marginLeft='auto'
        marginRight='auto'
        maxWidth={1332}
        paddingTop={2}
        spacing={1}
        width={1}
      >
        <Typography variant='h3'>
          Pre-saves
        </Typography>
        {rows.length > 0
          ? <Stack width={1}>
            <TableContainer>
              <Table
                aria-label='projects table'
                size='small'
                stickyHeader
                sx={{
                  minWidth: 750,
                  tableLayout: 'fixed',
                  borderCollapse: 'separate',
                }}
              >
                <EnhancedTableHead
                  onRequestSort={handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(fanItem =>
                      <LinkFanItem
                        country={fanItem.country as string}
                        createdAt={new Date(fanItem.createdAt)}
                        email={fanItem.email as string}
                        key={v4()}
                        name={fanItem.name as string}
                        platform={fanItem.platform as string}
                        premium={fanItem.premium as string}
                      />)}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component='div'
              count={filterData.length}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
            />
          </Stack>
          : <Stack
            alignItems='center'
            height={1}
            justifyContent='center'
            width='100%'
          >
            <Paper
              elevation={0}
              sx={{
                backgroundColor: theme => theme.palette.primary.main,
                borderRadius: 16,
              }}
            >
              <Stack
                alignItems='center'
                justifyContent='center'
                padding={2}
              >
                <NotificationAddRoundedIcon
                  sx={{
                    color: theme => theme.palette.primary.contrastText,
                    fontSize: '64px',
                  }}
                />
              </Stack>
            </Paper>
            <Typography
              color='textSecondary'
              sx={{
                marginTop: 1,
              }}
              variant='h4'
            >
              No Pre-save yet (This is good!)
            </Typography>
            <Typography
              color='textLabel'
              textAlign='center'
              variant='body1'
            >
              When a fan subscribes to your Releese profile, they will pre-save all your future releases. If you see
              them in the list above and not here, that means they are a super-fan.
            </Typography>
          </Stack>}
      </Stack>
    </Stack>
  )
}

export default ViewLinkFans
