import Account from 'src/models/Account'
import { Message } from 'src/models/Message'
import { Organisation } from 'src/models/Organisation'
import Project from 'src/models/Project'
import type { Serialized } from 'src/types/react-app-env'

export const CONVERSATION_TYPE = ['Organisation', 'Account', 'Project'] as const

export class NewConversation {
  id = ''
  title?: string
  type: typeof CONVERSATION_TYPE[number]
  selectedParticipants: string[] = [] // this is only used for the request, not response dto
  selectedAccounts: string[] = [] // this is only used for the request, not response dto
  selectedProject?: string

  constructor(dto: Partial<NewConversation>) {
    Object.assign(this, dto)
    this.type = dto.type ?? CONVERSATION_TYPE[0]
    this.title = dto.title ?? ''
  }
}

export class Conversation extends NewConversation {
  lastMessage: Message
  conversationImage: undefined
  conversationImageUrl?: string
  participants?: Organisation[] = []
  accounts?: Account[] = []
  createdAt: Date
  updatedAt: Date
  project?: {
    _id?: Project
  }
  constructor(dto: ConversationDto) {
    super({
      ...dto,
      title: dto.title ?? '',
      type: dto.type ?? CONVERSATION_TYPE[0],
    })
    this.lastMessage = new Message(dto.lastMessage ?? {})
    this.conversationImageUrl = dto.conversationImageUrl ?? ''
    this.participants = (dto.participants ?? []).map(participant => new Organisation(participant))
    this.accounts = (dto.accounts ?? []).map(account => new Account(account))
    this.createdAt = new Date(dto.createdAt ?? '')
    this.updatedAt = new Date(dto.updatedAt ?? '')
    this.project = dto.project?._id ? { _id: new Project(dto.project._id) } : undefined
  }
}

export type ConversationDto = Partial<Serialized<Conversation>> & { _id?: string }

