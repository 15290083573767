import { Card, CardActionArea, Skeleton, Stack } from '@mui/material'

const ReleaseItemSkeleton = () =>
  <Card elevation={0} sx={{ background: 'transparent' }}>
    <CardActionArea
      sx={{
        padding: 2,
        height: 1,
      }}
    >
      <Skeleton height={126} sx={{ borderRadius: 1 }} variant='rectangular' width={126} />
      <Stack alignItems='center' paddingTop={3}>
        <Skeleton variant='text' width={85} />
        <Skeleton variant='text' width={50} />
      </Stack>
    </CardActionArea>
  </Card>

export default ReleaseItemSkeleton
