import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Card, CardActionArea, Paper, Stack } from '@mui/material'
import { useState } from 'react'

import NewProjectModal from 'src/components/pages/Projects/EditProject/NewProjectModal'

const CreateReleaseItem = () => {
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false)
  return (
    <>
      <NewProjectModal
        close={() => setNewProjectModalOpen(false)}
        open={newProjectModalOpen}
      />

      <Card elevation={0} sx={{ minWidth: 128, height: 1, background: 'transparent' }}>
        <CardActionArea onClick={() => setNewProjectModalOpen(true)}>
          <Stack alignItems='center'>
            <Paper
              sx={{
                borderRadius: 1,
                height: 128,
                width: 128,
                alignContent: 'center',
                backgroundColor: 'transparent',
                border: theme => `2px solid ${theme.palette.primary.main}`,
              }}
              variant='outlined'
            >
              <Stack alignItems='center' height={1} justifyContent='center' width={1}>
                <AddRoundedIcon
                  sx={{
                    fontSize: 64,
                    color: theme => theme.palette.action.active,
                  }}
                />
              </Stack>
            </Paper>
          </Stack>
        </CardActionArea>
      </Card>
    </>
  )
}

export default CreateReleaseItem
