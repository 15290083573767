/* eslint-disable max-len */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-hooks/exhaustive-deps */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, Card, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { gptArtwork } from 'src/api/projects'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Project from 'src/models/Project'

type Props = {
  close: () => void
  open: boolean
  project?: Project
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
}

const GenerateArtworkModal = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [billingModal, setBillingModalOpen] = useState(false)
  const [prompt, setPrompt] = useState('')
  const [saving, setSaving] = useState(false)
  const [errorSaving, setErrorSaving] = useState(false)

  const save = async () => {
    if (props.project &&
        currentOrganisation &&
        currentOrganisation.membershipTier !== 'FREE') {
      setSaving(true)
      setErrorSaving(false)
      await gptArtwork(props.project.id, prompt)
        .then(item => {
          props.setProject(item)
        })
        .catch(() => {
          setSaving(false)
          setErrorSaving(true)
        })
        .finally(() => {
          setSaving(false)
          props.close()
        })
    }
  }

  return (
    <>
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen={fullScreen}
        fullWidth
        maxWidth='xs'
        onClose={props.close}
        open={props.open}
        sx={{
          justifyContent: 'center',
        }}
      >
        <Stack width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            paddingTop={2}
            paddingX={2}
            width={1}
          >
            <Stack alignItems='center' direction='row' spacing={0.5} width={1}>
              <AutoAwesomeRoundedIcon
                fontSize='medium'
                sx={{
                  color: 'rgba(186,120,255,1)',
                }}
              />
              <Typography
                lineHeight={1.2}
                sx={{
                  background: 'linear-gradient(90deg, rgba(186,120,255,1) 25%, rgba(255,136,228,1) 75%)',
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
                variant='h3'
              >
                AI Generate Artwork
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <IconButton onClick={() => props.close()}>
                <CloseRoundedIcon color='disabled' />
              </IconButton>
            </Stack>
          </Stack>
          <Stack paddingBottom={2} paddingX={2} width={1}>
            <Typography color={themeItem.palette.text.secondary} variant='body2'>
              Generate artwork from a simple text query
            </Typography>
          </Stack>
          {errorSaving &&
          <Stack paddingBottom={2} paddingX={2} width={1}>
            <Alert severity='error' variant='filled'>
              Content filters have blocked your request
            </Alert>
          </Stack>}

          {currentOrganisation?.membershipTier === 'FREE' &&
          <Stack paddingBottom={2} paddingX={2} width={1}>
            <Card
              elevation={0}
              sx={{
                background: theme => theme.palette.background.elevatedCard,
                padding: 2,
              }}
            >
              <Stack alignItems='center' spacing={2} width={1}>
                <AutoAwesomeRoundedIcon
                  color='success'
                  sx={{
                    width: 64,
                    height: 64,
                    color: '#FFA500',
                  }}
                />
                <Stack alignItems='center' width={1}>
                  <Typography
                    color={themeItem.palette.text.primary}
                    sx={{
                      width: 1,
                    }}
                    textAlign='center'
                    variant='h3'
                  >
                    Supercharge your productivity
                  </Typography>
                  <Typography
                    color={themeItem.palette.text.secondary}
                    sx={{
                      width: 1,
                    }}
                    textAlign='center'
                    variant='body2'
                  >
                    Leverage the power of Releese AI to generate marketing plans, artwork, lyrics, and get your own
                    AI manager today
                  </Typography>
                </Stack>
                <Button
                  color='success'
                  onClick={() => setBillingModalOpen(true)}
                  sx={{
                    color: 'white!important',
                    background: '#FFA500',
                    ':hover': {
                      background: '#c47f00',
                    },
                  }}
                >
                  Upgrade now
                </Button>
              </Stack>
            </Card>
          </Stack>}

          <Stack paddingBottom={2} paddingX={2} width={1}>
            <Stack alignItems='center' direction='row' spacing={1} width={1}>
              <TextField
                autoComplete='new-password'
                disabled={!currentOrganisation || currentOrganisation.membershipTier === 'FREE'}
                fullWidth
                minRows={5}
                multiline
                onChange={event => setPrompt(event.target.value)}
                placeholder='A cool music artwork over a black background'
                sx={{ marginTop: 0 }}
                value={prompt}
              />
            </Stack>

            <Stack direction='row' justifyContent='flex-end' paddingTop={2} spacing={1} width={1}>
              <Button onClick={props.close} variant='text'>
                Cancel
              </Button>
              <LoadingButton
                disabled={!currentOrganisation ||
                prompt.length === 0 ||
                currentOrganisation.membershipTier === 'FREE'}
                loading={saving}
                onClick={save}
                sx={{
                  background: 'linear-gradient(90deg, rgba(186,120,255,1) 25%, rgba(255,136,228,1) 75%, rgba(186,120,255,1) 100%)',
                  backgroundSize: '250% auto',
                  transition: 'all 0.25s ease',
                  '&:hover': {
                    backgroundPosition: '25% center',
                  },
                }}
                variant='contained'
              >
                Generate artwork
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
    </>
  )
}

export default GenerateArtworkModal
