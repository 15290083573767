import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, AvatarGroup, Divider, IconButton, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'

import AccountListItem from 'src/components/pages/Messages/InformationPanel/AccountListItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useMessenger } from 'src/components/providers/MessengerProvider'
import TeamAvatar from 'src/components/TeamAvatar'

type Props = {
  handleInformationPanel: () => void
}

const InformationPanel = (props: Props) => {
  const { currentAccount, currentOrganisation } = useAuth()
  const { currentConversation } = useMessenger()
  const otherOrganisations = currentConversation?.participants?.filter(participant =>
    participant.id !== currentOrganisation?.id)

  const otherAccounts = currentConversation?.accounts?.filter(participant =>
    participant.id !== currentAccount.id)

  return (
    <Stack height={1} sx={{ flexShrink: 0 }} width={360}>
      <Stack alignItems='center' direction='row' justifyContent='space-between' padding={2} spacing={1}>
        <Typography variant='h3'>
          Messages
        </Typography>
        <IconButton onClick={props.handleInformationPanel} size='small'>
          <CloseRoundedIcon />
        </IconButton>
      </Stack>
      <Stack alignItems='center' height={1} overflow='auto' paddingX={2} spacing={2} width={1}>
        {(currentConversation?.type === 'Organisation' ||
        currentConversation?.type === 'Project') &&
        <Stack alignItems='center' spacing={2} width={1}>
          {currentConversation.type === 'Organisation' &&
          <Stack alignItems='center' width={1}>
            <AvatarGroup max={4}>
              {otherOrganisations?.map(organisation =>
                <Avatar
                  key={organisation.id}
                  src={organisation.logoUrl}
                />)}
            </AvatarGroup>
            <Typography variant='h4'>
              {currentConversation.title
                ? currentConversation.title
                : otherOrganisations?.map(org => org.name).join(', ')}
            </Typography>
            <Typography color='text.secondary' variant='body2'>
              {currentConversation.participants?.length === 1
                ? '1 Team'
                : `${currentConversation.participants?.length} Teams`}
            </Typography>
          </Stack>}
          {currentConversation.type === 'Project' &&
          currentConversation.project?._id &&
          <Stack alignItems='center' width={1}>
            <Avatar
              src={currentConversation.project._id.artworkPreviewUrl}
              variant='rounded'
            >
              <MusicNoteRoundedIcon />
            </Avatar>
            <Typography variant='h4'>
              {currentConversation.project._id.title}
            </Typography>
            <Typography color='text.secondary' variant='body2'>
              {currentConversation.participants?.length === 1
                ? '1 Team'
                : `${currentConversation.participants?.length} Teams`}
            </Typography>
          </Stack>}
          <Divider sx={{ width: 1 }} />
          {currentConversation.participants?.map(organisation =>
            <Stack
              key={organisation.id}
              width={1}
            >
              <ListItem>
                <ListItemAvatar>
                  <TeamAvatar
                    organisation={organisation}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography lineHeight={1} variant='h4'>
                    {organisation.name}
                  </Typography>}
                  secondary='Team'
                />
              </ListItem>
              {organisation.seats.map(seat =>
                <Stack key={seat.account.id} width={1}>
                  <AccountListItem
                    account={seat.account}
                  />
                </Stack>)}
              <Divider sx={{ paddingTop: 2, width: 1 }} />
            </Stack>)}
        </Stack>}
        {currentConversation?.type === 'Account' &&
        <Stack alignItems='center' spacing={2} width={1}>
          <Stack alignItems='center' width={1}>
            <AvatarGroup max={4}>
              {otherAccounts?.map(account =>
                <Avatar
                  key={account.id}
                  src={account.profilePictureUrl}
                />)}
            </AvatarGroup>
            <Typography variant='h4'>
              {currentConversation.title
                ? currentConversation.title
                : otherAccounts?.map(account => `${account.firstName} ${account.lastName}`).join(', ')}
            </Typography>
            {otherAccounts && otherAccounts.length > 1 &&
            <Typography variant='helperText'>
              {`${currentConversation.accounts?.length} members`}
            </Typography>}
          </Stack>
          <Divider sx={{ width: 1 }} />
          <Stack width={1}>
            {otherAccounts && otherAccounts.length > 1 &&
          otherAccounts.map(account =>
            <Stack key={account.id} width={1}>
              <AccountListItem
                account={account}
              />
            </Stack>)}
          </Stack>
          {otherAccounts && otherAccounts.length === 1 &&
            <Stack direction='column' key={otherAccounts[0].id} spacing={2} width={1}>
              <Stack direction='row' justifyContent='space-between' width={1}>
                <Typography color='text.label' variant='body2'>
                  Full name
                </Typography>
                <Typography variant='body2'>
                  {`${otherAccounts[0].firstName} ${otherAccounts[0].lastName}`}
                </Typography>
              </Stack>
              <Stack direction='row' justifyContent='space-between' width={1}>
                <Typography color='text.label' variant='body2'>
                  Phone number
                </Typography>
                <Typography variant='body2'>
                  {otherAccounts[0].phone.phoneNumber}
                </Typography>
              </Stack>
              <Stack direction='row' justifyContent='space-between' width={1}>
                <Typography color='text.label' variant='body2'>
                  Timezone
                </Typography>
                <Typography variant='body2'>
                  {otherAccounts[0].timezone}
                </Typography>
              </Stack>
              <Stack direction='row' justifyContent='space-between' width={1}>
                <Typography color='text.label' variant='body2'>
                  Region
                </Typography>
                <Typography variant='body2'>
                  {otherAccounts[0].region}
                </Typography>
              </Stack>
              <Stack direction='row' justifyContent='space-between' width={1}>
                <Typography color='text.label' variant='body2'>
                  Country
                </Typography>
                <Typography variant='body2'>
                  {otherAccounts[0].country}
                </Typography>
              </Stack>
            </Stack>}
        </Stack>}
      </Stack>
    </Stack>)
}

export default InformationPanel
