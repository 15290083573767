import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import { Button, Menu, Paper, Stack, Typography } from '@mui/material'
import type { DateRange } from 'mui-daterange-picker'
import { useEffect, useState } from 'react'

import { getAllClicksPerBrowserLinkProject, getAllClicksPerOsLinkProject, getAllViewsPerBrowserLinkProject, getAllViewsPerOsLinkProject, getClicksLinkProject, getClicksPerCityLinkProject, getClicksPerCountryLinkProject, getClicksPerSourceLinkProject, getDestinationsLinkProject, getDestinationsPresavesLinkProject, getPresavesLinkProject, getViewsLinkProject, getViewsPerCityLinkProject, getViewsPerCountryLinkProject, getViewsPerSourceLinkProject } from 'src/api/allLinkProjectAnalytics'
import SelectServices from 'src/components/pages/Links/ViewLink/ViewLinkComponents/TimePeriodSelect/SelectService'
import TimeSelectAnalytics from 'src/components/pages/Links/ViewLink/ViewLinkComponents/TimePeriodSelect/TimeSelect'
import ViewLinkDevices from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkDevices'
import ViewLinkLocations from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkLocations'
import ViewLinkOverview from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkOverview'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { MarketingAnalyticsDates, MarketingAnalyticsString } from 'src/models/Marketing'

type Props = {
  readonly projectId: string
}

const LinkAnalyticsProject = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [clicks, setClicks] = useState<MarketingAnalyticsDates[]>([])
  const [views, setViews] = useState<MarketingAnalyticsDates[]>([])
  const [presaves, setPresaves] = useState<MarketingAnalyticsDates[]>([])
  const [destinations, setDestinations] = useState<MarketingAnalyticsString[]>([])
  const [destinationsPresaves, setDestinationsPresaves] = useState<MarketingAnalyticsString[]>([])
  const [sourcesClicks, setSourcesClicks] = useState<MarketingAnalyticsString[]>([])
  const [sourcesViews, setSourcesViews] = useState<MarketingAnalyticsString[]>([])
  const [countryClicks, setCountryClicks] = useState<MarketingAnalyticsString[]>([])
  const [countryViews, setCountryViews] = useState<MarketingAnalyticsString[]>([])
  const [cityClicks, setCityClicks] = useState<MarketingAnalyticsString[]>([])
  const [cityViews, setCityViews] = useState<MarketingAnalyticsString[]>([])
  const [browserClicks, setBrowserClicks] = useState<MarketingAnalyticsString[]>([])
  const [browserViews, setBrowserViews] = useState<MarketingAnalyticsString[]>([])
  const [osClicks, setOsClicks] = useState<MarketingAnalyticsString[]>([])
  const [osViews, setOsViews] = useState<MarketingAnalyticsString[]>([])

  const [loadingOverview, setLoadingOverview] = useState(false)
  const [loadingLocation, setLoadingLocation] = useState(false)
  const [loadingDevices, setLoadingDevices] = useState(false)

  const currentDate = new Date()
  const lastSevenDays = new Date()
  lastSevenDays.setDate(currentDate.getDate() - 7)
  const lastTwentyEightDays = new Date()
  lastTwentyEightDays.setDate(currentDate.getDate() - 28)

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: lastTwentyEightDays,
    endDate: currentDate,
  })

  const [services, setServices] = useState<string[]>([])

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      setLoadingOverview(true)
      setLoadingLocation(true)
      setLoadingDevices(true)

      // Overview
      void Promise.all([
        getClicksLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
          .then(setClicks),
        getViewsLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
          .then(setViews),
        getPresavesLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
          .then(setPresaves),
        getDestinationsLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
          .then(setDestinations),
        getDestinationsPresavesLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
          .then(setDestinationsPresaves),
        getViewsPerSourceLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
          .then(setSourcesViews),
        getClicksPerSourceLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
          .then(setSourcesClicks),
      ]).then(() => setLoadingOverview(false))

      // Locations
      void Promise.all([
        getClicksPerCityLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
          .then(setCityClicks),
        getViewsPerCityLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
          .then(setCityViews),
        getClicksPerCountryLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
          .then(setCountryClicks),
        getViewsPerCountryLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
          .then(setCountryViews),
      ]).then(() => setLoadingLocation(false))

      // Devices
      void Promise.all([
        getAllClicksPerBrowserLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
          .then(setBrowserClicks),
        getAllViewsPerBrowserLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
          .then(setBrowserViews),
        getAllClicksPerOsLinkProject(dateRange.startDate, dateRange.endDate, services, props.projectId)
          .then(setOsClicks),
        getAllViewsPerOsLinkProject(dateRange.startDate, dateRange.endDate, props.projectId)
          .then(setOsViews),
      ]).then(() => setLoadingDevices(false))
    }
  }, [currentOrganisation?.id, dateRange.startDate, dateRange.endDate, services, props.projectId])

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorElement)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  return (
    <>
      <Menu
        anchorEl={anchorElement}
        onClose={handleClose}
        open={open}
      >
        <Stack
          alignItems='flex-start'
          direction='column'
          padding={1.5}
          spacing={4}
          width={1}
        >
          <Typography lineHeight={1.2} textAlign='left' variant='h3'>
            Filters
          </Typography>
          <Stack marginTop='-8px!important'>
            <TimeSelectAnalytics
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </Stack>
          <SelectServices
            destinations={destinations.map(item => item._id)}
            services={services}
            setServices={setServices}
          />
        </Stack>
      </Menu>
      <Paper
        sx={{
          width: 1,
          height: 1,
          overflow: 'hidden',
          borderRadius: 0,
          background: 'transparent',
        }}
      >
        <Stack
          alignItems='flex-start'
          direction='row'
          height={1}
          width={1}
        >
          <Stack
            alignItems='stretch'
            boxSizing='border-box'
            direction='column'
            height={1}
            minWidth={0}
            padding={0}
            width={1}
          >
            <Stack alignItems='center' height={1} overflow='auto' width={1}>
              <Stack
                marginLeft='auto'
                marginRight='auto'
                maxWidth={1332}
                paddingBottom={10}
                paddingTop={1}
                paddingX={2}
                spacing={2}
                width={1}
              >
                <Stack
                  alignItems='center'
                  direction='row'
                  paddingTop={1}
                  spacing={1}
                  width={1}
                >
                  <Button
                    color='inherit'
                    onClick={handleClick}
                    startIcon={<FilterListRoundedIcon />}
                    sx={{
                      width: 'fit-content',
                    }}
                    variant='outlined'
                  >
                    Filters
                  </Button>
                  <Button
                    color='secondary'
                    disabled={services.length === 0}
                    onClick={() => {
                      setServices([])
                      setDateRange({
                        startDate: lastTwentyEightDays,
                        endDate: currentDate,
                      })
                    }}
                    variant='text'
                  >
                    Clear all
                  </Button>
                </Stack>
                <ViewLinkOverview
                  clicks={clicks}
                  destinations={destinations}
                  destinationsPresaves={destinationsPresaves}
                  loading={loadingOverview}
                  presaves={presaves}
                  sourcesClicks={sourcesClicks}
                  sourcesViews={sourcesViews}
                  views={views}
                />
                <ViewLinkLocations
                  cityClicks={cityClicks}
                  cityViews={cityViews}
                  countryClicks={countryClicks}
                  countryViews={countryViews}
                  loading={loadingLocation}
                />
                <ViewLinkDevices
                  browserClicks={browserClicks}
                  browserViews={browserViews}
                  loading={loadingDevices}
                  osClicks={osClicks}
                  osViews={osViews}
                />

              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </>
  )
}

export default LinkAnalyticsProject
