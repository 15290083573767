/* eslint-disable sonarjs/no-identical-functions */
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import { Card, CardActionArea, Grid, Paper, Stack, Typography } from '@mui/material'

type Props = {
  transactionType: string
  setTransactionType: React.Dispatch<React.SetStateAction<string>>
  selected: string
}

const TransactionCard = (props: Props) =>
  <Grid
    item
    lg={3}
    md={3}
    sm={6}
    xl={3}
    xs={6}
  >
    <Card
      elevation={0}
      sx={{
        background: 'transparent',
        width: 1,
        borderColor: theme => props.transactionType === props.selected
          ? props.transactionType === 'Income'
            ? `${theme.palette.success.main}!important`
            : props.transactionType === 'Expense'
              ? `${theme.palette.error.main}!important`
              : props.transactionType === 'Payout'
                ? `${theme.palette.secondary.main}!important`
                : `${theme.palette.primary.main}!important`
          : 'transparent!important',
      }}
      variant='outlined'
    >
      <CardActionArea
        onClick={() => props.setTransactionType(props.transactionType)}
        sx={{
          padding: 2,
          height: 1,
        }}
      >
        <Stack alignItems='center' width={1}>
          <Paper
            elevation={0}
            sx={{
              borderRadius: 8,
              width: 100,
              height: 100,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              border: '1px solid',
              background: theme =>  props.transactionType === props.selected
                ? props.transactionType === 'Income'
                  ? theme.palette.success.main
                  : props.transactionType === 'Expense'
                    ? theme.palette.error.main
                    : props.transactionType === 'Payout'
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main
                : theme.palette.action.disabled,
              borderColor: theme =>  props.transactionType === props.selected
                ? props.transactionType === 'Income'
                  ? theme.palette.success.main
                  : props.transactionType === 'Expense'
                    ? theme.palette.error.main
                    : props.transactionType === 'Payout'
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main
                : theme.palette.action.disabled,
            }}
          >
            <AttachMoneyRoundedIcon
              sx={{
                fontSize: 64,
                color: theme => theme.palette.primary.contrastText,
              }}
            />
          </Paper>
          <Stack alignItems='center' paddingTop={2}>
            <Typography
              sx={{
                wordBreak: 'break-word',
              }}
              textAlign='center'
              variant='subtitle2'
            >
              {props.transactionType}
            </Typography>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  </Grid>

export default TransactionCard
