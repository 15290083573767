/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-lines */
/* eslint-disable no-restricted-imports */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
import { Autocomplete, Box, Button, Divider, FilledInput, IconButton, Menu, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import store from 'storejs'

import { getProjectArtists, getProjectTags } from 'src/api/projects'
import SearchField from 'src/components/form-elements/SearchField'
import NewProjectModal from 'src/components/pages/Projects/EditProject/NewProjectModal'
import ProjectsGrid from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/ProjectsGrid'
import ProjectsTable from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/ProjectsTable'
import CreateReleaseTour from 'src/components/pages/Tour/Projects/CreateRelease'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'
import { PROJECT_STATUS } from 'src/models/Project'
import { GENRES, RECORDING_VERSIONS } from 'src/models/Recording'

const ProjectsTab = () => {
  const { currentAccountPermissions } = useAuth()

  const viewStorage = store.get('projectView') as string | undefined
  const sortStorage = store.get('projectSort') as string | undefined
  const [display, setDisplay] = useState(viewStorage !== undefined ? viewStorage : 'grid')
  const [orderBy, setOrderBy] = useState(sortStorage !== undefined ? sortStorage : 'Most recent')

  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [format, setFormat] = useState('')
  const [status, setStatus] = useState('')
  const [genre, setGenre] = useState('')

  const [tags, setTags] = useState<string[]>([])
  const [filterTags, setFilterTags] = useState<string[]>([])

  const [artists, setArtists] = useState<string[]>([])
  const [filterArtists, setFilterArtists] = useState<string[]>([])

  useEffect(() => {
    store.set('projectView', display)
  }, [display])

  useEffect(() => {
    store.set('projectSort', orderBy)
  }, [orderBy])

  useEffect(() => {
    void getProjectTags()
      .then(setTags)
  }, [])

  useEffect(() => {
    void getProjectArtists()
      .then(setArtists)
  }, [])

  const [anchorElementFilter, setAnchorElementFilter] = useState<HTMLElement | null>(null)
  const openMenuFilter = Boolean(anchorElementFilter)
  const handleCloseFilter = () => {
    setAnchorElementFilter(null)
  }

  return (
    <>
      {currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS) &&
      newProjectModalOpen &&
      <NewProjectModal
        close={() => setNewProjectModalOpen(false)}
        open={newProjectModalOpen}
      />}
      <CreateReleaseTour />

      <Stack height={1} width={1}>
        <Stack
          direction='row'
          padding={2}
          sx={{
            overflowX: 'auto',
            overflowY: 'clip',
            flexShrink: 0,
          }}
          width={1}
        >
          <Stack direction='row' marginRight='auto' spacing={1}>
            <SearchField
              onChange={event => setSearchQuery(event.target.value)}
              value={searchQuery}
            />
          </Stack>
          <Stack alignItems='center' direction='row' marginLeft='auto' spacing={1}>
            <Stack direction='row' spacing={0.5}>
              <Tooltip title='Advanced filters'>
                <IconButton
                  color='default'
                  onClick={event => setAnchorElementFilter(event.currentTarget)}
                >
                  <FilterListRoundedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='List'>
                <IconButton
                  color={display === 'list' ? 'primary' : 'default'}
                  onClick={() => setDisplay('list')}
                >
                  <ViewListRoundedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Grid'>
                <IconButton
                  color={display === 'grid' ? 'primary' : 'default'}
                  onClick={() => setDisplay('grid')}
                >
                  <GridViewRoundedIcon />
                </IconButton>
              </Tooltip>
            </Stack>

            <Button
              disabled={!currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS)}
              id='create-release'
              onClick={() => setNewProjectModalOpen(true)}
              startIcon={<AddRoundedIcon />}
              sx={{
                marginLeft: 2,
                flexShrink: 0,
                width: 'fit-content',
              }}
            >
              Create Release
            </Button>
          </Stack>
        </Stack>
        <Divider sx={{ width: 1 }} />
        <Stack height={1} sx={{ overflow: 'hidden' }} width={1}>
          {display === 'list' &&
          <Stack height={1} width={1}>
            <ProjectsTable
              filterArtists={filterArtists}
              filterTags={filterTags}
              formatQuery={format}
              genreQuery={genre}
              orderBy={orderBy}
              searchQuery={searchQuery}
              statusQuery={status}
            />
          </Stack>}
          {display === 'grid' &&
          <Stack height={1} width={1}>
            <ProjectsGrid
              filterArtists={filterArtists}
              filterTags={filterTags}
              formatQuery={format}
              genreQuery={genre}
              orderBy={orderBy}
              searchQuery={searchQuery}
              statusQuery={status}
            />
          </Stack>}
        </Stack>
      </Stack>

      <Menu
        anchorEl={anchorElementFilter}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleCloseFilter}
        open={openMenuFilter}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Stack
          overflow='auto'
          padding={2}
          spacing={1}
          width={360}
        >
          <Typography lineHeight={1.2} variant='h3'>
            Filters
          </Typography>
          <Stack direction='column' width={1}>
            <Stack direction='column' spacing={0.5} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                Order by
              </Typography>
              <Select
                displayEmpty
                fullWidth
                id='orderby-filter'
                input={<FilledInput />}
                onChange={event => setOrderBy(event.target.value)}
                renderValue={selected =>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
                    <Stack alignItems='center' direction='row' key={selected} spacing={1}>
                      <Typography>{selected.length === 0 ? 'All' : selected}</Typography>
                    </Stack>
                  </Box>}
                value={orderBy}
              >
                <MenuItem
                  key='Most recent'
                  value='Most recent'
                >
                  Most recent
                </MenuItem>
                <MenuItem
                  key='Oldest'
                  value='Oldest'
                >
                  Oldest
                </MenuItem>
                <MenuItem
                  key='Newest release date'
                  value='Newest release date'
                >
                  Newest release date
                </MenuItem>
                <MenuItem
                  key='Oldest release date'
                  value='Oldest release date'
                >
                  Oldest release date
                </MenuItem>
              </Select>
            </Stack>
          </Stack>
          <Stack direction='column' spacing={0.5} width={1}>
            <Typography
              sx={{
                marginTop: 0,
                width: 100,
                flexShrink: 0,
              }}
              variant='inputLabel'
            >
              Artists
            </Typography>
            <Autocomplete
              fullWidth
              multiple
              onChange={(event, value) => setFilterArtists(value)}
              options={artists}
              renderInput={params =>
                <TextField
                  {...params}
                  fullWidth
                  placeholder='Select artists to filter'
                  sx={{
                    marginTop: 0,
                  }}
                />}
              value={filterArtists}
            />
          </Stack>
          <Stack direction='column' spacing={0.5} width={1}>
            <Typography
              sx={{
                marginTop: 0,
                width: 100,
                flexShrink: 0,
              }}
              variant='inputLabel'
            >
              Tags
            </Typography>
            <Autocomplete
              fullWidth
              multiple
              onChange={(event, value) => setFilterTags(value)}
              options={tags}
              renderInput={params =>
                <TextField
                  {...params}
                  fullWidth
                  placeholder='Select tags to filter'
                  sx={{
                    marginTop: 0,
                  }}
                />}
              value={filterTags}
            />
          </Stack>
          <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
            <Typography
              sx={{
                marginTop: 0,
                width: 100,
                flexShrink: 0,
              }}
              variant='inputLabel'
            >
              Status
            </Typography>
            <Select
              displayEmpty
              fullWidth
              id='version-filter'
              input={<FilledInput />}
              onChange={event => setStatus(event.target.value)}
              renderValue={selected =>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
                  <Stack alignItems='center' direction='row' key={selected} spacing={1}>
                    <Typography>{selected.length === 0 ? 'All' : selected}</Typography>
                  </Stack>
                </Box>}
              value={status}
            >
              <MenuItem value=''>All</MenuItem>
              {PROJECT_STATUS.map(type =>
                <MenuItem key={type} value={type}>{type}</MenuItem>)}
            </Select>
          </Stack>

          <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
            <Typography
              sx={{
                marginTop: 0,
                width: 100,
                flexShrink: 0,
              }}
              variant='inputLabel'
            >
              Format
            </Typography>
            <Select
              displayEmpty
              fullWidth
              id='type-filter'
              input={<FilledInput />}
              onChange={event => setFormat(event.target.value)}
              renderValue={selected =>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
                  <Stack alignItems='center' direction='row' key={selected} spacing={1}>
                    <Typography>{selected.length === 0 ? 'All' : selected}</Typography>
                  </Stack>
                </Box>}
              value={format}
            >
              <MenuItem value=''>All</MenuItem>
              {RECORDING_VERSIONS.map(type =>
                <MenuItem key={type} value={type}>{type}</MenuItem>)}
            </Select>
          </Stack>

          <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
            <Typography
              sx={{
                marginTop: 0,
                width: 100,
                flexShrink: 0,
              }}
              variant='inputLabel'
            >
              Genre
            </Typography>
            <Select
              displayEmpty
              fullWidth
              id='genre-filter'
              input={<FilledInput />}
              onChange={event => setGenre(event.target.value)}
              renderValue={selected =>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
                  <Stack alignItems='center' direction='row' key={selected} spacing={1}>
                    <Typography>{selected.length === 0 ? 'All' : selected}</Typography>
                  </Stack>
                </Box>}
              value={genre}
            >
              <MenuItem value=''>All</MenuItem>
              {GENRES.map(type =>
                <MenuItem key={type} value={type}>{type}</MenuItem>)}
            </Select>
          </Stack>
        </Stack>
      </Menu>
    </>)
}

export default ProjectsTab
