/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { alpha, LinearProgress, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { Avatar, Card, ListItemText, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { useState } from 'react'

import ArtistModalSearch from 'src/components/pages/Audience/Components/Modals/ArtistModalSearch'
import type { ArtistItemSingle } from 'src/models/AnalyticsCloud'

type Props = {
  artist: ArtistItemSingle
  index: number
}

const TopArtistItemSearch = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const [openTrackModal, setOpenTrackModal] = useState(false)
  const artist = props.artist
  return (
    <>
      {props.artist.spotifyId &&
      openTrackModal &&
      <ArtistModalSearch
        artwork={props.artist.images?.[0].url ?? ''}
        close={() => setOpenTrackModal(false)}
        loading={false}
        open={openTrackModal}
        selectedArtist={props.artist.spotifyId}
        title={props.artist.name}
      />}
      <TableRow
        hover
        key={artist.spotifyId}
        onClick={() => setOpenTrackModal(true)}
        sx={{
          '.MuiTableCell-root': { borderBottomColor: 'divider', paddingY: 2 },
        }}
      >
        <TableCell width='160px'>
          <Stack alignItems='center' direction='row' spacing={1} width={1}>
            <Card
              elevation={0}
              sx={{
                borderRadius: 1,
                background: theme => alpha(theme.palette.text.primary, 0.16),
                width: 48,
              }}
            >
              <Stack
                alignItems='center'
                direction='row'
                height='32px'
                justifyContent='center'
                padding={0.75}
                spacing={0.25}
                width={1}
              >
                <Typography
                  lineHeight={1.2}
                  variant='h4'
                >
                  #
                  {props.index + 1}
                </Typography>
              </Stack>
            </Card>
            {props.artist.oldPosition !== -1
              ? <Card
                elevation={0}
                sx={{
                  borderRadius: 1,
                  width: 60,
                  background: theme => props.artist.oldPosition === props.index
                    ? alpha(theme.palette.text.primary, 0.16)
                    : props.artist.oldPosition > props.index
                      ? alpha(theme.palette.success.main, 0.16)
                      : alpha(theme.palette.error.main, 0.16),
                }}
              >
                <Stack
                  alignItems='center'
                  direction='row'
                  justifyContent='center'
                  padding={0.5}
                  spacing={0.25}
                  width={1}
                >
                  {props.artist.oldPosition === props.index &&
                  <HorizontalRuleRoundedIcon color='action' fontSize='medium' />}

                  {props.artist.oldPosition > props.index &&
                  <KeyboardArrowUpRoundedIcon color='success' fontSize='medium' />}

                  {props.artist.oldPosition < props.index &&
                  <KeyboardArrowDownRoundedIcon color='error' fontSize='medium' />}

                  <Typography
                    lineHeight={1.2}
                    sx={{
                      color: theme => theme.palette.success.contrastText,
                      minWidth: '16px',
                    }}
                    variant='h4'
                  >
                    {Math.abs(props.artist.oldPosition - props.index)}
                  </Typography>
                </Stack>
              </Card>
              : <Card
                elevation={0}
                sx={{
                  borderRadius: 1,
                  width: 60,
                  height: 32,
                  background: theme => alpha(theme.palette.success.main, 0.16),
                }}
              >
                <Stack
                  alignItems='center'
                  direction='row'
                  height={32}
                  justifyContent='center'
                  padding={0.75}
                  spacing={0.25}
                  width={1}
                >
                  <Typography
                    lineHeight={1.2}
                    sx={{
                      color: theme => theme.palette.success.contrastText,
                      minWidth: '16px',
                    }}
                    variant='h4'
                  >
                    New
                  </Typography>
                </Stack>
              </Card>}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack alignItems='center' direction='row' spacing={2} width={1}>
            <Avatar
              src={artist.images[0]?.url}
              sx={{
                height: 48,
                width: 48,
              }}
              variant='rounded'
            />
            <ListItemText
              primary={artist.name}
              secondary={`${new Intl.NumberFormat('en-US').format(artist.followers.total)} Followers`}
            />
          </Stack>
        </TableCell>
        {!mobile &&
        <>
          <TableCell sx={{ maxWidth: 200 }}>
            <Stack alignItems='center' direction='row' maxWidth={200}>
              <ListItemText
                secondary={artist.genres.slice(0, 3).join(', ')}
                sx={{
                  textTransform: 'capitalize',
                }}
              />
            </Stack>
          </TableCell>
          <TableCell sx={{ maxWidth: 120 }}>
            <Stack alignItems='center' direction='row' maxWidth={120}>
              <Tooltip title={`Popularity ${artist.popularity}%`}>
                <LinearProgress
                  sx={{
                    height: '16px',
                    borderRadius: 1,
                    width: 120,
                  }}
                  value={artist.popularity}
                  variant='determinate'
                />
              </Tooltip>
            </Stack>
          </TableCell>
        </>}
      </TableRow>
    </>
  )
}

export default TopArtistItemSearch
