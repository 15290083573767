import { alpha, Box, Button, Card, CardActionArea, Divider, Stack, Typography, useTheme } from '@mui/material'

type Props = {
  readonly activeBilling: number
  readonly billingCycle: number
  readonly handleBilling: (step: number) => () => void
  readonly creator: number
  readonly month: number
  readonly disabled: boolean
  readonly seats: number
}

const CreatorCard = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Card
      sx={{
        borderColor: theme => props.activeBilling === props.creator
          ? `${theme.palette.primary.main}!important`
          : null,
        borderWidth: '2px',
      }}
      variant='outlined'
    >
      <CardActionArea
        disabled={!props.disabled || props.seats > 1}
        onClick={props.handleBilling(props.creator)}
        sx={{ height: 1 }}
      >
        {!props.disabled &&
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: 1,
            bgcolor: theme => alpha(theme.palette.action.disabled, 0.25),
            height: 1,
          }}
        />}
        <Stack alignItems='flex-start' direction='column' height={1} padding={2} spacing={2}>
          <Stack>
            <Typography fontWeight='normal' variant='h2'>
              Essential
            </Typography>
            <Stack alignItems='flex-start' direction='column' spacing={1}>
              <Typography fontFamily='Domaine-Display' lineHeight={1} variant='h1'>
                <span style={{ fontFamily: 'Matter' }}>
                  $
                </span>
                {props.billingCycle === props.month
                  ? props.billingCycle * props.creator
                  : (props.billingCycle * props.creator / 12).toFixed(2)}
              </Typography>
              {props.billingCycle === props.month
                ? <Typography variant='body1'>
                  / month
                </Typography>
                : <Typography variant='body1'>
                  / month, billed yearly
                </Typography>}
            </Stack>
          </Stack>
          <Typography
            color={themeItem.palette.text.secondary}
            variant='body2'
          >
            Tools to start managing your career from one place.
          </Typography>
          <Button
            color='primary'
            disabled={!props.disabled || props.activeBilling === props.creator || props.seats > 1}
            fullWidth
          >
            {props.activeBilling === props.creator
              ? 'Essential Selected'
              : 'Select Essential'}
          </Button>
          <Stack direction='column'>
            <Typography lineHeight={1.4} variant='h3'>
              Features
            </Typography>
            <Typography color={themeItem.palette.text.secondary} variant='body2'>
              Monthly payouts, instant withdrawals, and...
            </Typography>
          </Stack>
          <Divider sx={{ width: 1 }} />
          <Stack direction='column' spacing={2}>
            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Unlimited Distribution
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Smart Links, Bio Link, & Pre-save Subscriptions
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Publishing & Neighboring Rights Collection
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Audience, Playlists, UGC & Chart Tracking
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Editorial Pitching to Spotify, Apple & more
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Release Planning and Task Management
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default CreatorCard
