import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Box, Grid, ListItemText, Stack, Tooltip, Typography } from '@mui/material'

import TeamAvatar from 'src/components/TeamAvatar'
import type { RightsHolder } from 'src/models/Recording'

type Props = {
  rightsholder: RightsHolder
}

const ViewUserSplit = (props: Props) =>
  <Box
    border={1}
    borderColor={theme => theme.palette.stripeBg}
    borderRadius={theme => theme.shape.inputRadius}
    marginTop={3}
    padding={2}
    sx={{
      background: theme => theme.palette.stripeBg,
    }}
  >
    <Grid columns={16} container>
      <Grid item lg={2} md={2} sm={3} xl={2} xs={3}>
        <Stack height={1} justifyContent='center'>
          <Typography variant='h4'>
            {`${props.rightsholder.ownership}%`}
          </Typography>
        </Stack>
      </Grid>
      <Grid item lg={6} md={6} sm={10} xl={6} xs={10}>
        <Stack alignItems='center' direction='row' spacing={0.5} width={1}>
          {props.rightsholder.status === 'Locked' &&
            <Tooltip title='Confirmed share'>
              <CheckCircleRoundedIcon color='success' fontSize='small' />
            </Tooltip>}
          <Stack alignItems='center' direction='row' spacing={1.5} width={1}>
            <TeamAvatar
              organisation={props.rightsholder.account}
            />
            <Stack direction='column' width={1}>
              <ListItemText
                primary={props.rightsholder.account.name}
                secondary={props.rightsholder.account.organisationEmail}
              />
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  </Box>

export default ViewUserSplit
