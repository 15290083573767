/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable react/jsx-key */
/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable react-hooks/exhaustive-deps */
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import { Autocomplete, Avatar, Box, InputAdornment, ListItemText, Stack, TextField  } from '@mui/material'
import countries, { getName } from 'i18n-iso-countries'
import countryCodes from 'i18n-iso-countries/langs/en.json'

type Props = {
  countries: string[]
  setCountry: (value: React.SetStateAction<string>) => void
  country: string
}

const SearchCountryItem = (props: Props) => {
  countries.registerLocale(countryCodes)

  return (
    <Autocomplete
      autoHighlight
      autoSelect
      disableClearable
      getOptionLabel={option => getName(option, 'en') ?? 'Global'}
      onChange={(_, newValue) => props.setCountry(newValue ?? '')}
      options={props.countries}
      renderInput={params =>
        <TextField
          {...params}
          placeholder='Select a country'
          slotProps={{
            ...params,
            input: {
              ...params.InputProps,
              startAdornment:
                        <InputAdornment position='start'>
                          <Avatar
                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/
${props.country.toUpperCase()}.svg`}
                            sx={{
                              background: theme => theme.palette.info.main,
                              color: theme => `${theme.palette.info.contrastText}!important`,
                              height: 23,
                              width: 35,
                              borderRadius: '4px',
                            }}
                            variant='rounded'
                          >
                            <PublicRoundedIcon
                              sx={{ color: theme => `${theme.palette.info.contrastText}!important` }}
                            />
                          </Avatar>
                        </InputAdornment>
              ,
            },
          }}
          sx={{ marginTop: 0,  height: 38 }}
        />}
      renderOption={(renderProps, option) =>
        <Box component='li' {...renderProps}>
          <Stack alignItems='center' direction='row' spacing={1} width={1}>
            <Avatar
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/
${option.toUpperCase()}.svg`}
              sx={{
                background: theme => theme.palette.info.main,
                color: theme => `${theme.palette.info.contrastText}!important`,
                height: 23,
                width: 35,
                borderRadius: '4px',
              }}
              variant='rounded'
            >
              <PublicRoundedIcon />
            </Avatar>
            <ListItemText
              primary={getName(option, 'en') ?? 'Global'}
            />
          </Stack>
        </Box>}
      sx={{ marginTop: 0, height: 42, width: 240 }}
      value={props.country}
    />
  )
}

export default SearchCountryItem
