/* eslint-disable react-hooks/exhaustive-deps */
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import { Button, Menu, Paper, Stack, Typography } from '@mui/material'
import countries from 'i18n-iso-countries'
import data from 'i18n-iso-countries/langs/en.json'
import type { DateRange } from 'mui-daterange-picker'
import { useEffect, useState } from 'react'

import { getClicksPerBrowser, getClicksPerCity, getClicksPerCountry, getClicksPerId, getClicksPerOs, getClicksPerSource, getDestinations, getDestinationsPresaves, getFans, getPresavesPerId, getViewsPerBrowser, getViewsPerCity, getViewsPerCountry, getViewsPerId, getViewsPerOs, getViewsPerSource } from 'src/api/linksAnalytics'
import SelectServices from 'src/components/pages/Links/ViewLink/ViewLinkComponents/TimePeriodSelect/SelectService'
import TimeSelectAnalytics from 'src/components/pages/Links/ViewLink/ViewLinkComponents/TimePeriodSelect/TimeSelect'
import ViewLinkDevices from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkDevices'
import ViewLinkFans from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkFans'
import ViewLinkLocations from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkLocations'
import ViewLinkOverview from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkOverview'
import type { MarketingAnalyticsDates, MarketingAnalyticsString, MarketingLink, MarketingLinkFan } from 'src/models/Marketing'

type Props = {
  readonly link: MarketingLink
}

const LinkView = (props: Props) => {
  const [_, setLoading] = useState(false)
  const [fans, setFans] = useState<MarketingLinkFan[]>([])
  const [clicks, setClicks] = useState<MarketingAnalyticsDates[]>([])
  const [views, setViews] = useState<MarketingAnalyticsDates[]>([])
  const [presaves, setPresaves] = useState<MarketingAnalyticsDates[]>([])
  const [destinations, setDestinations] = useState<MarketingAnalyticsString[]>([])
  const [destinationsPresaves, setDestinationsPresaves] = useState<MarketingAnalyticsString[]>([])
  const [sourcesClicks, setSourcesClicks] = useState<MarketingAnalyticsString[]>([])
  const [sourcesViews, setSourcesViews] = useState<MarketingAnalyticsString[]>([])
  const [countryClicks, setCountryClicks] = useState<MarketingAnalyticsString[]>([])
  const [countryViews, setCountryViews] = useState<MarketingAnalyticsString[]>([])
  const [cityClicks, setCityClicks] = useState<MarketingAnalyticsString[]>([])
  const [cityViews, setCityViews] = useState<MarketingAnalyticsString[]>([])
  const [browserClicks, setBrowserClicks] = useState<MarketingAnalyticsString[]>([])
  const [browserViews, setBrowserViews] = useState<MarketingAnalyticsString[]>([])
  const [osClicks, setOsClicks] = useState<MarketingAnalyticsString[]>([])
  const [osViews, setOsViews] = useState<MarketingAnalyticsString[]>([])

  const [loadingOverview, setLoadingOverview] = useState(false)
  const [loadingLocation, setLoadingLocation] = useState(false)
  const [loadingDevices, setLoadingDevices] = useState(false)

  const currentDate = new Date()
  const lastSevenDays = new Date()
  lastSevenDays.setDate(currentDate.getDate() - 7)
  const lastTwentyEightDays = new Date()
  lastTwentyEightDays.setDate(currentDate.getDate() - 28)

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: lastTwentyEightDays,
    endDate: currentDate,
  })

  const [services, setServices] = useState<string[]>([])

  useEffect(() => {
    getLinkData()
  }, [props.link.id, dateRange.startDate, dateRange.endDate, services])

  const getLinkData = () => {
    if (props.link.id && dateRange.startDate && dateRange.endDate) {
      setLoadingOverview(true)
      setLoadingLocation(true)
      setLoadingDevices(true)

      // Overview
      void Promise.all([
        getClicksPerId(props.link.id, dateRange.startDate, dateRange.endDate, services)
          .then(setClicks),
        getViewsPerId(props.link.id, dateRange.startDate, dateRange.endDate)
          .then(setViews),
        getPresavesPerId(props.link.id, dateRange.startDate, dateRange.endDate)
          .then(setPresaves),
        getDestinations(props.link.id, dateRange.startDate, dateRange.endDate)
          .then(setDestinations),
        getDestinationsPresaves(props.link.id, dateRange.startDate, dateRange.endDate)
          .then(setDestinationsPresaves),
        getViewsPerSource(props.link.id, dateRange.startDate, dateRange.endDate)
          .then(setSourcesViews),
        getClicksPerSource(props.link.id, dateRange.startDate, dateRange.endDate, services)
          .then(setSourcesClicks),
      ]).then(() => setLoadingOverview(false))

      // Locations
      void Promise.all([
        getClicksPerCity(props.link.id, dateRange.startDate, dateRange.endDate, services)
          .then(setCityClicks),
        getViewsPerCity(props.link.id, dateRange.startDate, dateRange.endDate)
          .then(setCityViews),
        getClicksPerCountry(props.link.id, dateRange.startDate, dateRange.endDate, services)
          .then(setCountryClicks),
        getViewsPerCountry(props.link.id, dateRange.startDate, dateRange.endDate)
          .then(setCountryViews),
      ]).then(() => setLoadingLocation(false))

      // Devices
      void Promise.all([
        getClicksPerBrowser(props.link.id, dateRange.startDate, dateRange.endDate, services)
          .then(setBrowserClicks),
        getViewsPerBrowser(props.link.id, dateRange.startDate, dateRange.endDate)
          .then(setBrowserViews),
        getClicksPerOs(props.link.id, dateRange.startDate, dateRange.endDate, services)
          .then(setOsClicks),
        getViewsPerOs(props.link.id, dateRange.startDate, dateRange.endDate)
          .then(setOsViews),
      ]).then(() => setLoadingDevices(false))

      // Fans
      void getFans(props.link.id)
        .then(setFans)
        .finally(() => setLoading(false))
    }
  }

  countries.registerLocale(data)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorElement)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  return (
    <>
      <Menu
        anchorEl={anchorElement}
        onClose={handleClose}
        open={open}
      >
        <Stack
          alignItems='flex-start'
          direction='column'
          padding={1.5}
          spacing={4}
          width={1}
        >
          <Typography lineHeight={1.2} textAlign='left' variant='h3'>
            Filters
          </Typography>
          <Stack marginTop='-8px!important'>
            <TimeSelectAnalytics
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </Stack>
          <SelectServices
            destinations={destinations.map(item => item._id)}
            services={services}
            setServices={setServices}
          />
        </Stack>
      </Menu>
      <Stack
        sx={{
          height: 1,
          width: 1,
        }}
      >
        <Paper
          sx={{
            width: 1,
            height: 1,
            overflow: 'hidden',
          }}
          variant='outlined'
        >
          <Stack
            alignItems='flex-start'
            direction='row'
            height={1}
            width={1}
          >
            <Stack
              alignItems='stretch'
              boxSizing='border-box'
              direction='column'
              height={1}
              minWidth={0}
              padding={0}
              width={1}
            >
              <Stack alignItems='center' height={1} overflow='auto' width={1}>
                <Stack
                  marginLeft='auto'
                  marginRight='auto'
                  maxWidth={1332}
                  paddingBottom={10}
                  paddingTop={1}
                  paddingX={3}
                  spacing={2}
                  width={1}
                >
                  <Stack
                    alignItems='center'
                    direction='row'
                    paddingTop={2}
                    spacing={1}
                    width={1}
                  >
                    <Button
                      color='inherit'
                      onClick={handleClick}
                      startIcon={<FilterListRoundedIcon />}
                      sx={{
                        width: 'fit-content',
                      }}
                      variant='outlined'
                    >
                      Filters
                    </Button>
                    <Button
                      color='secondary'
                      disabled={services.length === 0}
                      onClick={() => {
                        setServices([])
                        setDateRange({
                          startDate: lastTwentyEightDays,
                          endDate: currentDate,
                        })
                      }}
                      variant='text'
                    >
                      Clear all
                    </Button>
                  </Stack>
                  <ViewLinkOverview
                    clicks={clicks}
                    destinations={destinations}
                    destinationsPresaves={destinationsPresaves}
                    loading={loadingOverview}
                    presaves={presaves}
                    sourcesClicks={sourcesClicks}
                    sourcesViews={sourcesViews}
                    views={views}
                  />
                  <ViewLinkLocations
                    cityClicks={cityClicks}
                    cityViews={cityViews}
                    countryClicks={countryClicks}
                    countryViews={countryViews}
                    loading={loadingLocation}
                  />
                  <ViewLinkDevices
                    browserClicks={browserClicks}
                    browserViews={browserViews}
                    loading={loadingDevices}
                    osClicks={osClicks}
                    osViews={osViews}
                  />
                  <ViewLinkFans
                    marketingLinkFans={fans}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </>
  )
}

export default LinkView
