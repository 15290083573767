/* eslint-disable sonarjs/max-switch-cases */
/* eslint-disable complexity */
import HeadphonesRoundedIcon from '@mui/icons-material/HeadphonesRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import ModeCommentRoundedIcon from '@mui/icons-material/ModeCommentRounded'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import PhoneAndroidRoundedIcon from '@mui/icons-material/PhoneAndroidRounded'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded'
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded'
import ShareIcon from '@mui/icons-material/Share'
import StarIcon from '@mui/icons-material/Star'
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded'
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded'

import ShazamIcon from 'src/components/Icons/ShazamIcon'

const GetIconNumberComponent = (LinkName: string) => {
  switch (true) {
    case LinkName.toLowerCase().includes('stream'):
      return <PlayArrowRoundedIcon sx={{ marginLeft: '-8px!important' }} />
    case LinkName.toLowerCase().includes('follower'):
      return <PeopleAltRoundedIcon />
    case LinkName.toLowerCase().includes('popularity'):
      return <TrendingUpRoundedIcon />
    case LinkName.toLowerCase().includes('playlist'):
      return <PlaylistAddCheckRoundedIcon />
    case LinkName.toLowerCase().includes('listener'):
      return <HeadphonesRoundedIcon />
    case LinkName.toLowerCase().includes('view'):
      return <PlayCircleOutlineRoundedIcon />
    case LinkName.toLowerCase().includes('comment'):
      return <ModeCommentRoundedIcon />
    case LinkName.toLowerCase().includes('like'):
      return <ThumbUpRoundedIcon />
    case LinkName.toLowerCase().includes('share'):
      return <ShareIcon />
    case LinkName.toLowerCase().includes('video'):
      return <OndemandVideoIcon />
    case LinkName.toLowerCase().includes('shazam'):
      return <ShazamIcon />
    case LinkName.toLowerCase().includes('favorite'):
      return <StarIcon />
    case LinkName.toLowerCase().includes('shorts'):
      return <PhoneAndroidRoundedIcon />
    default:
      return <LinkRoundedIcon />
  }
}

export default GetIconNumberComponent
