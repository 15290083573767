/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable switch-case/no-case-curly */
/* eslint-disable max-lines */
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import { Paper, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import _ from 'lodash'
import type { Key } from 'react'
import { useEffect, useState } from 'react'

import ActivityRowItem from 'src/components/pages/Audience/Components/Activity/ActivityRowItem'
import type { ActivityItem } from 'src/models/Analytics'

type Data = {
  source: string
  activity_text: string
  activity_type: string
  activity_date: string
  activity_url: string
  activity_avatar: string
  activity_tier: number
  title: string
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

const getComparator = (
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
  ) => number => order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy)

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((element, index) => [element, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(element => element[0])
}

type Props = {
  chartItems: ActivityItem[]
  overview?: boolean
  currentTab: string
  track?: boolean
  loading: boolean
}

const maxTitleLength = (arrayString: string[]) => {
  const artists = arrayString.slice(0, 3)

  return arrayString.length > 3
    ? `${artists.join(', ')}, + ${arrayString.length - 3} more`
    : artists.join(', ')
}

const ActivityRows = (props: Props) => {
  const themeItem = useTheme()

  useEffect(() => {
    console.log(props.currentTab)
  }, [props.currentTab])

  const rows =  props.overview
    ? props.chartItems.map(objectItem => ({
      source: objectItem.source,
      activity_text: objectItem.activity_text,
      activity_type: objectItem.activity_type,
      activity_date: objectItem.activity_date,
      activity_url: objectItem.activity_url ?? '',
      activity_avatar: objectItem.activity_avatar ?? '',
      title: objectItem.track_info?.title ?? '',
      artists: maxTitleLength(objectItem.track_info?.artists?.map(item => item.name) ?? ['No artists']),
      track_avatar: objectItem.track_info?.avatar  ?? '',
      songstats_track_id: objectItem?.track_info?.songstats_track_id ?? '',
    }))
    : props.chartItems.filter(item => item.source.toLowerCase() === props.currentTab.toLowerCase())
      .map(objectItem => ({
        source: objectItem.source,
        activity_text: objectItem.activity_text,
        activity_type: objectItem.activity_type,
        activity_date: objectItem.activity_date,
        activity_url: objectItem.activity_url ?? '',
        activity_avatar: objectItem.activity_avatar ?? '',
        title: objectItem.track_info?.title ?? '',
        artists: maxTitleLength(objectItem.track_info?.artists?.map(item => item.name) ?? ['No artists']),
        track_avatar: objectItem.track_info?.avatar ?? '',
        songstats_track_id: objectItem?.track_info?.songstats_track_id ?? '',
      }))

  const [order, _setOrder] = useState<Order>('desc')
  const [orderBy, _setOrderBy] = useState<keyof Data>('activity_date')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Box width={1}>
      {props.loading
        ? <Stack
          spacing={1}
          width='100%'
        >
          {Array.from({ length: 8 }, () =>
            <Skeleton height={110} sx={{ transform: 'none ' }} width='100%' />)}
        </Stack>
        : rows.length > 0
          ? <Stack sx={{ width: '100%', mb: 2 }}>
            <Typography variant='h3'>
              Recent activities
            </Typography>
            <Stack spacing={2} width={1}>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-tabl-activitye-checkbox-${index}`
                  return (
                    <ActivityRowItem
                      activity_avatar={row.activity_avatar}
                      activity_date={row.activity_date}
                      activity_text={row.activity_text}
                      activity_type={row.activity_type}
                      artists={row.artists}
                      key={labelId}
                      songstats_track_id={row.songstats_track_id}
                      source={row.source}
                      title={row.title}
                      track={props.track}
                      track_avatar={row.track_avatar}
                    />
                  )
                })}
            </Stack>
            <TablePagination
              component='div'
              count={rows.length}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
            />
          </Stack>
          : <Stack sx={{ width: '100%', mb: 2 }} width={1}>
            <Typography variant='h3'>
              Recent activities
            </Typography>
            <Stack
              alignItems='center'
              height={1}
              justifyContent='center'
              width='100%'
            >
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: theme => theme.palette.action.disabledBackground,
                  borderRadius: 16,
                }}
              >
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  padding={4}
                >
                  <AnalyticsRoundedIcon
                    sx={{
                      color: theme => theme.palette.action.disabled,
                      fontSize: '144px',
                    }}
                  />
                </Stack>
              </Paper>
              <Typography
                color={themeItem.palette.text.secondary}
                sx={{
                  marginTop: 1,
                }}
                variant='h4'
              >
                No recent activities to show
              </Typography>
              <Typography
                color={themeItem.palette.text.label}
                sx={{
                  textAlign: 'center',
                }}
                variant='body1'
              >
                Your milestones, playlist placements, and charts will appear here
              </Typography>
            </Stack>
          </Stack>}
    </Box>
  )
}

export default ActivityRows
