/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
/* eslint-disable id-length */
/* eslint-disable max-lines */
/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import NotificationAddRoundedIcon from '@mui/icons-material/NotificationAddRounded'
import { Avatar, Badge, Card, CardActionArea, Grid, IconButton, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import ReactDevicePreview from 'react-device-preview'
import { NavLink } from 'react-router-dom'

import { getSubscriberCountModern, getSubscriberRecent } from 'src/api/allLinkAnalytics'
import { getAnalyticsArtist, getAnalyticsArtistFree, getAnalyticsCurrentArtist, getAnalyticsCurrentArtistFree, getAnalyticsCurrentLabel, getAnalyticsLabel } from 'src/api/analyticsCloud'
import { createFirstBioLink, createMarketingLink, getBioLink } from 'src/api/links'
import FollowersDashboard from 'src/components/pages/Dashboard/Analytics/FollowersDashboard'
import StreamsDashboard from 'src/components/pages/Dashboard/Analytics/StreamsDashboard'
import ReleasesCard from 'src/components/pages/Dashboard/Releases/ReleasesCard'
import DashboardWelcomeMessage from 'src/components/pages/Dashboard/WelcomeAI/WelcomeMessage'
import EditLink from 'src/components/pages/Links/EditLink/EditLink'
import EditLinkRightTab from 'src/components/pages/Links/EditLink/EditLinkRightTab'
import NewProjectModal from 'src/components/pages/Projects/EditProject/NewProjectModal'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AnalyticsCurrentData, AnalyticsHistoricData } from 'src/models/Analytics'
import { MarketingLink } from 'src/models/Marketing'
import { Permissions } from 'src/models/Organisation'
import type { Subscriber } from 'src/models/Subscriber'
import darkTheme from 'src/styles/dark.theme'
import lightTheme from 'src/styles/light.theme'
import SPACING from 'src/styles/spacing'

type Props = {
  readonly currentTheme: string
}
const widthRightComponent = 360

const Dashboard = (props: Props) => {
  const { currentAccountPermissions, currentOrganisation, currentAccount } = useAuth()
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false)
  const [subscriberCount, setSubscriberCount] = useState<number>()
  const [recentSubs, setRecentSubs] = useState<Subscriber[]>([])

  const [linkData, setLinkData] = useState<MarketingLink>()
  const [editLinkModal, setEditLinkModal] = useState(false)
  const [biolinkLoading, setBiolinkLoading] = useState(false)

  const balance =
  (currentOrganisation?.balance ?? 0) < 0 &&
  (currentOrganisation?.balance ?? 0) > -0.1
    ? 0
    : currentOrganisation?.balance

  const activeTheme = linkData?.palette !== 'Dark'
    ? lightTheme
    : darkTheme

  const getBiolinkItem = async () => {
    setBiolinkLoading(true)
    await getBioLink()
      .then(setLinkData)
      .finally(() => setBiolinkLoading(false))
      .catch(() => setBiolinkLoading(false))
  }

  const getTotalSubscribers = async () => {
    await getSubscriberCountModern()
      .then(setSubscriberCount)
  }

  const getRecentSubs = async () => {
    await getSubscriberRecent()
      .then(setRecentSubs)
  }

  const clickBioLink = () => {
    if (!biolinkLoading && !linkData) {
      void createBioLink()
    } else if (!biolinkLoading) {
      setEditLinkModal(true)
    }
  }

  useEffect(() => {
    void getTotalSubscribers()
    void getRecentSubs()
  }, [currentOrganisation?.id])

  const createBioLink = async () => {
    if (currentOrganisation) {
      setBiolinkLoading(true)
      await createMarketingLink(new MarketingLink({
        type: 'Biolink',
        title: currentOrganisation.name,
        domain: '',
        descriptionText: 'This is my bio!',
        artworkUrl: currentOrganisation.logoUrl,
        useOrgDefaultSocialIcons: true,
        theme: 'Subtle',
        url: '',
        releaseDate: new Date(),
        bioLinkList: [
          {
            active: true,
            id: '12345',
            blockType: 'link',
            linkPrimaryText: 'Instagram',
            textContent: '',
            linkDestinationUrl: '',
            linkSecondaryText: '',
            videoEmbedUrl: '',
            imageUrl: '',
            socialIcons: [],
            header: false,
            embedHtml: '',
            icon: '',
          },
          {
            active: true,
            id: '123456',
            blockType: 'link',
            linkPrimaryText: 'TikTok',
            textContent: '',
            linkDestinationUrl: '',
            linkSecondaryText: '',
            videoEmbedUrl: '',
            imageUrl: '',
            socialIcons: [],
            header: false,
            embedHtml: '',
            icon: '',
          },
          {
            active: true,
            id: '123457',
            blockType: 'chat',
            linkPrimaryText: '',
            textContent: 'You can add more block types and embed content here!',
            linkDestinationUrl: '',
            linkSecondaryText: '',
            videoEmbedUrl: '',
            imageUrl: '',
            socialIcons: [],
            header: false,
            embedHtml: '',
            icon: '',
          },
        ],
        useDefaultPixels: currentOrganisation.membershipTier === 'CREATOR_PRO' ||
        currentOrganisation.membershipTier === 'CREATOR_PRO_YEARLY' ||
        currentOrganisation.membershipTier === 'ENTERPRISE' ||
        currentOrganisation.membershipTier === 'ENTERPRISE_YEARLY',
      }))
        .then(() => {
          void createFirstBioLink()
            .catch(() => null)
          void getBiolinkItem()
            .then(() => setEditLinkModal(true))
        })
        .finally(() => setBiolinkLoading(false))
    }
  }

  useEffect(() => {
    void getBiolinkItem()
  }, [currentOrganisation?.id])

  const [historicData, setHistoricData] = useState<AnalyticsHistoricData>()
  const [currentData, setCurrentData] = useState<AnalyticsCurrentData>()

  useEffect(() => {
    if (currentOrganisation?.analyticsOnboarded &&
      currentOrganisation.membershipTier !== 'FREE') {
      getData()
    } else {
      getDataFree()
    }
  }, [currentOrganisation])

  const getData = () => {
    if (currentOrganisation?.analyticsOnboarded &&
      currentOrganisation.analyticsType === 'artist' &&
      currentOrganisation.analyticsArtistId &&
      currentOrganisation.analyticsArtistId.length > 0) {
      void getAnalyticsArtist()
        .then(result =>
          setHistoricData(result))
        .catch(() => null)
      void getAnalyticsCurrentArtist()
        .then(resultCurrent =>
          setCurrentData(resultCurrent))
        .catch(() => null)
    } else if (currentOrganisation?.analyticsOnboarded &&
      currentOrganisation.analyticsType === 'label' &&
      currentOrganisation.analyticsLabelId &&
      currentOrganisation.analyticsLabelId.length > 0) {
      void getAnalyticsLabel()
        .then(result =>
          setHistoricData(result))
        .catch(() => null)
      void getAnalyticsCurrentLabel()
        .then(resultCurrent =>
          setCurrentData(resultCurrent))
        .catch(() => null)
    }
  }

  const getDataFree = () => {
    void getAnalyticsArtistFree()
      .then(result =>
        setHistoricData(result))
      .catch(() => null)
    void getAnalyticsCurrentArtistFree()
      .then(resultCurrent =>
        setCurrentData(resultCurrent))
  }

  return (
    <>
      <Stack
        alignItems='center'
        height={1}
        overflow='auto'
        paddingBottom={2}
        sx={{
          padding: SPACING.DASHBOARD_CONTOUR,
        }}
        width={1}
      >
        <Stack
          alignItems='center'
          spacing={2}
          sx={{
            paddingBottom: SPACING.RESPONSIVE_CONTOUR,
            paddingRight: SPACING.RESPONSIVE_CONTOUR,
          }}
          width={1}
        >
          <Stack direction={mobile ? 'column-reverse' : 'row'} spacing={2} width={1}>
            <Grid
              container
              height='fit-content'
              spacing={2}
              sx={{
                marginTop: '-16px!important',
                marginLeft: '-16px!important',
                width: mobile
                  ? undefined
                  : `calc(100% - ${widthRightComponent}px)`,
              }}
            >
              {!mobile &&
              <Grid item xs={12}>
                <DashboardWelcomeMessage
                  currentTheme={props.currentTheme}
                />
              </Grid>}

              {currentAccountPermissions?.includes(Permissions.VIEW_ANALYTICS) &&
              <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                <Card sx={{ boxShadow: 'none' }}>
                  <Stack direction='row' justifyContent='space-between' padding={2}>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <Stack>
                        <Typography lineHeight={1.2} variant='h3'>
                          Followers
                        </Typography>
                        <Typography
                          color={themeItem.palette.text.secondary}
                          lineHeight={1.2}
                          variant='body2'
                        >
                          All time
                        </Typography>
                      </Stack>
                    </Stack>

                    <IconButton component={NavLink} to='/analytics/overview'>
                      <ArrowForwardIosRoundedIcon />
                    </IconButton>
                  </Stack>
                  {currentData && historicData
                    ? <FollowersDashboard
                      currentData={currentData}
                      historicData={historicData}
                    />
                    : <Stack width={1}>
                      <Typography lineHeight={1.1} textAlign='center' variant='h2'>
                        <Skeleton sx={{ marginX: 'auto' }} width='140px' />
                      </Typography>

                      <Skeleton height='240px' variant='rounded' width='100%' />
                    </Stack>}
                </Card>
              </Grid>}

              {currentAccountPermissions?.includes(Permissions.VIEW_ANALYTICS) &&
              <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                <Card sx={{ boxShadow: 'none' }}>
                  <Stack direction='row' justifyContent='space-between' padding={2}>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <Stack>
                        <Typography lineHeight={1.2} variant='h3'>
                          Streams
                        </Typography>
                        <Typography
                          color={themeItem.palette.text.secondary}
                          lineHeight={1.2}
                          variant='body2'
                        >
                          All time
                        </Typography>
                      </Stack>
                    </Stack>
                    <IconButton component={NavLink} to='/analytics/overview'>
                      <ArrowForwardIosRoundedIcon />
                    </IconButton>
                  </Stack>
                  {currentData && historicData
                    ? <StreamsDashboard
                      currentData={currentData}
                      historicData={historicData}
                    />
                    : <Stack width={1}>
                      <Typography lineHeight={1.1} textAlign='center' variant='h2'>
                        <Skeleton sx={{ marginX: 'auto' }} width='140px' />
                      </Typography>

                      <Skeleton height='240px' variant='rounded' width='100%' />
                    </Stack>}
                </Card>
              </Grid>}

              {currentAccountPermissions?.includes(Permissions.VIEW_PROJECTS) &&
              <ReleasesCard />}
            </Grid>

            <Stack direction='column' spacing={2} width={mobile ? 1 : widthRightComponent}>
              {mobile &&
              <DashboardWelcomeMessage
                currentTheme={props.currentTheme}
              />}

              <Card sx={{ boxShadow: 'none' }}>
                <Stack padding={2} spacing={1}>
                  <Stack alignItems='center' direction='row' justifyContent='space-between'>
                    <Typography lineHeight={1.2} variant='h2'>
                      Subscribers
                    </Typography>
                    <IconButton component={NavLink} to='/analytics/subscribers'>
                      <ArrowForwardIosRoundedIcon />
                    </IconButton>
                  </Stack>

                  <Typography textAlign='center' variant='h1'>
                    <NotificationAddRoundedIcon sx={{ color: theme => theme.palette.primary.main }} />
                    {' '}
                    {subscriberCount && new Intl.NumberFormat('en-US').format(subscriberCount)}
                  </Typography>

                  <Card elevation={0} sx={{ background: theme => theme.palette.background.elevatedCard }}>
                    {recentSubs.length > 0
                      ? <Stack direction='row' padding={2} spacing={1} width={1}>
                        {recentSubs.map(item =>
                          <Stack key={item.id} spacing={1} width={1}>
                            <Stack alignItems='center' direction='column' justifyContent='center' spacing={2}>
                              <Badge
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                badgeContent={GetIcon(item.platform)}
                                color='success'
                                sx={{
                                  '.MuiBadge-badge': {
                                    background: GetColorLink(item.platform),
                                  },
                                }}
                              >
                                <Avatar src={item.image} />
                              </Badge>
                              <Typography color='text.secondary' textAlign='center' variant='body1'>
                                {item.name}
                              </Typography>
                            </Stack>
                          </Stack>)}
                      </Stack>
                      : <Stack direction='row' padding={2} spacing={1} width={1}>
                        <Typography color='text.secondary' textAlign='left' variant='body1'>
                          Subscribers will pre-save all your releases and get notified by email. Share your bio link
                          and release links on social media to gain more.
                        </Typography>
                      </Stack>}
                  </Card>
                </Stack>
              </Card>

              {currentAccountPermissions?.includes(Permissions.MANAGE_PAYOUTS) &&
              <Card sx={{ boxShadow: 'none' }}>
                <Stack padding={2} spacing={2}>
                  <Stack alignItems='center' direction='row' justifyContent='space-between'>
                    <Typography lineHeight={1.2} variant='h2'>
                      Balance
                    </Typography>
                    <IconButton component={NavLink} to='/wallet'>
                      <ArrowForwardIosRoundedIcon />
                    </IconButton>
                  </Stack>
                  <Card
                    elevation={0}
                    sx={{
                      background: 'linear-gradient(150deg, rgba(251,66,131,1) 0%, rgba(255,144,46,1) 91%)',
                      filter: (balance ?? 0) <= 0.01
                        ? 'grayscale(100%)'
                        : undefined,
                    }}
                  >
                    <Stack padding={2} spacing={2}>
                      <Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant='body3'>
                        RELEESE INNOVATIONS
                      </Typography>
                      <Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant='h2'>
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                          .format(balance ?? 0)}
                      </Typography>
                      <Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant='body3'>
                        {'Member since '}
                        {dayjs(currentAccount.createdAt).format('MMMM YYYY')}
                      </Typography>
                    </Stack>
                  </Card>
                </Stack>
              </Card>}

              {currentAccountPermissions?.includes(Permissions.VIEW_MARKETING_LINKS) &&
              <Card sx={{ boxShadow: 'none' }}>
                <CardActionArea onClick={event => {
                  event.stopPropagation()
                  event.preventDefault()
                  clickBioLink()
                }}
                >
                  <Stack
                    height={460}
                    padding={2}
                    spacing={2}
                    sx={{
                      pointerEvents: 'none',
                    }}
                  >
                    <Typography lineHeight={1.2} variant='h2'>
                      Biolink
                    </Typography>
                    <Stack
                      alignItems='flex-start'
                      direction='row'
                      flexShrink={0}
                      height='100%'
                      paddingBottom={3}
                      paddingX={2}
                      position='relative'
                      right={0}
                      sx={{
                        marginLeft: 'auto!important',
                        marginRight: 'auto!important',
                      }}
                      width={327}
                    >
                      <Stack
                        height={665}
                        width='100%'
                      >
                        <ReactDevicePreview
                          device='iphonex'
                          scale='0.7'
                        >
                          {biolinkLoading
                            ? <Stack
                              alignItems='center'
                              flexGrow={0}
                              flexShrink={0}
                              height={750}
                              position='relative'
                              sx={{
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                '::-webkit-scrollbar': {
                                  display: 'none',
                                },
                              }}
                              top={0}
                              width={1}
                            >
                              <Skeleton
                                sx={{
                                  width: '100%',
                                  height: 750,
                                  backgroundColor: theme => theme.palette.background.elevatedCard,
                                  position: 'relative',
                                  transform: 'none!important',
                                }}
                              />
                            </Stack>
                            : <>
                              {linkData?.theme !== 'Vibrant' && <Stack
                                sx={{
                                  position: 'absolute',
                                  height: '100%',
                                  width: '100%',
                                  background: `url(${linkData?.artworkUrl ?? currentOrganisation?.logoUrl})`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                  filter: 'blur(60px)',
                                  zIndex: 2,
                                  transform: 'scale(1.5)',
                                  opacity: 0.6,
                                  minWidth: 1920,
                                  left: 'calc(50% - 980px)',
                                  backgroundPositionX: 'center',
                                  backgroundPositionY: 'center',
                                  backgroundRepeat: 'no-repeat',
                                }}
                              />}
                              <Stack
                                alignItems='center'
                                flexGrow={0}
                                flexShrink={0}
                                height={750}
                                position='relative'
                                sx={{
                                  background: linkData?.theme !== 'Vibrant'
                                    ? activeTheme.palette.background.default
                                    : linkData?.backgroundColor,
                                  overflowY: 'auto',
                                  overflowX: 'hidden',
                                  '::-webkit-scrollbar': {
                                    display: 'none',
                                  },
                                }}
                                top={0}
                                width={1}
                              >
                                {currentOrganisation &&
                                <EditLinkRightTab
                                  artworkPreview={linkData?.artworkUrl ?? currentOrganisation.logoUrl}
                                  organisation={currentOrganisation}
                                  smartLinkData={linkData ?? new MarketingLink({
                                    title: currentOrganisation.name,
                                    descriptionText: 'This is my bio!',
                                    bioLinkList: [
                                      {
                                        active: true,
                                        id: '12345',
                                        blockType: 'link',
                                        linkPrimaryText: 'Instagram',
                                        textContent: '',
                                        linkDestinationUrl: '',
                                        linkSecondaryText: '',
                                        videoEmbedUrl: '',
                                        imageUrl: '',
                                        socialIcons: [],
                                        header: false,
                                        embedHtml: '',
                                        icon: '',
                                      },
                                      {
                                        active: true,
                                        id: '123456',
                                        blockType: 'link',
                                        linkPrimaryText: 'TikTok',
                                        textContent: '',
                                        linkDestinationUrl: '',
                                        linkSecondaryText: '',
                                        videoEmbedUrl: '',
                                        imageUrl: '',
                                        socialIcons: [],
                                        header: false,
                                        embedHtml: '',
                                        icon: '',
                                      },
                                      {
                                        active: true,
                                        id: '123457',
                                        blockType: 'chat',
                                        linkPrimaryText: '',
                                        textContent: 'You can add more block types and embed content here!',
                                        linkDestinationUrl: '',
                                        linkSecondaryText: '',
                                        videoEmbedUrl: '',
                                        imageUrl: '',
                                        socialIcons: [],
                                        header: false,
                                        embedHtml: '',
                                        icon: '',
                                      },
                                    ],
                                    artworkUrl: currentOrganisation.logoUrl,
                                    type: 'Biolink',
                                    theme: 'Subtle',
                                    palette: 'Light',
                                  })}
                                />}
                              </Stack>
                            </>}
                        </ReactDevicePreview>
                      </Stack>
                    </Stack>
                  </Stack>
                </CardActionArea>
              </Card>}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {newProjectModalOpen && currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS) &&
      <NewProjectModal
        close={() => setNewProjectModalOpen(false)}
        open={newProjectModalOpen}
      />}
      {editLinkModal && linkData &&
      <EditLink
        close={() => setEditLinkModal(false)}
        link={linkData}
        open={editLinkModal}
        tab={0}
        updateLinks={getBiolinkItem}
      />}
    </>
  )
}

export default Dashboard
