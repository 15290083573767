/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-duplicated-branches */
import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Skeleton, Stack, Typography } from '@mui/material'

import AnalyticsFavoriteArtist from 'src/components/pages/Audience/Components/Navigation/FavoriteArtistItem'
import GetIconSearch from 'src/components/pages/Audience/Components/Navigation/getIconSearch'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'

type Props = {
  readonly currentTab: string
  readonly setCurrentTab: (tab: string) => void
  readonly titlesDiscovery: string[]
  readonly titlesDistribution: string[]
  readonly titlesSmartLinks: string[]
  readonly compact?: boolean
  readonly logoUrl?: string
  readonly artistName?: string
  readonly loading?: boolean
  readonly noNav?: boolean
}

const AnalyticsSearchNav = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const menuItems = [
    'Overview',
    'Catalog',
    'Audience',
    'Playlists',
  ]
  return (
    <Stack
      direction='column'
      flexShrink={0}
      justifyContent='space-between'
      overflow='auto'
      width={280}
    >
      <Stack alignItems='center'>
        {!props.compact &&
        <List dense sx={{ width: 1, paddingTop: 2 }}>
          <ListItem dense disablePadding>
            <Stack
              alignItems='center'
              direction='row'
              paddingX={3}
              spacing={1}
              width={1}
            >
              <ListItemAvatar>
                {props.loading
                  ? <Skeleton sx={{ width: '48px', height: '48px' }} variant='circular' />
                  : <Avatar
                    src={currentOrganisation?.membershipTier === 'FREE'
                      ? currentOrganisation.logoUrl
                      : props.logoUrl}
                    sx={{
                      height: 48,
                      width: 48,
                    }}
                  />}
              </ListItemAvatar>
              {props.loading
                ? <Stack>
                  <Skeleton sx={{ width: '120px' }} variant='text' />
                  <Skeleton sx={{ width: '60px' }} variant='text' />
                </Stack>
                : <ListItemText
                  primary={currentOrganisation?.membershipTier === 'FREE'
                    ? currentOrganisation.name
                    : props.artistName}
                  secondary='Analytics'
                  sx={{
                    '.MuiListItemText-secondary': {
                      textTransform: 'capitalize',
                    },
                  }}
                />}
            </Stack>
          </ListItem>
        </List>}
        <List dense sx={{ width: 1 }}>
          {menuItems.map(item =>
            props.noNav
              ? <ListItem
                dense
                disablePadding
                key={`item-${item}`}
              >
                <ListItemButton
                  onClick={() => props.setCurrentTab(item)}
                  selected={props.currentTab.toLowerCase() === item.toLowerCase()}
                  sx={{ borderRadius: 0 }}
                >
                  <ListItemAvatar
                    sx={{
                      color: theme => props.currentTab.toLowerCase() === item.toLowerCase()
                        ? theme.palette.primary.main
                        : theme.palette.action.active,
                    }}
                  >
                    {GetIconSearch(item)}
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography variant='body2'>
                      {item}
                    </Typography>}
                    sx={{ wordBreak: 'break-word' }}
                  />
                </ListItemButton>
              </ListItem>
              : <ListItem
                component={NavLink}
                dense
                disablePadding
                key={`item-${item}`}
                to={`/analytics/${item.toLowerCase()}`}
              >
                <ListItemButton
                  selected={props.currentTab.toLowerCase() === item.toLowerCase()}
                  sx={{ borderRadius: 0 }}
                >
                  <ListItemAvatar
                    sx={{
                      color: theme => props.currentTab.toLowerCase() === item.toLowerCase()
                        ? theme.palette.primary.main
                        : theme.palette.action.active,
                    }}
                  >
                    {GetIconSearch(item)}
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography variant='body2'>
                      {item}
                    </Typography>}
                    sx={{ wordBreak: 'break-word' }}
                  />
                </ListItemButton>
              </ListItem>)}
        </List>

        {!props.compact &&
        <List dense id='project-tabs' sx={{ width: 1, paddingTop: 0 }}>
          <ListSubheader sx={{ position: 'relative', paddingLeft: '24px', paddingBottom: '8px', paddingTop: '8px' }}>
            <Stack flexDirection='row' justifyContent='space-between'>
              <Stack alignItems='flex-start' direction='row' spacing={1}>
                <Typography sx={{ wordBreak: 'break-word' }} variant='textLabel'>
                  Distribution
                </Typography>
              </Stack>
            </Stack>
          </ListSubheader>
          <Stack width={1}>
            {props.titlesDistribution.map(item =>
              <ListItem
                component={NavLink}
                dense
                disablePadding
                key={item}
                to={`/analytics/${item.toLowerCase()}`}
              >
                <ListItemButton
                  selected={props.currentTab.toLowerCase() === item.toLowerCase()}
                  sx={{ borderRadius: 0 }}
                >
                  <ListItemAvatar
                    sx={{
                      color: theme => props.currentTab.toLowerCase() === item.toLowerCase()
                        ? theme.palette.primary.main
                        : theme.palette.action.active,
                    }}
                  >
                    {GetIconSearch(item)}
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography variant='body2'>
                      {item}
                    </Typography>}
                    sx={{ wordBreak: 'break-word' }}
                  />
                </ListItemButton>
              </ListItem>)}
          </Stack>
        </List>}

        {!props.compact &&
        <List dense id='project-tabs' sx={{ width: 1, paddingTop: 0 }}>
          <ListSubheader sx={{ position: 'relative', paddingLeft: '24px', paddingBottom: '8px', paddingTop: '8px' }}>
            <Stack flexDirection='row' justifyContent='space-between'>
              <Stack alignItems='flex-start' direction='row' spacing={1}>
                <Typography sx={{ wordBreak: 'break-word' }} variant='textLabel'>
                  Smart Links
                </Typography>
              </Stack>
            </Stack>
          </ListSubheader>
          <Stack width={1}>
            {props.titlesSmartLinks.map(item =>
              <ListItem
                component={NavLink}
                dense
                disablePadding
                key={item}
                to={`/analytics/${item.toLowerCase()}`}
              >
                <ListItemButton
                  selected={props.currentTab.toLowerCase() === item.toLowerCase()}
                  sx={{ borderRadius: 0 }}
                >
                  <ListItemAvatar
                    sx={{
                      color: theme => props.currentTab.toLowerCase() === item.toLowerCase()
                        ? theme.palette.primary.main
                        : theme.palette.action.active,
                    }}
                  >
                    {GetIconSearch(item)}
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography variant='body2'>
                      {item}
                    </Typography>}
                    sx={{ wordBreak: 'break-word' }}
                  />
                </ListItemButton>
              </ListItem>)}
          </Stack>
        </List>}

        {!props.compact &&
        <List dense id='project-tabs' sx={{ width: 1, paddingTop: 0 }}>
          <ListSubheader sx={{ position: 'relative', paddingLeft: '24px', paddingBottom: '8px', paddingTop: '8px' }}>
            <Stack flexDirection='row' justifyContent='space-between'>
              <Stack alignItems='flex-start' direction='row' spacing={1}>
                <Typography sx={{ wordBreak: 'break-word' }} variant='textLabel'>
                  Discovery
                </Typography>
              </Stack>
            </Stack>
          </ListSubheader>
          <Stack width={1}>
            {props.titlesDiscovery.map(item =>
              <ListItem
                component={NavLink}
                dense
                disablePadding
                key={item}
                to={`/analytics/${item.toLowerCase()}`}
              >
                <ListItemButton
                  selected={props.currentTab.toLowerCase() === item.toLowerCase()}
                  sx={{ borderRadius: 0 }}
                >
                  <ListItemAvatar
                    sx={{
                      color: theme => props.currentTab.toLowerCase() === item.toLowerCase()
                        ? theme.palette.primary.main
                        : theme.palette.action.active,
                    }}
                  >
                    {GetIconSearch(item)}
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography variant='body2'>
                      {item}
                    </Typography>}
                    sx={{ wordBreak: 'break-word' }}
                  />
                </ListItemButton>
              </ListItem>)}
          </Stack>
        </List>}
        {!props.compact &&
        currentOrganisation?.favoriteArtistProfiles &&
        currentOrganisation.favoriteArtistProfiles.length > 0 &&
        <List dense id='favorites-tabs' sx={{ width: 1, paddingTop: 0 }}>
          <ListSubheader sx={{ position: 'relative', paddingLeft: '24px', paddingBottom: '8px', paddingTop: '8px' }}>
            <Stack flexDirection='row' justifyContent='space-between'>
              <Stack alignItems='flex-start' direction='row' spacing={1}>
                <Typography sx={{ wordBreak: 'break-word' }} variant='textLabel'>
                  Favorites
                </Typography>
              </Stack>
            </Stack>
          </ListSubheader>
          {currentOrganisation.favoriteArtistProfiles.map(item =>
            <AnalyticsFavoriteArtist
              artworkUrl={item.imageUrl}
              key={item.spotifyId}
              name={item.name}
              spotifyId={item.spotifyId}
            />)}
        </List>}
      </Stack>
    </Stack>
  )
}

export default AnalyticsSearchNav
