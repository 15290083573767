/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { TabContext, TabList } from '@mui/lab'
import { alpha, Button, Checkbox, FormControlLabel, FormGroup, IconButton, ListItemIcon, Menu, MenuItem, Stack, Tab, Table, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import { useState } from 'react'
import type { DraggableLocation, DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { v4 } from 'uuid'

import { savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import AddTaskboardCustomField, { fieldTypes } from 'src/components/pages/Task/TaskViews/TaskViewComponents/AddCustomFieldsModal'
import { getCustomFieldIcon } from 'src/components/pages/Task/TaskViews/TaskViewComponents/CustomFieldIcon'
import DeleteCustomFieldModalItem from 'src/components/pages/Task/TaskViews/TaskViewComponents/DeleteCustomField'
import EditCustomField from 'src/components/pages/Task/TaskViews/TaskViewComponents/EditCustomField'
import TaskCardEmpty from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskCardEmpty'
import TaskListContainer from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskListContainer'
import TaskSectionModal from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskSectionModal'
import RenderListKeys from 'src/components/pages/Task/TaskViews/TaskViewTypes/RenderListKeys'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Account from 'src/models/Account'
import type { TaskBoard, TaskGroup, TaskItem } from 'src/models/Taskboard'
import { CustomFields, HIDDEN_FIELDS, TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  readonly toggleTaskDrawer: (toggleState: boolean, taskGroup: TaskGroup, taskData?: TaskItem) => () => void
  readonly toggleTaskModal: (toggleState: boolean, taskGroup?: TaskGroup) => () => void
  readonly toggleEditTaskModal: (toggleState: boolean, taskGroup: TaskGroup) => () => void
  readonly toggleDeleteTaskModal: (toggleState: boolean, taskGroup: TaskGroup) => () => void
  readonly setSwitchTemplate: (value: React.SetStateAction<boolean>) => void
  readonly taskDataSet: TaskBoard
  readonly searchQuery: string
  readonly statusQuery: string
  readonly priorityQuery: string
  readonly members: Account[]
  readonly setRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultFieldOrder = [
  'Due date',
  'Assignee',
  'Priority',
  'Status',
  'Last Updated',
]

const reorder = (list: TaskItem[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const reorderGroups = (list: TaskGroup[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const reorderKeys = (list: string[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const ListView = (props: Props) => {
  const defaultViewItems = [
    ...defaultFieldOrder,
    ...props.taskDataSet.customFields.map(item => item.id),
  ]

  let fieldOrder = props.taskDataSet.fieldOrder.length !== defaultViewItems.length
    ? defaultViewItems
    : props.taskDataSet.fieldOrder

  const { currentAccount } = useAuth()
  const alphaValue = 0.95

  const [newSectionModal, setNewSectionModal] = useState(false)

  const toggleNewSectionModal = (toggleState: boolean) => () => {
    setNewSectionModal(toggleState)
  }

  const [anchorElementEmbed, setAnchorElementEmbed] = useState<HTMLElement | null>(null)
  const openMenuEmbed = Boolean(anchorElementEmbed)

  const [chooseType, setChooseType] = useState('')
  const [activeMenuTabField, setActiveMenuTabField] = useState('Add')
  const [anchorElementField, setAnchorElementField] = useState<HTMLButtonElement | null>(null)
  const openMenuField = Boolean(anchorElementField)

  const handleCloseField = () => {
    setAnchorElementField(null)
    setActiveMenuTabField('Add')
  }

  const [manageEmbedItem, setManageEmbed] = useState('')
  const [editEmbedModal, setEditEmbedModal] = useState(false)
  const [deleteEmbedModal, setDeleteEmbedModal] = useState(false)
  const [manageFields, setManageFields] = useState(false)

  const handleCloseEmbed = () => {
    setAnchorElementEmbed(null)
  }

  const move = (source: TaskItem[],
    destination: TaskItem[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation) => {
    const sourceClone = [...source]
    const destinationClone = [...destination]
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destinationClone.splice(droppableDestination.index, 0, removed)

    const result: TaskGroup[] = [...props.taskDataSet.taskGroups]

    const sourceId = props.taskDataSet.taskGroups.findIndex(board => board.id === droppableSource.droppableId)
    const destinationId = props.taskDataSet.taskGroups.findIndex(board => board.id === droppableDestination.droppableId)

    result[sourceId].tasks = sourceClone
    result[destinationId].tasks = destinationClone

    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    } else if (result.destination.droppableId && result.type === 'task') {
      const destinationId =
        props.taskDataSet.taskGroups.findIndex(board => board.id === result.destination!.droppableId)
      const sourceId = props.taskDataSet.taskGroups.findIndex(board => board.id === result.source.droppableId)

      if (sourceId === destinationId) {
        const items =
          reorder(props.taskDataSet.taskGroups[sourceId].tasks, result.source.index, result.destination.index)
        const newState = [...props.taskDataSet.taskGroups]
        newState[sourceId].tasks = items

        const newData = props.taskDataSet
        newData.taskGroups = newState

        void savePartialTaskboard({ id: props.taskDataSet.id ?? '', ...newData })
      } else {
        const newDataResult = move(props.taskDataSet.taskGroups[sourceId].tasks,
          props.taskDataSet.taskGroups[destinationId].tasks,
          result.source,
          result.destination)
        const newState = [...props.taskDataSet.taskGroups]
        newState[sourceId] = newDataResult[sourceId]
        newState[destinationId] = newDataResult[destinationId]

        const newData = props.taskDataSet
        newData.taskGroups = newState

        void savePartialTaskboard({ id: props.taskDataSet.id ?? '', ...newData })
      }

      const taskUpdateItem = new TaskBoardEdit({
        id: v4(),
        taskboardId: props.taskDataSet.id,
        taskId: '',
        accountId: currentAccount.id,
        modificationType: 'Reorder',
        itemType: 'Field',
        title: '',
      })
      sendTasksboardUpdate(taskUpdateItem)
    } else if (result.destination.droppableId && result.type === 'taskboard') {
      const items = reorderGroups(props.taskDataSet.taskGroups, result.source.index, result.destination.index)
      props.taskDataSet.taskGroups = items
      const newBoard = { ...props.taskDataSet, taskGroups: items } as TaskBoard
      void savePartialTaskboard({ id: props.taskDataSet.id ?? '', ...newBoard })

      const taskUpdateItem = new TaskBoardEdit({
        id: v4(),
        taskboardId: props.taskDataSet.id,
        taskId: '',
        accountId: currentAccount.id,
        modificationType: 'Reorder',
        itemType: 'Field',
        title: '',
      })
      sendTasksboardUpdate(taskUpdateItem)
    } else if (result.destination.droppableId && result.type === 'headerKeys') {
      const items = reorderKeys(fieldOrder, result.source.index, result.destination.index)
      fieldOrder = items
      props.taskDataSet.fieldOrder = items
      const newBoard = { ...props.taskDataSet, fieldOrder: items } as TaskBoard
      void savePartialTaskboard({ id: props.taskDataSet.id ?? '', ...newBoard })

      const taskUpdateItem = new TaskBoardEdit({
        id: v4(),
        taskboardId: props.taskDataSet.id,
        taskId: '',
        accountId: currentAccount.id,
        modificationType: 'Reorder',
        itemType: 'Field',
        title: '',
      })
      sendTasksboardUpdate(taskUpdateItem)
    }
  }

  return (
    <>
      {props.taskDataSet.taskGroups.length > 0 &&
      <DragDropContext onDragEnd={onDragEnd}>
        <Stack
          direction='column'
          justifyContent='flex-start'
          minWidth={1400 + (props.taskDataSet.customFields.length * 250)}
        >
          <Table
            aria-label='projects table'
            size='small'
            stickyHeader
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <TableHead
              sx={{
                '.MuiTableCell-root': {
                  borderBottom: 2,
                  borderBottomColor: 'divider',
                  color: 'text.secondary',
                  display: 'flex',
                  alignItems: 'center',
                },
                width: '100%',
                display: 'flex',
              }}
            >
              <Droppable
                direction='horizontal'
                droppableId='headerKeys'
                key='headerKeys'
                type='headerKeys'
              >
                {provided =>
                  <TableRow
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      width: '100%',
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 0,
                        zIndex: 2,
                        backgroundColor: theme => alpha(theme.palette.background.default, alphaValue),
                      }}
                      width='48px!important'
                    />
                    <TableCell
                      align='left'
                      sx={{
                        position: 'sticky',
                        zIndex: 2,
                        left: '46px',
                        width: '350px',
                        backgroundColor: theme => alpha(theme.palette.background.default, alphaValue),
                      }}
                    >
                      Title
                    </TableCell>

                    {fieldOrder.map((field, index) =>
                      <Draggable
                        draggableId={field}
                        index={index}
                        key={field}
                      >
                        {providedItem =>
                          <RenderListKeys
                            customFields={props.taskDataSet.customFields}
                            hiddenFields={props.taskDataSet.hiddenFields}
                            keyItem={field}
                            providedItem={providedItem}
                            setAnchorElementEmbed={setAnchorElementEmbed}
                            setManageEmbed={setManageEmbed}
                          />}
                      </Draggable>)}

                    {provided.placeholder}

                    <TableCell
                      align='left'
                      sx={{
                        zIndex: 0,
                        flexGrow: '1!important',
                      }}
                      width='150px'
                    >
                      <Tooltip title='Add custom field'>
                        <IconButton
                          onClick={event => {
                            setAnchorElementField(event.currentTarget)
                          }}
                          size='small'
                          sx={{
                            marginLeft: 1,
                            width: 32,
                            height: 32,
                          }}
                        >
                          <AddCircleOutlineRoundedIcon
                            sx={{
                              fontSize: 24,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>}
              </Droppable>
            </TableHead>
          </Table>
          <Droppable
            direction='vertical'
            droppableId={props.taskDataSet.id ?? ''}
            key={props.taskDataSet.id}
            type='taskboard'
          >
            {provided =>
              <Stack
                direction='column'
                justifyContent='flex-start'
                minWidth={1400 + (props.taskDataSet.customFields.length * 250)}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {props.taskDataSet.taskGroups.map((column, index) =>
                  <Draggable
                    draggableId={column.id}
                    index={index}
                    key={column.id}
                  >
                    {providedItem =>
                      <Stack
                        direction='column'
                        key={column.id}
                        ref={providedItem.innerRef}
                        width={1}
                        {...providedItem.draggableProps}
                      >
                        <TaskListContainer
                          column={column.title}
                          dragHandle={providedItem.dragHandleProps}
                          fieldOrder={fieldOrder}
                          id={`${column.id}`}
                          members={props.members}
                          priorityQuery={props.priorityQuery}
                          searchQuery={props.searchQuery}
                          statusQuery={props.statusQuery}
                          taskColumn={column}
                          taskboard={props.taskDataSet}
                          toggleDeleteTaskModal={props.toggleDeleteTaskModal}
                          toggleEditTaskModal={props.toggleEditTaskModal}
                          toggleTaskDrawer={props.toggleTaskDrawer}
                          toggleTaskModal={props.toggleTaskModal}
                        />
                      </Stack>}
                  </Draggable>)}
                {provided.placeholder}
              </Stack>}
          </Droppable>
          <Stack paddingX={2} paddingY={1} sx={{ position: 'sticky', left: 0 }} width='fit-content'>
            <Button
              color='secondary'
              onClick={toggleNewSectionModal(true)}
              sx={{ width: 'fit-content' }}
              variant='text'
            >
              + Add a section
            </Button>
          </Stack>
        </Stack>
      </DragDropContext>}

      {props.taskDataSet.taskGroups.length === 0 &&
      <TaskCardEmpty setRefresh={props.setRefresh} taskboard={props.taskDataSet} />}

      <Menu
        anchorEl={anchorElementEmbed}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleCloseEmbed}
        onClose={handleCloseEmbed}
        open={openMenuEmbed}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem onClick={() => setEditEmbedModal(true)}>
          <ListItemIcon>
            <EditRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Edit custom field
        </MenuItem>
        <MenuItem onClick={() => setDeleteEmbedModal(true)}>
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Delete custom field
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={anchorElementField}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleCloseField}
        open={openMenuField}
        sx={{
          '.MuiList-root': {
            paddingTop: 0,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Stack minWidth={300}>
          <TabContext value={activeMenuTabField}>
            <TabList
              aria-label='Current tab'
              onChange={(event, value: string) => setActiveMenuTabField(value)}
              onClick={event => event.stopPropagation()}
              sx={{
                minHeight: 48,
                width: 1,
                '.MuiTab-root':
                  {
                    textTransform: 'none',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    minHeight: 48,
                    width: 1,
                    borderRadius: 0,
                  },
              }}
              variant='fullWidth'
            >
              <Tab
                icon={<AddRoundedIcon />}
                label='Add field'
                value='Add'
              />
              <Tab
                icon={<VisibilityRoundedIcon />}
                label='Show/Hide'
                value='Edit'
              />
            </TabList>
          </TabContext>
          {activeMenuTabField === 'Add'
            ? <>
              {fieldTypes.map(type =>
                <MenuItem
                  key={type}
                  onClick={() => {
                    setChooseType(type)
                    setManageFields(true)
                    handleCloseField()
                  }}
                  sx={{
                    paddingY: 1,
                  }}
                >
                  <ListItemIcon>
                    {getCustomFieldIcon(type)}
                  </ListItemIcon>
                  {type}
                </MenuItem>)}
            </>
            : <FormGroup sx={{ paddingX: 2, paddingY: 1 }}>
              {HIDDEN_FIELDS.map(field =>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!props.taskDataSet.hiddenFields.includes(field)}
                      onChange={async () => {
                        if (props.taskDataSet.hiddenFields.includes(field)) {
                          props.taskDataSet.hiddenFields = props.taskDataSet.hiddenFields.filter(item => item !== field)
                          await savePartialTaskboard({ id: props.taskDataSet.id ?? '', ...props.taskDataSet })
                            .then(() => {
                              const taskUpdateItem = new TaskBoardEdit({
                                id: v4(),
                                taskboardId: props.taskDataSet.id,
                                taskId: '',
                                accountId: currentAccount.id,
                                // eslint-disable-next-line sonarjs/no-duplicate-string
                                modificationType: 'Changed hidden fields',
                                itemType: 'Field',
                                title: 'Changed hidden fields',
                              })
                              sendTasksboardUpdate(taskUpdateItem)
                            })
                        } else {
                          props.taskDataSet.hiddenFields.push(field)
                          await savePartialTaskboard({ id: props.taskDataSet.id ?? '', ...props.taskDataSet })
                            .then(() => {
                              const taskUpdateItem = new TaskBoardEdit({
                                id: v4(),
                                taskboardId: props.taskDataSet.id,
                                taskId: '',
                                accountId: currentAccount.id,
                                modificationType: 'Changed hidden fields',
                                itemType: 'Field',
                                title: 'Changed hidden fields',
                              })
                              sendTasksboardUpdate(taskUpdateItem)
                            })
                        }
                      }}
                      onClick={event => event.stopPropagation()}
                    />
                  }
                  key={field}
                  label={field}
                />)}
            </FormGroup>}
        </Stack>
      </Menu>

      {manageFields &&
      <AddTaskboardCustomField
        close={() => setManageFields(false)}
        open={manageFields}
        taskboard={props.taskDataSet}
        typeSelected={chooseType}
      />}
      {editEmbedModal &&
      <EditCustomField
        close={() => setEditEmbedModal(false)}
        field={props.taskDataSet.customFields.find(item => item.id === manageEmbedItem) ??
         new CustomFields({})}
        open={editEmbedModal}
        taskboard={props.taskDataSet}
      />}
      {deleteEmbedModal &&
      <DeleteCustomFieldModalItem
        close={() => setDeleteEmbedModal(false)}
        fieldId={manageEmbedItem}
        open={deleteEmbedModal}
        taskboard={props.taskDataSet}
      />}
      {newSectionModal &&
      <TaskSectionModal
        close={toggleNewSectionModal(false)}
        open={newSectionModal}
        taskboard={props.taskDataSet}
      />}
    </>
  )
}
export default ListView
