/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import { Card, CardActionArea, CircularProgress, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { v4 } from 'uuid'

import { baseUrlLinksApi } from 'src/api/links'
import DeleteFileModal from 'src/components/pages/Files/Components/DeleteFileModal'
import FileDrawer from 'src/components/pages/Files/Components/FileDrawer'
import FileDrawerComponent from 'src/components/pages/Files/Components/FileDrawerComponent'
import RenameFileModal from 'src/components/pages/Files/Components/RenameFileModal'
import ShareFileModal from 'src/components/pages/Files/Components/ShareModalFile'
import PreviewFile from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/FilesTab/PreviewFile'
import type { FileItem } from 'src/models/File'
import type Project from 'src/models/Project'

type Props = {
  file?: File
  fileItem?: FileItem
  done?: boolean
  link?: string
  setFiles: React.Dispatch<React.SetStateAction<FileItem[]>>
  location: string
  project?: Project
  taskboardId?: string
  taskId?: string
  index: number
  disableDrag?: boolean
  uploadingId?: string
}

export const renderIcon = (extension: string) => {
  switch (extension.toLowerCase()) {
    case 'jpg':
    case 'png':
    case 'jpeg':
    case 'gif':
    case 'webp':
    case 'bmp':
      return <ImageRoundedIcon color='secondary' fontSize='large' />
    case 'docx':
    case 'doc':
    case 'pdf':
    case 'xml':
    case 'rtf':
    case 'pages':
    case 'odt':
    case 'f2b':
      return <ArticleRoundedIcon color='success' fontSize='large' />
    case 'mp3':
    case 'wav':
    case 'mp2':
    case 'flac':
    case 'ogg':
    case 'aiff':
    case 'aac':
      return <MusicNoteRoundedIcon color='info' fontSize='large' />
    default:
      return <AttachFileIcon color='primary' fontSize='large' />
  }
}

const FileItemView = (props: Props) => {
  const [openImage, setImageOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [renameModal, setRenameModal] = useState(false)
  const [fileObject, setFileObject] = useState(props.fileItem)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  const handleClose = () => {
    setAnchorElement(null)
  }

  const clickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorElement(event.currentTarget)
  }

  const regexExtension = /\w{3,4}($|\?)/
  const fileExtension = regexExtension.exec(props.link ?? props.file?.name ?? '')

  const preventClick = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  return (
    <>
      {props.fileItem &&
      <DeleteFileModal
        close={() => setDeleteModal(false)}
        file={props.fileItem}
        open={deleteModal}
        setFiles={props.setFiles}
      />}
      {props.fileItem &&
      <RenameFileModal
        close={() => setRenameModal(false)}
        file={props.fileItem}
        open={renameModal}
        setFile={setFileObject}
        setFiles={props.setFiles}
      />}
      {props.fileItem &&
      <ShareFileModal
        close={() => setShareOpen(false)}
        fileItem={props.fileItem}
        open={shareOpen}
      />}
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleClose}
        onClose={handleClose}
        open={openMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          disabled={!props.fileItem}
          onClick={() => setShareOpen(true)}
        >
          <ListItemIcon>
            <ShareRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Share
        </MenuItem>
        <MenuItem
          onClick={() => setRenameModal(true)}
        >
          <ListItemIcon>
            <EditRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Rename file
        </MenuItem>
        <MenuItem
          onClick={() => setDeleteModal(true)}
        >
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Delete File
        </MenuItem>
      </Menu>
      <Draggable
        draggableId={fileObject?.id ?? props.uploadingId ?? v4()}
        index={props.index}
        isDragDisabled={!props.fileItem || props.disableDrag}
        key={fileObject?.id ?? props.uploadingId ?? v4()}
      >
        {(provided, snapshot) =>
          <>
            <Card
              elevation={0}
              ref={provided.innerRef}
              style={{ ...provided.draggableProps.style, position: 'static' }}
              sx={{
                boxShadow: 'none',
                background: theme => theme.palette.background.elevatedCard,
                width: 1,
                height: 1,
              }}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <CardActionArea
                disabled={!props.link}
                onClick={() => setOpenDrawer(true)}
                sx={{
                  height: 1,
                }}
              >
                <Stack direction='column'>
                  <Stack alignItems='center' direction='row' padding={2} width={1}>
                    <Stack alignItems='center' direction='row' marginRight='auto' width={1}>
                      {renderIcon(fileExtension?.[0].toString() ?? '')}
                      <Stack direction='column' paddingX={2} width={1}>
                        <Typography sx={{ paddingY: 0 }} variant='body1'>
                          {props.file?.name ?? props.fileItem?.title}
                        </Typography>
                      </Stack>
                      {props.file &&
                      <CircularProgress
                        color='primary'
                      />}
                    </Stack>
                    <Stack alignItems='center' direction='row' marginLeft='auto' spacing={1}>
                      {props.fileItem && props.link &&
                      <IconButton
                        download
                        href={`${baseUrlLinksApi}/files/download?fileName=${encodeURIComponent(props.link)}`}
                        onClick={event => preventClick(event)}
                        size='small'
                        target='_blank'
                      >
                        <FileDownloadRoundedIcon />
                      </IconButton>}
                      {props.fileItem &&
                      <IconButton onClick={event => clickButton(event)} size='small'>
                        <MoreHorizRoundedIcon />
                      </IconButton>}
                    </Stack>
                  </Stack>
                </Stack>
              </CardActionArea>
            </Card>
            {snapshot.isDragging &&
            <Card
              elevation={6}
              sx={{
                boxShadow: 'none',
                background: theme => theme.palette.background.elevatedCard,
                width: 1,
                height: 1,
              }}
            >
              <CardActionArea disabled={!props.link} onClick={() => setImageOpen(true)}>
                <Stack direction='column'>
                  <Stack alignItems='center' direction='row' padding={2} width={1}>
                    <Stack alignItems='center' direction='row' marginRight='auto' width={1}>
                      {renderIcon(fileExtension?.[0].toString() ?? '')}
                      <Stack direction='column' paddingX={2} width={1}>
                        <Typography
                          sx={{
                            paddingY: 0,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                          variant='body1'
                        >
                          {props.file?.name ?? props.fileItem?.title}
                        </Typography>
                      </Stack>
                      {props.file &&
                      <CircularProgress
                        color='primary'
                      />}
                    </Stack>

                    <Stack alignItems='center' direction='row' marginLeft='auto' spacing={1}>
                      {props.fileItem && props.link &&
                      <IconButton
                        download
                        href={`${baseUrlLinksApi}/files/download?fileName=${props.link}`}
                        onClick={event => preventClick(event)}
                        size='small'
                        target='_blank'
                      >
                        <FileDownloadRoundedIcon />
                      </IconButton>}
                      {props.fileItem &&
                      <IconButton onClick={event => clickButton(event)} size='small'>
                        <MoreHorizRoundedIcon />
                      </IconButton>}
                    </Stack>
                  </Stack>
                </Stack>
              </CardActionArea>
            </Card>}
          </>}
      </Draggable>
      {props.link &&
      <PreviewFile
        close={() => setImageOpen(false)}
        fileType={fileExtension?.[0].toString() ?? 'jpg'}
        image={props.link}
        open={openImage}
        text={props.file?.name ?? props.fileItem?.title ?? ''}
      />}
      {fileObject &&
      <FileDrawer
        drawerWidth={640}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
      >
        <FileDrawerComponent
          closeTaskDrawer={() => setOpenDrawer(false)}
          fileItem={fileObject}
          setFileObject={setFileObject}
        />
      </FileDrawer>}
    </>
  )
}

export default FileItemView
