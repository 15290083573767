/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded'
import { Card, Grid, IconButton, Skeleton, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import FollowersDashboard from 'src/components/pages/Dashboard/Analytics/FollowersDashboard'
import MonthlyListenersDashboard from 'src/components/pages/Dashboard/Analytics/MonthlyListenersDashboard'
import ShazamsDashboard from 'src/components/pages/Dashboard/Analytics/ShazamsDashboard'
import StreamsDashboard from 'src/components/pages/Dashboard/Analytics/StreamsDashboard'
import ViewsDashboard from 'src/components/pages/Dashboard/Analytics/ViewsDashboard'
import SocialAssetModal from 'src/components/pages/SocialAssets/SocialAssetModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AnalyticsCurrentData, AnalyticsHistoricData } from 'src/models/Analytics'

type Props = {
  currentData?: AnalyticsCurrentData
  historicData?: AnalyticsHistoricData
  artworkUrl: string
}

const RenderAnalyticsCardsProfile = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const themeItem = useTheme()
  const [socialOpen, setSocialOpen] = useState(false)
  const [activeTitle, setActiveTitle] = useState('')
  const [activeTotal, setActiveTotal] = useState(0)
  const totalFollowers = props.currentData?.stats?.map(item => item.data.followers_total ?? 0)
    .reduce((partialSum, a) => partialSum + a, 0) ?? 0
  const totalStreams = props.currentData?.stats?.map(item => item.data.streams_total ?? 0)
    .reduce((partialSum, a) => partialSum + a, 0) ?? 0
  const totalShazams = props.currentData?.stats?.map(item => item.data.shazams_total ?? 0)
    .reduce((partialSum, a) => partialSum + a, 0) ?? 0
  const totalMonthlys = props.currentData?.stats?.map(item => item.data.monthly_listeners_current ?? 0)
    .reduce((partialSum, a) => partialSum + a, 0) ?? 0
  const totalViews = props.currentData?.stats?.map(item => item.data.views_total ?? 0)
    .reduce((partialSum, a) => partialSum + a, 0) ?? 0

  return (
    <>
      <Stack spacing={2} width={1}>
        <Stack direction='row' spacing={1} width={1}>
          <Grid
            container
            spacing={1}
            sx={{
              marginLeft: '-8px!important',
              width: 'calc(100% + 8px)!important',
            }}
          >
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Card
                elevation={0}
                sx={{
                  height: 1,
                  background: theme => theme.palette.background.elevatedCard,
                }}
              >
                <Stack direction='row' justifyContent='space-between' padding={2} width={1}>
                  <Stack direction='row' justifyContent='space-between' width={1}>
                    <Stack>
                      <Typography lineHeight={1.2} variant='h3'>
                        Followers
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.secondary}
                        lineHeight={1.2}
                        variant='body2'
                      >
                        All time
                      </Typography>
                    </Stack>
                    <Tooltip title='Share'>
                      <IconButton
                        onClick={() => {
                          setActiveTotal(totalFollowers)
                          setActiveTitle('Followers')
                          setSocialOpen(true)
                        }}
                      >
                        <IosShareRoundedIcon
                          fontSize='medium'
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>

                {props.currentData && props.historicData
                  ? <FollowersDashboard
                    currentData={props.currentData}
                    historicData={props.historicData}
                  />
                  : <Stack width={1}>
                    <Typography lineHeight={1.1} textAlign='center' variant='h2'>
                      <Skeleton sx={{ marginX: 'auto' }} width='140px' />
                    </Typography>

                    <Skeleton height='240px' variant='rounded' width='100%' />
                  </Stack>}

              </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Card
                elevation={0}
                sx={{
                  height: 1,
                  background: theme => theme.palette.background.elevatedCard,
                }}
              >
                <Stack direction='row' justifyContent='space-between' padding={2} width={1}>
                  <Stack direction='row' justifyContent='space-between' width={1}>
                    <Stack>
                      <Typography lineHeight={1.2} variant='h3'>
                        Streams
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.secondary}
                        lineHeight={1.2}
                        variant='body2'
                      >
                        All time
                      </Typography>
                    </Stack>
                    <Tooltip title='Share'>
                      <IconButton
                        onClick={() => {
                          setActiveTotal(totalStreams)
                          setActiveTitle('Streams')
                          setSocialOpen(true)
                        }}
                      >
                        <IosShareRoundedIcon
                          fontSize='medium'
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>

                {props.currentData && props.historicData
                  ? <StreamsDashboard
                    currentData={props.currentData}
                    historicData={props.historicData}
                  />
                  : <Stack width={1}>
                    <Typography lineHeight={1.1} textAlign='center' variant='h2'>
                      <Skeleton sx={{ marginX: 'auto' }} width='140px' />
                    </Typography>

                    <Skeleton height='240px' variant='rounded' width='100%' />
                  </Stack>}

              </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Card
                elevation={0}
                sx={{
                  height: 1,
                  background: theme => theme.palette.background.elevatedCard,
                }}
              >
                <Stack direction='row' justifyContent='space-between' padding={2} width={1}>
                  <Stack direction='row' justifyContent='space-between' width={1}>
                    <Stack>
                      <Typography lineHeight={1.2} variant='h3'>
                        Shazams
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.secondary}
                        lineHeight={1.2}
                        variant='body2'
                      >
                        All time
                      </Typography>
                    </Stack>
                    <Tooltip title='Share'>
                      <IconButton
                        onClick={() => {
                          setActiveTotal(totalShazams)
                          setActiveTitle('Shazams')
                          setSocialOpen(true)
                        }}
                      >
                        <IosShareRoundedIcon
                          fontSize='medium'
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
                {props.currentData && props.historicData
                  ? <ShazamsDashboard
                    currentData={props.currentData}
                    historicData={props.historicData}
                  />
                  : <Stack width={1}>
                    <Typography lineHeight={1.1} textAlign='center' variant='h2'>
                      <Skeleton sx={{ marginX: 'auto' }} width='140px' />
                    </Typography>
                    <Skeleton height='240px' variant='rounded' width='100%' />
                  </Stack>}
              </Card>
            </Grid>
            {currentOrganisation?.analyticsType !== 'label' &&
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Card
                elevation={0}
                sx={{
                  height: 1,
                  background: theme => theme.palette.background.elevatedCard,
                }}
              >
                <Stack direction='row' justifyContent='space-between' padding={2} width={1}>
                  <Stack direction='row' justifyContent='space-between' width={1}>
                    <Stack>
                      <Typography lineHeight={1.2} variant='h3'>
                        Monthly listeners
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.secondary}
                        lineHeight={1.2}
                        variant='body2'
                      >
                        Current
                      </Typography>
                    </Stack>
                    <Tooltip title='Share'>
                      <IconButton
                        onClick={() => {
                          setActiveTotal(totalMonthlys)
                          setActiveTitle('Monthly listeners')
                          setSocialOpen(true)
                        }}
                      >
                        <IosShareRoundedIcon
                          fontSize='medium'
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
                {props.currentData && props.historicData
                  ? <MonthlyListenersDashboard
                    currentData={props.currentData}
                    historicData={props.historicData}
                  />
                  : <Stack width={1}>
                    <Typography lineHeight={1.1} textAlign='center' variant='h2'>
                      <Skeleton sx={{ marginX: 'auto' }} width='140px' />
                    </Typography>
                    <Skeleton height='240px' variant='rounded' width='100%' />
                  </Stack>}
              </Card>
            </Grid>}
            {currentOrganisation?.analyticsType === 'label' &&
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Card
                elevation={0}
                sx={{
                  height: 1,
                  background: theme => theme.palette.background.elevatedCard,
                }}
              >
                <Stack direction='row' justifyContent='space-between' padding={2} width={1}>
                  <Stack direction='row' justifyContent='space-between' width={1}>
                    <Stack>
                      <Typography lineHeight={1.2} variant='h3'>
                        Views
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.secondary}
                        lineHeight={1.2}
                        variant='body2'
                      >
                        Total
                      </Typography>
                    </Stack>
                    <Tooltip title='Share'>
                      <IconButton
                        onClick={() => {
                          setActiveTotal(totalViews)
                          setActiveTitle('Views')
                          setSocialOpen(true)
                        }}
                      >
                        <IosShareRoundedIcon
                          fontSize='medium'
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
                {props.currentData && props.historicData
                  ? <ViewsDashboard
                    currentData={props.currentData}
                    historicData={props.historicData}
                  />
                  : <Stack width={1}>
                    <Typography lineHeight={1.1} textAlign='center' variant='h2'>
                      <Skeleton sx={{ marginX: 'auto' }} width='140px' />
                    </Typography>
                    <Skeleton height='240px' variant='rounded' width='100%' />
                  </Stack>}
              </Card>
            </Grid>}

          </Grid>
        </Stack>
      </Stack>
      {socialOpen &&
      <SocialAssetModal
        close={() => setSocialOpen(false)}
        imageMainUrl={props.artworkUrl}
        imageSecondaryUrl=''
        open={socialOpen}
        platform=''
        text={`Reached ${activeTotal.toLocaleString()} ${activeTitle}`}
      />}
    </>
  )
}

export default RenderAnalyticsCardsProfile
