/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable react/jsx-key */
/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Stack, useMediaQuery, useTheme } from '@mui/material'
import { createRef, useEffect, useState } from 'react'

import {
  getAnalyticsActivityArtistSearch,
  getAnalyticsArtistSearch,
  getAnalyticsAudienceArtistSearch,
  getAnalyticsCatalogArtistSearch,
  getAnalyticsCurrentArtistSearch,
  getAnalyticsProfileArtistSearch,
  getAnalyticsTopPlaylistsArtistSearch,
} from 'src/api/analyticsCloud'
import ActivityRows from 'src/components/pages/Audience/Components/Activity/ActivityRows'
import AnalyticsProfileUi from 'src/components/pages/Audience/Components/Audience/AnalyticsCloudOverview'
import AnalyticsControlerAudience from 'src/components/pages/Audience/Components/Audience/AudienceControler'
import CatalogRows from 'src/components/pages/Audience/Components/Catalog/CatalogRows'
import AnalyticSearchNavMobile from 'src/components/pages/Audience/Components/Navigation/AnalyticsSearchMobileNav'
import AnalyticsSearchNav from 'src/components/pages/Audience/Components/Navigation/AnalyticsSearchNav'
import TopPlaylistsRows from 'src/components/pages/Audience/Components/Playlists/TopPlaylists'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AnalyticsActivity, AnalyticsAudienceData, AnalyticsCatalog, AnalyticsCurrentData, AnalyticsHistoricData, AnalyticsProfileData, TopPlaylists } from 'src/models/Analytics'

type Props = {
  readonly artistId: string
}

const ViewArtistItem = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const { currentOrganisation } = useAuth()
  const [currentTab, setCurrentTab] = useState('Overview')

  const [loadingHistoric, setLoadingHistoric] = useState(false)
  const [loadingCurrent, setLoadingCurrent] = useState(false)
  const [loadingCatalog, setLoadingCatalog] = useState(false)
  const [loadingActivities, setLoadingActivities] = useState(false)
  const [loadingProfile, setLoadingProfile] = useState(false)
  const [loadingAudience, setLoadingAudience] = useState(false)
  const [loadingPlaylists, setLoadingPlaylists] = useState(false)
  const [historicData, setHistoricData] = useState<AnalyticsHistoricData>()
  const [currentData, setCurrentData] = useState<AnalyticsCurrentData>()
  const [catalogData, setCatalogData] = useState<AnalyticsCatalog>()
  const [activityData, setActivityData] = useState<AnalyticsActivity>()
  const [profileData, setProfileData] = useState<AnalyticsProfileData>()
  const [audienceData, setAudienceData] = useState<AnalyticsAudienceData>()
  const [topPlaylistsData, setTopPlaylistsData] = useState<TopPlaylists>()

  const refContainer = createRef<HTMLDivElement>()

  useEffect(() => {
    if (currentOrganisation?.membershipTier === 'NEW_BUSINESS' ||
      currentOrganisation?.membershipTier === 'NEW_BUSINESS_YEARLY' ||
      currentOrganisation?.membershipTier === 'ENTERPRISE' ||
      currentOrganisation?.membershipTier === 'ENTERPRISE_YEARLY') {
      getMyData()
    }
  }, [currentOrganisation, props.artistId])

  useEffect(() => {
    if (refContainer.current) {
      refContainer.current.scrollTo(0, 0)
    }
  }, [])

  const setLoading = () => {
    setCurrentTab('Overview')
    setLoadingHistoric(true)
    setLoadingCurrent(true)
    setLoadingCatalog(true)
    setLoadingActivities(true)
    setLoadingProfile(true)
    setLoadingAudience(true)
    setLoadingPlaylists(true)
  }

  const getMyData = () => {
    setLoading()
    if (currentOrganisation &&
      (currentOrganisation.membershipTier === 'NEW_BUSINESS' ||
        currentOrganisation.membershipTier === 'NEW_BUSINESS_YEARLY' ||
        currentOrganisation.membershipTier === 'ENTERPRISE' ||
        currentOrganisation.membershipTier === 'ENTERPRISE_YEARLY')
    ) {
      void getAnalyticsArtistSearch(props.artistId)
        .then(result => setHistoricData(result))
        .finally(() => setLoadingHistoric(false))
        .catch(() => setLoadingHistoric(false))
      void getAnalyticsCurrentArtistSearch(props.artistId)
        .then(resultCurrent => setCurrentData(resultCurrent))
        .finally(() => setLoadingCurrent(false))
        .catch(() => setLoadingCurrent(false))
      void getAnalyticsCatalogArtistSearch(props.artistId)
        .then(resultCurrent => setCatalogData(resultCurrent))
        .finally(() => setLoadingCatalog(false))
        .catch(() => setLoadingCatalog(false))
      void getAnalyticsActivityArtistSearch(props.artistId)
        .then(result => setActivityData(result))
        .finally(() => setLoadingActivities(false))
        .catch(() => setLoadingActivities(false))
      void getAnalyticsProfileArtistSearch(props.artistId)
        .then(result => setProfileData(result))
        .finally(() => setLoadingProfile(false))
        .catch(() => setLoadingProfile(false))
      void getAnalyticsAudienceArtistSearch(props.artistId)
        .then(result => setAudienceData(result))
        .finally(() => setLoadingAudience(false))
        .catch(() => setLoadingAudience(false))
      void getAnalyticsTopPlaylistsArtistSearch(props.artistId)
        .then(result => setTopPlaylistsData(result))
        .finally(() => setLoadingPlaylists(false))
        .catch(() => setLoadingPlaylists(false))
    }
  }

  const titlesSmartLinks = ['Clicks & Views', 'Subscribers']
  const titlesDiscovery = ['Search', 'Songs', 'Artists', 'Latest', 'Recommended']
  const titlesMobile = ['Overview', 'Catalog', 'Audience', 'Playlists']

  return (
    <Stack
      direction='row'
      height={1}
      id='analytics-container'
      justifyContent='center'
    >
      <Stack
        alignItems='stretch'
        boxSizing='border-box'
        direction={mobile ? 'column' : 'row'}
        height={1}
        minWidth={0}
        padding={0}
        width={1}
      >
        {mobile
          ? <AnalyticSearchNavMobile
            currentTab={currentTab}
            noNav
            setCurrentTab={setCurrentTab}
            titles={titlesMobile}
          />
          : <>
            <AnalyticsSearchNav
              artistName={profileData?.profileData.name}
              compact
              currentTab={currentTab}
              logoUrl={profileData?.profileData.avatar}
              noNav
              setCurrentTab={setCurrentTab}
              titlesDiscovery={titlesDiscovery}
              titlesDistribution={[]}
              titlesSmartLinks={titlesSmartLinks}
            />
            <Divider
              orientation='vertical'
              sx={{
                height: 1,
              }}
            />
          </>}
        <Stack alignItems='center' height='calc(100% - 96px)' overflow='auto' width={1}>
          {/* User audience */}
          <Stack alignItems='center' height={1} overflow='auto' padding={2} width={1}>
            {currentOrganisation &&
            currentOrganisation.membershipTier !== 'FREE' &&
            <Stack alignItems='center' width={1}>
              {currentTab === 'Overview' &&
                <>
                  <AnalyticsProfileUi
                    currentData={currentData}
                    historicData={historicData}
                    loading={loadingHistoric || loadingProfile || loadingCurrent}
                    profileData={profileData}
                  />
                  <ActivityRows
                    chartItems={activityData?.data ?? []}
                    currentTab={currentTab}
                    loading={loadingActivities}
                    overview
                  />
                </>}
              {currentTab === 'Catalog' &&
                <CatalogRows
                  chartItems={catalogData?.catalog ?? []}
                  loading={loadingCatalog}
                />}
              {currentTab === 'Audience' &&
                <AnalyticsControlerAudience
                  activityData={activityData}
                  audienceData={audienceData}
                  currentData={currentData}
                  historicData={historicData}
                  loading={loadingAudience || loadingHistoric || loadingCurrent}
                />}
              {currentTab === 'Playlists' &&
                <TopPlaylistsRows
                  loading={loadingPlaylists}
                  topPlaylistItems={topPlaylistsData}
                />}
            </Stack>}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ViewArtistItem
