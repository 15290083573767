
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import PayoutMethodTable from 'src/components/pages/Wallet/PayoutMethods/PayoutMethodTable'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly refresh: () => void
}

const PayoutMethodModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        '.MuiDialog-paper': {
          overflow: 'hidden',
        },
      }}
    >
      <Stack height={1} paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack direction='column' width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width={1}
          >
            <Typography variant='h3'>
              Payout methods
            </Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
        </Stack>
        <PayoutMethodTable refresh={props.refresh} />
      </Stack>
    </Dialog>
  )
}

export default PayoutMethodModal
