/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { MutableRefObject } from 'react'
import { useEffect, useState } from 'react'

export const useContainerDimensions = (myRef: MutableRefObject<HTMLDivElement | null | undefined>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const getDimensions = () => myRef?.current
      ? {
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight,
      } : {
        width: 0,
        height: 0,
      }

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    setDimensions(getDimensions())

    window.addEventListener('load', handleResize)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('load', handleResize)
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef?.current])

  return dimensions
}
