import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded'
import { Card, Pagination, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { getPayments } from 'src/api/payout'
import PaymentTableItem from 'src/components/pages/Wallet/PaymentItems/PaymentTableItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { PaymentObject } from 'src/models/Payout'

const PaymentTable = () => {
  const themeItem = useTheme()
  const { currentOrganisation } = useAuth()
  const [transactions, setTransactions] = useState<PaymentObject[]>([])
  const [page, setPage] = useState(1)
  const currentPageData = transactions.slice(((page * 100) / 10) - 10, (page * 100) / 10)

  useEffect(() => {
    void getPayments()
      .then(setTransactions)
  }, [currentOrganisation?.id])

  const updateCurrentDisplayData = (currentPage: number) => {
    setPage(currentPage)
  }

  return (
    <Card
      elevation={0}
      sx={{
        width: 1,
        height: '318px',
        overflow: 'hidden',
      }}
    >
      <Stack
        alignItems='flex-start'
        height={1}
        overflow='auto'
        padding={2}
        width={1}
      >
        <Stack>
          <Typography lineHeight={1.2} variant='h3'>
            Withdrawals
          </Typography>
        </Stack>
        {currentPageData.length > 0
          ? <Table>
            <TableHead>
              <TableRow
                sx={{
                  '.MuiTableCell-root': {
                    borderBottomColor: 'divider',
                    paddingY: 2,
                  },
                }}
              >
                <TableCell>
                  Date
                </TableCell>
                <TableCell>
                  Amount
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageData.map(transaction =>
                <PaymentTableItem
                  key={transaction.id}
                  transaction={transaction}
                />)}
            </TableBody>
          </Table>
          : <Stack
            alignItems='center'
            height={1}
            justifyContent='center'
            paddingBottom={4}
            paddingTop={2}
            width='100%'
          >
            <Paper
              elevation={0}
              sx={{
                backgroundColor: theme => theme.palette.success.main,
                borderRadius: 16,
              }}
            >
              <Stack
                alignItems='center'
                justifyContent='center'
                padding={2}
              >
                <AccountBalanceWalletRoundedIcon
                  sx={{
                    color: theme => theme.palette.primary.contrastText,
                    fontSize: '64px',
                  }}
                />
              </Stack>
            </Paper>
            <Typography
              color={themeItem.palette.text.secondary}
              sx={{
                marginTop: 1,
              }}
              variant='h4'
            >
              No Withdrawals Submitted Yet
            </Typography>
            <Typography
              color={themeItem.palette.text.label}
              sx={{
                textAlign: 'center',
              }}
              variant='body1'
            >
              Come back here later to view your transactions
            </Typography>
          </Stack>}

        <Stack>
          {
            transactions.length > 10 &&
            <Pagination
              count={transactions.length > 0 ? Math.ceil(transactions.length / 10) : 1}
              onChange={(event, value: number) => {
                updateCurrentDisplayData(value)
              }}
              page={page}
              sx={{ marginTop: 3, marginBottom: 4, marginLeft: 'auto' }}
            />
          }
        </Stack>
      </Stack>
    </Card>
  )
}

export default PaymentTable

