/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-hooks/exhaustive-deps */
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import { Avatar, CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, TableCell, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'

import { baseUrlLinksApi } from 'src/api/files'
import DeleteFileModal from 'src/components/pages/Files/Components/DeleteFileModal'
import FileDrawer from 'src/components/pages/Files/Components/FileDrawer'
import FileDrawerComponent from 'src/components/pages/Files/Components/FileDrawerComponent'
import { renderIcon } from 'src/components/pages/Files/Components/FileItemView'
import RenameFileModal from 'src/components/pages/Files/Components/RenameFileModal'
import ShareFileModal from 'src/components/pages/Files/Components/ShareModalFile'
import PreviewFile from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/FilesTab/PreviewFile'
import type { FileItem } from 'src/models/File'
import { bytesToSize } from 'src/utils/fileUtils'

type Props = {
  file?: File
  fileItem?: FileItem
  link?: string
  setFiles: React.Dispatch<React.SetStateAction<FileItem[]>>
}

const RecentFileTableItem = (props: Props) => {
  const themeItem = useTheme()
  const isMobile = useMediaQuery(themeItem.breakpoints.down('md'))

  const [fileObject, setFileObject] = useState(props.fileItem)
  const [openImage, setImageOpen] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [renameModal, setRenameModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  const handleClose = () => {
    setAnchorElement(null)
  }

  const clickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorElement(event.currentTarget)
  }

  const openPreview = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setImageOpen(true)
  }

  const regexExtension = /\w{3,4}($|\?)/
  const fileExtension = regexExtension.exec(props.link ?? props.file?.name ?? '')

  const preventClick = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  return (
    <>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleClose}
        onClose={handleClose}
        open={openMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          disabled={!props.fileItem}
          onClick={() => setShareOpen(true)}
        >
          <ListItemIcon>
            <ShareRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Share
        </MenuItem>
        <MenuItem
          onClick={() => setRenameModal(true)}
        >
          <ListItemIcon>
            <EditRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Rename file
        </MenuItem>
        <MenuItem
          onClick={() => setDeleteModal(true)}
        >
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Delete File
        </MenuItem>
      </Menu>
      {props.fileItem &&
      <ShareFileModal
        close={() => setShareOpen(false)}
        fileItem={props.fileItem}
        open={shareOpen}
      />}
      {fileObject &&
      <DeleteFileModal
        close={() => setDeleteModal(false)}
        file={fileObject}
        open={deleteModal}
        setFiles={props.setFiles}
      />}
      {fileObject &&
      <RenameFileModal
        close={() => setRenameModal(false)}
        file={fileObject}
        open={renameModal}
        setFile={setFileObject}
        setFiles={props.setFiles}
      />}
      <TableRow
        hover
        key={props.file?.name ?? fileObject?.url ?? ''}
        onClick={() => setOpenDrawer(true)}
        sx={{
          textDecoration: 'none',
          backgroundColor: theme => theme.palette.background.default,
          '&:hover .MuiTableCell-root': {
            backgroundColor: theme => theme.palette.background.input,
          },
          '.MuiTableCell-root': { borderBottomColor: 'divider' },
        }}
      >
        <TableCell id='icon'>
          {props.link
            ? renderIcon(fileExtension?.[0].toString() ?? '')
            : <CircularProgress
              color='primary'
              size={32}
            />}
        </TableCell>
        <TableCell id='name'>
          <Stack>
            <ListItemText
              primary={props.file?.name ?? fileObject?.title}
              secondary={isMobile && fileObject && bytesToSize(fileObject.size)}
            />
          </Stack>
        </TableCell>
        {!isMobile &&
        <>
          <TableCell id='release' sx={{ width: 160 }}>
            {fileObject?.projectId && typeof fileObject.projectId !== 'string' &&
            <Stack alignItems='center' direction='row' spacing={1}>
              <Avatar
                src={fileObject.projectId.artworkPreviewUrl}
                sx={{
                  borderRadius: '8px',
                }}
              />
              <ListItemText
                primary={fileObject.projectId.title}
                secondary={fileObject.projectId.primaryArtists.map(item => item.name).join(', ')}
              />
            </Stack>}
          </TableCell>
          <TableCell id='createdat' sx={{ width: 160 }}>
            {fileObject &&
            <Stack alignItems='center' direction='row' spacing={2}>
              <CalendarTodayIcon sx={{ fontSize: 'inherit' }} />
              <ListItemText
                primary={dayjs(fileObject.createdAt).format('MMM D YYYY')}
                secondary={dayjs(fileObject.createdAt).format('LT')}
              />
            </Stack>}
          </TableCell>
          <TableCell id='size' sx={{ width: 120 }}>
            <Typography color='text.secondary' variant='body1'>
              {fileObject &&
            bytesToSize(fileObject.size)}
            </Typography>
          </TableCell>
        </>}

        <TableCell>
          {props.link &&
          <Stack alignItems='center' direction='row' spacing={1}>
            {fileObject && props.link &&
            <Tooltip title='Download'>
              <IconButton
                download
                href={`${baseUrlLinksApi}/files/download?fileName=${encodeURIComponent(props.link)}`}
                onClick={event => preventClick(event)}
                size='small'
                target='_blank'
              >
                <FileDownloadRoundedIcon />
              </IconButton>
            </Tooltip>}
            {fileObject && props.link &&
            <Tooltip title='Preview'>
              <IconButton
                onClick={event => openPreview(event)}
                size='small'
              >
                <PreviewRoundedIcon />
              </IconButton>
            </Tooltip>}
            <Tooltip title='More'>
              <IconButton onClick={clickButton}>
                <MoreHorizRoundedIcon />
              </IconButton>
            </Tooltip>
          </Stack>}
        </TableCell>
      </TableRow>
      {props.link &&
      <PreviewFile
        close={() => setImageOpen(false)}
        fileType={fileExtension?.[0].toString() ?? 'jpg'}
        image={props.link}
        open={openImage}
        text={props.file?.name ?? fileObject?.title ?? ''}
      />}
      {fileObject &&
      <FileDrawer
        drawerWidth={640}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
      >
        <FileDrawerComponent
          closeTaskDrawer={() => setOpenDrawer(false)}
          fileItem={fileObject}
          setFileObject={setFileObject}
        />
      </FileDrawer>}
    </>
  )
}

export default RecentFileTableItem
