/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import MicExternalOnRoundedIcon from '@mui/icons-material/MicExternalOnRounded'
import { Avatar, Card, CardActionArea, CardContent, Grid, Paper, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import ArtistModalSearch from 'src/components/pages/Audience/Components/Modals/ArtistModalSearch'
import TopTrackModalSearch from 'src/components/pages/Audience/Components/Modals/TopTrackModalSearch'
import type { SearchObject } from 'src/models/Distribution'

type Props = {
  readonly artistItem: SearchObject
}

const SearchArtistItem = (props: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {props.artistItem.type === 'Track' &&
      props.artistItem.isrc &&
      open &&
      <TopTrackModalSearch
        artists={props.artistItem.artists ?? []}
        artwork={props.artistItem.images?.[0].url ?? ''}
        close={() => setOpen(false)}
        loading={false}
        open={open}
        selectedRecording={props.artistItem.isrc}
        title={props.artistItem.name}
      />}
      {props.artistItem.type === 'Artist' &&
      props.artistItem.spotifyId &&
      open &&
      <ArtistModalSearch
        artwork={props.artistItem.images?.[0].url ?? ''}
        close={() => setOpen(false)}
        loading={false}
        open={open}
        selectedArtist={props.artistItem.spotifyId}
        title={props.artistItem.name}
      />}
      <Grid
        item
        lg={3}
        md={3}
        sm={6}
        xl={3}
        xs={6}
      >
        <Card
          elevation={0}
          sx={{
            width: 'auto',
            minHeight: 200,
            maxHeight: '100%',
            background: theme => theme.palette.background.elevatedCard,
          }}
        >
          <CardActionArea
            onClick={() => setOpen(true)}
            sx={{
              height: 1,
              width: 1,
            }}
          >
            {props.artistItem.images?.[0]?.url && props.artistItem.images[0].url.length > 0
              ? <Avatar
                src={props.artistItem.images[0].url}
                sx={{
                  objectFit: 'cover',
                  aspectRatio: '1/1',
                  width: '100%',
                  height: 'auto',
                  borderRadius: '0px',
                  background: theme => theme.palette.background.input,
                }}
              >
                <MicExternalOnRoundedIcon
                  sx={{
                    fontSize: 150,
                    color: theme => theme.palette.action.disabled,
                  }}
                />
              </Avatar>
              : <Paper
                elevation={0}
                sx={{
                  height: 'auto',
                  width: 1,
                  borderRadius: 0,
                  backgroundColor: theme => theme.palette.background.input,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  aspectRatio: '1/1',
                }}
              >
                <MicExternalOnRoundedIcon
                  sx={{
                    fontSize: 150,
                    color: theme => theme.palette.action.disabled,
                  }}
                />
              </Paper>}
            <CardContent sx={{ width: 1, padding: 1.5 }}>
              <Stack spacing={1} width={1}>
                <Typography
                  fontSize='1rem'
                  fontWeight={600}
                  lineHeight={1.3}
                  textAlign='left'
                  variant='h4'
                >
                  {props.artistItem.name}
                </Typography>
                {props.artistItem.followers &&
                <Stack alignContent='center' alignItems='center' direction='row' flexWrap='wrap'>
                  <Typography
                    color='text.secondary'
                    textAlign='left'
                    variant='body1'
                  >
                    {new Intl.NumberFormat('en-US').format(props.artistItem.followers.total)}
                    {' followers'}
                  </Typography>
                </Stack>}
                {props.artistItem.artists &&
                <Stack alignContent='center' alignItems='center' direction='row' flexWrap='wrap'>
                  <Typography
                    color='text.secondary'
                    textAlign='left'
                    variant='body1'
                  >
                    {props.artistItem.artists.join(', ')}
                  </Typography>
                </Stack>}
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  )
}

export default SearchArtistItem
