/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { ArtistObject } from 'src/models/Distribution'
import { Organisation } from 'src/models/Organisation'
import type { Serialized } from 'src/types/react-app-env'

export const CREDIT_ROLES =
[
  'Publisher',
  'Manager',
  'Record Label',
  'Songwriter',
  'Producer',
  'Performer',
  'Musician',
  'Composer',
  'Vocalist',
  'Remixer',
  'Primary Artist',
  'Featured Artist',
  'Lyricist',
  'Engineer',
  'Mixing Engineer',
  'Mastering Engineer',
  'Executive Producer',
  'A&R',
  'Programmer',
  'Arranger',
  'Studio Engineer',
  'Additional Producer',
  'Recording Engineer',
  'Main Personel',
  'Other Studio Personel',
  'Other',
] as const

export const PUBLISHING_CREDIT_ROLES =
[
  'Songwriter',
  'Composer',
] as const

export const RECORDING_VERSIONS =
[
  'Master',
  'Pre-Master',
  'Mixed',
  'Demo',
  'Rough',
  'Instrumental',
  'Trailer',
  'Advertisement',
] as const

export const TRACK_TYPES =
[
  'Original',
  'Cover',
  'Remix',
  'Karaoke',
] as const

export const GENRES: string[] = [
  'Alternative',
  'Anime',
  'Blues',
  'Brazilian',
  'Christian & Gospel',
  'Classical',
  'Comedy',
  'Country',
  'Dance',
  'Easy Listening',
  'Electronic',
  'Enka',
  'Fitness & Workout',
  'French Pop',
  'German Folk',
  'German Pop',
  'Hip Hop/Rap',
  'Holiday',
  'Indian',
  'Instrumental',
  'J-Pop',
  'Jazz',
  'K-Pop',
  'Karoke',
  'Kayokyoku',
  'Korean',
  'Latin',
  'New Age',
  'Pop',
  'R&B/Soul',
  'Reggae',
  'Rock',
  'Singer/Songwriter',
  'Soundtrack',
  'Spoken Word',
  'Vocal',
  'World',
]

export const BEATPORT_GENRES: string[] = [
  '140 / Deep Dubstep / Grime',
  '140 / Deep Dubstep / Grime | Grime',
  'Afro House',
  'Afro House | Afro / Latin',
  'Amapiano | Gqom',
  'Bass / Club',
  'Bass House',
  'Breaks / Breakbeat / UK Bass',
  'Breaks / Breakbeat / UK Bass | Glitch Hop',
  'Dance / Pop',
  'Dance / Pop | Afro Pop',
  'Dance / Pop | Pop',
  'Dance / Pop | Tropical House',
  'Deep House',
  'DJ Tools',
  'DJ Tools | Loops',
  'DJ Tools | Acapellas',
  'DJ Tools | Battle Tools',
  'Drum & Bass',
  'Drum & Bass | Liquid',
  'Drum & Bass | Jump Up',
  'Drum & Bass | Jungle',
  'Drum & Bass | Deep',
  'Drum & Bass | Halftime',
  'Dubstep',
  'Dubstep | Melodic Dubstep',
  'Dubstep | Midtempo',
  'Electro (Classic / Detroit / Modern)',
  'Electronica',
  'Electronica | Ambient',
  'Funky House',
  'Hard Dance / Hardcore / Neo Rave',
  'Hard Dance / Hardcore / Neo Rave | Hardstyle',
  'Hard Dance / Hardcore / Neo Rave | Hard House',
  'Hard Dance / Hardcore / Neo Rave | Uptempo',
  'Hard Dance / Hardcore / Neo Rave | Terror',
  'Hard Dance / Hardcore / Neo Rave | UK / Happy Hardcore',
  'Hard Dance / Hardcore / Neo Rave | Frenchcore',
  'Hard Dance / Hardcore / Neo Rave | Neo Rave',
  'Hard Techno',
  'House',
  'House | Acid',
  'House | Soulful',
  'Indie Dance',
  'Indie Dance | Dark Disco',
  'Jackin House',
  'Mainstage',
  'Mainstage | Big Room',
  'Mainstage | Electro House',
  'Mainstage | Future House',
  'Mainstage | Speed House',
  'Mainstage | Future Rave',
  'Melodic House & Techno',
  'Melodic House & Techno | Melodic House',
  'Melodic House & Techno | Melodic Techno',
  'Minimal / Deep Tech',
  'Minimal / Deep Tech | Bounce',
  'Minimal / Deep Tech | Deep Tech',
  'Nu Disco / Disco',
  'Nu Disco / Disco | Funk / Soul',
  'Nu Disco / Disco | Italo',
  'Organic House / Downtempo',
  'Organic House / Downtempo | Organic House',
  'Organic House / Downtempo | Downtempo',
  'Progressive House',
  'Psy-Trance',
  'Psy-Trance | Full-On',
  'Psy-Trance | Progressive Psy',
  'Psy-Trance | Psychedelic',
  'Psy-Trance | Dark & Forest',
  'Psy-Trance | Goa Trance',
  'Psy-Trance | Psycore & Hi-Tech',
  'Tech House',
  'Tech House | Latin Tech',
  'Techno(Peak Time / Driving)',
  'Techno (Peak Time / Driving) | Driving',
  'Techno (Peak Time / Driving) | Peak Time',
  'Techno (Raw / Deep / Hypnotic)',
  'Techno (Raw / Deep / Hypnotic) | Broken',
  'Techno (Raw / Deep / Hypnotic) | Deep / Hypnotic',
  'Techno (Raw / Deep / Hypnotic) | Dub',
  'Techno (Raw / Deep / Hypnotic) | EBM',
  'Techno (Raw / Deep / Hypnotic) | Raw',
  'Trance (Main Floor)',
  'Trance (Main Floor) | Progressive Trance',
  'Trance (Main Floor) | Tech Trance',
  'Trance (Main Floor) | Uplifting Trance',
  'Trance (Main Floor) | Vocal Trance',
  'Trance (Main Floor) | Hard Trance',
  'Trance (Raw / Deep / Hypnotic)',
  'Trance (Raw / Deep / Hypnotic) | Raw Trance',
  'Trance (Raw / Deep / Hypnotic) | Deep Trance',
  'Trance (Raw / Deep / Hypnotic) | Hypnotic Trance',
  'Trap / Future Bass',
  'Trap / Future Bass | Trap',
  'Trap / Future Bass | Baile Funk',
  'UK Garage / Bassline',
  'UK Garage / Bassline | UK Garage',
  'UK Garage / Bassline | Bassline',
]

export const RIGHTSHOLDER_STATUS = ['Unlocked', 'Locked']

export class RightsHolder {
  account: Organisation

  ownership = 0
  credit: string[] = [CREDIT_ROLES[0]]
  status: typeof RIGHTSHOLDER_STATUS[number] = RIGHTSHOLDER_STATUS[0]

  constructor(dto: RightsHolderDto) {
    Object.assign(this, dto)
    this.account =  new Organisation(dto.account ?? {})
  }
}

export type RightsHolderDto = Partial<Serialized<RightsHolder>>

export class LinkItem {
  id = ''
  link = ''

  constructor(dto: LinkItemDto) {
    Object.assign(this, dto)
  }
}

export type LinkItemDto = Partial<Serialized<LinkItem>>

export class CoverItem {
  id?: string
  name = ''
  uri = ''
  album?: {
    images?: [{
      url: string
    }]
  }
  artists: ArtistObject[] = []

  constructor(dto: Partial<CoverItem>) {
    Object.assign(this, dto)
  }
}

export type CoverItemDto = Partial<CoverItem>

export class WaveForm {
  version = 0
  channels = 0
  sample_rate = 0
  samples_per_pixel = 0
  bits = 0
  length = 0
  data: number[] = []

  constructor(dto: WaveFormDto) {
    Object.assign(this, dto)
  }
}

export type WaveFormDto = Partial<Serialized<WaveForm>>

export class Recoupment {
  title = ''
  description = ''
  amount = 0
  dateAdded = new Date()
  transactionType = ''
  organisationAdvanceId = ''

  constructor(dto: RecoupmentDto) {
    Object.assign(this, dto)
  }
}

export type RecoupmentDto = Partial<Serialized<Recoupment>>

export class BulkRecording {
  isInstrumental = false
  isExplicit = false
  containsSamples = false
  language = ''
  tags: string[] = []

  writtenBy: string[] = []
  composedBy: string[] = []
  producedBy: string[] = []

  primaryArtists: ArtistObject[] = []
  featuredArtists: ArtistObject[] = []

  trackType: typeof TRACK_TYPES[number] = TRACK_TYPES[0]
  recordingVersion: typeof RECORDING_VERSIONS[number] = RECORDING_VERSIONS[0]

  constructor(dto: Partial<BulkRecording>) {
    Object.assign(this, dto)
  }
}

export class NewRecording {
  id?: string
  recordingVersion: typeof RECORDING_VERSIONS[number] = RECORDING_VERSIONS[0]
  audioFile?: File
  artwork?: File
  audioWaveform?: WaveForm
  title = ''
  subTitle = ''
  projectId: string

  ISRC = ''
  ISWC = ''
  bpm = 120
  duration = 0
  isInstrumental = false
  isExplicit = false
  containsSamples = false
  trackType: typeof TRACK_TYPES[number] = TRACK_TYPES[0]
  language = ''
  releaseDate = new Date()
  productionYear = ''

  previewStartTime = ''

  genre: typeof GENRES[number] = GENRES[0]
  subGenres: typeof GENRES[number][] = []
  notes = ''
  tags: string[] = []

  writtenBy: string[] = []
  composedBy: string[] = []
  producedBy: string[] = []

  primaryArtists: ArtistObject[] = []
  featuredArtists: ArtistObject[] = []
  remixArtists: ArtistObject[] = []

  lyrics = ''

  recoupItems: Recoupment[] = []

  cLine = ''
  pLine = ''
  catalogNumber = ''
  recordLabel = ''

  automaticRightsUpdates = false

  masterRightsHolders: RightsHolder[] = []
  publisherRightsHolders: RightsHolder[] = []

  streamingLinks: { url: string, name: string }[] = []

  appleDigitalMaster = false
  appleAtmos = false

  coverItem?: CoverItem

  constructor(dto: Partial<NewRecording> & { projectId: string }) {
    Object.assign(this, dto)
    this.projectId = dto.projectId
  }
}

export default class Recording extends NewRecording {
  id: string

  audioFileUrl: string
  audioFileStreamingUrl: string
  artworkUrl: string
  artworkPreviewUrl: string

  audioFile?: File
  artwork?: File

  linkItems: LinkItem[] = []

  constructor(dto: RecordingDto) {
    super({
      ...dto,
      releaseDate: dto.releaseDate && dto.releaseDate !== null ? new Date(dto.releaseDate) : new Date(),
      projectId: dto.projectId ?? '',
      masterRightsHolders: dto.masterRightsHolders?.map(master => new RightsHolder(master)),
      publisherRightsHolders: dto.publisherRightsHolders?.map(publisher => new RightsHolder(publisher)),
      recoupItems: dto.recoupItems?.map(recoup => new Recoupment(recoup)),
      audioWaveform: dto.audioWaveform,
      primaryArtists: dto.primaryArtists,
      featuredArtists: dto.featuredArtists,
      remixArtists: dto.remixArtists,
      writtenBy: dto.writtenBy,
      producedBy: dto.producedBy,
      composedBy: dto.composedBy,
      isInstrumental: dto.isInstrumental ?? false,
      artwork: dto.artwork as File | undefined,
      audioFile: dto.audioFile as File | undefined,
      isExplicit: dto.isExplicit ?? false,
      automaticRightsUpdates: dto.automaticRightsUpdates ?? false,
      containsSamples: dto.containsSamples ?? false,
      recordLabel: dto.recordLabel ?? '',
    })
    this.id = dto.id ?? ''
    this.linkItems = dto.linkItems ?? []
    this.audioFileUrl = dto.audioFileUrl ?? ''
    this.audioFileStreamingUrl = dto.audioFileStreamingUrl ?? ''
    this.artworkUrl = dto.artworkUrl ?? ''
    this.trackType = dto.trackType ?? TRACK_TYPES[0]
    this.artworkPreviewUrl = dto.artworkPreviewUrl ?? ''
  }
}

export type RecordingDto = Partial<Serialized<Recording>>
