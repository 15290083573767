import { Grid, Skeleton, Stack } from '@mui/material'

const LoadingAudience = () =>
  <Stack
    spacing={2}
    width={1}
  >
    <Stack
      alignItems='center'
      direction='row'
      flexDirection='row'
      flexWrap='wrap'
      width={1}
    >
      {Array.from({ length: 6 }, () =>
        <Skeleton
          sx={{
            width: 120,
            height: 32,
            transform: 'none',
            marginBottom: 0.5,
            marginRight: 0.5,
          }}
        />)}
    </Stack>
    <Stack direction='row' spacing={1} width={1}>
      <Grid
        container
        spacing={1}
        sx={{
          marginLeft: '-8px!important',
          width: 'calc(100% + 8px)!important',
          '.MuiGrid-item': {
            maxWidth: '100%',
            flexGrow: 1,
          },
        }}
      >
        {Array.from({ length: 6 }, () =>
          <Grid item lg={3} md={4} sm={6} xl={3} xs={6}>
            <Skeleton
              sx={{
                width: '100%',
                height: 128,
                transform: 'none',
              }}
            />
          </Grid>)}
      </Grid>
    </Stack>
    <Skeleton sx={{ height: 360, width: '100%', transform: 'none' }} />
  </Stack>

export default LoadingAudience
