/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Stack, Typography, useTheme } from '@mui/material'
import _ from 'lodash'
import type { DateRange } from 'mui-daterange-picker'
import { useRef, useState } from 'react'
import Chart from 'react-apexcharts'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AnalyticsCurrentData, AnalyticsHistoricData } from 'src/models/Analytics'

type Props = {
  readonly historicData: AnalyticsHistoricData
  readonly currentData: AnalyticsCurrentData
}

const FollowersDashboard = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const theme = useTheme()
  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  const test = props.historicData.stats?.filter(item =>
    item.data?.history && item.data.history.find(history => !!history.followers_total))
    .map(item => ({
      name: _.upperFirst(item.source.replaceAll('_', ' ')),
      data: item.data?.history?.map(data => ({
        x: data.date,
        y: data.followers_total ?? 0,
      })) ?? [{
        x: '1999-01-01',
        y: 0,
      }],
    }))

  const total = props.currentData.stats?.map(item => item.data.followers_total ?? 0)
    .reduce((partialSum, a) => partialSum + a, 0)

  const currentDate = new Date()
  const lastTwentyEightDays = new Date()
  lastTwentyEightDays.setDate(currentDate.getDate() - 28)
  const [dateRange, _setDateRange] = useState<DateRange>({
    startDate: lastTwentyEightDays,
    endDate: currentDate,
  })

  const colors = test?.map(item => GetColorLink(item.name))

  const getDateBoolean = (item: { x: string, y: number | string }) => (new Date(item.x).getTime() <= (dateRange.endDate
    ? new Date(dateRange.endDate).getTime()
    : Date.now())) &&
    (new Date(item.x).getTime() >= new Date(dateRange.startDate ?? 0).getTime())

  const optionsSectionsOverdue: ApexCharts.ApexOptions = {
    chart: {
      animations: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
      foreColor: theme.palette.text.primary,
      width: '100%',
      toolbar: {
        show: false,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    colors: colors ?? [theme.palette.primary.main],
    grid: {
      show: false,
      padding: { left: -10, right: -10, top: 0, bottom: 0 },
    },
    tooltip: {
      theme: 'dark',
      x: {
        format: 'MMMM dd, yyyy',
      },
    },
    fill: {
      type: 'gradient',
    },
    dataLabels: {
      enabled: false,
    },
    labels: test?.flatMap(item => item.data).filter(item => getDateBoolean(item)).map(item => item.x),
    xaxis: {
      labels: {
        show: false,
      },
      type: 'datetime',
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      labels: {
        formatter: value => value?.toLocaleString(),
      },
    },
    legend: {
      showForZeroSeries: false,
    },
  }

  const cleanDatas = test?.map(serie => ({
    name: serie.name,
    data: serie.data.filter(item => item.y !== 0)
      .sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()),
  }))

  return (
    <Stack
      ref={componentRef}
      sx={{
        filter: currentOrganisation
          ? currentOrganisation.membershipTier === 'FREE'
            ? 'blur(8px)'
            : undefined
          : undefined,
        pointerEvents: currentOrganisation
          ? currentOrganisation.membershipTier === 'FREE'
            ? 'none'
            : undefined
          : undefined,
      }}
      width={1}
    >
      <Typography lineHeight={1.1} textAlign='center' variant='h2'>
        {total?.toLocaleString()}
      </Typography>

      <Chart
        height={240}
        options={optionsSectionsOverdue}
        series={cleanDatas ?? []}
        type='area'
        width={width}
      />
    </Stack>
  )
}

export default FollowersDashboard
