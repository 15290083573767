/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded'
import { Chip, Stack, Typography, useTheme } from '@mui/material'

import TeamAvatar from 'src/components/TeamAvatar'
import type { Organisation } from 'src/models/Organisation'

type Props = {
  collaborator: Organisation
}

const CollaboratorItemDetailsOwner = (props: Props) => {
  const themeColors = useTheme()
  return (
    <Stack
      direction='column'
      key={props.collaborator.id}
      spacing={2}
      width={1}
    >
      <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={1}>
        <Stack alignItems='center' direction='row' spacing={2} width={1}>
          <TeamAvatar
            organisation={props.collaborator}
          />
          <Stack direction='column' overflow='hidden' whiteSpace='nowrap' width={1}>
            <Typography
              overflow='hidden'
              textAlign='start'
              textOverflow='ellipsis'
              variant='body1'
              whiteSpace='nowrap'
            >
              {props.collaborator.name}
              <Chip
                color='secondary'
                icon={<VerifiedUserRoundedIcon />}
                label='Owner'
                rounded
                size='small'
                sx={{
                  borderRadius: 16,
                  marginLeft: 1,
                }}
              />
            </Typography>
            <Typography
              color={themeColors.palette.text.label}
              overflow='hidden'
              textAlign='start'
              textOverflow='ellipsis'
              variant='body2'
              whiteSpace='nowrap'
            >
              {props.collaborator.organisationEmail}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CollaboratorItemDetailsOwner
