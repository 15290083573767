import CloudSyncRoundedIcon from '@mui/icons-material/CloudSyncRounded'
import { LoadingButton } from '@mui/lab'
import { Card, Stack, TextField, Typography } from '@mui/material'
import { DatePicker, DesktopTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useState } from 'react'
import { v4 } from 'uuid'

import { getLinksByISRC, getLinksByUPC, getLinksByUrl } from 'src/api/links'
import SearchArtistComponent from 'src/components/form-elements/SearchArtists'
import EditLinkMarketingServices from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkMarketingServices'
import { isrcValidation } from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingTabs/PublishingTab/RecordingPublishingTab'
import { possibleUrls } from 'src/data/smartLinkData'
import { upcValidation } from 'src/data/upcValidation'
import type { MarketingLink } from 'src/models/Marketing'

type Props = {
  smartLinkData: MarketingLink
  setLinkData: React.Dispatch<React.SetStateAction<MarketingLink>>
}

const EditLinkServices = (props: Props) => {
  const [scanModal, setScanModal] = useState(false)
  const [scanLink, setScanLink] = useState(props.smartLinkData.isrc)

  const checkLinkItems = (itemString: string[]) => {
    props.setLinkData(old => ({ ...old, inputList: [] }))

    for (const link of itemString) {
      const testRegex = possibleUrls.find(item => link.includes(item.value))

      if (testRegex) {
        const newItem = {
          label: testRegex.label,
          placeholder: testRegex.placeholder,
          value: link,
          id: v4(),
          customTitle: '',
        }
        props.setLinkData(old => ({
          ...old,
          inputList: old.inputList.length > 0
            ? [...old.inputList, newItem]
            : [newItem],
        }))
      }
    }

    props.setLinkData(old => ({
      ...old,
      inputList: old.inputList.sort((a, b) =>
        (possibleUrls.find(item => a.label.includes(item.label))?.weight ?? 0) >
        (possibleUrls.find(item => b.label.includes(item.label))?.weight ?? 0)
          ? 1
          : -1),
    }))
  }

  const getLinks = async () => {
    if (upcValidation(Number(scanLink))) {
      setScanModal(true)
      await getLinksByUPC(scanLink)
        .then(result => checkLinkItems(result))
        .finally(() => setScanModal(false))
        .catch(() => setScanModal(false))
    } else if (isrcValidation.test(scanLink)) {
      setScanModal(true)
      await getLinksByISRC(scanLink)
        .then(result => checkLinkItems(result))
        .finally(() => setScanModal(false))
        .catch(() => setScanModal(false))
    } else {
      setScanModal(true)
      await getLinksByUrl(scanLink)
        .then(result => checkLinkItems(result))
        .finally(() => setScanModal(false))
        .catch(() => setScanModal(false))
    }
  }

  return (
    <Stack direction='column' maxWidth={896} spacing={2}>
      <Stack width={1}>
        <Stack
          alignItems='flex-start'
          direction='row'
          justifyContent='space-between'
          paddingTop={2}
          width={1}
        >
          <Typography variant='h3'>
            {props.smartLinkData.type === 'Presave'
              ? 'Pre-save settings'
              : 'Links'}
          </Typography>
        </Stack>

        {props.smartLinkData.type !== 'Shortlink' &&
        <Card
          variant='outlined'
        >
          <Stack padding={2} width={1}>
            {props.smartLinkData.type === 'Presave' &&
            <Typography color='text.secondary' variant='body1'>
              Your song will be added in the libraries of the people who pre-save your release on the release date you
              choose. Please make sure your song is available worldwide on the release date.
            </Typography>}

            {props.smartLinkData.type === 'Smartlink' &&
            <Typography color='text.secondary' variant='body1'>
              Scan for your links by ISRC, UPC, or store link to import them automatically.
            </Typography>}

            {props.smartLinkData.type === 'Smartlink' &&
            <Stack alignItems='center' direction='row' paddingTop={2} spacing={1}>
              <TextField
                autoComplete='new-password'
                disabled={scanModal}
                onChange={event => setScanLink(event.target.value)}
                placeholder='ISRC, UPC, or store link'
                sx={{
                  marginTop: 0,
                  width: 360,
                }}
                value={scanLink}
              />
              <LoadingButton
                disabled={scanLink.length === 0}
                loading={scanModal}
                onClick={async () => getLinks()}
                startIcon={<CloudSyncRoundedIcon />}
                sx={{
                  width: 'fit-content',
                }}
                variant='contained'
              >
                Scan
              </LoadingButton>
            </Stack>}
            {props.smartLinkData.type === 'Presave' &&
            <Stack direction='column' paddingTop={2} spacing={5}>
              <TextField
                label='Song or album code'
                onChange={event => props.setLinkData(old => ({ ...old, isrc: event.target.value.replaceAll(' ', '') }))}
                placeholder='UPC or ISRC code'
                value={props.smartLinkData.isrc}
              />
              <DatePicker
                disablePast
                format='MMMM DD, YYYY'
                label='Release date'
                onChange={date =>
                  props.setLinkData(old => ({ ...old, releaseDate: dayjs(date).toDate() }))}
                value={dayjs(props.smartLinkData.releaseDate)}
              />
              <DesktopTimePicker
                label='Release time'
                onChange={date =>
                  props.setLinkData(old => ({ ...old, releaseDate: dayjs(date).toDate() }))}
                value={dayjs(props.smartLinkData.releaseDate)}
              />
              <Stack
                sx={{
                  marginTop: '24px!important',
                }}
                width={1}
              >
                <SearchArtistComponent
                  label='Artists to follow'
                  limit={5}
                  placeholder='Search for artists'
                  selectedArtists={props.smartLinkData.spotifyFollowArtists}
                  setSelectedArtists={value =>
                    props.setLinkData(old => ({ ...old, spotifyFollowArtists: value ?? [] }))}
                />
              </Stack>
            </Stack>}
          </Stack>
        </Card>}
      </Stack>
      {props.smartLinkData.type !== 'Presave' &&
      props.smartLinkData.type !== 'Biolink' &&
      <EditLinkMarketingServices
        setLinkData={props.setLinkData}
        smartLinkData={props.smartLinkData}
      />}
    </Stack>
  )
}

export default EditLinkServices
