import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, Card, CardActionArea, CardContent, Grid, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'

import CatalogRowModal from 'src/components/pages/Audience/Components/Catalog/CatalogTrackModal'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  readonly avatar: string
  readonly title: string
  readonly release_date: string
  readonly artists: string
  readonly idItem: string
}

const CatalogRowItem = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const row = {
    avatar: props.avatar,
    title: props.title,
    release_date: props.release_date,
    artists: props.artists,
    idItem: props.idItem,
  }

  const [open, setOpen] = useState(false)

  return (
    <>
      <Grid
        item
        lg={3}
        md={4}
        sm={6}
        xl={2.4}
        xs={6}
      >
        <Card
          elevation={0}
          sx={{
            width: 'auto',
            minHeight: 200,
            maxHeight: '100%',
            background: theme => theme.palette.background.elevatedCard,
          }}
        >
          <CardActionArea
            onClick={() => setOpen(true)}
            sx={{
              height: 1,
              width: 1,
            }}
          >
            {row.avatar.length > 0
              ? <Avatar
                src={row.avatar}
                sx={{
                  objectFit: 'cover',
                  aspectRatio: '1/1',
                  width: '100%',
                  height: 'auto',
                  borderRadius: '0px',
                  background: theme => theme.palette.background.input,
                }}
              >
                <MusicNoteRoundedIcon
                  sx={{
                    fontSize: 150,
                    color: theme => theme.palette.action.disabled,
                  }}
                />
              </Avatar>
              : <Paper
                elevation={0}
                sx={{
                  height: 'auto',
                  width: 1,
                  borderRadius: 0,
                  backgroundColor: theme => theme.palette.background.input,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  aspectRatio: '1/1',
                }}
              >
                <MusicNoteRoundedIcon
                  sx={{
                    fontSize: 150,
                    color: theme => theme.palette.action.disabled,
                  }}
                />
              </Paper>}
            <CardContent sx={{ width: 1, padding: mobile ? 1.5 : 2 }}>
              <Stack width={1}>
                <Typography
                  fontSize='1rem'
                  fontWeight={600}
                  lineHeight={1.3}
                  variant='h4'
                >
                  {row.title}
                </Typography>
                <Typography
                  color='textSecondary'
                  sx={{ lineHeight: 1.4 }}
                  variant='body1'
                >
                  {row.artists}
                </Typography>
                <Typography
                  color={themeItem.palette.text.label}
                  variant='body2'
                >
                  {dayjs(row.release_date).format('LL')}
                </Typography>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {open && currentOrganisation && currentOrganisation.membershipTier !== 'FREE' &&
      <CatalogRowModal
        artists={row.artists}
        avatar={row.avatar}
        close={() => setOpen(false)}
        idItem={row.idItem}
        open={open}
        title={row.title}
      />}
    </>
  )
}

export default CatalogRowItem
