import { Button, Stack } from '@mui/material'
import { useEffect, useState } from 'react'

import { getPaymentMethods, removePaymentMethod } from 'src/api/payout'
import OnboardingModal from 'src/components/pages/Wallet/OnboardingModal/OnbordingModal'
import PayoutMethodTableItem from 'src/components/pages/Wallet/PayoutMethods/PayoutMethodItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { PayoutMethod } from 'src/models/Payout'

type Props = {
  readonly refresh: () => void
}

const PayoutMethodTable = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [openNewPayoutMethodModal, setOpenNewPayoutMethodModal] = useState(false)
  const [payoutMethods, setPayoutMethods] = useState<PayoutMethod[]>()

  useEffect(() => {
    void getPaymentMethods()
      .then(setPayoutMethods)
  }, [currentOrganisation?.id, openNewPayoutMethodModal])

  const deletePaymentMethod = async (wiseId: string) => {
    await removePaymentMethod(wiseId)
      .then(() => {
        props.refresh()
        void getPaymentMethods()
          .then(setPayoutMethods)
      })
  }

  return (
    <>
      {openNewPayoutMethodModal &&
        <OnboardingModal
          close={() => setOpenNewPayoutMethodModal(false)}
          open={openNewPayoutMethodModal}
          refresh={props.refresh}
        />}
      <Stack alignItems='flex-start' overflow='auto' spacing={1} width={1}>
        <Stack alignItems='center' direction='row' spacing={1}>
          {
            payoutMethods && payoutMethods.length > 0 &&
                      payoutMethods.map(method =>
                        <Stack
                          alignItems='center'
                          direction='column'
                          key={method.wiseId}
                          spacing={1}
                          width={1}
                        >
                          <PayoutMethodTableItem
                            deletePaymentMethod={deletePaymentMethod}
                            payoutMethod={method}
                          />
                        </Stack>)
          }
          <Button
            color='secondary'
            disabled={payoutMethods?.length === 10}
            onClick={() => setOpenNewPayoutMethodModal(true)}
            rounded
            sx={{
              width: 'fit-content',
              flexShrink: 0,
              color: theme => theme.palette.secondary.main,
            }}
            variant='outlined'
          >
            + Add Payout Method
          </Button>
        </Stack>
      </Stack>
    </>
  )
}

export default PayoutMethodTable

