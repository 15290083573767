/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded'
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import BackupRoundedIcon from '@mui/icons-material/BackupRounded'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ChatBubbleIcon from '@mui/icons-material/ChatBubbleRounded'
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import LibraryMusicIcon from '@mui/icons-material/LibraryMusicRounded'
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { AppBar, Badge, Box, CircularProgress, Divider, Fab, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import store from 'storejs'

import Header from './Header'
import MenuDrawer, { DrawerState } from './MenuDrawer'
import { getRecordings } from 'src/api/recordings'
import type { themeSelector } from 'src/components/App'
import Loading from 'src/components/Loading'
import Audience from 'src/components/pages/Audience/Audience'
import Dashboard from 'src/components/pages/Dashboard/Dashboard'
import Files from 'src/components/pages/Files/Files'
import Links from 'src/components/pages/Links/Links'
import ManageUsers from 'src/components/pages/Login/ManageUsers'
import AudioPlayerItem from 'src/components/pages/Menu/AudioPlayerItem'
import CookiesHandler from 'src/components/pages/Menu/CookiesHandler'
import FilesMenu from 'src/components/pages/Menu/FilesMenuView'
import Content from 'src/components/pages/Menu/MenuContent'
import MenuMessages from 'src/components/pages/Menu/MenuMessages'
import MaxSeatsModal from 'src/components/pages/Menu/MenuModals/MaxSeatsModal'
import PaymentModal from 'src/components/pages/Menu/MenuModals/PaymentModal'
import { IconNavLink } from 'src/components/pages/Menu/MenuNavLink'
import MenuNotifications from 'src/components/pages/Menu/MenuNotifications'
import MenuProfile from 'src/components/pages/Menu/MenuProfile'
import MobileBottomNav from 'src/components/pages/Menu/Mobile/MobileBottomNav'
import MobileMenu from 'src/components/pages/Menu/Mobile/MobileMenu'
import SmallMessenger from 'src/components/pages/Menu/SmallMessenger'
import Messages from 'src/components/pages/Messages/Messages'
import PageNotFound from 'src/components/pages/PageNotFound'
import Projects from 'src/components/pages/Projects/Projects'
import ProjectPrivateShare from 'src/components/pages/Projects/ProjectSharePrivate'
import ReleaseSchedule from 'src/components/pages/Projects/ProjectTabs/ScheduleTab/ReleaseSchedule'
import SplitsTab from 'src/components/pages/Projects/ProjectTabs/SplitsTab/SplitsTab'
import ViewProject from 'src/components/pages/Projects/ViewProject/ViewProject'
import Settings from 'src/components/pages/Settings/Settings'
import Taskboard from 'src/components/pages/Task/Taskboard'
import Wallet from 'src/components/pages/Wallet/Wallet'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useFileUploader } from 'src/components/providers/FilesUploadProvider'
import { MessengerProvider } from 'src/components/providers/MessengerProvider'
import { usePlayer } from 'src/components/providers/MusicProvider'
import { NotificationProvider } from 'src/components/providers/NotificationProvider'
import { WorkspaceProvider } from 'src/components/providers/WorkspaceProvider'
import { Permissions } from 'src/models/Organisation'
import type Recording from 'src/models/Recording'
import SPACING from 'src/styles/spacing'

type Props = {
  readonly changeTheme: (theme: themeSelector) => void
  readonly currentTheme: string
}

const width = 45
const spacing = 7

const drawerWidth = SPACING.themeSpacing(width)

const Menu = (props: Props) => {
  const viewStorage = store.get('menuExpanded') as string | undefined
  const [display, setDisplay] = useState(viewStorage !== undefined ? viewStorage : 'large')
  useEffect(() => {
    store.set('menuExpanded', display)
  }, [display])

  const expandedDisplay = display !== 'small'

  const leftNavigationWidth = expandedDisplay
    ? SPACING.themeSpacing(19)
    : SPACING.themeSpacing(spacing)

  const {
    currentOrganisation,
    currentAccount,
    currentAccountPermissions,
  } = useAuth()
  const { files } = useFileUploader()
  const [drawerState, setDrawerState] = useState(DrawerState.Closed)
  const [smallMessages, setSmallMessages] = useState(false)
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [mobileNavModal, setMobileNavModal] = useState(false)

  const {
    expanded,
    recording,
  } = usePlayer()

  const maxSeatModalCheck = (currentOrganisation?.seats.length ?? 1) > (currentOrganisation?.seatAllocation ?? 1)
  const maxSeatCheckOrg = currentOrganisation?.seats.find(seat =>
    seat.account.id === currentAccount.id && seat.role.name === 'Owner')
  const maxSeatAdminCheck = maxSeatCheckOrg !== undefined

  const [refreshRecordings, setRefreshRecordings] = useState(false)
  const [recordings, setRecordings] = useState<Recording[]>([])
  const unreadMasters = recordings.flatMap(rec =>
    rec.masterRightsHolders.map(master =>
      master.account.id === currentOrganisation?.id && master.status === 'Unlocked'))
    .filter(item => item)
  const unreadPublishers = recordings.flatMap(rec =>
    rec.publisherRightsHolders.map(master =>
      master.account.id === currentOrganisation?.id && master.status === 'Unlocked'))
    .filter(item => item)
  const unreadTotal = unreadMasters.length + unreadPublishers.length

  useEffect(() => {
    void getRecordings()
      .then(setRecordings)
  }, [currentOrganisation?.id, refreshRecordings])

  const toggleDrawer = (toggleState: DrawerState) => () =>
    setDrawerState(drawerState === toggleState ? DrawerState.Closed : toggleState)

  const open = drawerState !== DrawerState.Closed

  return currentOrganisation?.id && currentAccountPermissions
    ? <Stack height={1} overflow='hidden'>
      <CookiesHandler />
      <MessengerProvider>
        <NotificationProvider>
          <WorkspaceProvider>
            <Header
              display={display}
              drawerState={drawerState}
              leftNavigationWidth={SPACING.themeSpacing(spacing)}
              setDisplay={setDisplay}
              toggleDrawer={toggleDrawer}
            />
            <MaxSeatsModal
              close={() => null}
              open={maxSeatModalCheck && !maxSeatAdminCheck}
            />
            <Stack
              direction='row'
              height={1}
              overflow='hidden'
            >
              {!fullScreen &&
              <Stack
                alignItems='center'
                display={{
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'none',
                  xs: 'none',
                }}
                flexShrink={0}
                height={1}
                spacing={2}
                width={leftNavigationWidth}
              >
                <AppBar
                  elevation={3}
                  sx={{
                    width: leftNavigationWidth,
                    top: SPACING.themeSpacing(spacing),
                    left: 0,
                    height: 1,
                    flexShrink: 0,
                    paddingRight: '0px!important',
                    zIndex: 500,
                    background: theme => theme.palette.background.input,
                  }}
                >
                  <Stack
                    alignItems='center'
                    flexShrink={0}
                    height='calc(100% - 80px)'
                    justifyContent='space-between'
                    spacing={2}
                    width={leftNavigationWidth}
                  >
                    <Stack
                      alignItems='center'
                      flexShrink={0}
                      paddingX={1}
                      spacing={expandedDisplay ? 1 : 2}
                      width={leftNavigationWidth}
                    >
                      <IconNavLink
                        expanded={expandedDisplay}
                        size='small'
                        to='/'
                        tooltip='Home'
                      >
                        <HomeRoundedIcon />
                      </IconNavLink>

                      <IconNavLink
                        disabled={!currentAccountPermissions.includes(Permissions.VIEW_PROJECTS)}
                        expanded={expandedDisplay}
                        id='projects'
                        size='small'
                        to='/projects/projects'
                        tooltip='Releases'
                      >
                        <LibraryMusicIcon />
                      </IconNavLink>

                      <IconNavLink
                        disabled={!currentAccountPermissions.includes(Permissions.VIEW_ANALYTICS)}
                        expanded={expandedDisplay}
                        size='small'
                        to='/analytics/overview'
                        tooltip='Analytics'
                      >
                        <AnalyticsRoundedIcon />
                      </IconNavLink>

                      <IconNavLink
                        disabled={!currentAccountPermissions.includes(Permissions.MANAGE_PAYOUTS)}
                        expanded={expandedDisplay}
                        size='small'
                        to='/wallet'
                        tooltip='Wallet'
                      >
                        <AccountBalanceWalletRoundedIcon />
                      </IconNavLink>

                      <Divider
                        sx={{
                          width: '0px',
                          paddingY: 1,
                        }}
                      />
                      {expandedDisplay &&
                      <Typography
                        color='text.label'
                        sx={{
                          width: 1,
                          textAlign: 'left',
                          paddingX: 1.5,
                        }}
                        variant='body2'
                      >
                        Shortcuts
                      </Typography>}

                      <IconNavLink
                        expanded={expandedDisplay}
                        size='small'
                        to='/message'
                        tooltip='Messages'
                      >
                        <ChatBubbleIcon />
                      </IconNavLink>

                      <IconNavLink
                        disabled={!currentAccountPermissions.includes(Permissions.VIEW_TASKBOARDS)}
                        expanded={expandedDisplay}
                        size='small'
                        to='/calendar'
                        tooltip='Tasks'
                      >
                        <TaskAltRoundedIcon />
                      </IconNavLink>

                      <IconNavLink
                        disabled={!currentAccountPermissions.includes(Permissions.VIEW_PROJECTS)}
                        expanded={expandedDisplay}
                        size='small'
                        to='/files/home'
                        tooltip='Files'
                      >
                        <FolderRoundedIcon />
                      </IconNavLink>

                      <IconNavLink
                        disabled={!currentAccountPermissions.includes(Permissions.VIEW_MARKETING_LINKS)}
                        expanded={expandedDisplay}
                        size='small'
                        to='/links'
                        tooltip='Smart Links'
                      >
                        <DevicesRoundedIcon />
                      </IconNavLink>

                      <Badge
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        badgeContent={unreadTotal}
                        color='error'
                        overlap='circular'
                        sx={{
                          width: expandedDisplay ? 1 : undefined,
                          '.MuiBadge-dot': {
                            display: unreadTotal > 0 ? undefined : 'none',
                            width: 12,
                            height: 12,
                            border: 2,
                            borderColor: 'background.paper',
                            borderRadius: '50%',
                          },
                        }}
                      >
                        <IconNavLink
                          disabled={!currentAccountPermissions.includes(Permissions.MANAGE_SPLITS)}
                          expanded={expandedDisplay}
                          id='splits'
                          size='small'
                          to='/splits'
                          tooltip='Splits'
                        >
                          <PieChartRoundedIcon />
                        </IconNavLink>
                      </Badge>

                      <IconNavLink
                        disabled={!currentAccountPermissions.includes(Permissions.VIEW_PROJECTS)}
                        expanded={expandedDisplay}
                        size='small'
                        to='/schedule'
                        tooltip='Schedule'
                      >
                        <CalendarMonthIcon />
                      </IconNavLink>

                    </Stack>
                    {(files ? files.length : 0) > 0 &&
                    <Box
                      sx={{
                        position: 'relative',
                      }}
                    >
                      <Fab
                        aria-label='loading'
                        color='default'
                        size='small'
                        sx={{
                          color: theme => theme.palette.primary.main,
                          boxShadow: 'none',
                          backgroundColor: theme => theme.palette.background.default,
                          width: '32px',
                          height: '32px',
                          minHeight: '32px',
                        }}
                      >
                        <BackupRoundedIcon />
                      </Fab>
                      <CircularProgress
                        color='primary'
                        size={39}
                        sx={{
                          position: 'absolute',
                          top: -3.6,
                          left: -3.6,
                          zIndex: 1,
                        }}
                      />
                    </Box>}
                  </Stack>
                </AppBar>
              </Stack>}

              <Content
                elevation={0}
                open={open}
                sx={{
                  height: expanded && recording && !fullScreen
                    ? 'calc(100% - 64px)!important'
                    : '100%',
                  overflow: 'visible',
                  borderRadius: {
                    xl: 0,
                    lg: 0,
                    md: 0,
                    sm: 0,
                    xs: 0,
                  },
                  width: `calc(100% - ${leftNavigationWidth})`,
                  backgroundColor: theme => theme.palette.background.input }}
              >
                <Box
                  height='100%'
                  overflow='visible'
                >
                  <Routes>
                    <Route element={<Dashboard currentTheme={props.currentTheme} />} path='/' />
                    {currentAccountPermissions.includes(Permissions.VIEW_PROJECTS) &&
                    <Route element={<Projects />} path='/projects/*' />}
                    {currentAccountPermissions.includes(Permissions.VIEW_PROJECTS) &&
                    <Route element={<ReleaseSchedule />} path='/schedule' />}
                    {currentAccountPermissions.includes(Permissions.VIEW_TASKBOARDS) &&
                    <Route element={<Taskboard />} path='/calendar/:taskboardId' />}
                    {currentAccountPermissions.includes(Permissions.VIEW_TASKBOARDS) &&
                    <Route element={<Taskboard />} path='/calendar/doc/:taskboardId' />}
                    {currentAccountPermissions.includes(Permissions.VIEW_TASKBOARDS) &&
                    <Route element={<Taskboard />} path='/calendar/embed/:taskboardId' />}
                    {currentAccountPermissions.includes(Permissions.VIEW_TASKBOARDS) &&
                    <Route element={<Taskboard />} path='/calendar' />}
                    <Route element={<Messages />} path='/message' />
                    <Route element={<Messages />} path='/message/:correspondantId' />
                    <Route element={<Settings />} path='/settings/*' />
                    {currentAccountPermissions.includes(Permissions.VIEW_PROJECTS) &&
                    <Route element={<ProjectPrivateShare />} path='/invite/:id/:hash' />}
                    {currentAccountPermissions.includes(Permissions.VIEW_PROJECTS) &&
                    <Route element={<ViewProject refresh={setRefreshRecordings} />} path='/project/:id' />}
                    {currentAccountPermissions.includes(Permissions.VIEW_MARKETING_LINKS) &&
                    <Route element={<Links />} path='/links/*' />}
                    {currentAccountPermissions.includes(Permissions.VIEW_PROJECTS) &&
                    <Route element={<Files />} path='/files/*' />}
                    {currentAccountPermissions.includes(Permissions.VIEW_ANALYTICS) &&
                    <Route element={<Audience />} path='/analytics/*' />}
                    {currentAccountPermissions.includes(Permissions.MANAGE_SPLITS) &&
                    <Route element={<SplitsTab refresh={setRefreshRecordings} />} path='/splits' />}

                    <Route element={<ManageUsers />} path='/manageuser/*' />
                    {currentAccountPermissions.includes(Permissions.MANAGE_PAYOUTS) &&
                    <Route element={<Wallet />} path='/wallet' />}

                    <Route element={<PageNotFound />} path='*' />
                  </Routes>
                </Box>
              </Content>
            </Stack>
            <AudioPlayerItem />
            <SmallMessenger
              expanded={smallMessages}
              openSmallMessages={setSmallMessages}
            />
            <MobileMenu
              changeTheme={props.changeTheme}
              close={() => setMobileNavModal(false)}
              currentTheme={props.currentTheme}
              isOpen={mobileNavModal}
              open={() => setMobileNavModal(true)}
            />
            <Stack
              visibility={fullScreen
                ? 'hidden'
                : 'visible'}
            >
              <FilesMenu />
            </Stack>
            <PaymentModal />
            <MenuDrawer
              drawerWidth={drawerWidth}
              onClose={toggleDrawer(DrawerState.Closed)}
              open={open}
            >
              {(drawerState === DrawerState.Messages) &&
              <MenuMessages
                openSmallMessages={setSmallMessages}
                toggleDrawer={setDrawerState}
              />}
              {drawerState === DrawerState.Notifications &&
              <MenuNotifications
                toggleDrawer={setDrawerState}
              />}
              {drawerState === DrawerState.Profile &&
              <MenuProfile
                changeTheme={props.changeTheme}
                currentTheme={props.currentTheme}
                toggleDrawer={toggleDrawer}
              />}
            </MenuDrawer>
            <MobileBottomNav
              setMobileMenuOpen={setMobileNavModal}
            />
          </WorkspaceProvider>
        </NotificationProvider>
      </MessengerProvider>
    </Stack>
    : <Stack height={1} overflow='hidden'>
      <Loading />
    </Stack>
}

export default Menu
