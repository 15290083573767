import { Chip, darken, Stack } from '@mui/material'

import { setAnalyticsTitle } from 'src/components/pages/Analytics/AnalyticsDistribution/AnalyticsDistributionAppbar'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'

type Props = {
  seriesTest: string[]
  setActiveOption: (value: React.SetStateAction<string>) => void
  activeOption: string
}

const SelectModeAnalyticsPlatform = (props: Props) =>
  <Stack
    alignItems='center'
    direction='row'
    flexDirection='row'
    flexWrap='wrap'
    width={1}
  >
    {props.seriesTest.map(item =>
      <Chip
        clickable
        color={props.activeOption === item ? 'primary' : 'default'}
        icon={GetIcon(item)}
        key={item}
        label={setAnalyticsTitle(item)}
        onClick={() => props.setActiveOption(item)}
        rounded
        size='medium'
        sx={{
          borderRadius: '64px',
          marginRight: 1,
          marginBottom: 1,
          paddingLeft: 0.8,
          background: props.activeOption === item
            ? GetColorLink(item)
            : undefined,
          ':hover': {
            background: props.activeOption === item
              ? darken(GetColorLink(item), 0.24)
              : undefined,
          },
        }}
      />)}
  </Stack>

export default SelectModeAnalyticsPlatform

