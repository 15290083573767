import { Skeleton, Stack, Table, TableCell, TableHead, TablePagination, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import SearchCountryItem from 'src/components/pages/Audience/Components/shared/SearchCountry'
import TopArtistItemSearch from 'src/components/pages/Audience/Components/Top Artists/TopArtistItemSearch'
import type { TopArtistAnalytics } from 'src/models/AnalyticsCloud'

type Props = {
  topArtists: TopArtistAnalytics[]
  loading: boolean
}

const TopArtistsCloud = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const topArtists = props.topArtists.sort(item => item.territory === 'global' ? -1 : 1)

  const [country, setCountry] = useState('global')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const activeItem = topArtists.find(item => item.territory === country)

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    setPage(0)
  }, [rowsPerPage, country])

  return (
    <Stack direction='row' id='analytics-container' width={1}>
      <Stack
        alignItems='stretch'
        boxSizing='border-box'
        direction={mobile ? 'column' : 'row'}
        minWidth={0}
        padding={0}
        width={1}
      >
        <Stack
          direction='column'
          minWidth={0}
          padding={0}
          spacing={2}
          width={1}
        >
          <Stack width={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={2} width={1}>
              <Typography lineHeight={1} variant='h3'>
                Top Artists
              </Typography>

              <SearchCountryItem
                countries={topArtists.map(items => items.territory)}
                country={country}
                setCountry={setCountry}
              />
            </Stack>
            {props.loading &&
            <Stack width={1}>
              <Stack
                alignItems='center'
                direction='row'
                flexDirection='row'
                flexWrap='wrap'
                width={1}
              >
                {Array.from({ length: 6 }, () =>
                  <Skeleton
                    sx={{
                      width: 120,
                      height: 32,
                      transform: 'none',
                      marginBottom: 0.5,
                      marginRight: 0.5,
                    }}
                  />)}
              </Stack>
            </Stack>}
            {activeItem?.artists && !props.loading &&
            <Stack
              direction='column'
              key={activeItem.id}
              minWidth={0}
              padding={0}
              width={1}
            >
              <Table>
                <TableHead
                  sx={{
                    '.MuiTableCell-root': {
                      borderBottom: 2,
                      borderBottomColor: 'divider',
                      color: 'text.secondary',
                    },
                  }}
                >
                  <TableRow>
                    <TableCell
                      align='left'
                      padding='normal'
                    >
                      Position
                    </TableCell>
                    <TableCell
                      align='left'
                      padding='normal'
                    >
                      Artist
                    </TableCell>
                    {!mobile &&
                    <>
                      <TableCell
                        align='left'
                        padding='normal'
                      >
                        Genres
                      </TableCell>
                      <TableCell
                        align='left'
                        padding='normal'
                      >
                        Popularity
                      </TableCell>
                    </>}
                  </TableRow>
                </TableHead>
                {activeItem.artists.items
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(artist =>
                    <TopArtistItemSearch
                      artist={artist}
                      index={activeItem.artists?.items.findIndex(item => item.spotifyId === artist.spotifyId) ?? 0}
                      key={artist.spotifyId}
                    />)}
              </Table>
              <TablePagination
                component='div'
                count={activeItem.artists.items.length}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
              />
            </Stack>}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default TopArtistsCloud
