export type SearchTerritoryItem = {
  code: string
  playlist: string
}

export type TrackItemSingle = {
  id: string
  name: string
  popularity: number
  explicit: boolean
  artists: {
    id: string
    name: string
  }[]
  external_ids: {
    isrc: string
  }
  oldPosition: number
  album: {
    id: string
    name: string
    images: {
      url: string
      height: number
      width: number
    }[]
    release_date: string
  }
}

export type ArtistItemSingle = {
  id: string
  name: string
  popularity: number
  genres: string[]
  followers: {
    total: number
  }
  spotifyId: string
  oldPosition: number
  images: {
    url: string
    height: number
    width: number
  }[]
  indexPosition: number
}

export type ArtistItemMap = {
  items: ArtistItemSingle[]
}

export type TrackItemMap = {
  items: TrackItemSingle[]
}

export class TopArtistAnalytics {
  territory = ''
  id = ''
  artists?: ArtistItemMap

  constructor(dto: TopArtistAnalytics) {
    Object.assign(this, dto)
  }
}

export class TopTracksAnalytics {
  territory = ''
  id = ''
  tracks?: TrackItemMap

  constructor(dto: TopTracksAnalytics) {
    Object.assign(this, dto)
  }
}

export class ViralTracksAnalytics {
  territory = ''
  id = ''
  tracks?: TrackItemMap

  constructor(dto: ViralTracksAnalytics) {
    Object.assign(this, dto)
  }
}

export class NewMusicFridayTrackAnalytics {
  territory = ''
  id = ''
  tracks?: TrackItemMap
  name = ''
  imageUrl = ''
  followers = 0

  constructor(dto: NewMusicFridayTrackAnalytics) {
    Object.assign(this, dto)
  }
}

export class NewMusicFridayTopGainersnalytics {
  _id = ''
  name = ''
  artists?: {
    id: string
    name: string
  }[]
  artwork: string[] = []
  popularity = 0
  explicit = false
  releaseDate = ''
  followers = 0
  count = 0

  constructor(dto: NewMusicFridayTopGainersnalytics) {
    Object.assign(this, dto)
  }
}
