import { Button, Card, CardActionArea, darken, Divider, Stack, Typography, useTheme } from '@mui/material'

type Props = {
  readonly activeBilling: number
  readonly billingCycle: number
  readonly handleBilling: (step: number) => () => void
  readonly enterprise: number
  readonly month: number
  readonly seats: number
}

const EnterpriseCard = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Card
      sx={{
        borderColor: props.activeBilling === props.enterprise
          ? '#327659!important'
          : null,
        borderWidth: '2px',
      }}
      variant='outlined'
    >
      <CardActionArea onClick={props.handleBilling(props.enterprise)} sx={{ height: 1 }} >
        <Stack alignItems='flex-start' direction='column' height={1} padding={2} spacing={2}>
          <Stack>
            <Typography fontWeight='normal' variant='h2'>
              Business
            </Typography>
            <Stack alignItems='flex-start' direction='column' spacing={1}>
              <Typography fontFamily='Domaine-Display' lineHeight={1} variant='h1'>
                <span style={{ fontFamily: 'Matter' }}>
                  $
                </span>
                {props.billingCycle === props.month
                  ? <>
                    {((props.billingCycle *
                  props.enterprise) +
                  Math.max(0, props.billingCycle *
                  (props.seats - 3) *
                  19))}
                  </>
                  : <>
                    {(((props.billingCycle *
                    props.enterprise) +
                    Math.max(0, props.billingCycle *
                    (props.seats - 3) *
                    19)) / 12).toFixed(2)}
                  </>}
              </Typography>
              {props.billingCycle === props.month
                ? <Typography variant='body1'>
                  / month
                </Typography>
                : <Typography variant='body1'>
                  / month, billed yearly
                </Typography>}
            </Stack>
          </Stack>
          <Typography
            color={themeItem.palette.text.secondary}
            variant='body2'
          >
            Next-gen music management and financial tools.
          </Typography>
          <Button
            color='info'
            disabled={props.activeBilling === props.enterprise}
            fullWidth
            sx={{
              background: '#327659',
              '&:hover': {
                background: darken('#327659', 0.25),
              },
            }}
          >
            {props.activeBilling === props.enterprise
              ? 'Business Selected'
              : 'Select Business'}
          </Button>
          <Stack direction='column'>
            <Typography lineHeight={1.4} variant='h3'>
              Features
            </Typography>
            <Typography color={themeItem.palette.text.secondary} variant='body2'>
              Everything in Pro and...
            </Typography>
          </Stack>
          <Divider sx={{ width: 1 }} />
          <Stack direction='column' spacing={2}>
            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                3 User Logins
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Audience Search
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                A&R Recommendations
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Royalty Recoups
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Territory Carving
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Dedicated Account Manager
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Custom User Roles
              </Typography>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography color='text.secondary' variant='body2'>
                Custom Releese Services
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default EnterpriseCard
