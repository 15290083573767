/* eslint-disable no-restricted-imports */
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded'
import NotificationAddRoundedIcon from '@mui/icons-material/NotificationAddRounded'
import { LoadingButton } from '@mui/lab'
import { Button, Divider, Paper, Stack, Typography } from '@mui/material'
import countries from 'i18n-iso-countries'
import data from 'i18n-iso-countries/langs/en.json'
import { useEffect, useState } from 'react'

import { getAllSubscribers } from 'src/api/allLinkAnalytics'
import { downloadFans, getAllFans } from 'src/api/links'
import SearchField from 'src/components/form-elements/SearchField'
import LinkFansModalFree from 'src/components/pages/Links/Fans/LinkFansModalFree'
import ViewLinkFans from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkFans'
import ViewSubscribers from 'src/components/pages/Links/ViewLink/ViewLinkComponents/ViewLinkTypes/ViewLinkSubscribers'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import type { MarketingLinkFan } from 'src/models/Marketing'
import type { Subscriber } from 'src/models/Subscriber'
import SPACING from 'src/styles/spacing'

const LinkFans = () => {
  const { currentOrganisation } = useAuth()
  const [fans, setFans] = useState<MarketingLinkFan[]>()
  const [subscribers, setSubscribers] = useState<Subscriber[]>()
  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const downloadAllFans = async () => {
    if (currentOrganisation?.membershipTier === 'CREATOR_PRO' ||
    currentOrganisation?.membershipTier === 'CREATOR_PRO_YEARLY' ||
    currentOrganisation?.membershipTier === 'ENTERPRISE' ||
    currentOrganisation?.membershipTier === 'ENTERPRISE_YEARLY') {
      setLoading(true)
      await downloadFans()
        .finally(() => setLoading(false))
    } else {
      setOpen(true)
    }
  }

  useEffect(() => {
    void getAllFans()
      .then(setFans)
    void getAllSubscribers()
      .then(setSubscribers)
  }, [currentOrganisation?.id])

  countries.registerLocale(data)

  return (
    <>
      <LinkFansModalFree
        close={() => setOpen(false)}
        open={open}
      />
      <Stack height={1} width={1}>
        <Stack
          direction='row'
          padding={SPACING.CONTENT_PADDING}
          sx={{
            overflowX: 'auto',
            overflowY: 'clip',
            flexShrink: 0,
          }}
          width={1}
        >
          <Stack alignItems='center' direction='row' spacing={1} width={1}>
            <SearchField
              onChange={event => setSearchQuery(event.target.value)}
              value={searchQuery}
            />
          </Stack>
          <Stack
            alignItems='center'
            direction='row'
            marginLeft='auto'
            paddingLeft={1}
            spacing={1}
            sx={{
              flexShrink: 0,
            }}
          >
            <LoadingButton
              disabled={!fans || fans.length === 0}
              loading={loading}
              onClick={() => downloadAllFans()}
              startIcon={<GetAppRoundedIcon />}
              variant='contained'
            >
              Export
            </LoadingButton>
          </Stack>
        </Stack>
        <Divider sx={{ width: 1 }} />
        {subscribers && fans && (subscribers.length > 0 || fans.length > 0) &&
        <Stack padding={SPACING.CONTENT_PADDING} width={1}>
          <ViewSubscribers
            marketingLinkFans={subscribers}
            query={searchQuery}
          />
          <ViewLinkFans
            marketingLinkFans={fans}
            query={searchQuery}
          />
        </Stack>}
        {subscribers && fans && subscribers.length === 0 && fans.length === 0 &&
        <Stack padding={SPACING.CONTENT_PADDING} width={1}>
          <Stack
            alignItems='center'
            height={1}
            justifyContent='center'
            marginTop={4}
            width='100%'
          >
            <Paper
              elevation={0}
              sx={{
                backgroundColor: theme => theme.palette.primary.main,
                borderRadius: 16,
              }}
            >
              <Stack
                alignItems='center'
                justifyContent='center'
                padding={2}
              >
                <NotificationAddRoundedIcon
                  sx={{
                    color: theme => theme.palette.primary.contrastText,
                    fontSize: '64px',
                  }}
                />
              </Stack>
            </Paper>
            <Typography
              color='textPrimary'
              sx={{
                marginTop: 1,
              }}
              variant='h4'
            >
              No Subscribers (Yet!)
            </Typography>
            <Typography
              color='textSecondary'
              textAlign='center'
              variant='body1'
            >
              Share your Pre-save pages and Bio Link to gain new Releese subscribers. Your subscribers will pre-save all
              your music and get notified by email whenever you release something new.
            </Typography>
            <Button component={NavLink} sx={{ marginTop: 2 }} to='/links'>
              Explore Marketing Suite
            </Button>
          </Stack>
        </Stack>}
      </Stack>
    </>
  )
}

export default LinkFans
