/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable react/jsx-key */
/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable react-hooks/exhaustive-deps */
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import { Autocomplete, Avatar, Box, InputAdornment, ListItemText, Stack, TextField  } from '@mui/material'

import type { NewMusicFridayTrackAnalytics } from 'src/models/AnalyticsCloud'

type Props = {
  latestTracks: NewMusicFridayTrackAnalytics[]
  setCountry: (value: React.SetStateAction<string>) => void
  country: string
}

const SearchPlaylistLatest = (props: Props) =>
  <Autocomplete
    autoHighlight
    autoSelect
    disableClearable
    getOptionLabel={option => option.name}
    onChange={(_, newValue) => props.setCountry(newValue.territory ?? '')}
    options={props.latestTracks}
    renderInput={params =>
      <TextField
        {...params}
        placeholder='Select a country'
        slotProps={{
          ...params,
          input: {
            ...params.InputProps,
            startAdornment:
                        <InputAdornment position='start'>
                          <Avatar
                            src={props.latestTracks.find(item => item.territory === props.country)?.imageUrl}
                            sx={{
                              background: theme => theme.palette.info.main,
                              color: theme => `${theme.palette.info.contrastText}!important`,
                              height: 35,
                              width: 35,
                              borderRadius: '4px',
                            }}
                            variant='rounded'
                          >
                            <PublicRoundedIcon
                              sx={{ color: theme => `${theme.palette.info.contrastText}!important` }}
                            />
                          </Avatar>
                        </InputAdornment>
            ,
          },
        }}
        sx={{ marginTop: 0,  height: 58, '.MuiInputBase-root': { height: 54 } }}
      />}
    renderOption={(renderProps, option) =>
      <Box component='li' {...renderProps}>
        <Stack alignItems='center' direction='row' spacing={1} width={1}>
          <Avatar
            src={option.imageUrl}
            sx={{
              background: theme => theme.palette.info.main,
              color: theme => `${theme.palette.info.contrastText}!important`,
              height: 35,
              width: 35,
              borderRadius: '4px',
            }}
            variant='rounded'
          >
            <PublicRoundedIcon />
          </Avatar>
          <ListItemText
            primary={option.name}
          />
        </Stack>
      </Box>}
    sx={{ marginTop: 0, height: 48, width: 240 }}
    value={props.latestTracks.find(item => item.territory === props.country)}
  />

export default SearchPlaylistLatest
