import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Card, CardActionArea, CardMedia, Paper, Stack, Typography } from '@mui/material'

import { RouterLink } from 'src/components/SafeRouterLink'
import type Project from 'src/models/Project'

type Props = {
  readonly project: Project
}

const ReleaseItem = (props: Props) =>
  <Card
    elevation={0}
    sx={{
      background: theme => theme.palette.background.elevatedCard,
      width: 160,
    }}
  >
    <CardActionArea
      component={RouterLink}
      sx={{
        height: 1,
      }}
      to={`/project/${props.project.id}`}
    >
      {props.project.artworkPreviewUrl
        ? <CardMedia
          alt='Artwork'
          component='img'
          src={props.project.artworkPreviewUrl}
          sx={{
            objectFit: 'cover',
            aspectRatio: '1/1',
            height: 'auto',
          }}
          width='126px'
        />
        : <Paper
          elevation={0}
          sx={{
            height: 'auto',
            width: 1,
            borderRadius: 0,
            backgroundColor: theme => theme.palette.background.input,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            aspectRatio: '1/1',
          }}
        >
          <MusicNoteRoundedIcon
            sx={{
              fontSize: 126,
              color: theme => theme.palette.action.disabled,
            }}
          />
        </Paper>}
      <Stack alignItems='flex-start' padding={2}>
        <Typography lineHeight={1.2} sx={{ wordBreak: 'break-word' }} textAlign='left' variant='h4'>
          {props.project.title}
        </Typography>
        <Typography color='text.label' textAlign='left' variant='body2'>
          {props.project.primaryArtists.map(item => item.name).join(', ')}
        </Typography>
      </Stack>
    </CardActionArea>
  </Card>

export default ReleaseItem
