/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable sonarjs/no-identical-functions */
// eslint-disable-next-line no-restricted-imports
import { Divider, Stack } from '@mui/material'
import { useState } from 'react'

import SearchField from 'src/components/form-elements/SearchField'
import ViewFolder from 'src/components/pages/Files/Folder/ViewFolder'
import FolderHome from 'src/components/pages/Files/Home/FolderHome'
import { DropzoneProvider } from 'src/components/pages/Projects/FullscreenDropzone'
import type Project from 'src/models/Project'

type Props = {
  project?: Project
}

const ViewProjectAttachmentsTab = (props: Props) => {
  const [viewType, setViewType] = useState('list')
  const [searchQuery, setSearchQuery] = useState('')
  const [location, setLocation] = useState('')
  const images = false
  const files = false
  const music = false
  const documents = false

  return (
    <Stack alignItems='center' paddingBottom={4} width={1}>
      <Stack
        direction='row'
        paddingX={2}
        paddingY={2}
        sx={{
          overflowX: 'auto',
          overflowY: 'clip',
          flexShrink: 0,
        }}
        width={1}
      >
        <Stack direction='row' marginRight='auto' spacing={1}>
          <SearchField
            onChange={event => setSearchQuery(event.target.value)}
            value={searchQuery}
          />
        </Stack>

      </Stack>
      <Divider sx={{ width: 1 }} />
      <Stack height={1} width={1}>
        <DropzoneProvider>
          {location === ''
            ? <FolderHome
              display={viewType}
              documents={documents}
              files={files}
              images={images}
              music={music}
              project={props.project}
              searchQuery={searchQuery}
              setDisplay={setViewType}
              setLocation={setLocation}
            />
            : <ViewFolder
              display={viewType}
              documents={documents}
              files={files}
              images={images}
              location={location}
              music={music}
              project={props.project}
              searchQuery={searchQuery}
              setDisplay={setViewType}
              setLocation={setLocation}
            />}
        </DropzoneProvider>
      </Stack>
    </Stack>
  )
}

export default ViewProjectAttachmentsTab
