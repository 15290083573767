/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable unicorn/better-regex */
/* eslint-disable no-await-in-loop */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded'
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded'
import { Card, IconButton, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { v4 } from 'uuid'

import { artistIntelligenceSpecficData } from 'src/api/artistIntelligence'

const timeout = (delay: number) => new Promise(response => setTimeout(response, delay))

type Props = {
  analyticsType: string
  countryCode: string
}

const ArtistIntelligenceCard = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const componentRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  const [key, setKey] = useState('')
  const [artistIntelligenceResponse, setArtistIntelligenceResponse] = useState<{ key: string, text: string }>({ key: '', text: '' })
  const [loadingArtistIntelligence, setLoadingArtistIntelligence] = useState(false)
  const [blurb, setBlurb] = useState<{ key: string, text: string }[]>([{ key: '', text: '' }])
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    const newKey = v4()
    setKey(newKey)
    setLoadingArtistIntelligence(true)
    void artistIntelligenceSpecficData(props.analyticsType, props.countryCode)
      .then(result => setArtistIntelligenceResponse({ key: newKey, text: result }))
      .catch(() => setArtistIntelligenceResponse({ key: newKey, text: 'I could not process your request. I am still in beta, sorry.' }))
      .finally(() => setLoadingArtistIntelligence(false))
  }, [props.analyticsType, props.countryCode])

  const generateBlurb = async (itemString: string) => {
    const textItems = itemString.split(' ')
    for (const textItem of textItems) {
      if (textItem.length > 0) {
        setBlurb(oldValue => !oldValue.some(item => item.key === key)
          ? [...oldValue, { key, text: `${textItem}` }]
          : oldValue.map(item =>
            item.key === key
              ? { key, text: `${item.text} ${textItem}` }
              : item))
      }
      await timeout(25)
    }
  }

  useEffect(() => {
    void generateBlurb(artistIntelligenceResponse.text)
  }, [artistIntelligenceResponse.text])

  useEffect(() => {
    const getDimensions = () => componentRef.current
      ? {
        width: componentRef.current.offsetWidth,
        height: componentRef.current.offsetHeight,
      } : {
        width: 0,
        height: 0,
      }

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    setDimensions(getDimensions())

    window.addEventListener('load', handleResize)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('load', handleResize)
      window.removeEventListener('resize', handleResize)
    }
  }, [componentRef, blurb])

  return (
    <Stack width={1}>
      <Card
        elevation={0}
        sx={{
          background: theme => theme.palette.background.elevatedCard,
          position: 'relative',
          transition: 'height 0.25s ease-in',
          minHeight: mobile ? '300px' : '200px',
          height: expanded
            ? dimensions.height + 32
            : mobile
              ? '300px'
              : '200px',
        }}
      >
        <Stack height={1} padding={2} spacing={1} sx={{ overflowY: 'scroll' }} width={1}>
          <Stack ref={componentRef} spacing={1} width={1}>
            <Stack alignItems='center' direction='row' spacing={0.5} width={1}>
              <AutoAwesomeRoundedIcon
                sx={{
                  color: 'rgba(186,120,255,1)',
                }}
              />
              <Typography
                lineHeight={1.2}
                sx={{
                  background: 'linear-gradient(90deg, rgba(186,120,255,1) 25%, rgba(255,136,228,1) 75%)',
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
                variant='h4'
              >
                Artist Intelligence
              </Typography>
            </Stack>
            {loadingArtistIntelligence &&
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>}
            <Stack width={1}>
              <Typography
                color='text.secondary'
                key={v4()}
                sx={{
                  '& > *': {
                    marginBottom: 1,
                    marginTop: 0,
                  },
                }}
                variant='body1'
              >
                <ReactMarkdown>
                  {blurb.find(item => item.key === key)?.text}
                </ReactMarkdown>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <IconButton
          onClick={() => setExpanded(old => !old)}
          size='small'
          sx={{
            position: 'absolute',
            bottom: 8,
            right: 8,
          }}
        >
          {expanded
            ? <CloseFullscreenRoundedIcon />
            : <OpenInFullRoundedIcon />}
        </IconButton>
      </Card>
    </Stack>
  )
}

export default ArtistIntelligenceCard
