/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, Chip, Collapse, Divider, IconButton, LinearProgress, Paper, Snackbar, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'

import { getProjectPublic } from 'src/api/projects'
import { remindRightsholders, toggleSplit } from 'src/api/splits'
import ViewUserSplit from 'src/components/pages/Projects/ProjectTabs/SplitsTab/ViewUserSplit'
import { generateTitleRecording } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/EditRecordingComponent'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  readonly recording: Recording
  readonly refresh: React.Dispatch<React.SetStateAction<boolean>>
}

const SplitsItem = (props: Props) => {
  const [savedToast, setSavedToastOpen] = useState(false)
  const [project, setProject] = useState<Project>()
  const theme = useTheme()

  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const { currentOrganisation, currentAccountPermissions } = useAuth()

  useEffect(() => {
    void getProjectPublic(props.recording.projectId)
      .then(setProject)
      .catch(() => null)
  }, [])

  const [loadingReminderMaster, setLoadingReminderMaster] = useState(false)
  const [loadingReminderPub, setLoadingReminderPub] = useState(false)

  const [expanded, setExpanded] = useState(false)

  const [loadingPub, setLoadingPub] = useState(false)
  const [loadingMaster, setLoadingMaster] = useState(false)

  // Splits
  const checkMasterOwnership = Number(props.recording.masterRightsHolders.map(holder =>
    Number(holder.ownership.toFixed(2)))
    .reduce((a, b) => a + b, 0).toFixed(2)) === 100
  const checkPublishingOwnership = Number(props.recording.publisherRightsHolders.map(holder =>
    Number(holder.ownership.toFixed(2)))
    .reduce((a, b) => a + b, 0).toFixed(2)) === 100
  const rightsHolderMaster = props.recording.masterRightsHolders.find(rightsholder =>
    rightsholder.account.id === currentOrganisation?.id)
  const publishingRightsHolder = props.recording.publisherRightsHolders.find(rightsholder =>
    rightsholder.account.id === currentOrganisation?.id)

  const masterOwnershipTotalLocked = props.recording.masterRightsHolders.map(holder =>
    holder.status === 'Locked'
      ?  Number(holder.ownership.toFixed(2))
      : 0)
    .reduce((a, b) => a + b, 0)
  const publishingOwnershipTotalLocked = props.recording.publisherRightsHolders.map(holder =>
    holder.status === 'Locked'
      ?  Number(holder.ownership.toFixed(2))
      : 0)
    .reduce((a, b) => a + b, 0)

  const [lockedPubItem, setLockedPubItem] = useState(publishingRightsHolder?.status === 'Locked')
  const [lockedMasterItem, setLockedMasterItem] = useState(rightsHolderMaster?.status === 'Locked')

  const checkMasterLocked = props.recording.masterRightsHolders.map(holder =>
    holder.status === 'Locked')
    .every(element => element) && checkMasterOwnership

  const checkPubLocked = props.recording.publisherRightsHolders.map(holder =>
    holder.status === 'Locked')
    .every(element => element) && checkPublishingOwnership

  const updateRightsHolder = async (type: 'master' | 'publishing') => {
    if (type === 'master') {
      setLoadingMaster(true)
    } else {
      setLoadingPub(true)
    }
    await toggleSplit(props.recording.id, type)
      .then(recordingUpdate => {
        setLoadingMaster(false)
        setLoadingPub(false)

        setLockedPubItem(recordingUpdate.publisherRightsHolders.find(rightsholder =>
          rightsholder.account.id === currentOrganisation?.id)?.status === 'Locked')
        setLockedMasterItem(recordingUpdate.masterRightsHolders.find(rightsholder =>
          rightsholder.account.id === currentOrganisation?.id)?.status === 'Locked')

        setSavedToastOpen(true)
        props.refresh(old => !old)
      })
  }

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSavedToastOpen(false)
  }

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        message='Recording saved'
        onClose={handleCloseToast}
        open={savedToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Saved
        </Alert>
      </Snackbar>
      <Paper
        elevation={0}
        sx={{
          width: 1,
          marginX: 'auto!important',
          maxWidth: 900,
          background: theme.palette.background.elevatedCard,
        }}
      >
        <Stack direction='column' padding={2} spacing={2} width={1}>
          <Stack
            direction={matches ? 'column' : 'row'}
            key={props.recording.id}
            spacing={matches ? 1 : 2}
          >
            <Stack id='project_image'>
              {project?.artworkPreviewUrl
                ? <img
                  alt='Artwork'
                  height='48px'
                  src={project.artworkPreviewUrl}
                  style={{
                    borderRadius: 8,
                    objectFit: 'cover',
                  }}
                  width='48px'
                />
                : <Paper
                  elevation={0}
                  sx={{
                    height: 48,
                    borderRadius: 0.5,
                    width: 48,
                    backgroundColor: theme.palette.background.input,
                  }}
                >
                  <MusicNoteRoundedIcon
                    sx={{
                      fontSize: 48,
                      color: theme.palette.action.disabled,
                    }}
                  />
                </Paper>}
            </Stack>

            <Stack direction='column' width={1}>
              <Typography
                color={theme.palette.text.label}
                lineHeight={1}
                variant='body1'
              >
                {props.recording.primaryArtists.map((artist, index) =>
                  `${artist.name}${props.recording.primaryArtists.length === index + 1 ? '' : ', '}`)}
              </Typography>
              <Typography
                fontSize='1.15rem'
                lineHeight={1.5}
                variant='h4'
              >
                {generateTitleRecording(undefined, props.recording)}
              </Typography>
            </Stack>

            {project?.distributed &&
              <Chip
                color='primary'
                icon={<PublishRoundedIcon />}
                label='Distributed'
                sx={{
                  width: 'fit-content',
                }}
              />}

            <Stack spacing={2}>
              <Button
                color='primary'
                onClick={() => setExpanded(old => !old)}
                size='small'
                startIcon={expanded
                  ? <ExpandLessRoundedIcon />
                  : <ExpandMoreRoundedIcon />}
                variant='text'
              >
                {expanded
                  ? 'Minimize'
                  : 'Expand'}
              </Button>
            </Stack>
          </Stack>

          <Divider />

          <Stack
            direction={matches ? 'column' : 'row'}
            spacing={matches ? 2 : 0}
          >
            <Stack width={1}>
              <Stack alignItems='center' direction='row' spacing={1}>
                <Typography color={theme.palette.text.label} variant='body2'>
                  Master
                </Typography>
                {checkMasterOwnership && checkMasterLocked &&
                <Chip
                  color='success'
                  icon={<LockRoundedIcon />}
                  label='Master Locked'
                  size='small'
                  sx={{
                    '.MuiSvgIcon-root': {
                      fontSize: '12px',
                    },
                  }}
                  variant='subtle'
                />}
              </Stack>

              <Stack alignItems='center' direction='row' spacing={1}>
                <Typography
                  color={rightsHolderMaster?.ownership
                    ? theme.palette.text.primary
                    : theme.palette.text.disabled}
                  variant='h2'
                >
                  {rightsHolderMaster?.ownership ? ` ${rightsHolderMaster.ownership}%` : ' 0%' }
                </Typography>

                {rightsHolderMaster?.ownership &&
                <Tooltip title={rightsHolderMaster.status === 'Locked'
                  ? 'Unlock your split'
                  : 'Lock your split'}
                >
                  <LoadingButton
                    color={lockedMasterItem
                      ? 'primary'
                      : 'success'}
                    disabled={checkMasterLocked ||
                      project?.distributed === true ||
                      !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)}
                    endIcon={
                      lockedMasterItem
                        ? <LockRoundedIcon
                          fontSize='small'
                        />
                        : <LockOpenRoundedIcon
                          fontSize='small'
                        />
                    }
                    loading={loadingMaster}
                    onClick={() => void updateRightsHolder('master')}
                    variant='contained'
                  >
                    {lockedMasterItem &&
              'Unlock'}
                    {!lockedMasterItem &&
              'Accept'}
                  </LoadingButton>
                </Tooltip>}
              </Stack>
            </Stack>

            <Stack width={1}>
              <Stack alignItems='center' direction='row' spacing={1}>
                <Typography color={theme.palette.text.label} variant='body2'>
                  Publishing
                </Typography>
                {checkPublishingOwnership && checkPubLocked &&
                <Chip
                  color='success'
                  icon={<LockRoundedIcon />}
                  label='Publishing Locked'
                  size='small'
                  sx={{
                    '.MuiSvgIcon-root': {
                      fontSize: '12px',
                    },
                  }}
                  variant='subtle'
                />}
              </Stack>

              <Stack alignItems='center' direction='row' spacing={1}>
                <Typography
                  color={publishingRightsHolder?.ownership
                    ? theme.palette.text.primary
                    : theme.palette.text.disabled}
                  variant='h2'
                >
                  {publishingRightsHolder?.ownership ? ` ${publishingRightsHolder.ownership}%` : ' 0%' }
                </Typography>

                {publishingRightsHolder?.ownership &&
                <Tooltip title={publishingRightsHolder.status === 'Locked'
                  ? 'Unlock your split'
                  : 'Lock your split'}
                >
                  <LoadingButton
                    color={lockedPubItem
                      ? 'primary'
                      : 'success'}
                    disabled={checkPubLocked ||
                      project?.distributed === true ||
                      !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)}
                    endIcon={
                      lockedPubItem
                        ? <LockRoundedIcon
                          fontSize='small'
                        />
                        : <LockOpenRoundedIcon
                          fontSize='small'
                        />
                    }
                    loading={loadingPub}
                    onClick={() => void updateRightsHolder('publishing')}
                    variant='contained'
                  >
                    {lockedPubItem &&
              'Unlock'}
                    {!lockedPubItem &&
              'Accept'}
                  </LoadingButton>
                </Tooltip>}
              </Stack>
            </Stack>
          </Stack>

          <Collapse in={expanded}>
            <Divider sx={{ marginTop: 4 }} />

            <Stack marginTop={2} spacing={4} width={1}>
              <Stack spacing={2} width={1}>
                <Stack
                  alignItems={matches ? 'flex-start' : 'center'}
                  direction={matches ? 'column' : 'row'}
                  spacing={1}
                  width={1}
                >
                  <Stack alignItems='center' direction='row' spacing={1}>
                    <Typography variant='h6'>
                      Master Splits
                    </Typography>
                    <Tooltip
                      title='To distribute your project, all Master Splits must be locked and add up to 100%.'
                    >
                      <IconButton size='small'>
                        <InfoRoundedIcon />
                      </IconButton>
                    </Tooltip>
                    {checkMasterLocked &&
                    <Chip
                      color='success'
                      icon={<LockRoundedIcon />}
                      label='Locked'
                    />}
                  </Stack>
                  {checkMasterLocked
                    ? <Chip
                      color='info'
                      icon={<CheckCircleRoundedIcon />}
                      label='Ready to distribute'
                    />
                    : <Chip
                      color='warning'
                      icon={<WarningRoundedIcon />}
                      label='Not ready to distribute'
                    />}
                  {project?.organisation?.id === currentOrganisation?.id &&
                  <LoadingButton
                    disabled={checkMasterLocked ||
                props.recording.masterRightsHolders.length === 0 ||
                !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)}
                    loading={loadingReminderMaster}
                    onClick={async () => {
                      setLoadingReminderMaster(true)
                      await remindRightsholders(props.recording.id, 'master')
                        .finally(() => setLoadingReminderMaster(false))
                    }}
                    size='small'
                    startIcon={<NotificationsActiveRoundedIcon />}
                    sx={{
                      marginLeft: matches ? 'inherit' : 'auto!important',
                    }}
                    variant='outlined'
                  >
                    Send reminder
                  </LoadingButton>}
                </Stack>
                <Stack marginTop={2}>
                  {masterOwnershipTotalLocked === 100 &&
                  <LinearProgress
                    color='success'
                    value={masterOwnershipTotalLocked}
                    variant='determinate'
                  />}
                  {masterOwnershipTotalLocked < 100 &&
                  <LinearProgress
                    color='primary'
                    value={masterOwnershipTotalLocked}
                    variant='determinate'
                  />}
                  {masterOwnershipTotalLocked > 100 &&
                  <LinearProgress
                    color='error'
                    value={0}
                    variant='determinate'
                  />}
                </Stack>
                {props.recording.masterRightsHolders.map(holder =>
                  <ViewUserSplit
                    key={`master-${props.recording.id}-${holder.account.id}`}
                    rightsholder={holder}
                  />)}
              </Stack>

              <Stack spacing={2} width={1}>
                <Stack
                  alignItems={matches ? 'flex-start' : 'center'}
                  direction={matches ? 'column' : 'row'}
                  spacing={1}
                  width={1}
                >
                  <Stack alignItems='center' direction='row' spacing={1}>
                    <Typography lineHeight={1.1} variant='h6'>
                      Publishing Splits
                    </Typography>
                    <Tooltip
                      title='To use Publishing, all Publishing Splits must be
                      locked and add up to 100%.'
                    >
                      <IconButton size='small'>
                        <InfoRoundedIcon />
                      </IconButton>
                    </Tooltip>
                    {checkPubLocked &&
                    <Chip
                      color='success'
                      icon={<LockRoundedIcon />}
                      label='Locked'
                    />}
                  </Stack>
                  {project?.organisation?.id === currentOrganisation?.id &&
                  <LoadingButton
                    disabled={checkPubLocked ||
                project?.distributed === true ||
                props.recording.publisherRightsHolders.length === 0 ||
                !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)}
                    loading={loadingReminderPub}
                    onClick={async () => {
                      setLoadingReminderPub(true)
                      await remindRightsholders(props.recording.id, 'publishing')
                        .finally(() => setLoadingReminderPub(false))
                    }}
                    size='small'
                    startIcon={<NotificationsActiveRoundedIcon />}
                    sx={{
                      marginLeft: matches ? 'inherit' : 'auto!important',
                    }}
                    variant='outlined'
                  >
                    Send reminder
                  </LoadingButton>}
                </Stack>
                <Stack marginTop={2}>
                  {publishingOwnershipTotalLocked === 100 &&
                  <LinearProgress
                    color='success'
                    value={publishingOwnershipTotalLocked}
                    variant='determinate'
                  />}
                  {publishingOwnershipTotalLocked < 100 &&
                  <LinearProgress
                    color='primary'
                    value={publishingOwnershipTotalLocked}
                    variant='determinate'
                  />}
                  {publishingOwnershipTotalLocked > 100 &&
                  <LinearProgress
                    color='error'
                    value={0}
                    variant='determinate'
                  />}
                </Stack>
                {props.recording.publisherRightsHolders.map(holderPub =>
                  <ViewUserSplit
                    key={`master-${props.recording.id}-${holderPub.account.id}`}
                    rightsholder={holderPub}
                  />)}
              </Stack>
            </Stack>
          </Collapse>
        </Stack>
      </Paper>
    </>
  )
}

export default SplitsItem
