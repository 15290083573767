
import { useState } from 'react'

import AnalyticsAudience from 'src/components/pages/Audience/Components/Audience/AnalyticsAudience'
import LoadingAudience from 'src/components/pages/Audience/Components/Audience/LoadingAudience'
import AnalyticsPlatformDistribution from 'src/components/pages/Audience/Components/Overview/OverviewPlatform'
import SelectModeAnalyticsPlatform from 'src/components/pages/Audience/Components/shared/SelectPlatformAnalytics'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AnalyticsActivity, Data } from 'src/models/Analytics'
import type { AnalyticsAudienceData, AnalyticsCurrentData, AnalyticsHistoricData } from 'src/models/Analytics'

type Props = {
  currentData?: AnalyticsCurrentData
  historicData?: AnalyticsHistoricData
  audienceData?: AnalyticsAudienceData
  activityData?: AnalyticsActivity
  loading: boolean
}

const AnalyticsControlerAudience = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [activeTab, setActiveTab] = useState('spotify')
  const tabs = props.currentData?.stats?.filter(item => {
    let count = 0
    const keys = Object.keys(item.data)
    for (const key of keys) {
      if (item.data[key as keyof Data] !== undefined &&
        item.data[key as keyof Data] !== null) {
        const test = item.data[key as keyof Data]
        if (test && (typeof test === 'number' || (typeof test !== 'number' && test.length > 0))) {
          count++
        }
      }
    }
    return count > 0 && keys.length > 0
  })
  return (
    props.loading
      ? <LoadingAudience />
      : <>
        <SelectModeAnalyticsPlatform
          activeOption={activeTab}
          seriesTest={tabs?.map(tab => tab.source) ?? []}
          setActiveOption={setActiveTab}
        />
        {currentOrganisation &&
        <AnalyticsPlatformDistribution
          activityData={props.activityData}
          artworkUrl={currentOrganisation.logoUrl}
          audience
          currentData={props.currentData}
          currentTab={activeTab}
          historicData={props.historicData}
          loadingActivities={false}
        />}
        {currentOrganisation &&
        <AnalyticsAudience
          artworkUrl={currentOrganisation.logoUrl}
          audienceData={props.audienceData}
          currentTab={activeTab}
        />}
      </>
  )
}

export default AnalyticsControlerAudience
