/* eslint-disable react-hooks/exhaustive-deps */
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, CircularProgress, Collapse, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getAllProjectBoards } from 'src/api/taskboards'
import { NavLink } from 'src/components/SafeRouterLink'
import type { TaskBoard } from 'src/models/Taskboard'

type Props = {
  readonly searchQuery: string
}

const BoardsProjects = (props: Props) => {
  const { taskboardId } = useParams<{ taskboardId?: string }>()
  const [expanded, setExpanded] = useState(true)
  const [page, setPage] = useState(0)
  const [boards, setBoards] = useState<TaskBoard[]>([])
  const [loading, setLoading] = useState(true)

  const getBoardItems = async (pageNumber: number) => {
    setLoading(true)
    await getAllProjectBoards(pageNumber)
      .then(items => setBoards(old => [...old, ...items]))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    void getBoardItems(page)
  }, [page])

  const boardItems = boards.filter(item => item.title.toLowerCase().includes(props.searchQuery.toLowerCase()))

  return (
    <Stack direction='column' id='all-tasks' justifyContent='flex-start' width={1}>
      <List dense>
        <ListSubheader sx={{ position: 'relative' }}>
          <Stack flexDirection='row' justifyContent='space-between'>
            <Stack alignItems='center' direction='row' spacing={1}>
              <IconButton onClick={() => setExpanded(!expanded)} size='small'>
                {expanded ? <KeyboardArrowDownRoundedIcon />
                  : <KeyboardArrowUpRoundedIcon />}
              </IconButton>
              <Typography sx={{ wordBreak: 'break-word' }} variant='textLabel'>
                Releases
              </Typography>
            </Stack>
          </Stack>
        </ListSubheader>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <List dense>
            {boardItems.map(board =>
              <ListItem
                component={NavLink}
                dense
                disablePadding
                key={board.id}
                to={`/calendar/${board.id}`}
              >
                <ListItemButton
                  sx={{
                    borderRadius: 0,
                    backgroundColor: board.id && taskboardId === board.id.replace('doc/', '').replace('embed/', '')
                      ? theme => theme.palette.primary.contrast2
                      : theme => theme.palette.background.default,
                    '.MuiIconButton-root': {
                      visibility: 'hidden',
                    },
                    ':hover .MuiIconButton-root': {
                      visibility: 'visible',
                    },
                  }}
                >
                  {typeof board.projectId !== 'string' &&
                  <ListItemAvatar>
                    <Avatar
                      src={board.projectId.artworkPreviewUrl}
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: 0.25,
                        '.MuiSvgIcon-root': {
                          fontSize: 16,
                        },
                      }}
                    >
                      <MusicNoteRoundedIcon />
                    </Avatar>
                  </ListItemAvatar>}
                  {typeof board.projectId !== 'string' &&
                  <ListItemText
                    primary={board.projectId.title}
                    sx={{
                      wordBreak: 'break-word',
                      marginLeft: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      lineClamp: '1',
                      display: 'block',
                      '.MuiTypography-root': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: '1',
                        display: 'block',
                        whiteSpace: 'nowrap',
                      },
                    }}
                  />}
                </ListItemButton>
              </ListItem>)}
            <ListItem disablePadding>
              <ListItemButton
                disabled={loading}
                onClick={() => setPage(old => old + 1)}
                sx={{ borderRadius: 0 }}
              >
                <ListItemAvatar>
                  {loading
                    ? <CircularProgress sx={{ width: '14px!important', height: '14px!important' }} />
                    : <MoreHorizRoundedIcon />}
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={<Typography
                    sx={{ color: theme => theme.palette.text.secondary }}
                    variant='body2'
                  >
                    Load more
                  </Typography>}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Stack>
  )
}
export default BoardsProjects
