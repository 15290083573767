/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Button, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import { updateCollaboratorPermissions } from 'src/api/projects'
import DeleteCollaboratorModal from 'src/components/pages/Projects/ViewProject/InviteCollaborators/DeleteCollaboratorModal'
import GetIconFeature from 'src/components/pages/Projects/ViewProject/InviteCollaborators/GetFeatureIcon'
import { useAuth } from 'src/components/providers/AuthProvider'
import TeamAvatar from 'src/components/TeamAvatar'
import type Project from 'src/models/Project'
import type { Collaborator } from 'src/models/Project'
import { COLLABORATOR_RESTRICTIONS } from 'src/models/Project'

type Props = {
  project?: Project
  updatedFields: (project: Project) => void
  collaborator: Collaborator
}

const CollaboratorItem = (props: Props) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const { currentOrganisation } = useAuth()
  const themeItem = useTheme()
  const [collaboratorPermissions, setCollaboratorPermissions] = useState(props.collaborator.restrictions)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)
  const handleClose = async () => {
    setAnchorElement(null)
    if (props.project) {
      const index = props.project.collaborators.findIndex(item => item.account.id === props.collaborator.account.id)
      if (index !== -1) {
        await updateCollaboratorPermissions(props.project, props.collaborator, collaboratorPermissions)
          .then(item => {
            props.updatedFields(item)
          })
      }
    }
  }

  return (
    <>
      <DeleteCollaboratorModal
        close={() => setOpenDeleteModal(false)}
        collaborator={props.collaborator}
        open={openDeleteModal}
        project={props.project}
        updatedFields={props.updatedFields}
      />
      <Stack direction='column' key={props.collaborator.account.id} spacing={2} width={1}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={1}>
          <Stack alignItems='center' direction='row' spacing={2} width={1}>
            <TeamAvatar
              organisation={props.collaborator.account}
            />
            <Stack direction='column'>
              <Typography variant='body1'>
                {props.collaborator.account.name}
              </Typography>
              <Typography color={themeItem.palette.text.label} variant='body2'>
                {props.collaborator.account.organisationEmail}
              </Typography>
            </Stack>
          </Stack>
          {props.project?.organisation?.id === currentOrganisation?.id &&
          <Stack direction='row' flexShrink={0} justifyContent='flex-end' width={144}>
            <Button
              color='inherit'
              endIcon={<KeyboardArrowDownIcon />}
              onClick={event => setAnchorElement(event.currentTarget)}
              sx={{
                width: 'fit-content',
                flexShrink: 0,
              }}
            >
              {`${COLLABORATOR_RESTRICTIONS.filter(restriction =>
                !collaboratorPermissions.includes(restriction)).map(item => item).length} Permissions`}
            </Button>
          </Stack>}
          {(props.project?.organisation?.id === currentOrganisation?.id ||
              props.collaborator.account.id === currentOrganisation?.id) &&
              <IconButton
                color='error'
                onClick={() => setOpenDeleteModal(true)}
                size='small'
              >
                <ClearRoundedIcon />
              </IconButton>}

        </Stack>
      </Stack>

      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleClose}
        open={openMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {COLLABORATOR_RESTRICTIONS.map(restrictionItem =>
          <MenuItem
            key={restrictionItem}
            onClick={() => {
              if (collaboratorPermissions.includes(restrictionItem)) {
                setCollaboratorPermissions(old => old.filter(item => item !== restrictionItem))
              } else {
                setCollaboratorPermissions(old => [...old, restrictionItem])
              }
            }}
            selected={!collaboratorPermissions.includes(restrictionItem)}
          >
            <ListItemIcon>
              {GetIconFeature(restrictionItem, !collaboratorPermissions.includes(restrictionItem))}
            </ListItemIcon>
            {restrictionItem}
          </MenuItem>)}
      </Menu>
    </>
  )
}

export default CollaboratorItem
