/* eslint-disable no-restricted-imports */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded'
import { Box, Button, Divider, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import SearchField from 'src/components/form-elements/SearchField'
import LinksListBioLink from 'src/components/pages/Links/LinksList/LinksListComponents/LinkListBiolink'
import LinksListContent from 'src/components/pages/Links/LinksList/LinksListComponents/LinksListContent'
import LinksListActions from 'src/components/pages/Links/LinksList/LinksListComponents/ListListActions'
import MarketingSettingsModal from 'src/components/pages/Links/LinksList/LinksListModal/MarketingCloudSettingsModal'
import NewLinkModal from 'src/components/pages/Links/LinksList/LinksListModal/NewLinkModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'
import SPACING from 'src/styles/spacing'

const LinksList = () => {
  const {
    currentAccountPermissions,
  } = useAuth()

  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))

  const [newLinkModal, setNewLinkModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [settingsTab, setSettingsTab] = useState('profile')
  const [settings, setSettings] = useState(false)

  return (
    <>
      {newLinkModal &&
      currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS) &&
      <NewLinkModal
        close={() => setNewLinkModalOpen(false)}
        open={newLinkModal}
      />}
      <MarketingSettingsModal
        close={() => setSettings(false)}
        open={settings}
        tab={settingsTab}
      />
      <Paper
        sx={{
          width: 1,
          height: 1,
          overflow: 'hidden',
          borderRadius: 0,
          background: 'transparent',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: 1,
            height: 1,
          }}
        >
          <Stack
            direction='row'
            paddingX={2}
            paddingY={2}
            sx={{
              overflowX: 'auto',
              overflowY: 'clip',
              flexShrink: 0,
            }}
            width={1}
          >
            <Stack alignItems='center' direction='row' spacing={1} width={1}>
              <SearchField
                onChange={event => setSearchQuery(event.target.value)}
                value={searchQuery}
              />
            </Stack>
            <Stack
              alignItems='center'
              direction='row'
              marginLeft='auto'
              paddingLeft={1}
              spacing={1}
              sx={{
                flexShrink: 0,
              }}
            >
              <Button
                color='warning'
                disabled={!currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS)}
                onClick={() => {
                  setSettingsTab('profile')
                  setSettings(true)
                }}
                startIcon={<DevicesRoundedIcon />}
              >
                Settings
              </Button>
              <Button
                disabled={!currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS)}
                onClick={() => setNewLinkModalOpen(true)}
                startIcon={<AddRoundedIcon />}
              >
                Create Link
              </Button>
            </Stack>
          </Stack>
          <Divider sx={{ width: 1 }} />
          <Stack
            alignItems='center'
            height={1}
            padding={SPACING.CONTENT_PADDING}
            spacing={4}
            sx={{
              overflow: 'auto',
            }}
            width={1}
          >
            {!matches &&
            <LinksListActions />}

            <LinksListBioLink />

            <Stack maxWidth={1332} spacing={2} width={1}>
              <Typography variant='h3'>
                Links
              </Typography>
              <LinksListContent
                linkType=''
                searchQuery={searchQuery}
              />
            </Stack>
          </Stack>
        </Box>
      </Paper>
    </>
  )
}

export default LinksList
