/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import { LoadingButton } from '@mui/lab'
import { Button, Card, Chip, Divider, Grid, Link, Stack, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import type { StripeError } from '@stripe/stripe-js'
import type { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import store from 'storejs'

import { createSubscription, setupIntent } from 'src/api/payment'
import { useAuth } from 'src/components/providers/AuthProvider'
import { type PriceLookupKey, PRICE_LOOKUP_KEYS, PRICE_LOOKUP_KEYS_PRETTY } from 'src/models/Organisation'
import type { UpcomingInvoice } from 'src/models/Payment'
const textDecoration = 'line-through'
type Props = {
  readonly selectedPrice: Exclude<PriceLookupKey, 'FREE'>
  readonly priceSelected: number | undefined
  readonly proratedPrice: UpcomingInvoice | undefined
  readonly term: number
  readonly seats: number
  readonly setCheckout: (checkout: string) => void
  readonly firstName: string
  readonly lastName: string
  readonly city: string
  readonly line1: string
  readonly region: string
  readonly postalCode: string
  readonly country: string
  readonly setFirstName: React.Dispatch<React.SetStateAction<string>>
  readonly setLastName: React.Dispatch<React.SetStateAction<string>>
  readonly setCity: React.Dispatch<React.SetStateAction<string>>
  readonly setLine1: React.Dispatch<React.SetStateAction<string>>
  readonly setRegion: React.Dispatch<React.SetStateAction<string>>
  readonly setPostalCode: React.Dispatch<React.SetStateAction<string>>
  readonly setCountry: React.Dispatch<React.SetStateAction<string>>
}
const CheckoutPage = (props: Props) => {
  const { currentOrganisation, updateCurrentOrganisation, refreshCurrentOrganisation } = useAuth()
  const stripe = useStripe()
  const elements = useElements()
  const locationStorage = store.get('location') as string | undefined

  const [loadingStripe, setLoadingStripe] = useState(false)

  const [clientSecret, setClientSecret] = useState<string | undefined>()

  const [cardNumberCompleted, setCardNumberCompleted] = useState(false)
  const [addressCompleted, setAddressCompleted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const doesElementExist = !!elements

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_checkout',
        userId: currentOrganisation?.id,
        eventProps: {
          city: currentOrganisation?.city,
          country: currentOrganisation?.country,
          email: currentOrganisation?.organisationEmail,
          firstName: currentOrganisation?.firstName,
          lastName: currentOrganisation?.lastName,
          state: currentOrganisation?.region,
          zip: currentOrganisation?.postalCode,
        },
      },
    })
  }, [currentOrganisation])

  const createSetupIntent = useCallback(async () => {
    try {
      // We go to stripe to initialise the Setup intent on the server side
      // This gets attached to the current stripe element
      const intent = await setupIntent()
      setClientSecret(intent.client_secret)
      stripe?.elements({
        clientSecret: intent.client_secret,
        loader: 'auto',
      })
    } catch (error: unknown) {
      setErrorMessage((error as StripeError).message ?? ' ')
      setLoadingStripe(false)
    }
  }, [])

  useEffect(() => {
    if (doesElementExist && props.proratedPrice) {
      elements.update({
        amount: props.proratedPrice.total <= 0 ? 0 : Math.trunc(props.proratedPrice.total * 100),
      })

      void createSetupIntent()
    }
  }, [doesElementExist, elements, props.proratedPrice])

  const validateForm =
    addressCompleted &&
    cardNumberCompleted

  const handleFormSubmit = async () => {
    if (!stripe) return
    setErrorMessage(' ')
    setLoadingStripe(true)
    try {
      await updateCurrentOrganisation({
        firstName: props.firstName,
        lastName: props.lastName,
        addressLine1: props.line1,
        city: props.city,
        country: props.country,
        region: props.region,
        postalCode: props.postalCode,
      }).then(async () => {
        await refreshCurrentOrganisation()
      })

      if (!elements) {
        setErrorMessage('An error has occured while creating your subscription')
        setLoadingStripe(false)
        return
      }

      const getSubscriptionWithMethod = async (setupIntentId: string) =>
        createSubscription(
          props.selectedPrice,
          props.seats,
          setupIntentId
        ).catch((error: AxiosError<Error>) => {
          setErrorMessage(error.response?.data.message ?? 'An error has occured while creating your subscription')
          setLoadingStripe(false)
        })

      const submitResult = await elements.submit()

      if (submitResult.error?.message) {
        setErrorMessage(submitResult.error.message)
        setLoadingStripe(false)
        return
      }

      // After the user has confirmed the payment method, we create confirm the setupIntent, this handles 3DS as well
      const intent = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
        clientSecret: clientSecret ?? '',
        confirmParams: {
          return_url: 'https://cloud.releese.io',
        },
      })

      if (intent.error?.message) {
        setErrorMessage(intent.error.message)
        setLoadingStripe(false)
        return
      }

      // finally, the Subscription gets added
      const subscription = await getSubscriptionWithMethod(intent.setupIntent?.id ?? '')
      if (!subscription) return

      if (currentOrganisation?.neverTrial) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'trial',
            userId: currentOrganisation.id,
            eventProps: {
              city: props.city,
              country: props.country,
              email: currentOrganisation.organisationEmail,
              firstName: props.firstName,
              lastName: props.lastName,
              state: props.region,
              zip: props.postalCode,
              value: props.priceSelected,
            },
          },
        })
      }

      if (subscription.pending_setup_intent !== null &&
        subscription.pending_setup_intent.status !== 'succeeded' &&
        intent.setupIntent?.payment_method) {
        const confirmSetupResult = await stripe.confirmSetup({
          clientSecret: subscription.pending_setup_intent.client_secret,
          confirmParams: {
            return_url: 'https://cloud.releese.io',
            payment_method: intent.setupIntent.payment_method as string,
          },
        })
        if (confirmSetupResult.error.message) {
          setErrorMessage(confirmSetupResult.error.message)
          setLoadingStripe(false)
          return
        }
      } else if (subscription.latest_invoice.payment_intent &&
        intent.setupIntent?.payment_method) {
        const confirmPaymentResult = await stripe.confirmPayment({
          clientSecret: subscription.latest_invoice.payment_intent.client_secret,
          confirmParams: {
            return_url: 'https://cloud.releese.io',
            payment_method: intent.setupIntent.payment_method as string,
          },
        })
        if (confirmPaymentResult.error.message) {
          setErrorMessage(confirmPaymentResult.error.message)
          setLoadingStripe(false)
          return
        }
      }

      props.setCheckout('subscribed')
      return void refreshCurrentOrganisation()
    } catch (error: unknown) {
      setErrorMessage((error as StripeError).message ?? ' ')
      setLoadingStripe(false)
    }
  }
  const userText = props.seats > 1 ? 'users' : 'user'
  return <FormControl>
    <Grid
      container
      marginLeft='-32px!important'
      spacing={4}
      width='calc(100% + 32px)'
    >
      <Grid item md={7} sm={12}>
        <Card sx={{ overflow: 'visible' }} variant='outlined'>
          <Stack paddingX={3} paddingY={4} spacing={2} width={1}>
            <Stack direction='row'>
              <Typography variant='h3'>
                Checkout
              </Typography>
            </Stack>
            <PaymentElement
              onChange={event => {
                setCardNumberCompleted(event.complete)
              }}
            />
            <AddressElement
              onChange={event => {
                setAddressCompleted(event.complete)
                props.setFirstName(event.value.firstName ?? '')
                props.setLastName(event.value.lastName ?? '')
                props.setCity(event.value.address.city)
                props.setCountry(event.value.address.country)
                props.setLine1(event.value.address.line1)
                props.setPostalCode(event.value.address.postal_code)
                props.setRegion(event.value.address.state)
              }}
              options={{
                mode: 'billing',
                display: {
                  name: 'split',
                },
                defaultValues: {
                  firstName: currentOrganisation?.firstName ?? '',
                  lastName: currentOrganisation?.lastName ?? '',
                  address: props.country.length === 0
                    ? {
                      country: locationStorage ?? '',
                    }
                    : {
                      line1: props.line1,
                      city: props.city,
                      country: props.country,
                      postal_code: props.postalCode,
                      state: props.region,
                    },
                },
              }}
            />
          </Stack>
        </Card>
      </Grid>
      <Grid item md={5} sm={12}>
        <Card variant='outlined'>
          <Stack paddingX={2} paddingY={4} spacing={2}>
            <Typography variant='h3'>
              Summary
            </Typography>
            {props.priceSelected &&
              <Stack direction='row' justifyContent='space-between' spacing={1}>
                <Stack direction='column'>
                  <Typography variant='body2'>
                    {PRICE_LOOKUP_KEYS_PRETTY[PRICE_LOOKUP_KEYS.indexOf(props.selectedPrice)]}
                    ,
                    {' '}
                    {props.seats}
                    {' '}
                    {userText}
                  </Typography>
                  {props.term === 10 &&
                    <Typography color='action.active' variant='body2'>
                      Yearly plan, save 2 months
                    </Typography>}
                </Stack>
                <Stack direction='column'>
                  <Typography variant='body2'>
                    $
                    {props.priceSelected.toFixed(2)}
                  </Typography>

                  {props.term === 10 &&
                    <Typography
                      color='action.active'
                      sx={{ textDecoration }}
                      variant='body2'
                    >
                      $
                      {(props.priceSelected / 10 * 12).toFixed(2)}
                    </Typography>}

                </Stack>
              </Stack>}
            <Divider />
            {props.proratedPrice?.discounts?.map(discount =>
              <Stack direction='row' justifyContent='space-between' key={discount.discount_name}>
                <Typography color='text.label' variant='body2'>
                  {discount.discount_name}
                </Typography>
                <Typography color='text.label' variant='body2'>
                  {discount.percent &&
                    discount.percent.toFixed(0)}
                  % off, forever
                </Typography>
              </Stack>)}

            {props.proratedPrice &&
              <Stack direction='row' justifyContent='space-between'>
                <Typography variant='body2'>
                  Subtotal
                </Typography>
                <Typography variant='body2'>
                  $
                  {`${props.proratedPrice.subtotal <= 0
                    ? '0.00'
                    : props.proratedPrice.subtotal.toFixed(2)}`}
                </Typography>
              </Stack>}

            {props.proratedPrice?.total_tax_amounts?.map(tax =>
              <Stack direction='row' justifyContent='space-between' key={tax.tax_rate}>
                <Typography color='text.label' variant='body2'>
                  {tax.tax_rate}
                </Typography>
                <Typography color='text.label' variant='body2'>
                  $
                  {`${tax.amount && tax.amount <= 0
                    ? '0.00'
                    : tax.amount?.toFixed(2)}`}
                </Typography>
              </Stack>)}
            {props.proratedPrice &&
              <Stack direction='row' justifyContent='space-between'>
                <Typography variant='body2'>
                  Total
                </Typography>
                <Typography variant='body2'>
                  $
                  {`${props.proratedPrice.total <= 0
                    ? '0.00'
                    : props.proratedPrice.total.toFixed(2)}`}
                </Typography>
              </Stack>}

            {props.proratedPrice &&
              <Stack direction='row' justifyContent='space-between'>
                <Typography>
                  Due today (USD)
                </Typography>
                <Typography>
                  $
                  {`${props.proratedPrice.due_today < 0 ? '0.00' : props.proratedPrice.due_today.toFixed(2)}`}
                </Typography>
              </Stack>}

            <Divider />

            <Stack direction='column' spacing={2}>
              {errorMessage.length > 0 &&
                <Typography color='error' variant='body2'>
                  {errorMessage}
                </Typography>}
              <LoadingButton
                color='success'
                disabled={!validateForm}
                fullWidth
                loading={loadingStripe}
                loadingPosition='start'
                onClick={handleFormSubmit}
                variant='contained'
              >
                {currentOrganisation?.neverTrial
                  ? 'Start free trial'
                  : currentOrganisation?.trial
                    ? 'Update free trial'
                    : 'Submit purchase'}
              </LoadingButton>
              <Stack alignItems='center' direction='row' justifyContent='space-between'>
                <Chip
                  label='Secure transaction'
                  size='small'
                  variant='subtle'
                />
                <Button
                  color='secondary'
                  onClick={() => props.setCheckout('plan')}
                  size='small'
                  variant='text'
                >
                  Edit plan
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Card>
        <Stack paddingX={2} paddingY={1}>
          <Typography textAlign='center' variant='helperText' >
            {'By subscribing, you agree to the '}
            <Link color='secondary' href='https://releese.io/legal/terms-and-conditions' target='_blank'>
              Terms and conditions
            </Link>
            {' and the '}
            <Link color='secondary' href='https://releese.io/legal/privacy-policy' target='_blank'>
              Privacy policy.
            </Link>
            {` Releese is required to collect sales tax on subscriptions fees for customers in certain locations.
            The charged tax amount might differ from the fields above if your billing address changes.`}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  </FormControl>
}
export default CheckoutPage
