import { Autocomplete, TextField, useMediaQuery, useTheme } from '@mui/material'

type Props = {
  readonly services: string[]
  readonly setServices: React.Dispatch<React.SetStateAction<string[]>>
  readonly destinations: string[]
  readonly subtle?: boolean
}

const SelectServices = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  return (
    <Autocomplete
      id='serviceFilter'
      multiple
      onChange={(event, values) => props.setServices(values)}
      options={props.destinations}
      renderInput={params =>
        <TextField
          {...params}
          label={props.subtle === true ? '' : 'Services'}
          placeholder='Services'
          sx={{
            marginTop: 0,
          }}
        />}
      sx={{
        width: mobile ? 1 : 360,
        marginTop: 0,
      }}
      value={props.services}
    />
  )
}

export default SelectServices

