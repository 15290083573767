import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded'
import { Paper, Stack, useTheme } from '@mui/material'
import Wave from 'react-wavify'

const AnalyticsAnimation = () => {
  const themeColor = useTheme()
  return (
    <Paper
      elevation={0}
      sx={{
        height: 300,
        width: 1,
        backgroundColor: theme => theme.palette.background.input,
        overflow: 'hidden',
        position: 'static',
      }}
    >
      <Stack
        alignItems='center'
        height={1}
        justifyContent='flex-end'
        width={1}
      >
        <Stack
          alignItems='center'
          height={100}
          justifyContent='center'
          position='static'
          sx={{
            backgroundColor: theme => theme.palette.success.main,
            borderRadius: 2,
          }}
          width={100}
        >
          <AutoGraphRoundedIcon
            sx={{
              width: 64,
              height: 64,
              color: theme => theme.palette.primary.contrastText,
            }}
          />
        </Stack>
        <Wave
          fill={themeColor.palette.success.main}
          options={{
            height: 20,
            amplitude: 40,
            speed: 0.15,
            points: 6,
          }}
          paused={false}
          style={{
            height: '45%',
          }}
        />
      </Stack>
    </Paper>
  )
}

export default AnalyticsAnimation
