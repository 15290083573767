/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { LinearProgress, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { Avatar, ListItemText, Stack, TableCell, TableRow } from '@mui/material'
import { useState } from 'react'

import ArtistModalSearch from 'src/components/pages/Audience/Components/Modals/ArtistModalSearch'
import type { ArtistItemSingle } from 'src/models/AnalyticsCloud'

type Props = {
  artist: ArtistItemSingle
}

const RecommendedArtistItem = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const [openTrackModal, setOpenTrackModal] = useState(false)
  const artist = props.artist
  return (
    <>
      {props.artist.spotifyId &&
      openTrackModal &&
      <ArtistModalSearch
        artwork={props.artist.images?.[0].url ?? ''}
        close={() => setOpenTrackModal(false)}
        loading={false}
        open={openTrackModal}
        selectedArtist={props.artist.spotifyId}
        title={props.artist.name}
      />}
      <TableRow
        hover
        key={artist.spotifyId}
        onClick={() => setOpenTrackModal(true)}
        sx={{
          '.MuiTableCell-root': { borderBottomColor: 'divider', paddingY: 2 },
        }}
      >
        <TableCell>
          <Stack alignItems='center' direction='row' spacing={2} width={1}>
            <Avatar
              src={artist.images[0]?.url}
              sx={{
                height: 48,
                width: 48,
              }}
              variant='rounded'
            />
            <ListItemText
              primary={artist.name}
              secondary={`${new Intl.NumberFormat('en-US').format(artist.followers.total)} Followers`}
            />
          </Stack>
        </TableCell>
        {!mobile &&
        <>
          <TableCell sx={{ maxWidth: 200 }}>
            <Stack alignItems='center' direction='row' maxWidth={200}>
              <ListItemText
                secondary={artist.genres.slice(0, 3).join(', ')}
                sx={{
                  textTransform: 'capitalize',
                }}
              />
            </Stack>
          </TableCell>
          <TableCell sx={{ maxWidth: 120 }}>
            <Stack alignItems='center' direction='row' maxWidth={120}>
              <Tooltip title={`Popularity ${artist.popularity}%`}>
                <LinearProgress
                  sx={{
                    height: '16px',
                    borderRadius: 1,
                    width: 120,
                  }}
                  value={artist.popularity}
                  variant='determinate'
                />
              </Tooltip>
            </Stack>
          </TableCell>
        </>}
      </TableRow>
    </>
  )
}

export default RecommendedArtistItem
