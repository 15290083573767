/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import FolderCopyRoundedIcon from '@mui/icons-material/FolderCopyRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { alpha, Card, LinearProgress, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useLocation } from 'react-router-dom'

import SearchField from 'src/components/form-elements/SearchField'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import { bytesToSize } from 'src/utils/fileUtils'

type Props = {
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  images: boolean
  setImages: React.Dispatch<React.SetStateAction<boolean>>
  files: boolean
  setFiles: React.Dispatch<React.SetStateAction<boolean>>
  music: boolean
  setMusic: React.Dispatch<React.SetStateAction<boolean>>
  documents: boolean
  setDocuments: React.Dispatch<React.SetStateAction<boolean>>
}

const FilesMenu = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('lg'))
  const location = useLocation()

  return (
    <Stack
      alignItems='center'
      direction='column'
      display={matches ? 'none' : 'flex'}
      paddingTop={2}
      sx={{ flexShrink: 0, maxHeight: '100%', overflow: 'auto' }}
      width={matches ? 1 : 280}
    >
      <Stack paddingX={2} width={1}>
        <SearchField
          fullWidth
          onChange={event => props.setSearchQuery(event.target.value)}
          sx={{ marginTop: 0 }}
          value={props.searchQuery}
        />
      </Stack>

      <List dense sx={{ width: 1, marginTop: 2 }}>
        <ListItem
          dense
          disablePadding
        >
          <ListItemButton
            component={NavLink}
            selected={location.pathname.includes('home')}
            sx={{ borderRadius: 0 }}
            to='/files/home'
          >
            <ListItemAvatar
              sx={{
                color: theme => location.pathname.includes('home')
                  ? theme.palette.primary.main
                  : theme.palette.action.active,
              }}
            >
              <HomeRoundedIcon />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>
                Home
              </Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          dense
          disablePadding
        >
          <ListItemButton
            component={NavLink}
            selected={location.pathname.includes('workspace')}
            sx={{ borderRadius: 0 }}
            to='/files/workspace'
          >
            <ListItemAvatar
              sx={{
                color: theme => location.pathname.includes('workspace')
                  ? theme.palette.primary.main
                  : theme.palette.action.active,
              }}
            >
              <FolderCopyRoundedIcon />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>
                Team Files
              </Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
          </ListItemButton>
        </ListItem>
      </List>

      <List dense sx={{ width: 1 }}>
        <ListSubheader sx={{ position: 'relative', paddingLeft: '24px', paddingBottom: '8px', paddingTop: '8px' }}>
          <Stack flexDirection='row' justifyContent='space-between'>
            <Stack alignItems='flex-start' direction='row' spacing={1}>
              <Typography sx={{ wordBreak: 'break-word' }} variant='textLabel'>
                Filters
              </Typography>
            </Stack>
          </Stack>
        </ListSubheader>
        <ListItem
          dense
          disablePadding
          sx={{
            '& .Mui-selected .MuiListItemText-root': {
              color: theme => `${theme.palette.secondary.main}!important`,
            },
            '& .Mui-selected': {
              backgroundColor: theme => alpha(theme.palette.secondary.light, 0.16),
              ':hover': {
                backgroundColor: theme => alpha(theme.palette.secondary.light, 0.18),
              },
            },
          }}
        >
          <ListItemButton
            color='secondary'
            onClick={() => props.setImages(!props.images)}
            selected={props.images}
            sx={{
              borderRadius: 0,
            }}
          >
            <ListItemAvatar
              sx={{
                color: props.images
                  ? themeItem.palette.secondary.main
                  : themeItem.palette.action.active,
              }}
            >
              <ImageRoundedIcon />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>
                Images
              </Typography>}
              sx={{
                wordBreak: 'break-word',
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          dense
          disablePadding
          sx={{
            '& .Mui-selected .MuiListItemText-root': {
              color: theme => `${theme.palette.success.main}!important`,
            },
            '& .Mui-selected': {
              backgroundColor: theme => alpha(theme.palette.success.light, 0.16),
              ':hover': {
                backgroundColor: theme => alpha(theme.palette.success.light, 0.18),
              },
            },
          }}
        >
          <ListItemButton
            color='success'
            onClick={() => props.setFiles(!props.files)}
            selected={props.files}
            sx={{ borderRadius: 0 }}
          >
            <ListItemAvatar
              sx={{
                color: props.files
                  ? themeItem.palette.success.main
                  : themeItem.palette.action.active,
              }}
            >
              <ArticleRoundedIcon />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>
                Files
              </Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          dense
          disablePadding
          sx={{
            '& .Mui-selected .MuiListItemText-root': {
              color: theme => `${theme.palette.info.main}!important`,
            },
            '& .Mui-selected': {
              backgroundColor: theme => alpha(theme.palette.info.light, 0.16),
              ':hover': {
                backgroundColor: theme => alpha(theme.palette.info.light, 0.18),
              },
            },
          }}
        >
          <ListItemButton
            color='info'
            onClick={() => props.setMusic(!props.music)}
            selected={props.music}
            sx={{ borderRadius: 0 }}
          >
            <ListItemAvatar
              sx={{
                color: props.music
                  ? themeItem.palette.info.main
                  : themeItem.palette.action.active,
              }}
            >
              <MusicNoteRoundedIcon />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>
                Audio
              </Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          dense
          disablePadding
        >
          <ListItemButton
            color='primary'
            onClick={() => props.setDocuments(!props.documents)}
            selected={props.documents}
            sx={{ borderRadius: 0 }}
          >
            <ListItemAvatar
              sx={{
                color: props.documents
                  ? themeItem.palette.primary.main
                  : themeItem.palette.action.active,
              }}
            >
              <AttachFileIcon />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>
                Documents
              </Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
          </ListItemButton>
        </ListItem>
      </List>

      <Stack marginBottom={2} marginTop='auto' paddingX={2} width={1}>
        <Card sx={{ width: 1 }} variant='outlined'>
          <Stack padding={2} spacing={1} width={1}>
            <Typography variant='subtitle1'>
              Storage
            </Typography>
            {currentOrganisation &&
            <Typography
              color={currentOrganisation.storageUsed < currentOrganisation.storageAllocation
                ? 'inherit'
                : 'error'}
              variant='body2'
            >
              <span style={{ fontWeight: 'bold' }}>
                {bytesToSize(currentOrganisation.storageUsed)}
              </span>
              {' '}
              of
              {' '}
              {bytesToSize(currentOrganisation.storageAllocation)}
            </Typography>}
            <Stack sx={{ marginTop: 2 }} width={1}>
              {currentOrganisation &&
                  currentOrganisation.storageUsed < currentOrganisation.storageAllocation
                ? <LinearProgress
                  color='primary'
                  sx={{ height: 8 }}
                  value={(currentOrganisation.storageUsed / currentOrganisation.storageAllocation) * 100}
                  variant='determinate'
                />
                : <LinearProgress
                  color='error'
                  sx={{ height: 8 }}
                  value={100}
                  variant='determinate'
                />}
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Stack>
  )
}

export default FilesMenu
