/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Stack } from '@mui/material'
import { getDatabase, onDisconnect, onValue, push, ref, serverTimestamp, set } from 'firebase/database'
import Cookies from 'js-cookie'
import { useEffect } from 'react'

import { useAuth } from 'src/components/providers/AuthProvider'

const CookiesHandler = () => {
  const {
    currentOrganisation,
    currentAccount,
    updateCurrentOrganisation,
    refreshCurrentOrganisation,
  } = useAuth()

  // Google Analytics
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.ga === undefined) {
      const googleAnalyticsCookie = Cookies.get('_ga')
      if (googleAnalyticsCookie) {
        const clientId = googleAnalyticsCookie.slice(6)
        void updateCurrentOrganisation({ ...currentOrganisation, ga: clientId })
          .then(refreshCurrentOrganisation)
      }
    }
  }, [currentOrganisation?.ga])

  // Meta Pixel fbp cookie
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.fbp === undefined) {
      const fbpCookie = Cookies.get('_fbp')
      if (fbpCookie) {
        const clientId = fbpCookie
        void updateCurrentOrganisation({ ...currentOrganisation, fbp: clientId })
          .then(refreshCurrentOrganisation)
      }
    }
  }, [currentOrganisation?.fbp])

  // Meta Pixel fbc cookie
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.fbc === undefined) {
      const fbcCookie = Cookies.get('_fbc')
      if (fbcCookie) {
        const clientId = fbcCookie
        void updateCurrentOrganisation({ ...currentOrganisation, fbc: clientId })
          .then(refreshCurrentOrganisation)
      }
    }
  }, [currentOrganisation?.fbc])

  // First landing page visited cookie
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.firstPage === undefined) {
      const firstPageCookie = Cookies.get('firstPage')
      if (firstPageCookie) {
        const clientId = firstPageCookie
        void updateCurrentOrganisation({ ...currentOrganisation, firstPage: clientId })
          .then(refreshCurrentOrganisation)
      }
    }
  }, [currentOrganisation?.firstPage])

  // UTM source
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.utm_source === undefined) {
      const utmCookieCookie = Cookies.get('utm_source')
      if (utmCookieCookie) {
        const clientId = utmCookieCookie
        void updateCurrentOrganisation({ ...currentOrganisation, utm_source: clientId })
          .then(refreshCurrentOrganisation)
      }
    }
  }, [currentOrganisation?.utm_source])

  // UTM medium
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.utm_medium === undefined) {
      const utmCookieCookie = Cookies.get('utm_medium')
      if (utmCookieCookie) {
        const clientId = utmCookieCookie
        void updateCurrentOrganisation({ ...currentOrganisation, utm_medium: clientId })
          .then(refreshCurrentOrganisation)
      }
    }
  }, [currentOrganisation?.utm_medium])

  // UTM campaign
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.utm_campaign === undefined) {
      const utmCookieCookie = Cookies.get('utm_campaign')
      if (utmCookieCookie) {
        const clientId = utmCookieCookie
        void updateCurrentOrganisation({ ...currentOrganisation, utm_campaign: clientId })
          .then(refreshCurrentOrganisation)
      }
    }
  }, [currentOrganisation?.utm_campaign])

  // UTM content
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.utm_content === undefined) {
      const utmCookieCookie = Cookies.get('utm_content')
      if (utmCookieCookie) {
        const clientId = utmCookieCookie
        void updateCurrentOrganisation({ ...currentOrganisation, utm_content: clientId })
          .then(refreshCurrentOrganisation)
      }
    }
  }, [currentOrganisation?.utm_content])

  // UTM term
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.utm_term === undefined) {
      const utmCookieCookie = Cookies.get('utm_term')
      if (utmCookieCookie) {
        const clientId = utmCookieCookie
        void updateCurrentOrganisation({ ...currentOrganisation, utm_term: clientId })
          .then(refreshCurrentOrganisation)
      }
    }
  }, [currentOrganisation?.utm_term])

  // Realtime DB connection login
  const database = getDatabase()
  const myConnectionsRef = ref(database, `users/${currentAccount.id}/connections`)
  const connectedRef = ref(database, '.info/connected')
  const lastOnlineRef = ref(database, `users/${currentAccount.id}/lastOnline`)

  useEffect(() =>
    onValue(connectedRef, snap => {
      if (snap.val() === true) {
        const connection = push(myConnectionsRef)

        onDisconnect(connection).remove()
          .catch(() => null)

        set(connection, true)
          .catch(() => null)

        onDisconnect(lastOnlineRef).set(serverTimestamp())
          .catch(() => null)
      }
    }), [])

  // Baremetrics
  useEffect(() => {
    if (!window.barepay) {
      window.barepay = { created: true }
      const a = document.createElement('script')
      a.src = 'https://baremetrics-dunning.baremetrics.com/js/application.js', a.async = true
      const b = document.getElementsByTagName('script')[0]
      b.parentNode?.insertBefore(a, b),
      window.barepay.params = {
        access_token_id: '2f245dd1-182a-4fa4-9fc6-c543960f4c48',
        customer_oid: currentOrganisation?.stripeCustomerId ?? '',
      }
    }
  }, [])
  return (
    <Stack />
  )
}

export default CookiesHandler
