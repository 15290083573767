/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import { Card, CardActionArea, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'

import DeleteFolderModal from 'src/components/pages/Files/Components/DeleteFolderModal'
import RenameFolderModal from 'src/components/pages/Files/Components/RenameFolderModal'
import ShareFodlerModal from 'src/components/pages/Files/Components/ShareModalFolder'
import { NavLink } from 'src/components/SafeRouterLink'
import type { Folder } from 'src/models/Folder'

type Props = {
  folder: Folder
  setFolders: React.Dispatch<React.SetStateAction<Folder[]>>
  setLocation?: React.Dispatch<React.SetStateAction<string>>
}

const FolderItem = (props: Props) => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [renameModal, setRenameModal] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const [folder, setFolder] = useState(props.folder)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  const handleClose = () => {
    setAnchorElement(null)
  }

  const clickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorElement(event.currentTarget)
  }

  return (
    <>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleClose}
        onClose={handleClose}
        open={openMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          onClick={() => setShareModal(true)}
        >
          <ListItemIcon>
            <ShareRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Share
        </MenuItem>
        <MenuItem
          onClick={() => setRenameModal(true)}
        >
          <ListItemIcon>
            <EditRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Rename Folder
        </MenuItem>
        <MenuItem
          onClick={() => setDeleteModal(true)}
        >
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Delete Folder
        </MenuItem>
      </Menu>
      <DeleteFolderModal
        close={() => setDeleteModal(false)}
        folder={props.folder}
        open={deleteModal}
        setFolders={props.setFolders}
      />
      <RenameFolderModal
        close={() => setRenameModal(false)}
        folder={props.folder}
        open={renameModal}
        setFolder={setFolder}
      />
      <ShareFodlerModal
        close={() => setShareModal(false)}
        folderItem={props.folder}
        open={shareModal}
      />
      {props.setLocation && props.setLocation !== undefined
        ? <Droppable droppableId={folder.id ?? 'undefined'} key={folder.id ?? ''}>
          {provided => <Stack {...provided.droppableProps} ref={provided.innerRef}>
            <Card
              elevation={0}
              sx={{
                background: theme => theme.palette.background.elevatedCard,
              }}
            >
              <CardActionArea onClick={() => props.setLocation && props.setLocation(props.folder.id ?? '')}>
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  padding={1}
                >
                  <Stack alignItems='flex-end' width={1}>
                    <IconButton onClick={event => clickButton(event)} size='small'>
                      <MoreHorizRoundedIcon />
                    </IconButton>
                  </Stack>
                  <FolderRoundedIcon
                    color='action'
                    sx={{
                      height: 64,
                      width: 64,
                    }}
                  />
                  <Typography
                    textAlign='center'
                    variant='body2'
                  >
                    {folder.title}
                  </Typography>
                  {provided.placeholder}
                </Stack>
              </CardActionArea>
            </Card>
          </Stack>}
        </Droppable>
        : <Droppable droppableId={folder.id ?? 'undefined'} key={folder.id ?? ''}>
          {provided => <Stack {...provided.droppableProps} ref={provided.innerRef}>
            <Card
              elevation={0}
              sx={{
                background: theme => theme.palette.background.elevatedCard,
              }}
            >
              <CardActionArea component={NavLink} to={`/files/folder/${props.folder.id}`}>
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  padding={1}
                >
                  <Stack alignItems='flex-end' width={1}>
                    <IconButton onClick={event => clickButton(event)} size='small'>
                      <MoreHorizRoundedIcon />
                    </IconButton>
                  </Stack>
                  <FolderRoundedIcon
                    color='action'
                    sx={{
                      height: 64,
                      width: 64,
                    }}
                  />
                  <Typography
                    textAlign='center'
                    variant='body2'
                  >
                    {folder.title}
                  </Typography>
                  {provided.placeholder}
                </Stack>
              </CardActionArea>
            </Card>
          </Stack>}
        </Droppable>}
    </>
  )
}

export default FolderItem
