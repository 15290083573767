/* eslint-disable react-hooks/exhaustive-deps */
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CopyrightRoundedIcon from '@mui/icons-material/CopyrightRounded'
import HandymanRoundedIcon from '@mui/icons-material/HandymanRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import StoreRoundedIcon from '@mui/icons-material/StoreRounded'
import { TabContext, TabList } from '@mui/lab'
import { AppBar, Backdrop, Button, Card, Dialog, Divider, IconButton, Slide, Stack, Tab, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { onboardingEvent } from 'src/api/organisation'
import UpgradeCTAButtons from 'src/components/pages/Menu/UpgradeCTA/UpgradeCTAButtons'
import StoresDistribute from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/StoresDistribute'
import { useAuth } from 'src/components/providers/AuthProvider'
import { defaultSelectAllPlatforms } from 'src/data/storesData'
import MarketingCloudItem from 'src/images/marketingCloud.jpg'

type Props = {
  readonly close: () => void
  readonly open: boolean
}

const premiumFeatures = [
  'Distribute an unlimited amount of music to all major platforms',
  'Gain new fans with recurring Pre-saves on Spotify, Apple Music, Deezer and Amazon',
  'Increase your earnings with publishing and neighboring rights collection',
  'Stay organized with collaborative file storage and task management',
  'Track playlists, influencers, and charts that feature your music in realtime',
]

const DistributeModalFree = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const { currentOrganisation } = useAuth()
  const notRightTier = currentOrganisation?.membershipTier === 'FREE'

  useEffect(() => {
    if (notRightTier) {
      void onboardingEvent('View premium feature')
    }
  }, [notRightTier])

  const [stores, setStores] = useState(defaultSelectAllPlatforms)
  const [genre, setGenre] = useState('')

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen
      onClose={props.close}
      open={props.open}
      sx={{
        '.MuiDialog-container': {
          overflow: 'hidden',
        },
      }}
    >
      <Slide direction='up' in={props.open}>
        <Stack height={1}>
          <TabContext value='1'>
            <AppBar position='sticky' sx={{ top: 0, background: theme => theme.palette.background.default }}>
              <Toolbar
                sx={{
                  justifyContent: 'space-between',
                  paddingX: 3,
                  minHeight: '64px!important',
                }}
              >
                <Typography variant='h3'>
                  Distribution
                </Typography>
                <IconButton
                  aria-label='close'
                  onClick={props.close}
                >
                  <CloseRoundedIcon />
                </IconButton>
              </Toolbar>
              <Stack paddingX={2} width={1}>
                <TabList
                  aria-label='project view'
                  sx={{
                    minHeight: '32px',
                    width: 1,
                  }}
                  variant='scrollable'
                >
                  <Tab
                    disabled
                    icon={<MusicNoteRoundedIcon />}
                    label='Recordings'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<PublicRoundedIcon />}
                    label='Territories'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<StoreRoundedIcon />}
                    label='Stores'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<HandymanRoundedIcon />}
                    label='Add-ons'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<SaveRoundedIcon />}
                    label='Pre-save'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<CopyrightRoundedIcon />}
                    label='Publishing'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<AddShoppingCartRoundedIcon />}
                    label='Services'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<CheckCircleOutlineRoundedIcon />}
                    label='Submit'
                    sx={{ minHeight: 32 }}
                  />
                </TabList>
              </Stack>
              <Divider sx={{ width: 1 }} />
            </AppBar>

            <Stack alignItems='center' height='calc(100% - 162px)' width={1}>
              <Stack
                direction='column'
                height={1}
                paddingX={2}
                position='relative'
                width={1}
              >
                <StoresDistribute
                  beatportGenre={genre}
                  setBeatportGenre={setGenre}
                  setStores={setStores}
                  stores={stores}
                  territories={[]}
                />
                <Backdrop
                  open
                  sx={{
                    position: 'absolute',
                    zIndex: theme => theme.zIndex.drawer - 2,
                    height: '100%',
                    width: 1,
                  }}
                >
                  <Stack alignItems='center' maxHeight={1} maxWidth='md' padding={2} width={1}>
                    <Card sx={{ overflow: fullScreen ? 'auto' : undefined }}>
                      <Stack
                        height='auto'
                        padding={2}
                        spacing={2}
                        width={1}
                      >
                        <Stack justifyContent='center' width={1}>
                          <Typography
                            color={themeItem.palette.text.primary}
                            lineHeight={1.3}
                            variant='h3'
                          >
                            Maximize your music&apos;s potential with Releese
                          </Typography>
                          <Typography
                            color={themeItem.palette.text.secondary}
                            maxWidth={500}
                            variant='body1'
                          >
                            Releese is trusted by thousands of artists and record labels to maximize earnings, gain
                            more fans, and stay organized, all in one place.
                          </Typography>
                        </Stack>
                        <Stack
                          alignItems='center'
                          direction={fullScreen ? 'column' : 'row'}
                          height={1}
                          spacing={2}
                          width={1}
                        >
                          <Stack direction='column' spacing={2} width={fullScreen ? '100%' : '50%'}>
                            {premiumFeatures.map(item =>
                              <Stack alignItems='center' direction='row' key={item} spacing={1} width={1}>
                                <CheckCircleRoundedIcon color='success' fontSize='medium' />
                                <Typography
                                  color={themeItem.palette.primary.contrastText}
                                  textAlign='left'
                                  variant='body2'
                                >
                                  {item}
                                </Typography>
                              </Stack>)}
                          </Stack>
                          <img
                            alt='Releese Marketing Cloud'
                            height='auto'
                            src={MarketingCloudItem}
                            style={{
                              borderRadius: '16px',
                              objectFit: 'cover',
                              width: fullScreen ? '100%' : '50%',
                            }}
                            width='100%'
                          />
                        </Stack>
                        <Stack width={1}>
                          <UpgradeCTAButtons />
                        </Stack>
                      </Stack>
                    </Card>
                  </Stack>
                </Backdrop>
              </Stack>
            </Stack>
          </TabContext>
          <AppBar
            position='fixed'
            sx={{
              top: 'auto',
              bottom: 0,
              background: theme => theme.palette.background.default,
              zIndex: theme => theme.zIndex.drawer - 1,
            }}
          >
            <Divider sx={{ width: 1 }} />
            <Toolbar
              sx={{
                justifyContent: 'space-between',
                paddingX: 3,
                minHeight: '64px!important',
              }}
            >
              <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
                <Button
                  disabled
                  variant='subtle'
                >
                  Previous
                </Button>

                <Button
                  disabled
                >
                  Continue
                </Button>
                <Button
                  disabled
                  variant='contained'
                >
                  Distribute
                </Button>
              </Stack>
            </Toolbar>
          </AppBar>
        </Stack>
      </Slide>
    </Dialog>
  )
}

export default DistributeModalFree
