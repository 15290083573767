/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable id-length */
/* eslint-disable no-restricted-imports */
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded'
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded'
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded'
import ShowChartRoundedIcon from '@mui/icons-material/ShowChartRounded'
import { Button, Card, IconButton, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { NavLink } from 'react-router-dom'
import { v4 } from 'uuid'

import { artistIntelligenceWelcomeMessage } from 'src/api/artistIntelligence'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'

type Props = {
  readonly currentTheme: string
}

const timeout = (delay: number) => new Promise(response => setTimeout(response, delay))

const DashboardWelcomeMessage = (props: Props) => {
  const { currentAccountPermissions, currentOrganisation } = useAuth()
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const componentRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  const [key, setKey] = useState('')
  const [artistIntelligenceResponse, setArtistIntelligenceResponse] = useState<{ key: string, text: string }>({ key: '', text: '' })
  const [loadingArtistIntelligence, setLoadingArtistIntelligence] = useState(false)
  const [blurb, setBlurb] = useState<{ key: string, text: string }[]>([{ key: '', text: '' }])
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (currentOrganisation) {
      const newKey = v4()
      setKey(newKey)
      setLoadingArtistIntelligence(true)
      void artistIntelligenceWelcomeMessage()
        .then(result => setArtistIntelligenceResponse({ key: newKey, text: result }))
        .catch(() => setArtistIntelligenceResponse({ key: newKey, text: 'I could not process your request. I am still in beta, sorry.' }))
        .finally(() => setLoadingArtistIntelligence(false))
    }
  }, [currentOrganisation?.id])

  const generateBlurb = async (itemString: string) => {
    const textItems = itemString.split(' ')
    for (const textItem of textItems) {
      if (textItem.length > 0) {
        setBlurb(oldValue => !oldValue.some(item => item.key === key)
          ? [...oldValue, { key, text: `${textItem}` }]
          : oldValue.map(item =>
            item.key === key
              ? { key, text: `${item.text} ${textItem}` }
              : item))
      }
      await timeout(25)
    }
  }

  useEffect(() => {
    void generateBlurb(artistIntelligenceResponse.text)
  }, [artistIntelligenceResponse.text])

  useEffect(() => {
    const getDimensions = () => componentRef.current
      ? {
        width: componentRef.current.offsetWidth,
        height: componentRef.current.offsetHeight,
      } : {
        width: 0,
        height: 0,
      }

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    setDimensions(getDimensions())

    window.addEventListener('load', handleResize)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('load', handleResize)
      window.removeEventListener('resize', handleResize)
    }
  }, [componentRef, blurb])

  return (
    <Card
      sx={{
        boxShadow: 'none',
        background: theme => props.currentTheme === 'dark'
          ? theme.palette.background.elevatedCard
          : theme.palette.background.default,
        position: 'relative',
        transition: 'height 0.25s ease-in',
        minHeight: mobile ? '300px' : '200px',
        height: expanded
          ? mobile
            ? dimensions.height + 64
            : dimensions.height + 32
          : mobile
            ? '340px'
            : '240px',
      }}
    >
      <Stack
        direction={mobile ? 'column' : 'row'}
        height={1}
        paddingBottom={1}
        paddingTop={2}
        paddingX={2}
        spacing={2}
        sx={{
          overflowY: 'scroll',
        }}
      >
        <Stack
          marginY='auto'
          spacing={1}
          width={1}
        >
          <Stack alignItems='center' direction='row' spacing={0.5} width={1}>
            <AutoAwesomeRoundedIcon
              sx={{
                color: 'rgba(186,120,255,1)',
              }}
            />
            <Typography
              lineHeight={1.2}
              sx={{
                background: 'linear-gradient(90deg, rgba(186,120,255,1) 25%, rgba(255,136,228,1) 75%)',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
              variant='h4'
            >
              Artist Intelligence
            </Typography>
          </Stack>
          <Stack paddingBottom={8} ref={componentRef} spacing={1} width={1}>
            {loadingArtistIntelligence &&
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>}
            <Typography
              color='text.secondary'
              sx={{
                '& > *': {
                  marginBottom: 1,
                  marginTop: 0,
                },
              }}
              variant='body1'
            >
              <ReactMarkdown>
                {blurb.find(item => item.key === key)?.text}
              </ReactMarkdown>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {!loadingArtistIntelligence &&
        <Stack
          bottom={0}
          left={0}
          position='absolute'
          sx={{
            background: theme => expanded
              ? 'transparent'
              : `linear-gradient(0deg, ${props.currentTheme === 'dark' ? theme.palette.background.elevatedCard : theme.palette.background.default} 35%, rgba(255,136,228,0) 100%)`,
          }}
          width={1}
        >
          <Stack direction='row' flexWrap='wrap' paddingBottom={1} paddingLeft={1.5} paddingTop={4}>
            <Button
              component={NavLink}
              disabled={!currentAccountPermissions?.includes(Permissions.VIEW_MARKETING_LINKS)}
              rounded
              size='small'
              startIcon={<LibraryMusicRoundedIcon />}
              sx={{
                width: 'fit-content',
                marginRight: 1,
                marginBottom: 1,
                background: 'linear-gradient(90deg, rgba(186,120,255,1) 25%, rgba(255,136,228,1) 75%, rgba(186,120,255,1) 100%)',
                backgroundSize: '250% auto',
                transition: 'all 0.25s ease',
                '&:hover': {
                  backgroundPosition: '25% center',
                },
              }}
              to='/projects/projects'
            >
              Create release
            </Button>
            <Button
              component={NavLink}
              disabled={!currentAccountPermissions?.includes(Permissions.VIEW_MARKETING_LINKS)}
              rounded
              size='small'
              startIcon={<ShowChartRoundedIcon />}
              sx={{
                width: 'fit-content',
                marginRight: 1,
                marginBottom: 1,
                background: 'linear-gradient(90deg, rgba(186,120,255,1) 25%, rgba(255,136,228,1) 75%, rgba(186,120,255,1) 100%)',
                backgroundSize: '250% auto',
                transition: 'all 0.25s ease',
                '&:hover': {
                  backgroundPosition: '25% center',
                },
              }}
              to='/links'
            >
              Gain new fans
            </Button>
            <Button
              component={NavLink}
              disabled={!currentAccountPermissions?.includes(Permissions.VIEW_ANALYTICS)}
              rounded
              size='small'
              startIcon={<AnalyticsRoundedIcon />}
              sx={{
                width: 'fit-content',
                marginRight: 1,
                marginBottom: 1,
                background: 'linear-gradient(90deg, rgba(186,120,255,1) 25%, rgba(255,136,228,1) 75%, rgba(186,120,255,1) 100%)',
                backgroundSize: '250% auto',
                transition: 'all 0.25s ease',
                '&:hover': {
                  backgroundPosition: '25% center',
                },
              }}
              to='/analytics/overview'
            >
              View analytics
            </Button>
            {currentOrganisation && currentOrganisation.balance > 0 &&
            <Button
              component={NavLink}
              disabled={!currentAccountPermissions?.includes(Permissions.VIEW_MARKETING_LINKS)}
              rounded
              size='small'
              startIcon={<AccountBalanceWalletRoundedIcon />}
              sx={{
                width: 'fit-content',
                marginRight: 1,
                marginBottom: 1,
                background: 'linear-gradient(90deg, rgba(186,120,255,1) 25%, rgba(255,136,228,1) 75%, rgba(186,120,255,1) 100%)',
                backgroundSize: '250% auto',
                transition: 'all 0.25s ease',
                '&:hover': {
                  backgroundPosition: '25% center',
                },
              }}
              to='/wallet'
            >
              Withdraw earnings
            </Button>}
          </Stack>
        </Stack>}
      <IconButton
        onClick={() => setExpanded(old => !old)}
        size='small'
        sx={{
          position: 'absolute',
          bottom: 8,
          right: 8,
        }}
      >
        {expanded
          ? <CloseFullscreenRoundedIcon />
          : <OpenInFullRoundedIcon />}
      </IconButton>
    </Card>
  )
}

export default DashboardWelcomeMessage
