/* eslint-disable complexity */
import { IconButton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import ActivityRows from 'src/components/pages/Audience/Components/Activity/ActivityRows'
import ChartsTable from 'src/components/pages/Audience/Components/Charts/ChartsTable'
import PreviousChartsTable from 'src/components/pages/Audience/Components/Charts/PreviousChartsTable'
import PreviousTrackChartTable from 'src/components/pages/Audience/Components/Charts/PreviousTrackCharts'
import TrackChartTable from 'src/components/pages/Audience/Components/Charts/TrackChart'
import DistributionFullChart from 'src/components/pages/Audience/Components/Overview/DistributionFullChart'
import RenderAnalyticsCards from 'src/components/pages/Audience/Components/Overview/RenderCards'
import PlaylistsTable from 'src/components/pages/Audience/Components/Playlists/PlaylistsTable'
import PlaylistsTableNoFollowers from 'src/components/pages/Audience/Components/Playlists/PlaylistTableNoFollowers'
import RemovedPlaylistsTable from 'src/components/pages/Audience/Components/Playlists/RemovedPlaylists'
import RemovedPlaylistsTableNoFollowers from 'src/components/pages/Audience/Components/Playlists/RemovedPlaylistTableNoFollowers'
import ShortsItems from 'src/components/pages/Audience/Components/Shorts/ShortsItem'
import TikTokInstagramVideoItems from 'src/components/pages/Audience/Components/Videos/TikTokInstagramVideos'
import VideoItems from 'src/components/pages/Audience/Components/Videos/VideoItems'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import type { AnalyticsActivity, AnalyticsCurrentData, AnalyticsHistoricData } from 'src/models/Analytics'

type Props = {
  currentData?: AnalyticsCurrentData
  historicData?: AnalyticsHistoricData
  artworkUrl: string
  activityData?: AnalyticsActivity
  loadingActivities: boolean
  currentTab?: string
  audience?: boolean
}

const AnalyticsPlatformDistribution = (props: Props) => {
  const [activeTab, setActiveTab] = useState(props.currentTab ?? 'spotify')
  const charts = props.currentData?.stats?.find(chartFind => chartFind.source === activeTab)?.data
    .charts?.filter(chart => !chart.removed_at)
  const previousCharts = props.currentData?.stats?.find(chartFind => chartFind.source === activeTab)?.data
    .charts?.filter(chart => chart.removed_at)

  const playlistsWithFollowers = props.currentData?.stats?.find(chartFind =>
    chartFind.source === activeTab)?.data.playlists?.filter(playlist =>
    !playlist.removed_at && playlist.followers_count)
  const removedPlaylistsWithFollowers = props.currentData?.stats?.find(chartFind =>
    chartFind.source === activeTab)?.data.playlists?.filter(playlist =>
    playlist.removed_at && playlist.followers_count)

  const playlistsWithNoFollowers = props.currentData?.stats?.find(chartFind =>
    chartFind.source === activeTab)?.data.playlists?.filter(playlist =>
    !playlist.removed_at && !playlist.followers_count && !playlist.spotifyid)
  const removedPlaylistsWithNoFollowers = props.currentData?.stats?.find(chartFind =>
    chartFind.source === activeTab)?.data.playlists?.filter(playlist =>
    playlist.removed_at && !playlist.followers_count && !playlist.spotifyid)

  const trackCharts = props.currentData?.stats?.find(chartFind =>
    chartFind.source === activeTab)?.data.track_charts?.filter(chart => !chart.removed_at)
  const albumCharts = props.currentData?.stats?.find(chartFind =>
    chartFind.source === activeTab)?.data.album_charts?.filter(chart => !chart.removed_at)

  const previousTrackCharts = props.currentData?.stats?.find(chartFind =>
    chartFind.source === activeTab)?.data.track_charts?.filter(chart => chart.removed_at)
  const previousAlbumCharts = props.currentData?.stats?.find(chartFind =>
    chartFind.source === activeTab)?.data.album_charts?.filter(chart => chart.removed_at)

  const videoItems = props.currentData?.stats?.find(chartFind =>
    chartFind.source === activeTab)?.data.videos?.filter(chart => chart.title)
  const socialVideoItems = props.currentData?.stats?.find(chartFind =>
    chartFind.source === activeTab)?.data.videos?.filter(chart => !chart.title)

  const shortsItems = props.currentData?.stats?.find(chartFind =>
    chartFind.source === activeTab)?.data.shorts

  const historicDataItem = props.historicData?.stats?.find(historic => historic.source === activeTab)?.data

  useEffect(() => {
    if (props.currentTab) {
      setActiveTab(props.currentTab)
    }
  }, [props.currentTab])

  return (
    <Stack spacing={3} width={1}>
      {props.currentData?.stats?.map(item =>
        item.source === activeTab &&
        <Stack spacing={4} width={1}>

          {item.source !== 'Overview' && !props.audience &&
          <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1}>
            <Stack alignItems='center' direction='row' spacing={1}>
              {/* Platform */}
              <IconButton
                disableRipple
                sx={{
                  backgroundColor: GetColorLink(item.source),
                  color: 'white!important',
                  ':hover': {
                    backgroundColor: GetColorLink(item.source),
                    cursor: 'default',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'white!important',
                  },
                  borderRadius: '8px',
                }}
              >
                {GetIcon(item.source)}
              </IconButton>
              <Typography variant='h3'>
                {item.source.split('_').map(word => word[0].toLocaleUpperCase() + word.slice(1)).join(' ')}
              </Typography>
            </Stack>
          </Stack>}

          {/* Data Cards */}
          <RenderAnalyticsCards
            artworkUrl={props.artworkUrl}
            currentData={props.currentData}
            currentTab={activeTab}
          />

          {historicDataItem?.history &&
          <DistributionFullChart
            data={historicDataItem.history}
            source={item.source}
          />}

          {/* Track activities */}
          <ActivityRows
            chartItems={props.activityData?.data ?? []}
            currentTab={activeTab}
            loading={props.loadingActivities}
            overview={activeTab === 'Overview'}
            track
          />

          {/* Current and Previous Charts */}
          {charts && charts.length > 0 &&
          <ChartsTable
            artworkUrl={props.artworkUrl}
            chartItems={charts}
            currentTab={activeTab}
          />}
          {previousCharts && previousCharts.length > 0 &&
          <PreviousChartsTable
            artworkUrl={props.artworkUrl}
            chartItems={previousCharts}
            currentTab={activeTab}
          />}

          {/* Current Charts Regions */}
          {trackCharts && trackCharts.length > 0 &&
          <TrackChartTable
            artworkUrl={props.artworkUrl}
            chartItems={trackCharts}
            currentTab={activeTab}
            track
          />}
          {albumCharts && albumCharts.length > 0 &&
          <TrackChartTable
            artworkUrl={props.artworkUrl}
            chartItems={albumCharts}
            currentTab={activeTab}
          />}

          {/* Previous Charts Regions */}
          {previousTrackCharts && previousTrackCharts.length > 0 &&
          <PreviousTrackChartTable
            artworkUrl={props.artworkUrl}
            chartItems={previousTrackCharts}
            currentTab={activeTab}
            track
          />}
          {previousAlbumCharts && previousAlbumCharts.length > 0 &&
          <PreviousTrackChartTable
            artworkUrl={props.artworkUrl}
            chartItems={previousAlbumCharts}
            currentTab={activeTab}
          />}

          {/* Follower Playlists */}
          {playlistsWithFollowers && playlistsWithFollowers.length > 0 &&
          <PlaylistsTable
            artworkUrl={props.artworkUrl}
            chartItems={playlistsWithFollowers}
            currentTab={activeTab}
          />}
          {removedPlaylistsWithFollowers && removedPlaylistsWithFollowers.length > 0 &&
          <RemovedPlaylistsTable
            artworkUrl={props.artworkUrl}
            chartItems={removedPlaylistsWithFollowers}
            currentTab={activeTab}
          />}

          {/* No Follower Playlists */}
          {playlistsWithNoFollowers && playlistsWithNoFollowers.length > 0 &&
          <PlaylistsTableNoFollowers
            artworkUrl={props.artworkUrl}
            chartItems={playlistsWithNoFollowers}
            currentTab={activeTab}
          />}
          {removedPlaylistsWithNoFollowers && removedPlaylistsWithNoFollowers.length > 0 &&
          <RemovedPlaylistsTableNoFollowers
            artworkUrl={props.artworkUrl}
            chartItems={removedPlaylistsWithNoFollowers}
            currentTab={activeTab}
          />}

          {/* YouTube Videos */}
          {videoItems && videoItems.length > 0 &&
          <VideoItems
            chartItems={videoItems}
          />}

          {/* YouTube Videos */}
          {socialVideoItems && socialVideoItems.length > 0 &&
          <TikTokInstagramVideoItems
            chartItems={socialVideoItems}
          />}

          {/* YouTube Shorts */}
          {shortsItems && shortsItems.length > 0 &&
          <ShortsItems
            chartItems={shortsItems}
          />}

        </Stack>)}
    </Stack>
  )
}
export default AnalyticsPlatformDistribution
