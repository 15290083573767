import { Paper, Stack } from '@mui/material'
import { Route, Routes } from 'react-router'

import LinksList from 'src/components/pages/Links/LinksList/LinksList'
import SPACING from 'src/styles/spacing'

const Links = () =>
  <Stack
    sx={{
      paddingBottom: SPACING.RESPONSIVE_CONTOUR,
      paddingRight: SPACING.RESPONSIVE_CONTOUR,
      height: 1,
      width: 1,
    }}
  >
    <Paper
      elevation={0}
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
        borderRadius: {
          xl: 1,
          lg: 1,
          md: 1,
          sm: 0,
          xs: 0,
        },
      }}
    >
      <Stack height={1} width={1}>
        <Stack height={1} overflow='auto' width={1}>
          <Routes>
            <Route element={<LinksList />} path='' />
          </Routes>
        </Stack>
      </Stack>
    </Paper>
  </Stack>

export default Links
