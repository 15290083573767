/* eslint-disable max-lines */
// Will have to divide this file into multiple ones at a later date
import type { PaletteOptions, ThemeOptions } from '@mui/material/styles'
import { alpha, createTheme, darken } from '@mui/material/styles'

import baseThemeOptions from './base.theme'
import COLOR from './colors'
import { mergeDeep } from 'src/utils/objectUtils'

const globalThemeLight: PaletteOptions = {
  mode: 'light',
  primary: {
    main: COLOR.PRIMARY.MAIN,
    subtleResting: COLOR.PRIMARY.SUBTLE_RESTING_BG,
    containedResting: COLOR.PRIMARY.CONTAINED_RESTING_BG,
    contrast2: COLOR.PRIMARY.CONTRAST_2,
  },
  secondary: {
    main: COLOR.SECONDARY.MAIN,
    subtleResting: COLOR.SECONDARY.SUBTLE_RESTING_BG,
    containedResting: COLOR.SECONDARY.CONTAINED_RESTING_BG,
  },
  success: {
    main: COLOR.SUCCESS.MAIN,
    componentBackground: COLOR.SUCCESS.COMPONENTS_BG,
  },
  warning: {
    main: COLOR.WARNING.MAIN,
    componentBackground: COLOR.WARNING.COMPONENTS_BG,
  },
  info: {
    main: COLOR.INFO.MAIN,
    componentBackground: COLOR.INFO.COMPONENTS_BG,
  },
  error: {
    main: COLOR.ERROR.MAIN,
    componentBackground: COLOR.ERROR.COMPONENTS_BG,
  },
  background: {
    default: COLOR.SURFACE.BACKGROUND,
    input: COLOR.SURFACE.INPUT,
    elevatedCard: COLOR.SURFACE.PAPER_ELEVATED,
  },
  text: {
    primary: COLOR.TEXT.PRIMARY,
    secondary: COLOR.TEXT.SECONDARY,
    title: COLOR.TEXT.PRIMARY,
    disabled: COLOR.TEXT.DISABLED,
    link: COLOR.TEXT.LINK,
    label: COLOR.TEXT.LABEL,
  },
  action: {
    active: COLOR.ACTION.ACTIVE,
  },
  divider: COLOR.OTHER.DIVIDER,
  border: COLOR.OTHER.OUTLINE_BORDER,
  rating: COLOR.OTHER.RATING_ACTIVE,
  facebook: COLOR.SOCIAL.FACEBOOK,
  apple: COLOR.SOCIAL.APPLE,
  amazon: COLOR.STREAMING.AMAZON_MUSIC,
  soundcloud: COLOR.STREAMING.SOUNDCLOUD,
  google: COLOR.SOCIAL.GOOGLE,
  appleMusic: COLOR.STREAMING.APPLE_MUSIC,
  youtube: COLOR.SOCIAL.YOUTUBE,
  spotify: COLOR.STREAMING.SPOTIFY,
  deezer: COLOR.STREAMING.DEEZER,
  instagram: COLOR.SOCIAL.INSTAGRAM,
  twitter: COLOR.SOCIAL.TWITTER,
  tiktok: COLOR.SOCIAL.TIKTOK,
  shadow: COLOR.OTHER.SHADOW,
  waveform: COLOR.OTHER.WAVEFORM_LIGHT,
  stripeBg: darken(COLOR.SURFACE.PAPER_ELEVATED, 0.04),
  tableHoverBg: COLOR.OTHER.TABLE_HOVER,
}

const themeOptions: ThemeOptions = {
  palette: globalThemeLight,
  typography: {
    helperText: {
      color: COLOR.TEXT.LABEL,
    },
    body3: {
      color: COLOR.TEXT.SECONDARY,
    },
  },
  components: {
    MuiChip: {
      variants: [
        {
          props: { variant: 'subtle', color: 'primary' },
          style: {
            backgroundColor: COLOR.PRIMARY.SUBTLE_RESTING_BG,
            color: COLOR.PRIMARY.MAIN,
          },
        },
        {
          props: { variant: 'subtle', color: 'secondary' },
          style: {
            backgroundColor: COLOR.SECONDARY.SUBTLE_RESTING_BG,
            color: COLOR.SECONDARY.MAIN,
          },
        },
        {
          props: { variant: 'subtle', color: 'success' },
          style: {
            backgroundColor: COLOR.SUCCESS.COMPONENTS_BG,
            color: COLOR.SUCCESS.COMPONENTS_CONTENT,
          },
        },
        {
          props: { variant: 'subtle', color: 'warning' },
          style: {
            backgroundColor: COLOR.WARNING.COMPONENTS_BG,
            color: COLOR.WARNING.MAIN,
          },
        },
        {
          props: { variant: 'subtle', color: 'error' },
          style: {
            backgroundColor: COLOR.ERROR.COMPONENTS_BG,
            color: COLOR.ERROR.MAIN,
          },
        },
        {
          props: { color: 'default' },
          style: {
            color: COLOR.TEXT.SECONDARY,
            backgroundColor: alpha(COLOR.TEXT.PRIMARY, 0.14),
          },
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label[data-shrink=false]+.MuiInputBase-formControl > .MuiFilledInput-input::placeholder': {
            opacity: '0.42!important',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '.Mui-error': {
            '&.MuiInputLabel-root.MuiInputLabel-root, &.MuiFormHelperText-root': {
              color: COLOR.ERROR.MAIN,
            },
          },
        },
      },
      variants: [
        {
          props: { color: 'warning' },
          style: {
            '.MuiInputLabel-root.MuiInputLabel-root, .MuiFormHelperText-root': {
              color: COLOR.WARNING.MAIN,
            },
          },
        },
        {
          props: { color: 'success' },
          style: {
            '.MuiInputLabel-root.MuiInputLabel-root, .MuiFormHelperText-root': {
              color: COLOR.SUCCESS.MAIN,
            },
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: COLOR.TEXT.DISABLED,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          border: `1px solid ${COLOR.OTHER.DIVIDER}`,
          background: COLOR.SURFACE.BACKGROUND,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: darken(COLOR.SURFACE.INPUT, 0.04),
          borderColor: darken(COLOR.SURFACE.INPUT, 0.04),
          ':focus-within.Mui-focused': {
            backgroundColor: darken(COLOR.SURFACE.INPUT, 0.04),
            borderColor: `${COLOR.PRIMARY.MAIN}!important`,
            '& .MuiSvgIcon-root': {
              color: COLOR.PRIMARY.MAIN,
            },
          },
          '& .MuiInputAdornment-root': {
            padding: 0,
            color: COLOR.TEXT.LABEL,
          },
          ':hover': {
            backgroundColor: darken(COLOR.SURFACE.INPUT, 0.04),
          },
          '&.Mui-disabled': {
            borderColor: darken(COLOR.SURFACE.INPUT, 0.04),
            backgroundColor: COLOR.ACTION.DISABLED_BG,
            color: COLOR.TEXT.DISABLED,
          },
          '&.Mui-error': {
            backgroundColor: darken(COLOR.SURFACE.INPUT, 0.04),
            borderColor: COLOR.ERROR.MAIN,
            ':focus-within.Mui-focused': {
              backgroundColor: darken(COLOR.SURFACE.INPUT, 0.04),
              borderColor: COLOR.ERROR.MAIN,
            },
          },
        },
        input: {
          color: COLOR.TEXT.PRIMARY,
        },
      },
      variants: [
        {
          props: { color: 'warning' },
          style: {
            backgroundColor: COLOR.SURFACE.BACKGROUND,
            borderColor: COLOR.WARNING.MAIN,
            ':focus-within.Mui-focused': {
              backgroundColor: COLOR.SURFACE.BACKGROUND,
              borderColor: COLOR.WARNING.DEFAULT,
            },
          },
        },
        {
          props: { color: 'success' },
          style: {
            backgroundColor: COLOR.SURFACE.BACKGROUND,
            borderColor: COLOR.SUCCESS.MAIN,
            ':focus-within.Mui-focused': {
              backgroundColor: COLOR.SURFACE.BACKGROUND,
              borderColor: COLOR.SUCCESS.DEFAULT,
            },
          },
        },
      ],
    },
    MuiStep: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: COLOR.SUCCESS.MAIN,
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: COLOR.SUCCESS.MAIN,
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '&.MuiStepIcon-root': {
            '&.Mui-completed': {
              color: COLOR.SUCCESS.MAIN,
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              color: COLOR.SURFACE.BACKGROUND,
              '&.Mui-disabled + .MuiSwitch-track': {
                color: COLOR.SURFACE.BACKGROUND,
              },
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              color: COLOR.SURFACE.BACKGROUND,
            },
          },
          '& .MuiSwitch-track': {
            backgroundColor: COLOR.ACTION.ACTIVE,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR.SURFACE.BACKGROUND,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'subtle', color: 'primary' },
          style: {
            backgroundColor: COLOR.PRIMARY.SUBTLE_RESTING_BG,
            borderColor: COLOR.PRIMARY.SUBTLE_RESTING_BG,
            color: COLOR.PRIMARY.MAIN,
            ':focus-within.Mui-focused': {
              backgroundColor: COLOR.PRIMARY.SUBTLE_ACTIVE_BG,
              borderColor: COLOR.PRIMARY.SUBTLE_ACTIVE_BG,
            },
            ':hover': {
              backgroundColor: COLOR.PRIMARY.SUBTLE_HOVER_BG,
              borderColor: COLOR.PRIMARY.SUBTLE_HOVER_BG,
            },
            '&.Mui-disabled': {
              backgroundColor: COLOR.ACTION.DISABLED_BG,
              borderColor: COLOR.ACTION.DISABLED_BG,
              color: COLOR.TEXT.DISABLED,
            },
          },
        },
        {
          props: { variant: 'subtle', color: 'secondary' },
          style: {
            backgroundColor: COLOR.SECONDARY.SUBTLE_RESTING_BG,
            borderColor: COLOR.SECONDARY.SUBTLE_RESTING_BG,
            color: COLOR.SECONDARY.MAIN,
            ':focus-within.Mui-focused': {
              backgroundColor: COLOR.SECONDARY.SUBTLE_ACTIVE_BG,
              borderColor: COLOR.SECONDARY.SUBTLE_ACTIVE_BG,
            },
            ':hover': {
              backgroundColor: COLOR.SECONDARY.SUBTLE_HOVER_BG,
              borderColor: COLOR.SECONDARY.SUBTLE_HOVER_BG,
            },
            '&.Mui-disabled': {
              backgroundColor: COLOR.ACTION.DISABLED_BG,
              borderColor: COLOR.ACTION.DISABLED_BG,
              color: COLOR.TEXT.DISABLED,
            },
          },
        },
        {
          props: { variant: 'subtle', color: 'error' },
          style: {
            backgroundColor: COLOR.ERROR.COMPONENTS_BG,
            borderColor: COLOR.ERROR.COMPONENTS_BG,
            color: COLOR.ERROR.MAIN,
            ':focus-within.Mui-focused': {
              backgroundColor: COLOR.ERROR.OUTLINED_HOVER_BG,
              borderColor: COLOR.ERROR.OUTLINED_HOVER_BG,
            },
            ':hover': {
              backgroundColor: COLOR.ERROR.OUTLINED_HOVER_BG,
              borderColor: COLOR.ERROR.OUTLINED_HOVER_BG,
            },
            '&.Mui-disabled': {
              backgroundColor: COLOR.ACTION.DISABLED_BG,
              borderColor: COLOR.ACTION.DISABLED_BG,
              color: COLOR.TEXT.DISABLED,
            },
          },
        },
        {
          props: { variant: 'subtle', color: 'success' },
          style: {
            backgroundColor: COLOR.SUCCESS.COMPONENTS_BG,
            borderColor: COLOR.SUCCESS.COMPONENTS_BG,
            color: COLOR.SUCCESS.MAIN,
            ':focus-within.Mui-focused': {
              backgroundColor: COLOR.SUCCESS.OUTLINED_HOVER_BG,
              borderColor: COLOR.SUCCESS.OUTLINED_HOVER_BG,
            },
            ':hover': {
              backgroundColor: COLOR.SUCCESS.OUTLINED_HOVER_BG,
              borderColor: COLOR.SUCCESS.OUTLINED_HOVER_BG,
            },
            '&.Mui-disabled': {
              backgroundColor: COLOR.ACTION.DISABLED_BG,
              borderColor: COLOR.ACTION.DISABLED_BG,
              color: COLOR.TEXT.DISABLED,
            },
          },
        },
        {
          props: { variant: 'subtle', color: 'info' },
          style: {
            backgroundColor: COLOR.INFO.COMPONENTS_BG,
            borderColor: COLOR.INFO.COMPONENTS_BG,
            color: COLOR.INFO.MAIN,
            ':focus-within.Mui-focused': {
              backgroundColor: COLOR.INFO.OUTLINED_HOVER_BG,
              borderColor: COLOR.INFO.OUTLINED_HOVER_BG,
            },
            ':hover': {
              backgroundColor: COLOR.INFO.OUTLINED_HOVER_BG,
              borderColor: COLOR.INFO.OUTLINED_HOVER_BG,
            },
            '&.Mui-disabled': {
              backgroundColor: COLOR.ACTION.DISABLED_BG,
              borderColor: COLOR.ACTION.DISABLED_BG,
              color: COLOR.TEXT.DISABLED,
            },
          },
        },
        {
          props: {
            rounded: true,
          },
          style: {
            borderRadius: 100,
          },
        },

      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: COLOR.ACTION.ACTIVE,
          '&.Mui-selected': {
            background: COLOR.PRIMARY.SURFACE,
          },
          ':hover': {
            background: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          overflow: 'auto',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          background: 'transparent',
          width: 8,
          height: 8,
        },
        '*::-webkit-scrollbar-thumb': {
          background: COLOR.OTHER.DIVIDER,
          borderRadius: 16,
        },
        '*::-webkit-scrollbar-corner': {
          background: 'rgba(0,0,0,0)',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        hr: {
          border: `1px solid ${COLOR.OTHER.DIVIDER}`,
          color: COLOR.OTHER.DIVIDER,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiListSubheader-root': {
            color: COLOR.TEXT.LABEL,
          },
          '& .MuiListItemButton-root': {
            color: COLOR.TEXT.SECONDARY,
          },
          '& .MuiListItemIcon-root': {
            color: COLOR.TEXT.SECONDARY,
          },
          '& .Mui-selected .MuiListItemIcon-root': {
            color: COLOR.PRIMARY.MAIN,
          },
          '& .Mui-selected .MuiListItemText-root': {
            color: COLOR.PRIMARY.MAIN,
          },
        },
        dense: {
          '& .MuiListItemButton-dense': {
            color: COLOR.TEXT.SECONDARY,
          },
        },
      },
    },
  },
}

const theme = createTheme(mergeDeep(baseThemeOptions, themeOptions))

export default theme
