/* eslint-disable sonarjs/no-identical-functions */
import { Organisation } from 'src/models/Organisation'
import type Project from 'src/models/Project'
import type { Serialized } from 'src/types/react-app-env'

export const TASK_STATUS = ['Not started', 'In progress', 'Done', 'Not approved'] as const
export const TASK_PRIORITY = ['None', 'Low', 'Medium', 'High'] as const
export const TASK_MODIFICATION_TYPES = [
  'Update',
  'Reorder',
  'Task created',
  'Task assignee',
  'Task details',
  'Task deleted',
  'Column created',
  'Column details',
  'Column deleted',
  'Taskboard details',
  'Taskboard embed added',
  'Taskboard embed removed',
  'Taskboard embed details',
  'Taskboard field added',
  'Taskboard field details',
  'Taskboard field removed',
  'Import template',
  'Changed hidden fields',
  'Uploaded a file',
  'Duplicated a section',
]

export const HIDDEN_FIELDS = [
  'Due date',
  'Assignee',
  'Priority',
  'Status',
  'Last Updated',
  'Tags',
  'Description',
]

export type TasksboardEditField = {
  fieldName: string
  oldValue: string
  newValue: string
}

export class CheckListItem {
  id = ''
  title = ''
  completed = false

  constructor(dto: CheckListItemDto) {
    Object.assign(this, dto)
  }
}

export type CheckListItemDto = Partial<CheckListItem>

export class TaskBoardEdit {
  id = ''
  taskboardId = ''
  taskId = ''
  accountId = ''
  modificationType = ''
  itemType = ''
  title = ''
  createdAt = new Date()
  fields: TasksboardEditField[] = []

  constructor(dto: TaskBoardEditDto) {
    Object.assign(this, dto)
    this.createdAt = dto.createdAt ? new Date(dto.createdAt) : new Date()
  }
}

export type TaskBoardEditDto = Partial<Serialized<TaskBoardEdit>>

export class Workspace {
  id = ''
  title = ''
  organisation?: Organisation
  color = ''
  icon = 0

  constructor(dto: WorkspaceDto) {
    Object.assign(this, dto)
    this.id = dto.id ?? ''
    this.color = dto.color ?? ''
    this.title = dto.title ?? ''
    this.organisation = dto.organisation ? new Organisation(dto.organisation) : undefined
  }
}

export type WorkspaceDto = Partial<Serialized<Workspace>>

export class NewTaskboardGroup {
  id?: string

  title: string
  boardIds: string[] = []
  workspaceId?: string

  constructor(dto: TaskboardGroupDto & { title: string }) {
    Object.assign(this, dto)
    this.title = dto.title
  }
}

export class TaskboardGroup extends NewTaskboardGroup {
  id?: string
  organisation: Organisation

  constructor(dto: TaskboardGroupDto) {
    super({
      ...dto,
      title: dto.title ?? '',
      boardIds: dto.boardIds ?? [],
      workspaceId: dto.workspaceId,
    })
    this.organisation = new Organisation(dto.organisation ?? {})
    this.id = dto.id ?? ''
  }
}

export type TaskboardGroupDto = Partial<Serialized<TaskboardGroup>>

export class EmbeddedItem {
  id = ''
  title = ''
  embedType = ''
  content = ''

  constructor(dto: EmbeddedItemDto) {
    Object.assign(this, dto)
  }
}

export type EmbeddedItemDto = Partial<Serialized<EmbeddedItem>>

export class TaskBoard {
  id?: string
  title = ''

  color = ''

  icon = 0

  taskGroups: TaskGroup[] = []

  members: string[] = []
  createdAt = new Date()

  projectId: Project | string = ''
  iCalHash = ''

  hiddenFields: string[] = []
  embeddedItems: EmbeddedItem[] = []
  customFields: CustomFields[] = []
  fieldOrder: string[] = []
  archived = false

  constructor(dto: TaskBoardDto) {
    Object.assign(this, dto)
  }
}

export type TaskBoardDto = Partial<Serialized<TaskBoard>>

export class TaskBoardTemplate {
  id = ''
  title = ''

  color = ''

  icon = 0

  taskGroups: TaskGroup[] = []
  customFields: CustomFields[] = []
  hiddenFields: string[] = []
  fieldOrder: string[] = []

  organisationId?: string
  description?: string
  createdAt = new Date()

  constructor(dto: TaskBoardTemplateDto) {
    Object.assign(this, dto)
  }
}

export type TaskBoardTemplateDto = Partial<Serialized<TaskBoardTemplate>>

export class TaskGroup {
  id = ''
  title: string

  color: string

  tasks: TaskItem[] = []

  constructor(dto: TaskGroupDto & { title: string, color: string }) {
    Object.assign(this, dto)
    this.title = dto.title
    this.color = dto.color
  }
}

export type TaskGroupDto = Partial<Serialized<TaskGroup>>

export class TaskItem {
  id = ''
  title: string

  lastUpdated = new Date()
  startDate?: Date | null
  dueDate?: Date | null
  allDay = false
  startTime = ''
  dueTime = ''

  status: typeof TASK_STATUS[number] = TASK_STATUS[0]
  priority: typeof TASK_PRIORITY[number] = TASK_PRIORITY[0]

  tags: string[] = []
  description = ''

  recurring = 'None'
  lastDoneOn?: Date | null

  assignee: string[] = []

  updates: TaskUpdate[] = []
  customFields: CustomFieldValue[] = []

  dependencies: string[] = []
  checklist: CheckListItem[] = []

  constructor(dto: TaskItemDto & { title: string }) {
    Object.assign(this, dto)
    this.title = dto.title
    this.id = dto.id ?? ''
    this.recurring = dto.recurring && dto.recurring.length > 0 ? dto.recurring : 'None'
    this.startDate = dto.startDate !== undefined ? new Date(Number(dto.startDate)) : undefined
    this.dueDate = dto.dueDate !== undefined ? new Date(Number(dto.dueDate)) : undefined
    this.lastDoneOn = dto.lastDoneOn !== undefined ? new Date(Number(dto.lastDoneOn)) : undefined
  }
}

export type TaskItemDto = Partial<Serialized<TaskItem>>

export class TaskUpdate {
  id = ''
  account: string
  message: string
  timestamp = new Date()

  constructor(dto: TaskUpdateDto & { account: string, message: string }) {
    Object.assign(this, dto)
    this.account = dto.account
    this.message = dto.message
  }
}

export type TaskUpdateDto = Partial<TaskUpdate>

export class CustomFields {
  id = ''
  title = ''
  fieldType = ''
  dropdownOptions: string[] = []
  dropdownColors: string[] = []
  currency = ''
  formulaType = ''
  formulaValues: string[] = []

  constructor(dto: CustomFieldsDto) {
    Object.assign(this, dto)
  }
}

export type CustomFieldsDto = Partial<CustomFields>

export class CustomFieldValue {
  stringValue = ''
  stringArrayValue: string[] = []
  numberValue = 0
  checkboxValue = false
  dateValue?: Date | null
  websiteValue = ''
  dropdownValue = ''
  ratingValue = 0
  peopleValue: string[] = []

  rowId = ''

  constructor(dto: CustomFieldValueDto) {
    Object.assign(this, dto)
    this.dateValue = dto.dateValue !== undefined
      ? new Date(Number(dto.dateValue))
      : undefined
  }
}

export type CustomFieldValueDto = Partial<CustomFieldValue>

export class EmbedItemWorkspace {
  id = ''
  title = ''
  embedType = ''
  content = ''
  taskboardGroupId = ''
  workspaceId = ''
  _id = ''

  constructor(dto: EmbedItemWorkspaceDto) {
    Object.assign(this, dto)
    this.id = dto._id ?? ''
  }
}

export type EmbedItemWorkspaceDto = Partial<EmbedItemWorkspace>
