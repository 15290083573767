/* eslint-disable sonarjs/no-duplicated-branches */
import { TabContext, TabList } from '@mui/lab'
import { Divider, Stack, Tab } from '@mui/material'

import GetIconSearch from 'src/components/pages/Audience/Components/Navigation/getIconSearch'
import { NavLink } from 'src/components/SafeRouterLink'

type Props = {
  readonly currentTab: string
  readonly setCurrentTab: (tab: string) => void
  readonly titles: string[]
  readonly noNav?: boolean
}

const AnalyticSearchNavMobile = (props: Props) =>
  <Stack direction='column' justifyContent='space-between' width={1}>
    <Stack alignItems='center' direction='row' spacing={2} width={1}>
      <TabContext value={props.noNav ? props.currentTab : props.currentTab.toLowerCase()}>
        <TabList
          aria-label='Current analytics view'
          onChange={(event, value: string) => props.setCurrentTab(value)}
          sx={{
            minHeight: 32,
            width: 1,
            '.MuiTabs-scroller': {
              paddingLeft: 2,
            },
          }}
          variant='scrollable'
        >
          {props.titles.map(item =>
            props.noNav
              ? <Tab
                icon={GetIconSearch(item)}
                iconPosition='start'
                key={item}
                label={item}
                sx={{ minHeight: 32 }}
                value={item}
              />
              : <Tab
                component={NavLink}
                icon={GetIconSearch(item)}
                iconPosition='start'
                key={item}
                label={item}
                sx={{ minHeight: 32 }}
                to={`/analytics/${item.toLowerCase()}`}
                value={item.toLowerCase()}
              />)}
        </TabList>
      </TabContext>
    </Stack>
    <Divider sx={{ width: 1 }} />
  </Stack>

export default AnalyticSearchNavMobile
