/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-indent */
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import { Fab, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import SearchField from 'src/components/form-elements/SearchField'
import ConversationItem from 'src/components/pages/Messages/ConversationsPanel/ConversationItem'
import NewMessageModal from 'src/components/pages/Messages/NewMessageModal/NewMessageModal'
import { useMessenger } from 'src/components/providers/MessengerProvider'

const ConversationsPanel = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const { conversations } = useMessenger()
  const [newMessage, setNewMessage] = useState(false)
  const [searchText, setSearchText] = useState('')

  return (
    <Stack
      sx={{ flexShrink: 0 }}
      width={matches ? 1 : 280}
    >
      <Stack alignItems='center' direction='row' padding={2} spacing={1}>
        <SearchField
          onChange={event => setSearchText(event.target.value)}
          sx={{ marginTop: 0, width: matches ? 1 : 280 }}
        />
        <Tooltip
          onClick={() => setNewMessage(true)}
          sx={{
            flexShrink: 0,
          }}
          title='New conversation'
        >
          <Fab color='primary' size='small'>
            <CreateRoundedIcon />
          </Fab>
        </Tooltip>
      </Stack>
      <Stack height={1} overflow='auto' width={1}>
      {conversations
        .filter(item => item.lastMessage.content.length > 0)
        .sort((a, b) =>
        +b.lastMessage.createdAt - +a.lastMessage.createdAt).map(conversation =>
          <ConversationItem
          conversation={conversation}
          key={conversation.id}
          searchText={searchText}
          />)}
      </Stack>
      <NewMessageModal
        close={() => setNewMessage(false)}
        conversations={conversations.sort((a, b) =>
          +b.lastMessage.createdAt - +a.lastMessage.createdAt)}
        open={newMessage}
      />
    </Stack>
  )
}

export default ConversationsPanel
