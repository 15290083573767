/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-duplicate-imports */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import { alpha, Button, Input, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useDropzone } from 'react-dropzone'
import { v4 } from 'uuid'

import { getRecentFiles, updateFile } from 'src/api/files'
import SearchField from 'src/components/form-elements/SearchField'
import AddFileUpload from 'src/components/pages/Files/Components/AddFileUpload'
import RecentFileTableItem from 'src/components/pages/Files/Components/RecentFileTableItem'
import useDropzoneFullscreen from 'src/components/pages/Projects/FullscreenDropzone'
import { audioFiles, imageFiles, msDocumentFiles, pdfFiles } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/FilesTab/PreviewFile'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { UploadingItem } from 'src/components/providers/FilesUploadProvider'
import { useFileUploader } from 'src/components/providers/FilesUploadProvider'
import type { FileItem } from 'src/models/File'
import SPACING from 'src/styles/spacing'

type Props = {
  setLocation?: React.Dispatch<React.SetStateAction<string>>
  searchQuery: string
  images: boolean
  files: boolean
  music: boolean
  documents: boolean
}

const RecentFiles = (props: Props) => {
  const [searchQuery, setSearchQuery] = useState('')
  const { files, setFiles, setLoading } = useFileUploader()
  const { currentOrganisation } = useAuth()
  const { getRootProps } = useDropzone()
  const isDragActive = useDropzoneFullscreen()
  const [filesItems, setFilesItems] = useState<FileItem[]>([])
  const [page, setPage] = useState(1)
  const themeItem = useTheme()
  const isMobile = useMediaQuery(themeItem.breakpoints.down('md'))

  const uploadQueue = files?.filter(item => item.organisationId === currentOrganisation?.id)

  const regexExtension = /\w{3,4}($|\?)/

  const filterFiles = filesItems
    .filter(item => item.title.toLowerCase().includes(props.searchQuery.toLowerCase()))
    .filter(item => (props.documents && msDocumentFiles.has(regexExtension.exec(item.title)?.[0] ?? '')) ||
    (props.music && audioFiles.has(regexExtension.exec(item.title)?.[0] ?? '')) ||
    (props.files && pdfFiles.has(regexExtension.exec(item.title)?.[0] ?? '')) ||
    (props.images && imageFiles.has(regexExtension.exec(item.title)?.[0] ?? '')) ||
    ((!props.documents && !props.music && !props.files && !props.images)))

  const currentPageDataFiles = filterFiles.slice(((page * 100) / 5) - 20, (page * 100) / 5)

  const getAllItems = async () => {
    await getRecentFiles(searchQuery)
      .then(fileItems => setFilesItems(fileItems))
  }

  useEffect(() => {
    void getAllItems()
  }, [currentOrganisation?.id, files?.length, searchQuery])

  const addAttachmentDropFile = (event: FileList) => {
    const fileArray: File[] = [...event]
    const items = fileArray.map(item => ({
      id: v4(),
      file: item,
      parent: '',
      projectId: '',
      organisationId: currentOrganisation?.id,
      taskId: undefined,
      taskboardId: undefined,
      loading: 0,
      total: item.size,
    })) as UploadingItem[]
    setFiles(oldUploads => oldUploads ? [...items, ...oldUploads] : [...items])
    setLoading(old => old
      ? [...old, ...items.map(item => ({ id: item.id, loading: 0, total: item.file.size }))]
      : [...items.map(item => ({ id: item.id, loading: 0, total: item.file.size }))])
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (event.dataTransfer.files.length > 0) {
      addAttachmentDropFile(event.dataTransfer.files)
      event.dataTransfer.clearData()
    }
  }

  const handleAttachmentUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return
    const items = {
      id: v4(),
      file,
      parent: '',
      projectId: '',
      organisationId: currentOrganisation?.id,
      taskId: undefined,
      taskboardId: undefined,
      loading: 0,
      total: file.size,
    } as UploadingItem

    setFiles(oldUploads => oldUploads ? [items, ...oldUploads] : [items])
    setLoading(old => old
      ? [...old, { id: items.id, loading: 0, total: items.file.size }]
      : [{ id: items.id, loading: 0, total: items.file.size }])
  }

  const updateCurrentDisplayData = (currentPage: number) => {
    setPage(currentPage)
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    } else if (result.destination.droppableId && result.destination.droppableId !== 'undefined') {
      const newFile = filesItems.find(item => item.id === result.draggableId)
      if (newFile?.id) {
        void updateFile({ ...newFile, parent: result.destination.droppableId }, newFile.id)
          .then(() => {
            setFilesItems(oldItems => oldItems.filter(item => item.id !== newFile.id))
          })
      }
    }
  }

  return (
    <>
      <Stack
        {...getRootProps()}
        onDrop={handleDrop}
        sx={{
          border: theme => `dashed ${theme.palette.primary.main} 4px`,
          backgroundColor: theme => alpha(theme.palette.background.paper, 0.9),
          position: 'absolute',
          visibility: isDragActive ? 'visible' : 'hidden',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          animation: 'blinker 2s linear infinite',
          '@keyframes blinker': {
            '50%': {
              opacity: 0.9,
            },
          },
        }}
      >
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            right: 0,
            left: 0,
            textAlign: 'center',
            color: 'grey',
            fontSize: 36,
          }}
        >
          <Typography
            color={themeItem.palette.text.label}
          >
            Drop files here
          </Typography>
        </Stack>
      </Stack>
      <DragDropContext onDragEnd={onDragEnd}>

        <Stack
          alignItems='center'
          height={1}
          overflow='auto'
          padding={SPACING.CONTENT_PADDING}
          spacing={2}
          width={1}
        >
          <Stack width={1}>
            <Typography lineHeight={1.2} textAlign='center' variant='h2'>
              Your Files
            </Typography>
            <Typography color='text.secondary' textAlign='center' variant='body1'>
              All your stored files, release files, and shared files will appear here
            </Typography>
          </Stack>
          <Stack alignItems='center' justifyContent='center' spacing={1} width={1}>
            <SearchField
              autoComplete='off'
              onChange={event => setSearchQuery(event.target.value)}
              sx={{
                maxWidth: 600,
                width: 1,
                height: '48px',
                '.MuiInputBase-root': {
                  height: '48px',
                  borderRadius: '32px',
                },
              }}
              value={searchQuery}
            />
          </Stack>
          <Stack spacing={1} width={1}>
            <Droppable droppableId='undefined' isDropDisabled key='undefined'>
              {provided =>
                <Stack spacing={1} width={1} {...provided.droppableProps} ref={provided.innerRef}>
                  <Stack direction='row' justifyContent='space-between' width={1}>
                    <Typography variant='h3'>
                      Recent Files
                    </Typography>
                  </Stack>
                  {filesItems.length > 0 || (uploadQueue ? uploadQueue.length : 0) > 0
                    ? <>
                      <Stack display='flex' width={1}>
                        <TableContainer sx={{ overflowY: 'hidden' }}>
                          <Table
                            aria-label='projects table'
                            size='small'
                            sx={{
                              minWidth: isMobile
                                ? 0
                                : 750,
                            }}
                          >
                            <TableHead sx={{
                              '.MuiTableCell-root':
              {
                borderBottom: 2,
                borderBottomColor: 'divider',
                color: 'text.secondary',
              },
                            }}
                            >
                              <TableRow>
                                <TableCell align='left' />
                                <TableCell align='left'>
                                  Name
                                </TableCell>
                                {!isMobile &&
                                <>
                                  <TableCell align='left'>
                                    Release
                                  </TableCell>
                                  <TableCell align='left'>
                                    Created At
                                  </TableCell>
                                  <TableCell align='left'>
                                    Size
                                  </TableCell>
                                </>}
                                <TableCell align='left'>
                                  Actions
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {uploadQueue?.map(file =>
                                <RecentFileTableItem
                                  file={file.file}
                                  key={file.id}
                                  setFiles={setFilesItems}
                                />)}
                              {currentPageDataFiles.map(file =>
                                <RecentFileTableItem
                                  fileItem={file}
                                  key={file.id}
                                  link={`https://cdn.releese.io/${file.url}`}
                                  setFiles={setFilesItems}
                                />)}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <AddFileUpload
                          handleAttachmentUpload={handleAttachmentUpload}
                        />
                      </Stack>
                      <Stack>
                        {
                          filterFiles.length > 20 &&
                          <Pagination
                            count={filterFiles.length > 0 ? Math.ceil(filterFiles.length / 20) : 1}
                            onChange={(event, value: number) => {
                              updateCurrentDisplayData(value)
                            }}
                            page={page}
                            sx={{ marginTop: 3, marginBottom: 4, marginLeft: 'auto' }}
                          />
                        }
                      </Stack>
                    </>

                    : <Stack alignItems='center' height={1} justifyContent='center' width={1} >
                      <Paper
                        elevation={0}
                        sx={{
                          backgroundColor: theme => theme.palette.error.main,
                          borderRadius: 16,
                        }}
                      >
                        <Stack
                          alignItems='center'
                          justifyContent='center'
                          padding={2}
                        >
                          <FolderRoundedIcon
                            sx={{
                              color: theme => theme.palette.primary.contrastText,
                              fontSize: '64px',
                            }}
                          />
                        </Stack>
                      </Paper>
                      <Typography
                        color={themeItem.palette.text.secondary}
                        sx={{
                          marginTop: 1,
                        }}
                        variant='h4'
                      >
                        No Files found
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.label}
                        variant='body1'
                      >
                        Drag and drop a new file to store it
                      </Typography>

                      <label htmlFor='upload-attachment'>
                        <Button
                          color='error'
                          component='span'
                          startIcon={<AddRoundedIcon />}
                          sx={{
                            marginTop: 2,
                          }}
                          variant='contained'
                        >
                          Upload a File
                        </Button>
                        <Input
                          id='upload-attachment'
                          onChange={handleAttachmentUpload}
                          sx={{ display: 'none', zIndex: '100000' }}
                          type='file'
                        />
                      </label>
                    </Stack>}
                </Stack>}
            </Droppable>
          </Stack>
        </Stack>
      </DragDropContext>
    </>
  )
}

export default RecentFiles
