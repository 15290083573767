/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import { alpha, Button, Input, Paper, Stack, Typography, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useDropzone } from 'react-dropzone'
import { v4 } from 'uuid'

import { getFilesByTaskboardId, getFilesByTaskId } from 'src/api/files'
import FileItemView from 'src/components/pages/Files/Components/FileItemView'
import useDropzoneFullscreen from 'src/components/pages/Projects/FullscreenDropzone'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { LoadingItem, UploadingItem } from 'src/components/providers/FilesUploadProvider'
import { useFileUploader } from 'src/components/providers/FilesUploadProvider'
import { useTasksboard } from 'src/components/providers/TasksboardProvider'
import type { FileItem } from 'src/models/File'

type Props = {
  taskId?: string
  taskboardId?: string
  home?: boolean
}

const TaskboardFiles = (props: Props) => {
  const themeItem = useTheme()
  const { currentOrganisation } = useAuth()
  const { files, setFiles, setLoading } = useFileUploader()
  const { tasksboardUpdates } = useTasksboard()
  const { getRootProps } = useDropzone()
  const isDragActive = useDropzoneFullscreen()
  const [filesItems, setFilesItems] = useState<FileItem[]>([])
  const [loading, setLoadingTest] = useState(false)
  const [firstDone, setFirstDone] = useState(false)
  const uploadQueue = files?.filter(item => item.taskboardId === props.taskboardId && props.taskId === item.taskId)

  const getAllItems = async (taskId?: string, taskboardId?: string) => {
    setLoadingTest(true)
    if (taskId && taskboardId) {
      await getFilesByTaskId(taskId, taskboardId)
        .then(fileItems => setFilesItems(fileItems))
        .finally(() => {
          setLoadingTest(false)
          setFirstDone(true)
        })
    } else if (taskboardId) {
      await getFilesByTaskboardId(taskboardId)
        .then(fileItems => setFilesItems(fileItems))
        .finally(() => {
          setLoadingTest(false)
          setFirstDone(true)
        })
    }
  }

  const getAllItemsNoLoad = async (taskId?: string, taskboardId?: string) => {
    if (taskId && taskboardId) {
      await getFilesByTaskId(taskId, taskboardId)
        .then(fileItems => setFilesItems(fileItems))
    } else if (taskboardId) {
      await getFilesByTaskboardId(taskboardId)
        .then(fileItems => setFilesItems(fileItems))
    }
  }

  useEffect(() => {
    void getAllItems(props.taskId, props.taskboardId)
  }, [currentOrganisation?.id, props.taskId, props.taskboardId])

  useEffect(() => {
    if (firstDone) {
      void getAllItemsNoLoad(props.taskId, props.taskboardId)
    }
  }, [tasksboardUpdates.length, files?.length])

  const addAttachmentDropFile = (event: FileList) => {
    const fileArray: File[] = [...event]
    const items = fileArray.map(item => ({
      id: v4(),
      file: item,
      parent: '',
      projectId: undefined,
      organisationId: currentOrganisation?.id,
      taskId: props.taskId,
      taskboardId: props.taskboardId,
      loading: 0,
      total: item.size,
    })) as UploadingItem[]
    setFiles(oldUploads => oldUploads
      ? [...items, ...oldUploads]
      : [...items])
    setLoading(old => old
      ? [...old, ...items.map(item => ({ id: item.id, loading: 0, total: item.file.size })) as LoadingItem[]]
      : [...items.map(item => ({ id: item.id, loading: 0, total: item.file.size }))] as LoadingItem[])
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (event.dataTransfer.files.length > 0) {
      addAttachmentDropFile(event.dataTransfer.files)
      event.dataTransfer.clearData()
    }
  }

  const handleAttachmentUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return
    const items = {
      id: v4(),
      file,
      parent: '',
      projectId: undefined,
      organisationId: currentOrganisation?.id,
      taskId: props.taskId,
      taskboardId: props.taskboardId,
      loading: 0,
      total: file.size,
    } as UploadingItem

    setFiles(oldUploads => oldUploads ? [items, ...oldUploads] : [items])
    setLoading(old => old
      ? [...old, { id: items.id, loading: 0, total: items.file.size }]
      : [{ id: items.id, loading: 0, total: items.file.size }])
  }

  const onDragEnd = () => null

  return (
    <>
      <Stack
        {...getRootProps()}
        onDrop={handleDrop}
        sx={{
          border: theme => `dashed ${theme.palette.primary.main} 4px`,
          backgroundColor: theme => alpha(theme.palette.background.paper, 0.9),
          position: 'absolute',
          visibility: isDragActive ? 'visible' : 'hidden',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          animation: 'blinker 2s linear infinite',
          '@keyframes blinker': {
            '50%': {
              opacity: 0.9,
            },
          },
        }}
      >
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            right: 0,
            left: 0,
            textAlign: 'center',
            color: 'grey',
            fontSize: 36,
          }}
        >
          <Typography
            color={themeItem.palette.text.label}
          >
            Drop files here
          </Typography>
        </Stack>
      </Stack>
      <DragDropContext onDragEnd={onDragEnd}>
        {props.home && !loading && filesItems.length === 0 && (uploadQueue ? uploadQueue.length : 0) === 0 &&
        <Stack alignItems='center' height={1} justifyContent='center' width={1} >
          <Paper
            elevation={0}
            sx={{
              backgroundColor: theme => theme.palette.error.main,
              borderRadius: 16,
            }}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              padding={2}
            >
              <FolderRoundedIcon
                sx={{
                  color: theme => theme.palette.primary.contrastText,
                  fontSize: '64px',
                }}
              />
            </Stack>
          </Paper>
          <Typography
            color={themeItem.palette.text.secondary}
            sx={{
              marginTop: 1,
            }}
            variant='h4'
          >
            No Files found
          </Typography>
          <Typography
            color={themeItem.palette.text.label}
            variant='body1'
          >
            Drag and drop a new file to store it
          </Typography>

          <label htmlFor='upload-attachment'>
            <Button
              color='error'
              component='span'
              startIcon={<AddRoundedIcon />}
              sx={{
                marginTop: 2,
              }}
              variant='contained'
            >
              Upload a File
            </Button>
            <Input
              id='upload-attachment'
              onChange={handleAttachmentUpload}
              sx={{ display: 'none', zIndex: '100000' }}
              type='file'
            />
          </label>
        </Stack>}
        <Droppable droppableId='undefined' isDropDisabled key='undefined'>
          {provided =>
            <Stack spacing={1} width={1} {...provided.droppableProps} ref={provided.innerRef}>
              <Stack spacing={2} width={1}>

                {(!props.home || ((filesItems.length > 0 || (uploadQueue ? uploadQueue.length : 0) > 0))) &&
                <>
                  <Stack display='flex' width={1}>
                    <Stack
                      direction='column'
                      spacing={2}
                      sx={{
                        alignContent: 'flex-start',
                      }}
                      width={1}
                    >
                      {uploadQueue?.map((file, index) =>
                        <FileItemView
                          disableDrag
                          file={file.file}
                          index={index}
                          key={file.id}
                          location=''
                          setFiles={setFilesItems}
                          taskId={props.taskId}
                          taskboardId={props.taskboardId}
                          uploadingId={file.id}
                        />)}
                      {filesItems.map((file, index) =>
                        <FileItemView
                          disableDrag
                          done
                          fileItem={file}
                          index={index + (uploadQueue ? uploadQueue.length : 0)}
                          key={file.id}
                          link={`https://cdn.releese.io/${file.url}`}
                          location=''
                          setFiles={setFilesItems}
                        />)}
                    </Stack>
                  </Stack>

                  <Stack alignItems='flex-start' height={1} justifyContent='flex-start' paddingBottom={2} width={1}>
                    <label htmlFor='upload-attachment-item'>
                      <Button
                        color='inherit'
                        component='span'
                        size='small'
                        startIcon={<AddRoundedIcon />}
                        sx={{
                          color: theme => theme.palette.text.label,
                          width: 'fit-content',
                        }}
                        variant='text'
                      >
                        Add a File
                      </Button>
                      <Input
                        id='upload-attachment-item'
                        onChange={handleAttachmentUpload}
                        sx={{ display: 'none', zIndex: '100000' }}
                        type='file'
                      />
                    </label>
                  </Stack>
                </>}
              </Stack>
            </Stack>}
        </Droppable>
      </DragDropContext>
    </>
  )
}

export default TaskboardFiles
