import { Autocomplete, TextField, useMediaQuery, useTheme } from '@mui/material'

type Props = {
  readonly periods: string[]
  readonly setPeriods: React.Dispatch<React.SetStateAction<string[]>>
  readonly periodOptions: string[]
  readonly subtle?: boolean
}

const SelectPeriod = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))

  const orderedPeriods = props.periodOptions
    .sort((a, b) => Number(new Date(b)) - Number(new Date(a)))
  return (
    <Autocomplete
      getOptionLabel={option => `${new Date(option).toLocaleString('default', { month: 'long' })} 
    ${new Date(option).getFullYear()}`}
      id='periodFilter'
      multiple
      onChange={(event, values) => props.setPeriods(values)}
      options={orderedPeriods}
      renderInput={params =>
        <TextField
          {...params}
          label={props.subtle === true ? '' : 'Statements'}
          placeholder='Select a Statement'
          sx={{
            marginTop: 0,
          }}
        />}
      sx={{
        width: mobile ? 1 : 240,
        marginTop: 0,
      }}
      value={props.periods}
    />
  )
}

export default SelectPeriod

