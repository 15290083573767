/* eslint-disable max-lines */
export class Analytics {
  ISRC = ''
  projectId = ''
  creations?: number
  downloads?: number
  streams?: number
  views?: number
  month = ''
  amount = 0
  territory = ''
  store = ''
  constructor(dto: Analytics) {
    Object.assign(this, dto)
  }
}

export class AnalyticsMonthlyChart {
  _id = ''
  creations = 0
  downloads = 0
  streams = 0
  amount = 0
  views = 0
  projectId = ''
  constructor(dto: AnalyticsMonthlyChart) {
    Object.assign(this, dto)
  }
}

export class AnalyticsTopArtists {
  _id = ''
  ISRC?: string[]
  name?: string[]
  images?: string[][]
  uri?: string[]
  constructor(dto: AnalyticsTopArtists) {
    Object.assign(this, dto)
  }
}

export type CatalogItem = {
  songstats_track_id?: number | null
  avatar?: string | null
  title?: string | null
  release_date?: string | null
  site_url?: string | null
  artists?: [{
    name?: string | null
    songstats_artist_id?: string | null
  }] | null
}

export class AnalyticsCatalog {
  catalog?: (CatalogItem)[] | null

  constructor(dto: AnalyticsCatalog) {
    Object.assign(this, dto)
  }
}

export type ActivityItem = {
  source: string
  activity_text: string
  activity_type: string
  activity_date: string
  activity_url: string | null
  activity_avatar: string | null
  activity_tier: number
  track_info: {
    songstats_track_id: string
    avatar: string
    title: string
    release_date: string
    site_url: string
    artists: [{
      name: string
      songstats_artist_id: string
    }]
  }
}

export class AnalyticsActivity {
  data?: (ActivityItem)[] | null

  constructor(dto: AnalyticsActivity) {
    Object.assign(this, dto)
  }
}

// Current Data
export class AnalyticsCurrentData {
  stats?: (StatsEntity)[] | null
  track_info?: TrackInfo
  source_ids = ''
  isrc = ''
  lastRefreshed = new Date()

  constructor(dto: AnalyticsCurrentData) {
    Object.assign(this, dto)
  }
}

export type StatsEntity = {
  source: string
  data: Data
}

export type Data = {
  streams_total?: number | null
  popularity_current?: number | null
  playlists_current?: number | null
  playlists_total?: number | null
  playlist_reach_current?: number | null
  playlist_reach_total?: number | null
  charts_current?: number | null
  charts_total?: number | null
  playlists?: (PlaylistsEntity)[] | null
  charts?: (ChartsEntity)[] | null
  charted_countries_total?: number | null
  track_charts?: (TrackChartsEntity)[] | null
  album_charts?: (AlbumChartsEntity)[] | null
  track_features?: (AlbumChartsEntityOrTrackFeaturesEntityOrAlbumFeaturesEntity)[] | null
  album_features?: (AlbumChartsEntityOrTrackFeaturesEntityOrAlbumFeaturesEntity)[] | null
  videos_total?: number | null
  views_total?: number | null
  likes_total?: number | null
  comments_total?: number | null
  videos?: (VideosEntity)[] | null
  shares_total?: number | null
  video_views_total?: number | null
  video_likes_total?: number | null
  video_comments_total?: number | null
  shorts_total?: number | null
  short_views_total?: number | null
  short_likes_total?: number | null
  short_comments_total?: number | null
  shorts?: (VideosEntityOrShortsEntity)[] | null
  shazams_total?: number | null
  charted_cities_total?: number | null
  favorites_total?: number | null
  reposts_total?: number | null
  reposters?: (RepostersEntity)[] | null
  charted_tracks_current?: number | null
  charted_tracks_total?: number | null
  monthly_listeners_current?: number | null
  followers_total?: number | null
}

export type PlaylistsEntity = {
  spotifyid?: string | null
  name: string
  spotify_userid?: string | null
  followers_count?: number | null
  owner_name?: string | null
  external_url: string
  artwork: string
  top_position: number
  top_position_date: string
  current_position?: number | null
  added_at: string
  removed_at?: string | null
  type?: string | null
  applemusicid?: string | null
  curator_name?: string | null
  curator_id?: string | null
  playlist_country_code?: string | null
  amazonid?: string | null
  rank?: number | null
  region?: string | null
  deezerid?: string | null
  deezer_userid?: string | null
  tidalid?: string | null
}

export type ChartsEntity = {
  spotifyid?: string | null
  name: string
  chart_type?: string | null
  external_url: string
  top_position: number
  top_position_date: string
  current_position?: number | null
  added_at: string
  removed_at?: string | null
  location_type?: string | null
  shazamid?: string | null
  tidalid?: string | null
  artwork?: string | null
}

export type TrackChartsEntity = {
  applemusicid?: string | null
  name: string
  top_position: number
  top_position_date?: string | null
  current_position?: number | null
  added_at: string
  removed_at?: string | null
  amazonid?: string | null
  amazon_userid?: string | null
  chart_type?: string | null
  owner_name?: string | null
  external_url?: string | null
}

export type AlbumChartsEntity = {
  applemusicid?: string | null
  name: string
  top_position: number
  top_position_date: string
  current_position?: number
  added_at: string
  removed_at: string
  amazonid?: string | null
  amazon_userid?: string | null
  chart_type?: string | null
  owner_name?: string | null
  external_url?: string | null
}

export type AlbumChartsEntityOrTrackFeaturesEntityOrAlbumFeaturesEntity = {
  amazonid: string
  name: string
  amazon_userid?: string | null
  chart_type: string
  owner_name: string
  external_url: string
  top_position: number
  top_position_date: string
  current_position?: number
  added_at: string
  removed_at: string
}

export type VideosEntity = {
  views_total?: number | null
  likes_total?: number | null
  comments_total?: number | null
  instagram_video_id?: string | null
  upload_date: string
  instagram_user_name?: string | null
  instagram_user_handle?: string | null
  instagram_user_followers?: number | null
  shares_total?: number | null
  tiktok_video_id?: string | null
  tiktok_user_name?: string | null
  tiktok_user_handle?: string | null
  tiktok_user_followers?: number | null
  view_count?: number | null
  like_count?: number | null
  comment_count?: number | null
  title?: string | null
  image_url?: string | null
  external_id?: string | null
}

export type VideosEntityOrShortsEntity = {
  view_count: number
  like_count: number
  comment_count: number
  title: string
  image_url: string
  external_id: string
  upload_date: string
}

export type RepostersEntity = {
  scid: string
  username: string
  permalink: string
  followers: number
  reposted_at: string
}

// Historic Data
export class AnalyticsHistoricData {
  stats?: (StatsEntityHistoric)[] | null
  track_info?: TrackInfo
  source_ids = ''
  isrc = ''
  lastRefreshed = new Date()

  constructor(dto: AnalyticsHistoricData) {
    Object.assign(this, dto)
  }
}

export type StatsEntityHistoric = {
  source: string
  data?: DataHistory | null
}

export type DataHistory = {
  history?: (HistoryEntity)[] | null
}

export type HistoryEntity = {
  date: string
  popularity_current?: number
  streams_total?: number
  video_views_total?: number
  video_likes_total?: number
  video_comments_total?: number
  views_total?: number
  likes_total?: number
  comments_total?: number
  videos_total?: number
  shares_total?: number
  shazams_total?: number
  favorites_total?: number
  reposts_total?: number
  charts_current?: number
  playlists_current?: number
  playlists_reach_current?: number
  playlist_reach_current?: number
  track_charts_current?: number
  charted_tracks_current?: number
  followers_total?: number
  monthly_listeners_current?: number
  streams_current?: number
}

// Shared SongStats Endpoint
export type TrackInfo = {
  songstats_track_id: string
  avatar: string
  title: string
  release_date: string
  site_url: string
  artists?: (ArtistsEntity)[] | null
}

export type ArtistsEntity = {
  name: string
  songstats_artist_id: string
}

export class SongStatsLabels {
  songstats_label_id = ''
  avatar = ''
  name = ''
  site_url = ''

  constructor(dto: SongStatsLabels) {
    Object.assign(this, dto)
  }
}

export class AnalyticsProfileData {
  links = [{
    source: '',
    external_id: '',
    url: '',
  }]
  profileData = {
    avatar: '',
    name: '',
    site_url: '',
  }
  constructor(dto: AnalyticsProfileData) {
    Object.assign(this, dto)
  }
}

export type AnalyticsAudience = {
  source: string
  data: {
    monthly_listeners?: [{
      city_name: string
      city_region: string
      country_code: string
      city_lat: string
      city_lng: string
      current_listeners: number
      peak_listeners: number
    }]
    city_charts?: [{
      country_code: string
      city_name: string
      city_region: string | null
      city_lat: number
      city_lng: number
      charted_tracks_current: number
      charted_tracks_total: number
    }]
    country_charts?: [{
      country_code: string
      charted_tracks_current: number
      charted_tracks_total: number
    }]
    followers?: [{
      country_code: string
      followers_total: number
      follower_reach_total: number
    }]
    creators?: [{
      country_code: string
      creators_total: number
      creator_reach_total: number
    }]
    reposts?: [{
      country_code: string
      reposts_total: number
      repost_reach_total: number
    }]
  }
}

export class AnalyticsAudienceData {
  audience?: (AnalyticsAudience)[] | null
  constructor(dto: AnalyticsAudienceData) {
    Object.assign(this, dto)
    this.audience = dto.audience
  }
}

export class TopPlaylists {
  data?: [{
    source: string
    top_playlists: [{
      playlist_id: string
      playlist_name: string
      playlist_type: string
      curator_id: string
      curator_name: string
      followers_total: string
      image_url: string
      top_position: number
      recorded_at: string
    }]
  }]
  lastRefreshed?: Date
  labelId?: string
  artistId?: string

  constructor(dto: TopPlaylists) {
    Object.assign(this, dto)
    this.data = dto.data
  }
}
