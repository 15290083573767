/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-len */
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { alpha, Card, CardActionArea, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIconNumberComponent from 'src/components/pages/SmartLink/Content/GetIconNumberComponent'
import SocialAssetModal from 'src/components/pages/SocialAssets/SocialAssetModal'

type Props = {
  number: number
  title: string
  secondaryTitle: string
  currentTab: string
  artworkUrl: string
  titleSocial: string
}

const NumberComponentAnalytics = (props: Props) => {
  const themeItem = useTheme()
  const [socialDrawer, setSocialDrawer] = useState(false)
  const roundup = 10 ** (`${props.number}`.length - 1) * Number(props.number.toString().charAt(0))
  return (
    <>
      <Grid item lg={3} md={4} sm={6} xl={3} xs={6}>
        <Card
          elevation={0}
          sx={{
            height: 1,
            background: themeItem.palette.background.elevatedCard,
          }}
        >
          <Card
            elevation={0}
            sx={{
              height: 1,
              background: alpha(GetColorLink(props.currentTab), 0.1),
            }}
          >
            <CardActionArea onClick={() => setSocialDrawer(true)} sx={{ height: 1 }}>
              <Stack padding={2} spacing={2} width={1}>
                <Stack alignItems='center' direction='row' justifyContent='space-between'>
                  <Stack alignItems='center' direction='row' spacing={1}>
                    <Stack
                      sx={{
                        color: GetColorLink(props.currentTab),
                        fontSize: '24px',
                      }}
                    >
                      {GetIconNumberComponent(props.title)}
                    </Stack>
                    <Typography
                      color={themeItem.palette.text.secondary}
                      lineHeight={1.2}
                      variant='body1'
                    >
                      {props.title}
                    </Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <ChevronRightRoundedIcon
                      fontSize='small'
                      sx={{
                        color: themeItem.palette.text.secondary,
                      }}
                    />
                  </Stack>
                </Stack>
                <Typography
                  lineHeight={1.1}
                  textAlign='left'
                  variant='h3'
                >
                  {props.number.toLocaleString()}
                </Typography>
                <Typography
                  color={themeItem.palette.text.label}
                  lineHeight={1.2}
                  variant='body2'
                >
                  {props.secondaryTitle}
                </Typography>
              </Stack>
            </CardActionArea>
          </Card>
        </Card>
      </Grid>
      {socialDrawer &&
      <SocialAssetModal
        close={() => setSocialDrawer(false)}
        imageMainUrl={props.artworkUrl}
        imageSecondaryUrl=''
        open={socialDrawer}
        platform={props.currentTab}
        text={`Reached ${roundup.toLocaleString()} ${props.titleSocial}`}
      />}
    </>
  )
}

export default NumberComponentAnalytics
