/* eslint-disable max-len */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import { Button, Paper, Skeleton, Stack, Table, TableCell, TableHead, TablePagination, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import ArtistIntelligenceCard from 'src/components/pages/Audience/Components/Intelligence/ArtistIntelligence'
import RecommendedArtistItem from 'src/components/pages/Audience/Components/Recommendations/RecommendedArtistItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { ArtistItemSingle } from 'src/models/AnalyticsCloud'
import { Permissions } from 'src/models/Organisation'

type Props = {
  topArtists?: ArtistItemSingle[]
  loading: boolean
}

const RecommendedArtists = (props: Props) => {
  const { currentOrganisation, currentAccountPermissions } = useAuth()
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const topArtists = props.topArtists

  const [billingModal, setBillingModalOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const activeItem = topArtists

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    setPage(0)
  }, [rowsPerPage])

  return (
    <>
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}

      <Stack direction='row' id='analytics-container' width={1}>
        <Stack
          alignItems='stretch'
          boxSizing='border-box'
          direction={mobile ? 'column' : 'row'}
          minWidth={0}
          padding={0}
          width={1}
        >
          <Stack
            direction='column'
            minWidth={0}
            padding={0}
            spacing={2}
            width={1}
          >
            <Stack width={1}>
              <ArtistIntelligenceCard
                analyticsType='recommended'
                countryCode='global'
              />

              {currentOrganisation &&
            (currentOrganisation.membershipTier === 'FREE' ||
              currentOrganisation.membershipTier === 'CREATOR' ||
              currentOrganisation.membershipTier === 'CREATOR_YEARLY')
                ? <Stack alignItems='center' width={1}>
                  <Stack
                    alignItems='center'
                    justifyContent='center'
                    padding={2}
                    width='100%'
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        background: 'linear-gradient(90deg, rgba(186,120,255,1) 25%, rgba(255,136,228,1) 75%)',
                        borderRadius: 16,
                      }}
                    >
                      <Stack
                        alignItems='center'
                        justifyContent='center'
                        padding={2}
                      >
                        <AutoAwesomeRoundedIcon
                          sx={{
                            color: theme => theme.palette.primary.contrastText,
                            fontSize: '64px',
                          }}
                        />
                      </Stack>
                    </Paper>
                    <Typography
                      color={themeItem.palette.text.secondary}
                      sx={{
                        marginTop: 1,
                      }}
                      variant='h4'
                    >
                      Discover new artists tailored to you
                    </Typography>
                    <Typography
                      color={themeItem.palette.text.label}
                      sx={{
                        textAlign: 'center',
                      }}
                      variant='body1'
                    >
                      Once you have distributed releases from 5 different artists, Artist Intelligence will be able
                      to make recommendations
                    </Typography>
                    <Button
                      disabled={!currentAccountPermissions?.includes(Permissions.MANAGE_BILLING)}
                      onClick={() => setBillingModalOpen(true)}
                      startIcon={<AutoAwesomeRoundedIcon />}
                      sx={{
                        background: 'linear-gradient(90deg, rgba(186,120,255,1) 25%, rgba(255,136,228,1) 75%, rgba(186,120,255,1) 100%)',
                        backgroundSize: '250% auto',
                        transition: 'all 0.25s ease',
                        '&:hover': {
                          backgroundPosition: '25% center',
                        },
                        marginTop: 2,
                      }}
                      variant='contained'
                    >
                      Upgrade to Pro or Business
                    </Button>
                  </Stack>
                </Stack>
                : <>
                  <Stack alignItems='center' direction='row' paddingTop={2} spacing={0.5} width={1}>
                    <Typography
                      variant='h3'
                    >
                      Recommended
                    </Typography>
                  </Stack>
                  {props.loading &&
                  <Stack width={1}>
                    <Stack
                      alignItems='center'
                      direction='row'
                      flexDirection='row'
                      flexWrap='wrap'
                      width={1}
                    >
                      {Array.from({ length: 6 }, () =>
                        <Skeleton
                          sx={{
                            width: '100%',
                            height: 32,
                            transform: 'none',
                            marginBottom: 0.5,
                            marginRight: 0.5,
                          }}
                        />)}
                    </Stack>
                  </Stack>}
                  {!props.loading && (!activeItem || activeItem.length === 0) &&
                  <Stack
                    direction='column'
                    minWidth={0}
                    padding={0}
                    width={1}
                  >
                    <Stack
                      alignItems='center'
                      height={1}
                      justifyContent='center'
                      width='100%'
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          background: 'linear-gradient(90deg, rgba(186,120,255,1) 25%, rgba(255,136,228,1) 75%)',
                          borderRadius: 16,
                        }}
                      >
                        <Stack
                          alignItems='center'
                          justifyContent='center'
                          padding={2}
                        >
                          <AutoAwesomeRoundedIcon
                            sx={{
                              color: theme => theme.palette.primary.contrastText,
                              fontSize: '64px',
                            }}
                          />
                        </Stack>
                      </Paper>
                      <Typography
                        color={themeItem.palette.text.secondary}
                        sx={{
                          marginTop: 1,
                        }}
                        variant='h4'
                      >
                        No recommendations ready
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.label}
                        sx={{
                          textAlign: 'center',
                        }}
                        variant='body1'
                      >
                        Once you have distributed releases from 5 different artists, Artist Intelligence will be able
                        to make recommendations
                      </Typography>
                    </Stack>
                  </Stack>}
                  {!props.loading && activeItem && activeItem.length > 0 &&
                  <Stack
                    direction='column'
                    minWidth={0}
                    padding={0}
                    width={1}
                  >
                    <Table>
                      <TableHead
                        sx={{
                          '.MuiTableCell-root': {
                            borderBottom: 2,
                            borderBottomColor: 'divider',
                            color: 'text.secondary',
                          },
                        }}
                      >
                        <TableRow>
                          <TableCell
                            align='left'
                            padding='normal'
                          >
                            Artist
                          </TableCell>
                          {!mobile &&
                          <>
                            <TableCell
                              align='left'
                              padding='normal'
                            >
                              Genres
                            </TableCell>
                            <TableCell
                              align='left'
                              padding='normal'
                            >
                              Popularity
                            </TableCell>
                          </>}
                        </TableRow>
                      </TableHead>
                      {activeItem.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(artist =>
                        <RecommendedArtistItem
                          artist={artist}
                          key={artist.spotifyId}
                        />)}
                    </Table>
                    <TablePagination
                      component='div'
                      count={activeItem.length}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[10, 25, 50]}
                    />
                  </Stack>}
                </>}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default RecommendedArtists
