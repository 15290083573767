/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Slide } from '@mui/material'
import { Stack } from '@mui/system'
import { useEffect, useState } from 'react'

import InformationPanel from 'src/components/pages/Messages/InformationPanel/InformationPanel'
import MessagesPanel from 'src/components/pages/Messages/MessagesPanel/MessagesPanel'
import { useMessenger } from 'src/components/providers/MessengerProvider'
import type { Conversation } from 'src/models/Conversation'

type Props = {
  conversation?: Conversation
  currentTab: string
}

const ViewProjectMessages = (props: Props) => {
  const { currentConversation, selectConversation } = useMessenger()

  const [openInformationpanel, setOpenInformationpanel] = useState(false)

  const handleInformationPanel = () => {
    setOpenInformationpanel(previous => !previous)
  }

  useEffect(() => {
    if (props.currentTab === '11' && props.conversation) {
      selectConversation(props.conversation)
    }
  }, [props.conversation, props.currentTab])

  return (
    <Stack
      direction='row'
      height={1}
      width={1}
    >
      <MessagesPanel
        handleInformationPanel={handleInformationPanel}
      />
      {currentConversation &&
      <Slide direction='left' in={openInformationpanel} mountOnEnter unmountOnExit>
        <Stack direction='row'>
          <Divider orientation='vertical' />
          <InformationPanel
            handleInformationPanel={handleInformationPanel}
          />
        </Stack>
      </Slide>}
    </Stack>
  )
}

export default ViewProjectMessages
