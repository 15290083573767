/* eslint-disable sonarjs/cognitive-complexity */
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import type { Theme } from '@mui/material'
import { Avatar, AvatarGroup, ListItemText, Stack } from '@mui/material'
import { v4 } from 'uuid'

import AccountAvatar from 'src/components/AccountAvatar'
import { timeSince } from 'src/components/pages/Messages/TimeSince'
import type Account from 'src/models/Account'
import type { Conversation } from 'src/models/Conversation'
import type { Organisation } from 'src/models/Organisation'

type Props = {
  conversation: Conversation
  theme: Theme
  currentAccount: Account
  currentOrganisation?: Organisation | null
}

const ConversationHeader = (props: Props) => {
  const otherOrganisation = props.conversation.participants?.filter(participant =>
    participant.id !== props.currentOrganisation?.id)
  const otherAccounts = props.conversation.accounts?.filter(participant =>
    participant.id !== props.currentAccount.id)

  const title = props.conversation.title === ''
    ? props.conversation.type === 'Organisation'
      ? otherOrganisation?.map(org => org.name).join(', ')
      : props.conversation.type === 'Project' && props.conversation.project?._id
        ? props.conversation.project._id.title
        : otherAccounts?.map(account => `${account.firstName} ${account.lastName}`).join(', ')
    : props.conversation.title

  return (
    <Stack alignItems='center' direction='row' flex='1' padding={1} spacing={1} width='90%'>
      <Stack alignItems='flex-start' width={48}>
        {props.conversation.conversationImageUrl === ''
          ? props.conversation.type === 'Organisation'
            ? <AvatarGroup
              componentsProps={{
                additionalAvatar: {
                  sx: {
                    marginLeft: '-20px!important',
                    marginTop: 2,
                    height: 28,
                    width: 28,
                    fontSize: 16,
                  },
                },
              }}
              max={1}
              spacing='small'
            >
              {otherOrganisation?.map(org =>
                <Avatar
                  alt={org.name}
                  key={v4()}
                  src={org.logoUrl}
                  sx={{
                    height: otherOrganisation.length > 1 ? 28 : null,
                    width: otherOrganisation.length > 1 ? 28 : null,
                    fontSize: otherOrganisation.length > 1 ? 20 : null,
                  }}
                >
                  {org.name.charAt(0)}
                </Avatar>)}
            </AvatarGroup>
            : props.conversation.type === 'Project' && props.conversation.project?._id
              ? <Avatar
                src={props.conversation.project._id.artworkPreviewUrl}
                variant='rounded'
              >
                <MusicNoteRoundedIcon />
              </Avatar>
              : otherAccounts && otherAccounts.length > 1
                ? <AvatarGroup
                  componentsProps={{
                    additionalAvatar: {
                      sx: {
                        marginLeft: '-20px!important',
                        marginTop: 2,
                        height: 28,
                        width: 28,
                        fontSize: 16,
                      },
                    },
                  }}
                  max={1}
                  spacing='small'
                  total={otherAccounts.length + 1}
                >
                  {otherAccounts.map(account =>
                    <Avatar
                      key={v4()}
                      src={account.profilePictureUrl}
                      sx={{
                        height: 28,
                        width: 28,
                        fontSize: 18,
                      }}
                    >
                      {`${account.firstName.charAt(0)}${account.lastName.charAt(0)}`}
                    </Avatar>)}
                </AvatarGroup>
                : otherAccounts &&
                <AccountAvatar
                  account={otherAccounts[0]}
                  key={v4()}
                  nolink
                  showBadge
                  size={44}
                />
          : <img
            alt={props.conversation.conversationImageUrl}
            height={48}
            src={props.conversation.conversationImageUrl}
            style={{ borderRadius: props.theme.shape.inputRadius }}
            width={48}
          />}
      </Stack>
      <Stack alignContent='start' direction='column'>
        <ListItemText
          primary={title}
          secondary={
            props.conversation.lastMessage.content.length > 0
              ? (/(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/).test(props.conversation.lastMessage.content)
                ? `Image - ${timeSince(props.conversation.lastMessage.createdAt.getTime())}`
                : props.conversation.lastMessage.content.length >= 35
                  ? `${props.conversation.lastMessage.content.slice(0, 35)}...  - 
            ${timeSince(props.conversation.lastMessage.createdAt.getTime())}`
                  : `${props.conversation.lastMessage.content}  - 
                  ${timeSince(props.conversation.lastMessage.createdAt.getTime())}`
              : 'No messages exchanged'
          }
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        />
      </Stack>
    </Stack>
  )
}

export default ConversationHeader
