/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-restricted-imports */
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import { Box, Divider, FilledInput, IconButton, Menu, MenuItem, Paper, Select, Stack, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'

import SearchField from 'src/components/form-elements/SearchField'
import SplitsTable from 'src/components/pages/Projects/ProjectTabs/SplitsTab/SplitsTable'
import SPACING from 'src/styles/spacing'

type Props = {
  readonly refresh: React.Dispatch<React.SetStateAction<boolean>>
}

const SplitsTab = (props: Props) => {
  const [splitStatus, setSplitStatus] = useState('')
  const [masterStatus, setMasterStatus] = useState('')
  const [pubStatus, setPubStatus] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  const [anchorElementFilter, setAnchorElementFilter] = useState<HTMLElement | null>(null)
  const openMenuFilter = Boolean(anchorElementFilter)
  const handleCloseFilter = () => {
    setAnchorElementFilter(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElementFilter(event.currentTarget)
  }

  return (
    <>
      <Stack
        sx={{
          paddingBottom: SPACING.RESPONSIVE_CONTOUR,
          paddingRight: SPACING.RESPONSIVE_CONTOUR,
          height: 1,
          width: 1,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            height: 1,
            width: 1,
            overflow: 'hidden',
            borderRadius: {
              xl: 1,
              lg: 1,
              md: 1,
              sm: 0,
              xs: 0,
            },
          }}
        >
          <Stack height={1} width={1}>
            <Stack height={1}>
              <Stack
                direction='row'
                padding={2}
                sx={{
                  overflowX: 'auto',
                  overflowY: 'clip',
                }}
              >
                <Stack
                  direction='row'
                  spacing={1}
                >
                  <SearchField
                    onChange={event => setSearchQuery(event.target.value)}
                    value={searchQuery}
                  />
                  <Tooltip title='Advanced filters'>
                    <IconButton
                      color='default'
                      onClick={handleClick}
                    >
                      <FilterListRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
              <Divider sx={{ width: 1 }} />
              <Stack height={1} paddingBottom={2} sx={{ overflow: 'auto' }} width={1}>
                <Stack alignItems='center' height={1} marginX='auto' paddingBottom={2} width={1}>
                  <SplitsTable
                    masterContract={masterStatus}
                    pubContract={pubStatus}
                    refresh={props.refresh}
                    searchQuery={searchQuery}
                    statusQuery={splitStatus}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
      <Menu
        anchorEl={anchorElementFilter}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleCloseFilter}
        open={openMenuFilter}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Stack
          overflow='auto'
          padding={2}
          spacing={1}
          width={360}
        >
          <Typography lineHeight={1.2} variant='h3'>
            Filters
          </Typography>

          <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
            <Typography
              sx={{
                marginTop: 0,
                width: 100,
                flexShrink: 0,
              }}
              variant='inputLabel'
            >
              Status
            </Typography>
            <Select
              displayEmpty
              fullWidth
              id='version-filter'
              input={<FilledInput />}
              onChange={event => setSplitStatus(event.target.value)}
              renderValue={selected =>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
                  <Stack alignItems='center' direction='row' key={selected} spacing={1}>
                    <Typography>{selected.length === 0 ? 'Locked and Unlocked' : selected}</Typography>
                  </Stack>
                </Box>}
              value={splitStatus}
            >
              <MenuItem value=''>Locked and Unlocked</MenuItem>
              {['Locked', 'Unlocked'].map(type =>
                <MenuItem key={type} value={type}>{type}</MenuItem>)}
            </Select>
          </Stack>

          <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
            <Typography
              sx={{
                marginTop: 0,
                width: 100,
                flexShrink: 0,
              }}
              variant='inputLabel'
            >
              Master
            </Typography>
            <Select
              displayEmpty
              fullWidth
              id='version-filter'
              input={<FilledInput />}
              onChange={event => setMasterStatus(event.target.value)}
              renderValue={selected =>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
                  <Stack alignItems='center' direction='row' key={selected} spacing={1}>
                    <Typography>{selected.length === 0 ? 'Locked and Unlocked' : selected}</Typography>
                  </Stack>
                </Box>}
              value={masterStatus}
            >
              <MenuItem value=''>Locked and Unlocked</MenuItem>
              {['Locked', 'Unlocked'].map(type =>
                <MenuItem key={type} value={type}>{type}</MenuItem>)}
            </Select>
          </Stack>

          <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
            <Typography
              sx={{
                marginTop: 0,
                width: 100,
                flexShrink: 0,
              }}
              variant='inputLabel'
            >
              Publishing
            </Typography>
            <Select
              displayEmpty
              fullWidth
              id='version-filter'
              input={<FilledInput />}
              onChange={event => setPubStatus(event.target.value)}
              renderValue={selected =>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
                  <Stack alignItems='center' direction='row' key={selected} spacing={1}>
                    <Typography>{selected.length === 0 ? 'Locked and Unlocked' : selected}</Typography>
                  </Stack>
                </Box>}
              value={pubStatus}
            >
              <MenuItem value=''>Locked and Unlocked</MenuItem>
              {['Locked', 'Unlocked'].map(type =>
                <MenuItem key={type} value={type}>{type}</MenuItem>)}
            </Select>
          </Stack>
        </Stack>
      </Menu>
    </>
  )
}

export default SplitsTab
