import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Button, Card, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { onboardingEvent } from 'src/api/organisation'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import AnalyticsAnimation from 'src/components/pages/Audience/Components/Modals/AnalyticsAnimation'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  close: () => void
}

const premiumFeatures = [
  'Discover new artists',
  'Dig deep into multiple DSPs',
  'Track playlists',
  'Track social media',
  'Advanced artist data',
  'AI-Driven analytics',
  'Royalty recoups',
  'Multi-User Team',
]

const DiscoveryUpgrade = (props: Props) => {
  const themeColors = useTheme()
  const fullScreen = useMediaQuery(themeColors.breakpoints.down('md'))
  const { currentOrganisation } = useAuth()
  const [billingModal, setBillingModalOpen] = useState(false)

  useEffect(() => {
    void onboardingEvent('View premium feature')
  }, [])

  return (
    <>
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      <Stack alignItems='center' height={1} overflow='auto' paddingX={2} width={1}>
        <Stack maxWidth={1000} width={1}>
          <Card sx={{ background: theme => theme.palette.background.elevatedCard }}>
            <Stack height={1} padding={3} spacing={3} width={1}>
              <Stack justifyContent='center' width={1}>
                <Typography lineHeight={1.2} variant='h3'>
                  Discover new artists
                </Typography>
                <Typography color='text.label' variant='body1'>
                  Subscribe to the new Business Plan to unlock Discovery
                </Typography>
              </Stack>

              <Grid
                container
              >
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                  <Stack
                    direction='column'
                    paddingBottom={fullScreen ? 2 : 0}
                    paddingRight={fullScreen ? 0 : 2}
                    spacing={1}
                    width={1}
                  >
                    {premiumFeatures.map(item =>
                      <Stack
                        alignItems='center'
                        direction='row'
                        justifyContent='space-between'
                        key={item}
                        width={1}
                      >
                        <Typography variant='body2'>
                          {item}
                        </Typography>
                        <CheckCircleRoundedIcon
                          color='success'
                          fontSize='medium'
                        />
                      </Stack>)}
                  </Stack>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                  <AnalyticsAnimation />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                  <Stack
                    direction='column'
                    justifyContent='flex-end'
                    paddingRight={fullScreen ? 0 : 2}
                    paddingTop={2}
                    spacing={1}
                    width={1}
                  >
                    <Button
                      onClick={() => setBillingModalOpen(true)}
                    >
                      Upgrade now
                    </Button>
                    <Button
                      onClick={props.close}
                      variant='text'
                    >
                      Close
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </>
  )
}

export default DiscoveryUpgrade
