import CloseIcon from '@mui/icons-material/Close'
import { Autocomplete, Avatar, Button, Dialog, IconButton, ListItem, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import SearchField from 'src/components/form-elements/SearchField'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useMessenger } from 'src/components/providers/MessengerProvider'
import type Account from 'src/models/Account'
import type { Conversation } from 'src/models/Conversation'
import { NewConversation } from 'src/models/Conversation'
import type { Organisation } from 'src/models/Organisation'

type Props = {
  close: () => void
  open: boolean
  conversations?: Conversation[]
  targetOrganisation?: Organisation
}

const NewMessageModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const { createConversation } = useMessenger()
  const { currentOrganisation, currentAccount } = useAuth()
  const [accounts, setAccounts] = useState<Account[]>([])

  const create = () => {
    const convo = new NewConversation(
      {
        type: 'Account',
        selectedAccounts:
        [
          ...accounts.map(account => account.id), currentAccount.id,
        ],
      }
    )
    createConversation(convo)
      .finally(() => props.close())
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width={1}
        >
          <Typography variant='h3'>
            New Message
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack
          alignItems='center'
          justifyContent='space-between'
          sx={{
            paddingBottom: '32px!important',
          }}
          width={1}
        >
          <Typography color='text.secondary' variant='body2'>
            Send a message to a team member or multiple team members. If they are offline they will receive an email.
          </Typography>
        </Stack>
        {currentOrganisation &&
        <Stack minWidth={300} spacing={2} width={1}>
          {/* Search for users in organization */}
          <Autocomplete
            getOptionLabel={(option: Account) => `${option.firstName} ${option.lastName}`}
            multiple
            noOptionsText='No contacts found...'
            onChange={(event, newValue) => setAccounts(newValue)}
            options={currentOrganisation.seats.filter(seat =>
              seat.account.id !== currentAccount.id).map(seat => seat.account)}
            renderInput={params =>
              <SearchField
                {...params}
                label='To'
                placeholder='Search team members'
              />}
            renderOption={(renderProps, option) =>
              <ListItem {...renderProps}>
                <Stack
                  alignItems='center'
                  direction='row'
                  paddingX={2}
                  paddingY={1}
                  width={1}
                >
                  <Avatar src={option.profilePictureUrl} />
                  <Stack flex='1' padding={1} width='90%'>
                    <Typography variant='subtitle2'>
                      {`${option.firstName} ${option.lastName}`}
                    </Typography>
                    <Typography variant='body2'>
                      {option.email}
                    </Typography>
                  </Stack>
                </Stack>
              </ListItem>}
            value={accounts}
          />
        </Stack>}
        <Stack direction='row' justifyContent='flex-end' paddingTop={2} spacing={1} width={1}>
          <Button
            disabled={accounts.length === 0}
            onClick={create}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default NewMessageModal
