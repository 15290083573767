import { Stack } from '@mui/material'

import TopReleasesChart from 'src/components/pages/Earnings/EarningsDistribution/Overview/TopReleasesChart'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  readonly selectedRecording: string
  readonly topReleases: AnalyticsMonthlyChart[]
}

const AnalyticsDistributionOverview = (props: Props) =>
  <Stack spacing={3} width={1}>
    {props.topReleases.length > 0 && props.selectedRecording.length === 0 &&
    <Stack width={1}>
      <TopReleasesChart
        analytics
        data={props.topReleases}
      />
    </Stack>}
  </Stack>

export default AnalyticsDistributionOverview
