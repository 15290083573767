import { Avatar, ListItem, ListItemAvatar, ListItemButton, ListItemText,  Typography } from '@mui/material'
import { useState } from 'react'

import ArtistModalSearch from 'src/components/pages/Audience/Components/Modals/ArtistModalSearch'

type Props = {
  name: string
  spotifyId: string
  artworkUrl: string
}

const AnalyticsFavoriteArtist = (props: Props) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <ArtistModalSearch
        artwork={props.artworkUrl}
        close={() => setOpen(false)}
        loading={false}
        open={open}
        selectedArtist={props.spotifyId}
        title={props.name}
      />
      <ListItem
        dense
        disablePadding
      >
        <ListItemButton
          onClick={() => setOpen(true)}
          selected={false}
          sx={{ borderRadius: 0 }}
        >
          <ListItemAvatar>
            <Avatar
              src={props.artworkUrl}
              sx={{
                width: 24,
                height: 24,
              }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant='body2'>
              {props.name}
            </Typography>}
            sx={{ wordBreak: 'break-word' }}
          />
        </ListItemButton>
      </ListItem>
    </>
  )
}

export default AnalyticsFavoriteArtist
