/* eslint-disable complexity */
import { Grid, Skeleton, Stack } from '@mui/material'

import RenderAnalyticsCardsProfile from 'src/components/pages/Audience/Components/Audience/RenderProfileCards'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AnalyticsCurrentData, AnalyticsHistoricData, AnalyticsProfileData } from 'src/models/Analytics'

type Props = {
  currentData?: AnalyticsCurrentData
  historicData?: AnalyticsHistoricData
  profileData?: AnalyticsProfileData
  loading: boolean
}

const AnalyticsProfileUi = (props: Props) => {
  const { currentOrganisation } = useAuth()
  return (
    <Stack paddingBottom={4} spacing={4} width={1}>
      {props.loading
        ? <Grid
          container
          spacing={1}
          sx={{
            marginLeft: '-8px!important',
            width: 'calc(100% + 8px)!important',
          }}
        >
          {Array.from({ length: 4 }, () =>
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Skeleton
                sx={{
                  height: 348,
                  width: '100%',
                  transform: 'none',
                }}
              />
            </Grid>)}
        </Grid>
        : <RenderAnalyticsCardsProfile
          artworkUrl={currentOrganisation?.logoUrl ?? props.profileData?.profileData.avatar ?? ''}
          currentData={props.currentData}
          historicData={props.historicData}
        />}
    </Stack>
  )
}

export default AnalyticsProfileUi
