/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable switch-case/no-case-curly */
/* eslint-disable max-lines */
import { Grid, Skeleton, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import _ from 'lodash'
import type { Key } from 'react'
import { useState } from 'react'

import SearchField from 'src/components/form-elements/SearchField'
import CatalogRowItem from 'src/components/pages/Audience/Components/Catalog/CatalogRowItem'
import type { CatalogItem } from 'src/models/Analytics'

type Data = {
  release_date: string
  avatar: string
  title: number
  top_position_date: string
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

const getComparator = (
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
  ) => number => order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy)

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((element, index) => [element, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(element => element[0])
}

const maxTitleLength = (arrayString: string[]) => {
  const artists = arrayString.slice(0, 3)

  return arrayString.length > 3
    ? `${artists.join(', ')}, + ${arrayString.length - 3} more`
    : artists.join(', ')
}

type Props = {
  readonly chartItems: CatalogItem[]
  loading: boolean
}

const CatalogRows = (props: Props) => {
  const rows =  props.chartItems.map(objectItem => ({
    avatar: objectItem.avatar ?? '',
    title: objectItem.title ?? '',
    release_date: objectItem.release_date ?? '',
    artists: maxTitleLength(objectItem.artists?.map(item => item.name ?? '') ?? ['No artists']),
    idItem: objectItem.songstats_track_id ?? '',
  }))

  const [searchQuery, setSearchQuery] = useState('')

  const [order, _setOrder] = useState<Order>('desc')
  const [orderBy, _setOrderBy] = useState<keyof Data>('release_date')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const filteredRows = rows.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.artists.toLowerCase().includes(searchQuery.toLowerCase()))

  return (
    <Box width={1}>
      <Stack sx={{ width: '100%', mb: 2 }}>
        <SearchField
          onChange={event => setSearchQuery(event.target.value)}
          sx={{
            width: 240,
            marginTop: 0,
          }}
          value={searchQuery}
        />
        {props.loading
          ?  <Grid
            container
            spacing={2}
            sx={{
              marginLeft: '-16px!important',
              marginTop: 1,
            }}
          >
            {Array.from({ length: 8 }, () =>
              <Grid item lg={6} md={6} sm={6} xl={3} xs={12}>
                <Skeleton variant='rounded' width='100%'>
                  <div style={{ paddingTop: '57%' }} />
                </Skeleton>
              </Grid>)}
          </Grid>
          : <>
            <Grid
              container
              spacing={1.5}
              sx={{
                marginTop: 1,
              }}
            >
              {stableSort(filteredRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <CatalogRowItem
                      artists={row.artists}
                      avatar={row.avatar}
                      idItem={row.idItem.toString()}
                      key={labelId}
                      release_date={row.release_date}
                      title={row.title}
                    />
                  )
                })}
            </Grid>
            <TablePagination
              component='div'
              count={rows.length}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[20, 40, 60]}
            />
          </>}
      </Stack>
    </Box>
  )
}

export default CatalogRows
