import CloseIcon from '@mui/icons-material/Close'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, Dialog, IconButton, ListItemText, Skeleton, Slide, Stack } from '@mui/material'

import DiscoveryUpgrade from 'src/components/pages/Audience/Components/Modals/DiscoveryUpgrade'
import ProjectAnalyticsTab from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/AnalyticsTab/ProjectAnalyticsTab'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  close: () => void
  open: boolean
  selectedRecording: string
  loading: boolean
  artwork: string
  title: string
  artists: string[]
}

const TopTrackModalSearch = (props: Props) => {
  const { currentOrganisation } = useAuth()
  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen
      fullWidth
      maxWidth='lg'
      onClose={props.close}
      open={props.open}
      sx={{
        '& .MuiPaper-root': {
          transform: 'none!important',
        },
        '.MuiDialog-paper': {
          height: '100%',
        },
      }}
    >
      <Slide direction='up' in={props.open}>
        <Stack height={1} overflow='hidden' width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            paddingX={2}
            position='sticky'
            width={1}
          >
            <Stack alignItems='center' direction='row' paddingY={2} spacing={2}>
              {props.loading
                ? <>
                  <Skeleton
                    height={64}
                    variant='rounded'
                    width={64}
                  />
                  <Skeleton
                    height={20}
                    variant='text'
                    width={100}
                  />
                </>
                : <>
                  <Avatar
                    src={props.artwork}
                    sx={{
                      width: 64,
                      height: 64,
                    }}
                    variant='rounded'
                  >
                    <MusicNoteRoundedIcon />
                  </Avatar>
                  <ListItemText
                    primary={props.title}
                    secondary={props.artists.join(', ')}
                  />
                </>}
            </Stack>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
          {(currentOrganisation?.membershipTier === 'ENTERPRISE' ||
          currentOrganisation?.membershipTier === 'ENTERPRISE_YEARLY') &&
          currentOrganisation.analyticsExtension
            ? <Stack height={1} justifyContent='flex-start' overflow='auto' width={1}>
              <Stack
                sx={{
                  overflow: 'auto',
                }}
                width={1}
              >
                <ProjectAnalyticsTab
                  artwork={props.artwork}
                  selectedRecording={props.selectedRecording}
                />
              </Stack>
            </Stack>
            : <DiscoveryUpgrade
              close={props.close}
            />}
        </Stack>
      </Slide>
    </Dialog>
  )
}

export default TopTrackModalSearch
