/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, Button, Chip, ListItemText, Stack } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import { useState } from 'react'
import { v4 } from 'uuid'

import { setAnalyticsTitle } from 'src/components/pages/Analytics/AnalyticsDistribution/AnalyticsDistributionAppbar'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import SocialAssetModal from 'src/components/pages/SocialAssets/SocialAssetModal'

type Props = {
  name: string
  followers_count: number
  owner_name: string
  external_url: string
  artwork: string
  added_at: string
  type: string
  source: string
}

const TopPlaylistRowItem = (props: Props) => {
  const row = {
    name: props.name,
    followers_count: props.followers_count,
    owner_name: props.owner_name,
    external_url: props.external_url,
    artwork: props.artwork,
    added_at: props.added_at,
    type: props.type,
    source: props.source,
  }

  const [open, setOpen] = useState(false)

  const labelId = `enhanced-table-checkbox-${row.name}${v4()}`
  return (
    <>
      <TableRow
        hover
        key={labelId}
        onClick={() => setOpen(true)}
        role='checkbox'
        sx={{
          '.MuiTableCell-root': { borderBottomColor: 'divider', paddingY: 2 },
        }}
        tabIndex={-1}
      >
        <TableCell
          component='th'
          id={labelId}
          padding='normal'
          scope='row'
          width={64}
        >
          <Avatar
            src={row.artwork}
            sx={{
              width: 64,
              height: 64,
              background: theme => theme.palette.background.input,
            }}
            variant='rounded'
          >
            <MusicNoteRoundedIcon
              sx={{
                color: theme => theme.palette.action.disabled,
              }}
            />
          </Avatar>
        </TableCell>
        <TableCell align='left'>
          <ListItemText
            primary={row.name}
            secondary={row.owner_name}
          />
          {(row.type === 'Editorial' || row.type === 'Personalized') &&
          <Chip
            icon={GetIcon(row.source)}
            label='Editorial'
            size='small'
            sx={{
              color: 'white',
              background: GetColorLink(row.source),
              '.MuiSvgIcon-root': {
                color: 'white',
                marginLeft: '12px',
              },
            }}
          />}
        </TableCell>
        <TableCell align='left' width={64}>
          <Stack direction='column' spacing={1} width={1}>
            <Chip
              icon={GetIcon(row.source)}
              label={setAnalyticsTitle(row.source)}
              sx={{
                color: 'white',
                background: GetColorLink(row.source),
                '.MuiSvgIcon-root': {
                  color: 'white',
                  marginLeft: '12px',
                },
                borderRadius: 2,
                width: 'fit-content',
              }}
            />
            {row.external_url?.length > 0 &&
              <Button
                endIcon={<ArrowForwardRoundedIcon />}
                href={row.external_url}
                onClick={event => {
                  event.stopPropagation()
                }}
                size='small'
                sx={{
                  width: 'fit-content',
                }}
                target='_blank'
                variant='subtle'
              >
                Preview
              </Button>}
          </Stack>
        </TableCell>
        <TableCell align='left'>
          {new Intl.NumberFormat('en-US',
            { notation: 'compact', maximumSignificantDigits: 3 }).format(row.followers_count)}
        </TableCell>
        <TableCell align='left'>
          {dayjs(row.added_at).format('LL')}
        </TableCell>
      </TableRow>
      {open &&
      <SocialAssetModal
        close={() => setOpen(false)}
        imageMainUrl={props.artwork}
        imageSecondaryUrl=''
        open={open}
        platform={props.source}
        text={`Playlisted in ${props.name}`}
      />}
    </>
  )
}

export default TopPlaylistRowItem
