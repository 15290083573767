/* eslint-disable max-len */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DoneIcon from '@mui/icons-material/Done'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded'
import PercentRoundedIcon from '@mui/icons-material/PercentRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Checkbox, Chip, Divider, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, ListItem, Stack, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { v4 } from 'uuid'

import { getAllCollaborators } from 'src/api/projects'
import { editSplit, inviteMasterCollaborator, invitePubCollaborator, remindRightsholders, removeCollaborator, toggleSplit } from 'src/api/splits'
import SearchField from 'src/components/form-elements/SearchField'
import type { EditRecordingFormProps } from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingsListItem'
import RightsListItem from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingTabs/RightsTab/RightsListItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import TeamAvatar from 'src/components/TeamAvatar'
import { Organisation } from 'src/models/Organisation'
import { Permissions } from 'src/models/Organisation'
import type { RightsHolder } from 'src/models/Recording'
import { emailCheck } from 'src/utils/regexUtil'

const RecordingRightsTab = (props: EditRecordingFormProps) => {
  const { currentOrganisation, currentAccountPermissions } = useAuth()

  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('md'))

  const [myCollaborators, setMyCollaborators] = useState<Organisation[]>([])

  const [loadingReminderMaster, setLoadingReminderMaster] = useState(false)
  const [loadingReminderPub, setLoadingReminderPub] = useState(false)

  const [loadingMaster, setLoadingMaster] = useState(false)
  const [loadingPub, setLoadingPub] = useState(false)

  const [recordingItem, setRecordingFields] = useState(props.recording)

  const [masterKey, setMasterKey] = useState(false)
  const [masterOwnership, setMasterOwnership] = useState<number | undefined>(100)
  const [selectedMaster, setSelectedMaster] = useState<Organisation>()
  const [searchMaster, setSearchMaster] = useState('')
  const emptyMasterItem = new Organisation({
    id: v4(),
    name: 'New Collaborator',
    organisationEmail: searchMaster,
  })

  const [pubKey, setPubKey] = useState(false)
  const [publisherOwnership, setPublisherOwnership] = useState<number | undefined>(100)
  const [selectedPub, setSelectedPub] = useState<Organisation>()
  const [searchPub, setSearchPub] = useState('')
  const emptyPubItem = new Organisation({
    id: v4(),
    name: 'New Collaborator',
    organisationEmail: searchPub,
  })

  const [masterRightsHolders, setMasterRightsHolders] = useState(props.recording.masterRightsHolders)
  const [publisherRightsHolders, setPublisherRightsHolders] = useState(props.recording.publisherRightsHolders)

  const masterOwnershipTotal = Number(masterRightsHolders.map(holder =>
    Number(holder.ownership.toFixed(2))).reduce((a, b) => a + b, 0).toFixed(2))
  const publisherOwnershipTotal = Number(publisherRightsHolders.map(holder =>
    Number(holder.ownership.toFixed(2))).reduce((a, b) => a + b, 0).toFixed(2))

  const checkMasterOwnershipTotal = masterOwnershipTotal === 100
  const checkPublisherOwnershipTotal = publisherOwnershipTotal === 100

  const checkOnlyLocks = masterRightsHolders.map(holder =>
    holder.status === 'Locked')
    .every(element => element)

  const checkPubEmail = (selectedPub &&
    selectedPub.organisationEmail.length > 0 &&
    !emailCheck.test(selectedPub.organisationEmail)) || (selectedPub &&
    !publisherRightsHolders.map(item => item.account.organisationEmail !== selectedPub.organisationEmail)
      .every(item => item))

  const checkMasterEmail = (selectedMaster &&
    selectedMaster.organisationEmail.length > 0 &&
    !emailCheck.test(selectedMaster.organisationEmail)) || (selectedMaster &&
    !masterRightsHolders.map(item => item.account.organisationEmail !== selectedMaster.organisationEmail)
      .every(item => item))

  const checkMasterLocked = masterRightsHolders.map(holder =>
    holder.status === 'Locked')
    .every(element => element) && checkMasterOwnershipTotal

  const checkPubLocked = publisherRightsHolders.map(holder =>
    holder.status === 'Locked')
    .every(element => element) && checkPublisherOwnershipTotal

  const addRightsholder = async (type: 'master' | 'publisher') => {
    if (selectedMaster && type === 'master' && masterOwnership) {
      setLoadingMaster(true)
      await inviteMasterCollaborator(props.recording.id,
        selectedMaster.organisationEmail,
        masterOwnership,
        ['Artist'])
        .then(async recording => {
          setMasterRightsHolders(recording.masterRightsHolders)
          setSearchMaster('')
          setMasterOwnership(0)
          setSelectedMaster(undefined)
          setMasterKey(key => !key)
          await props.onSave({ ...props.recording, masterRightsHolders: recording.masterRightsHolders })
        })
        .finally(() => {
          setLoadingMaster(false)
        })
    } else if (selectedPub && type === 'publisher' && publisherOwnership) {
      setLoadingPub(true)
      await invitePubCollaborator(props.recording.id,
        selectedPub.organisationEmail,
        publisherOwnership,
        ['Songwriter'])
        .then(async recording => {
          setPublisherRightsHolders(recording.publisherRightsHolders)
          setSearchPub('')
          setSelectedPub(undefined)
          setPublisherOwnership(0)
          setPubKey(key => !key)
          await props.onSave({ ...props.recording, publisherRightsHolders: recording.publisherRightsHolders })
        })
        .finally(() => {
          setLoadingPub(false)
        })
    }
  }

  const updateRightsHolder = async (type: 'master' | 'publishing', rightsholder: RightsHolder) => {
    await editSplit(props.recording.id, rightsholder.account.id, type, rightsholder.credit, rightsholder.ownership)
      .then(recording => {
        setMasterRightsHolders(recording.masterRightsHolders)
        setPublisherRightsHolders(recording.publisherRightsHolders)
        void props.onSave(props.recording)
      })
  }

  const removeRightsholder = async (type: 'master' | 'publishing', rightsholder: RightsHolder) => {
    await removeCollaborator(props.recording.id, rightsholder.account.id, type)
      .then(recording => {
        setMasterRightsHolders(recording.masterRightsHolders)
        setPublisherRightsHolders(recording.publisherRightsHolders)
        void props.onSave(props.recording)
      })
  }

  const toggleSplitHolder = async (type: 'master' | 'publishing') => {
    await toggleSplit(props.recording.id, type)
      .then(recording => {
        setMasterRightsHolders(recording.masterRightsHolders)
        setPublisherRightsHolders(recording.publisherRightsHolders)
        void props.onSave(props.recording)
      })
  }

  useEffect(() => {
    void getAllCollaborators()
      .then(setMyCollaborators)
  }, [masterRightsHolders, publisherRightsHolders])

  const updateAutomaticReminders = async () => {
    setRecordingFields({ ...recordingItem, automaticRightsUpdates: !recordingItem.automaticRightsUpdates })
    await props.onSave({ ...recordingItem, automaticRightsUpdates: !recordingItem.automaticRightsUpdates })
  }

  return (
    <Stack>
      <Stack>
        <Stack
          alignItems={matches ? 'flex-start' : 'center'}
          direction={matches ? 'column' : 'row'}
          spacing={1}
          width={1}
        >
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography variant='h6'>
              Master Splits
            </Typography>
            <Tooltip
              title='To distribute your project, all Master Splits must be locked and add up to 100%.'
            >
              <IconButton size='small'>
                <InfoRoundedIcon />
              </IconButton>
            </Tooltip>
            {checkMasterLocked &&
              <Chip
                color='success'
                icon={<LockRoundedIcon />}
                label='Locked'
              />}
          </Stack>
          {checkMasterLocked
            ? <Chip
              color='info'
              icon={<CheckCircleRoundedIcon />}
              label='Ready to distribute'
            />
            : <Chip
              color='warning'
              icon={<WarningRoundedIcon />}
              label='Not ready to distribute'
            />}
          {props.project?.organisation?.id === currentOrganisation?.id &&
          <LoadingButton
            disabled={checkMasterLocked ||
              masterRightsHolders.length === 0 ||
              !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)}
            loading={loadingReminderMaster}
            onClick={async () => {
              setLoadingReminderMaster(true)
              await remindRightsholders(props.recording.id, 'master')
                .finally(() => setLoadingReminderMaster(false))
            }}
            size='small'
            startIcon={<NotificationsActiveRoundedIcon />}
            sx={{
              marginLeft: matches ? 'inherit' : 'auto!important',
            }}
            variant='outlined'
          >
            Send reminder
          </LoadingButton>}
        </Stack>
        {props.project?.organisation?.id === currentOrganisation?.id &&
        <Grid columns={16} container marginTop={1} spacing={2}>
          <Grid item lg={3} md={3} sm={16} xl={3} xs={16}>
            <NumericFormat
              InputProps={{
                endAdornment:
  <InputAdornment position='end'>
    <PercentRoundedIcon color='disabled' fontSize='small' />
  </InputAdornment>,
              }}
              customInput={TextField}
              decimalScale={2}
              decimalSeparator='.'
              disabled={props.project?.distributed ||
                checkMasterLocked ||
                !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)}
              displayType='input'
              fullWidth
              label='Ownership'
              onValueChange={values => {
                setMasterOwnership(values.floatValue)
              }}
              thousandSeparator=','
              thousandsGroupStyle='thousand'
              value={masterOwnership}
            />
          </Grid>
          <Grid item lg={10} md={10} sm={16} xl={10} xs={16}>
            {currentOrganisation &&
            <Autocomplete
              disabled={checkMasterLocked ||
                !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)}
              getOptionLabel={(option: Organisation) => option.organisationEmail}
              inputValue={searchMaster}
              key={masterKey ? 'MasterKey' : 'NewMasterKey'}
              noOptionsText='Type an email'
              onChange={(event, newValue) => setSelectedMaster(newValue ?? undefined)}
              onInputChange={(event, value) => setSearchMaster(value.replace(/\s/g, ''))}
              options={searchMaster.length > 0
                ? [emptyMasterItem,
                  currentOrganisation,
                  ...myCollaborators.filter(item => item.id !== currentOrganisation.id)]
                : [currentOrganisation,
                  ...myCollaborators.filter(item => item.id !== currentOrganisation.id)]}
              renderInput={params =>
                <SearchField
                  {...params}
                  disabled={checkMasterLocked}
                  error={checkMasterEmail}
                  label='Collaborator Email'
                  placeholder='Search collaborators'
                  sx={{ marginTop: 2 }}
                />}
              renderOption={(renderProps, option) =>
                <ListItem {...renderProps}>
                  <Stack
                    alignItems='center'
                    direction='row'
                    paddingX={2}
                    paddingY={1}
                    width={1}
                  >
                    <TeamAvatar
                      organisation={option}
                    />
                    <Stack flex='1' padding={1} width='90%'>
                      <Typography variant='subtitle2'>{option.name}</Typography>
                      <Typography variant='body2'>{option.organisationEmail}</Typography>
                      {option.id === currentOrganisation.id &&
                        <Chip
                          color='primary'
                          label='Current account'
                          size='small'
                          sx={{
                            width: 'fit-content',
                            borderRadius: 8,
                          }}
                          variant='filled'
                        />}
                    </Stack>
                  </Stack>
                </ListItem>}
              value={selectedMaster}
            />}
          </Grid>
          <Grid item lg={3} md={3} sm={16} xl={3} xs={16}>
            <LoadingButton
              disabled={
                !selectedMaster ||
                selectedMaster.organisationEmail.length === 0 ||
                !masterOwnership ||
                masterOwnership === 0 ||
                masterRightsHolders.length > 9 ||
                props.project?.distributed ||
                checkMasterLocked ||
                checkMasterEmail ||
                !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)
              }
              fullWidth
              loading={loadingMaster}
              onClick={async () => {
                await addRightsholder('master')
              }}
              startIcon={<AddRoundedIcon />}
              sx={{
                marginTop: 2,
              }}
              variant='contained'
            >
              Add
            </LoadingButton>
          </Grid>
        </Grid>}

        <Stack>
          {
            masterRightsHolders.map((rightsholder, index) =>
              <RightsListItem
                index={index}
                key={`master-rightsholder-${rightsholder.account.id}-${v4()}`}
                locked={props.project?.distributed || checkMasterLocked}
                project={props.project}
                remove={() => removeRightsholder('master', rightsholder)}
                rightsholder={rightsholder}
                toggleSplitHolder={toggleSplitHolder}
                type='Master'
                updateRightsHolder={updated => updateRightsHolder('master', updated)}
              />)
          }
          <Stack
            direction='row'
            marginTop={2}
            spacing={1}
            visibility={masterRightsHolders.length === 0 ? 'hidden' : 'visible'}
          >
            {masterOwnershipTotal === 100
              ? <DoneIcon
                color='success'
                fontSize='small'
              />
              : <CloseRoundedIcon
                color='error'
                fontSize='small'
              /> }
            <Typography
              sx={{ color: `${masterOwnershipTotal === 100
                ? 'success.main'
                : 'error.main'}` }}
              variant='helperText'
            >
              {masterOwnershipTotal === 100
                ? 'Master Splits - Ownership equals 100%'
                : `Master Splits - Ownership must equal 100%, currently ${Number(masterOwnershipTotal.toFixed(2))}%`}
            </Typography>
          </Stack>
          <Stack
            direction='row'
            marginTop={1}
            spacing={1}
            visibility={masterRightsHolders.length === 0 ? 'hidden' : 'visible'}
          >
            {checkOnlyLocks
              ? <DoneIcon
                color='success'
                fontSize='small'
              />
              : <CloseRoundedIcon
                color='error'
                fontSize='small'
              />}
            <Typography
              sx={{ color: `${checkOnlyLocks
                ? 'success.main'
                : 'error.main'}` }}
              variant='helperText'
            >
              {checkOnlyLocks
                ? 'Master Splits - All splits are locked'
                : 'Master Splits - Splits are not all locked'}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ marginY: 3 }} />

      <Stack>
        <Stack
          alignItems={matches ? 'flex-start' : 'center'}
          direction={matches ? 'column' : 'row'}
          spacing={1}
          width={1}
        >
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography variant='h6'>
              Publishing Splits
            </Typography>
            <Tooltip
              title='To use Publishing, all Publishing Splits must be locked and add up to 100%.'
            >
              <IconButton size='small'>
                <InfoRoundedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          {checkPubLocked &&
          <Chip
            color='success'
            icon={<LockRoundedIcon />}
            label='Locked'
          />}
          {props.project?.organisation?.id === currentOrganisation?.id &&
          <LoadingButton
            disabled={checkPubLocked ||
              publisherRightsHolders.length === 0 ||
              !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)}
            loading={loadingReminderPub}
            onClick={async () => {
              setLoadingReminderPub(true)
              await remindRightsholders(props.recording.id, 'publishing')
                .finally(() => setLoadingReminderPub(false))
            }}
            size='small'
            startIcon={<NotificationsActiveRoundedIcon />}
            sx={{
              marginLeft: matches ? 'inherit' : 'auto!important',
            }}
            variant='outlined'
          >
            Send reminder
          </LoadingButton>}
        </Stack>
        {props.project?.organisation?.id === currentOrganisation?.id &&
        <Grid columns={16} container marginTop={1} spacing={3}>
          <Grid item lg={3} md={3} sm={16} xl={3} xs={16}>
            <NumericFormat
              InputProps={{
                endAdornment:
  <InputAdornment position='end'>
    <PercentRoundedIcon color='disabled' fontSize='small' />
  </InputAdornment>,
              }}
              customInput={TextField}
              decimalScale={2}
              decimalSeparator='.'
              disabled={props.project?.distributed ||
                checkPubLocked ||
                !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)}
              displayType='input'
              fullWidth
              label='Ownership'
              onValueChange={values => {
                setPublisherOwnership(values.floatValue)
              }}
              thousandSeparator=','
              thousandsGroupStyle='thousand'
              value={publisherOwnership}
            />
          </Grid>
          <Grid item lg={10} md={10} sm={16} xl={10} xs={16}>
            {currentOrganisation &&
            <Autocomplete
              disabled={props.project?.distributed ||
                checkPubLocked  ||
                !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)}
              getOptionLabel={(option: Organisation) => option.organisationEmail}
              inputValue={searchPub}
              key={pubKey ? 'PubKey' : 'NewPubKey'}
              noOptionsText='Type an email'
              onChange={(event, newValue) => setSelectedPub(newValue ?? undefined)}
              onInputChange={(event, value) => setSearchPub(value.replace(/\s/g, ''))}
              options={searchPub.length > 0
                ? [emptyPubItem,
                  currentOrganisation,
                  ...myCollaborators.filter(item => item.id !== currentOrganisation.id)]
                : [currentOrganisation,
                  ...myCollaborators.filter(item => item.id !== currentOrganisation.id)]}
              renderInput={params =>
                <SearchField
                  {...params}
                  disabled={props.project?.distributed || checkPubLocked}
                  error={checkPubEmail}
                  label='Collaborator Email'
                  placeholder='Search collaborators'
                  sx={{ marginTop: 2 }}
                />}
              renderOption={(renderProps, option) =>
                <ListItem {...renderProps}>
                  <Stack
                    alignItems='center'
                    direction='row'
                    paddingX={2}
                    paddingY={1}
                    width={1}
                  >
                    <TeamAvatar
                      organisation={option}
                    />
                    <Stack flex='1' padding={1} width='90%'>
                      <Typography variant='subtitle2'>{option.name}</Typography>
                      <Typography variant='body2'>{option.organisationEmail}</Typography>
                      {option.id === currentOrganisation.id &&
                        <Chip
                          color='primary'
                          label='Current account'
                          size='small'
                          sx={{
                            width: 'fit-content',
                            borderRadius: 8,
                          }}
                          variant='filled'
                        />}
                    </Stack>
                  </Stack>
                </ListItem>}
              value={selectedPub}
            />}
          </Grid>
          <Grid item lg={3} md={3} sm={16} xl={3} xs={16}>
            <LoadingButton
              disabled={
                !selectedPub ||
                selectedPub.organisationEmail.length === 0 ||
                !publisherOwnership ||
                publisherOwnership === 0 ||
                publisherRightsHolders.length > 9 ||
                checkPubLocked ||
                checkPubEmail ||
                props.project?.distributed ||
                !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)
              }
              fullWidth
              loading={loadingPub}
              onClick={async () => {
                await addRightsholder('publisher')
              }}
              startIcon={<AddRoundedIcon />}
              sx={{
                marginTop: 2,
              }}
              variant='contained'
            >
              Add
            </LoadingButton>
          </Grid>
        </Grid>}

        <Stack>
          {
            publisherRightsHolders.map(rightsholder =>
              <RightsListItem
                key={`publisher-rightsholder-${rightsholder.account.id}-${v4()}`}
                locked={props.project?.distributed || checkPubLocked}
                project={props.project}
                remove={() => removeRightsholder('publishing', rightsholder)}
                rightsholder={rightsholder}
                toggleSplitHolder={toggleSplitHolder}
                type='Publishing'
                updateRightsHolder={updated => updateRightsHolder('publishing', updated)}
              />)
          }
          <Stack
            direction='row'
            marginTop={2}
            spacing={1}
            visibility={publisherRightsHolders.length === 0 ? 'hidden' : 'visible'}
          >
            <DoneIcon
              color={`${publisherOwnershipTotal === 100
                ? 'success'
                : 'error'}`}
              fontSize='small'
            />
            <Typography
              sx={{ color: `${publisherOwnershipTotal === 100
                ? 'success.main'
                : 'error.main'}` }}
              variant='helperText'
            >
              {publisherOwnershipTotal === 100
                ? 'Publishing Splits - Ownership equals 100%'
                : `Publishing Splits - Ownership must equal 100%, currently ${Number(publisherOwnershipTotal.toFixed(2))}%`}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {props.project?.organisation?.id === currentOrganisation?.id &&
      <Grid item sx={{ marginTop: 1 }} xs={12}>
        <FormGroup>
          <FormControlLabel
            aria-label='Set automatic reminders'
            checked={recordingItem.automaticRightsUpdates}
            control={<Checkbox checked={recordingItem.automaticRightsUpdates} disabled={props.project?.distributed} />}
            disabled={props.project?.distributed}
            label={
              <>
                {'Send automatic reminders '}
                <Tooltip
                  title='We will email rightsholders who have not accepted their share every 72 hours'
                >
                  <InfoRoundedIcon color='action' fontSize='tiny' />
                </Tooltip>
              </>
            }
            onChange={() => updateAutomaticReminders()}
          />
        </FormGroup>
      </Grid>}
    </Stack>
  )
}

export default RecordingRightsTab
