
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Dialog, IconButton, InputAdornment, Link, Stack, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material'
import NumericInput from 'material-ui-numeric-input'
import { createContext, useEffect, useState } from 'react'

import { completeWithdrawFundsOrganisation, getPaymentMethods, requestWithdrawFundsOrganisation } from 'src/api/payout'
import WalletTermsModal from 'src/components/pages/Wallet/WalletTermsModal'
import WithdrawComplete from 'src/components/pages/Wallet/WithdrawModal/WithdrawComplete'
import WithdrawError from 'src/components/pages/Wallet/WithdrawModal/WithdrawError'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { PayoutMethod, WiseQuoteResponse } from 'src/models/Payout'

type Props = {
  readonly close: () => void
  readonly open: boolean
}

export const ErrorContext = createContext({})

const WithdrawModal = (props: Props) => {
  const [openTermsModal, setOpenTermsModal] = useState(false)
  const { currentOrganisation, refreshCurrentOrganisation } = useAuth()
  const [loading, setLoading] = useState(false)
  const [payoutMethods, setPayoutMethods] = useState<PayoutMethod[]>()
  const [payoutMethod, setPayoutMethod] = useState<PayoutMethod>()
  const [amount, setAmount] = useState(0)
  const [withdrawQuote, setWithdrawQuote] = useState<WiseQuoteResponse>()
  const [page, setPage] = useState(0)

  useEffect(() => {
    async function fetchCurrencies() {
      await getPaymentMethods()
        .then(setPayoutMethods)
    }
    void fetchCurrencies()
  }, [])

  const withDrawFunds = async () => {
    if (currentOrganisation &&
      payoutMethod &&
      amount > 0 &&
      amount <= Number(currentOrganisation.balance ? currentOrganisation.balance.toFixed(2) : 0)) {
      setLoading(true)
      if (!withdrawQuote) {
        await requestWithdrawFundsOrganisation(payoutMethod.wiseId, amount)
          .then(setWithdrawQuote)
          .catch(() => setPage(2))
          .finally(() => setLoading(false))
      } else {
        setLoading(true)
        await completeWithdrawFundsOrganisation(payoutMethod.wiseId, withdrawQuote.id)
          .then(() => setPage(1))
          .catch(() => setPage(2))
          .then(() => refreshCurrentOrganisation())
          .finally(() => setLoading(false))
      }
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      onClose={props.close}
      open={props.open}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'scroll',
        '& .MuiPaper-root': {
          maxWidth: 1000,
        },
      }}
    >
      <Stack height={1} paddingX={3} paddingY={3} spacing={4} width={400}>
        <Stack direction='column'>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width={1}
          >
            <Typography variant='h3'>
              Withdraw Funds
            </Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
        </Stack>
        {page === 0 &&
        <Stack spacing={2}>
          {!withdrawQuote &&
          <Stack spacing={4}>
            <NumericInput
              decimalChar='.'
              error={amount > Number(currentOrganisation?.balance ? currentOrganisation.balance.toFixed(2) : 0)}
              inputProps={{
                maxLength: 50,
                style: {
                  fontSize: 48,
                  fontWeight: 500,
                  textAlign: 'center',
                },
                autoComplete: 'off',
              }}
              onChange={event => setAmount(Number(event.target.value ?? 0))}
              precision={2}
              thousandChar=','
              value={amount}
              variant='standard'
            />

            {payoutMethods &&
            <Autocomplete
              autoHighlight
              autoSelect
              fullWidth
              getOptionLabel={option => option.name}
              onChange={(_, newValue) => setPayoutMethod(newValue ?? undefined)}
              options={payoutMethods}
              renderInput={params =>
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <InputAdornment position='start'>
                      {payoutMethod &&
                      <img
                        alt='country-flag'
                        height={22}
                        src={`https://wise.com/public-resources/assets/flags/rectangle/${payoutMethod.currency
                          .toString()
                          .toLowerCase()}.png`}
                        width={28}
                      />}
                    </InputAdornment>,
                  }}
                  label='Payout method'
                  placeholder='Select a payout method'
                  sx={{
                    marginTop: 1,
                  }}
                />}
              renderOption={(renderProps, option) =>
                <Box component='li' {...renderProps} >
                  <Stack alignItems='center' direction='row' spacing={1}>
                    <img
                      alt='country-flag'
                      height={22}
                      src={`https://wise.com/public-resources/assets/flags/rectangle/${option.currency
                        .toString()
                        .toLowerCase()}.png`}
                      width={28}
                    />
                    <Typography>
                      {option.name}
                    </Typography>
                  </Stack>
                </Box>}
              sx={{ marginTop: 0, height: 42 }}
              value={payoutMethod}
            />}
          </Stack>}

          {withdrawQuote &&
          <Stack alignItems='center' spacing={2}>
            <Table>
              <TableBody>
                <TableRow
                  sx={{
                    '& .MuiTableCell-root': {
                      borderColor: theme => theme.palette.divider,
                    },
                  }}
                >
                  <TableCell>
                    <Typography variant='textLabel'>
                      Initial amount (USD)
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                      .format(amount)}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '& .MuiTableCell-root': {
                      borderColor: theme => theme.palette.divider,
                    },
                  }}
                >
                  <TableCell>
                    <Typography variant='textLabel'>
                      {`Target amount (${withdrawQuote.paymentOptions[0].targetCurrency})`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat('en-US',
                      {
                        style: 'currency',
                        currency: withdrawQuote.paymentOptions[0].targetCurrency,
                      })
                      .format(withdrawQuote.paymentOptions[0].targetAmount)}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '& .MuiTableCell-root': {
                      borderColor: theme => theme.palette.divider,
                    },
                  }}
                >
                  <TableCell>
                    <Typography variant='textLabel'>
                      Should arrive
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {`${withdrawQuote.paymentOptions[0].formattedEstimatedDelivery}`}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '& .MuiTableCell-root': {
                      borderColor: theme => theme.palette.divider,
                    },
                  }}
                >
                  <TableCell>
                    <Typography variant='textLabel'>
                      Total fees
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {`$${withdrawQuote.paymentOptions[0].price.total.value.amount.toFixed(2)}`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Stack>
              <Typography textAlign='center' variant='helperText'>
                {'By requesting a withdrawal, I agree to the '}
                <Link
                  onClick={() => setOpenTermsModal(true)}
                  sx={{
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  Wise Terms and Conditions
                </Link>
              </Typography>
              <WalletTermsModal
                close={() => setOpenTermsModal(false)}
                open={openTermsModal}
              />
            </Stack>
          </Stack>}

          <LoadingButton
            color={withdrawQuote
              ? 'success'
              : 'primary'}
            disabled={
              amount === 0 ||
            amount > Number(currentOrganisation?.balance ? currentOrganisation.balance.toFixed(2) : 0) ||
            payoutMethod === undefined
            }
            loading={loading}
            onClick={() => withDrawFunds()}
            variant='contained'
          >
            {withdrawQuote
              ? 'Confirm withdrawal'
              : 'Continue'}
          </LoadingButton>
        </Stack>}
        {page === 1 &&
          <WithdrawComplete />}
        {page === 2 &&
          <WithdrawError />}
      </Stack>
    </Dialog>
  )
}
export default WithdrawModal
