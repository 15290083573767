/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable unicorn/prefer-array-find */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ForumRoundedIcon from '@mui/icons-material/ForumRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import { Avatar, AvatarGroup, CircularProgress, IconButton, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { getApp } from 'firebase/app'
import { getDatabase, onValue, ref as refFirebase } from 'firebase/database'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import CorrespondantMessage from 'src/components/pages/Messages/MessagesPanel/MessagesPanelComponents/CorrespondantMessage'
import MessagesField from 'src/components/pages/Messages/MessagesPanel/MessagesPanelComponents/MessagesField'
import SelfMessage from 'src/components/pages/Messages/MessagesPanel/MessagesPanelComponents/SelfMessage'
import { timeSince } from 'src/components/pages/Messages/TimeSince'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useMessenger } from 'src/components/providers/MessengerProvider'
import SPACING from 'src/styles/spacing'

type Props = {
  handleInformationPanel?: () => void
  handleSmallMessages?: () => void
}

const MessagesPanel = (props: Props) => {
  const { ref, inView } = useInView({
    threshold: 0,
  })
  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('md'))

  const [connection, setConnection] = useState(false)
  const [lastOnline, setLastOnline] = useState('')
  const { currentAccount, currentOrganisation } = useAuth()
  const [loading, setLoading] = useState(false)
  const { currentConversationMessages, currentConversation, fetchMessageBatch, setCurrentConversation } = useMessenger()
  const otherOrganisation = currentConversation?.participants?.filter(participant =>
    participant.id !== currentOrganisation?.id)

  const otherAccounts = currentConversation?.accounts?.filter(participant =>
    participant.id !== currentAccount.id)

  const app = getApp()
  const database = getDatabase(app)

  const allAccountMembers = currentConversation?.accounts?.map(item => item) ?? []
  const allOrgMembers = currentConversation?.participants?.flatMap(item => item.seats.map(seat => seat.account)) ?? []
  const allMembersArray = [...allOrgMembers, ...allAccountMembers]

  useEffect(() => {
    if (currentConversation && currentConversation.type === 'Account') {
      const userOnlineRef = refFirebase(database, `users/${otherAccounts?.[0].id  ?? ''}/connections`)
      onValue(userOnlineRef, snapshot => {
        const isOnline: boolean = snapshot.val()
        setConnection(isOnline)
      })
    }
  }
  , [currentConversation?.id])

  useEffect(() => {
    if (currentConversation && currentConversation.type === 'Account') {
      const userLastOnlineRef = refFirebase(database, `users/${otherAccounts?.[0].id ?? ''}/lastOnline`)
      onValue(userLastOnlineRef, snapshot => {
        const lastSeen: string = snapshot.val()
        setLastOnline(lastSeen)
      })
    }
  }
  , [currentConversation?.id])

  useEffect(() => {
    const fetchData = async () => {
      if (currentConversation) {
        setLoading(true)
        await fetchMessageBatch(currentConversation, currentConversationMessages)
          .then(() => setLoading(false))
      }
    }

    if (inView) {
      void fetchData()
        .finally(null)
    }
  }, [inView, currentConversation?.id])

  return <Stack flex={1} height={1} width={1}>
    <Stack
      alignItems='center'
      borderBottom={SPACING.borderStyle}
      direction='row'
      justifyContent='space-between'
      paddingX={3}
      paddingY={1.5}
      spacing={1.5}
    >
      {matches &&
      <IconButton
        color='primary'
        onClick={() => setCurrentConversation(null)}
        size='small'
      >
        <ChevronLeftRoundedIcon />
      </IconButton>}
      <Stack spacing={0}>
        <Typography variant='subtitle1'>
          {currentConversation?.type === 'Organisation'
            ? currentConversation.title
              ? currentConversation.title
              : otherOrganisation?.map(org => org.name).join(', ')
            : currentConversation?.project?._id
              ? currentConversation.project._id.title
              : otherAccounts?.map(account => `${account.firstName} ${account.lastName}`).join(', ')}
        </Typography>
        <Typography color='text.secondary' variant='body2'>
          {currentConversation?.type === 'Organisation' ||
          currentConversation?.type === 'Project'
            ? currentConversation.participants?.length === 1
              ? '1 Team'
              : `${currentConversation.participants?.length} Teams`
            : currentConversation?.accounts && currentConversation.accounts.length > 2
              ? `${currentConversation.accounts.length} Members`
              : connection
                ? 'Online'
                : `Last seen ${timeSince(Number(lastOnline))} ago`}
        </Typography>
      </Stack>

      <div style={{ flex: 1 }} />
      {props.handleInformationPanel &&
      <Tooltip title='Conversation details'>
        <IconButton onClick={props.handleInformationPanel} size='small' >
          <InfoRoundedIcon />
        </IconButton>
      </Tooltip>}
      {props.handleSmallMessages &&
        <IconButton onClick={props.handleSmallMessages} size='small' >
          <CloseRoundedIcon />
        </IconButton>}
    </Stack>

    <Stack direction='column-reverse' height={1} overflow='auto' paddingX={1.5} spacing={1}>
      <Stack width={1}>
        {currentConversationMessages[0]?.readByAccountIds &&
        <AvatarGroup max={99} spacing='medium'>
          {_.uniq(currentConversationMessages[0].readByAccountIds)
            .filter(accountItem => accountItem !== currentAccount.id)
            .map(readbyAccount =>
              <Avatar
                key={`readby-${readbyAccount}`}
                src={allMembersArray.find(item => item.id === readbyAccount)?.profilePictureUrl}
                sx={{ height: 16, width: 16 }}
              />)}
        </AvatarGroup>}
      </Stack>

      {currentConversationMessages &&
      currentConversation &&
      currentConversationMessages.length === 0 &&
      !loading &&
      <Stack alignItems='center' justifyContent='center' paddingY={2} spacing={1} width={1}>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: theme => theme.palette.action.disabled,
            borderRadius: 16,
          }}
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            padding={1.5}
          >
            <ForumRoundedIcon
              sx={{
                color: theme => theme.palette.primary.contrastText,
                fontSize: '48px',
              }}
            />
          </Stack>
        </Paper>
        <Stack width={1}>
          <Typography
            color='text.secondary'
            lineHeight={1.4}
            textAlign='center'
            variant='body2'
          >
            This conversation is empty, send a message to get started.
          </Typography>
        </Stack>
      </Stack>}

      {currentConversationMessages.map((message, index) => {
        const key = message.id ?? message.createdAt.toString()
        return message.senderId !== currentAccount.id
          ? currentConversationMessages[index + 1] &&
          Number(currentConversationMessages[index].createdAt) -
          Number(currentConversationMessages[index + 1].createdAt) > 3_600_000
            ? <>
              <CorrespondantMessage
                accounts={allMembersArray}
                key={key}
                message={message}
                time={message.createdAt}
              />
              <Typography textAlign='center' variant='textLabel'>
                {dayjs(currentConversationMessages[index].createdAt).format('llll')}
              </Typography>
            </>
            : <CorrespondantMessage
              accounts={allMembersArray}
              key={key}
              message={message}
              time={message.createdAt}
            />
          : currentConversationMessages[index + 1] &&
          Number(currentConversationMessages[index].createdAt) -
          Number(currentConversationMessages[index + 1].createdAt) > 3_600_000
            ? <>
              <SelfMessage
                key={key}
                message={message}
              />
              <Typography textAlign='center' variant='textLabel'>
                {dayjs(currentConversationMessages[index].createdAt).format('llll')}
              </Typography>
            </>
            : <SelfMessage
              key={key}
              message={message}
            />
      })}
      <Stack
        id='topofconvo'
        ref={ref}
        sx={{
          width: 1,
          height: 16,
          minHeight: 16,
          alignItems: 'center',
        }}
      >
        {loading &&
        <CircularProgress color='primary' />}
      </Stack>
    </Stack>
    <MessagesField />
  </Stack >
}

export default MessagesPanel
