/* eslint-disable unicorn/filename-case */
/* eslint-disable sonarjs/max-switch-cases */
/* eslint-disable complexity */
import AdsClickRoundedIcon from '@mui/icons-material/AdsClickRounded'
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded'
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import MicExternalOnRoundedIcon from '@mui/icons-material/MicExternalOnRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import NotificationAddRoundedIcon from '@mui/icons-material/NotificationAddRounded'
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded'
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import WhatshotRoundedIcon from '@mui/icons-material/WhatshotRounded'

const GetIconSearch = (LinkName: string) => {
  switch (true) {
    case LinkName.toLowerCase().includes('search'):
      return <SearchRoundedIcon />
    case LinkName.toLowerCase().includes('overview'):
      return <AnalyticsRoundedIcon />
    case LinkName.toLowerCase().includes('catalog'):
      return <LibraryMusicRoundedIcon />
    case LinkName.toLowerCase().includes('playlists'):
      return <PlaylistAddRoundedIcon />
    case LinkName.toLowerCase().includes('audience'):
      return <PeopleRoundedIcon />
    case LinkName.toLowerCase().includes('charts'):
      return <QueryStatsRoundedIcon />
    case LinkName.toLowerCase().includes('songs'):
      return <MusicNoteRoundedIcon />
    case LinkName.toLowerCase().includes('artists'):
      return <MicExternalOnRoundedIcon />
    case LinkName.toLowerCase().includes('latest'):
      return <WhatshotRoundedIcon />
    case LinkName.toLowerCase().includes('recommended'):
      return <AutoAwesomeRoundedIcon />
    case LinkName.toLowerCase().includes('engagement'):
      return <AdsClickRoundedIcon />
    case LinkName.toLowerCase().includes('subscribers'):
      return <NotificationAddRoundedIcon />
    case LinkName.toLowerCase().includes('insights'):
      return <InsightsRoundedIcon />
    default:
      return <LinkRoundedIcon />
  }
}

export default GetIconSearch
