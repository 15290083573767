/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable no-await-in-loop */
/* eslint-disable react-hooks/exhaustive-deps */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import PaidRoundedIcon from '@mui/icons-material/PaidRounded'
import { Button, Card, Grid, Menu, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { getAnalyticsStatement, getAnalyticsStatementStores, getAnalyticsStatementTerritory, getAnalyticsStatementTopReleases, getNetOverall, getNetProject, getTopArtists } from 'src/api/analytics'
import { getDistributionByProjectId } from 'src/api/distribution'
import { getProject } from 'src/api/projects'
import { getRecordingsByProjectId } from 'src/api/recordings'
import NewEarningsDistributionLocations from 'src/components/pages/Earnings/EarningsDistribution/Locations/NewEarningsDistribution'
import EarningsExportModal from 'src/components/pages/Earnings/EarningsDistribution/Modals/EarningsExportModal'
import EarningsDistributionLifetime from 'src/components/pages/Earnings/EarningsDistribution/Overview/EarningsDistributionLifetime'
import EarningsDistributionNextPayout from 'src/components/pages/Earnings/EarningsDistribution/Overview/EarningsDistributionNextPayout'
import EarningsDistributionOverview from 'src/components/pages/Earnings/EarningsDistribution/Overview/EarningsDistributionOverview'
import MyEarningsDistributionLifetime from 'src/components/pages/Earnings/EarningsDistribution/Overview/MyEarningsLifetime'
import EarningsDistributionStores from 'src/components/pages/Earnings/EarningsDistribution/Stores/EarningsDistributionStores'
import ViewEarningsAccounting from 'src/components/pages/Earnings/EarningsTabs/EarningsAccounting'
import SelectPeriod from 'src/components/pages/Links/ViewLink/ViewLinkComponents/TimePeriodSelect/SelectEarningsPeriod'
import SelectServices from 'src/components/pages/Links/ViewLink/ViewLinkComponents/TimePeriodSelect/SelectService'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AnalyticsMonthlyChart, AnalyticsTopArtists } from 'src/models/Analytics'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'
import SPACING from 'src/styles/spacing'

type Props = {
  readonly project?: Project
  readonly selectedProject?: string
  readonly selectedRecording?: string
}

const timeout = (delay: number) => new Promise(response => setTimeout(response, delay))

const EarningsDistribution = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))

  const [loadingMonth, setLoadingMonth] = useState(false)
  const [loadingTerritory, setLoadingTerritory] = useState(false)
  const [loadingStores, setLoadingStores] = useState(false)

  const { currentOrganisation } = useAuth()
  const [distributionItems, setDistributionItems] = useState<Recording[]>()
  const selectedRecording = props.selectedRecording ?? ''
  const selectedProject = props.selectedProject ?? ''

  const [stores, setStores] = useState<string[]>([])
  const [periods, setPeriods] = useState<string[]>([])
  const [project, setProject] = useState<Project>()
  const [recordings, setRecordings] = useState<Recording[]>([])

  const recordingItem = recordings.find(item => item.ISRC === props.selectedRecording)

  useEffect(() => {
    if (props.selectedProject) {
      void getProject(props.selectedProject)
        .then(proj => setProject(proj))
        .catch(() => null)
    }
  }, [props.selectedProject])

  const isOwner = project?.organisation?.id === currentOrganisation?.id

  const [exportItem, setExport] = useState(false)

  const [analyticsMonthlyChart, setAnalyticsMonthlyChart] = useState<AnalyticsMonthlyChart[]>([])
  const [analyticsTerritoryChart, setAnalyticsTerritoriesChart] = useState<AnalyticsMonthlyChart[]>([])
  const [analyticsStoresChart, setAnalyticsStoresChart] = useState<AnalyticsMonthlyChart[]>([])

  const [topReleases, setTopReleases] = useState<AnalyticsMonthlyChart[]>([])

  const [netIncome, setNetIncome] = useState<AnalyticsMonthlyChart[]>([])

  const [topArtists, setTopArtists] = useState<AnalyticsTopArtists[]>([])

  const filteredItems = distributionItems?.filter(item =>
    item.masterRightsHolders.some(object => object.account.id === currentOrganisation?.id))

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorElement)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  useEffect(() => {
    if (props.project) {
      void getDistributionByProjectId(props.project.id)
        .then(item => setDistributionItems(item.recordings))
        .catch(() => null)
    }
  }, [props.project?.id, currentOrganisation?.id])

  useEffect(() => {
    getData()
  }, [stores, periods, distributionItems, currentOrganisation?.id])

  const getData = () => {
    if (selectedRecording.length > 0) {
      setLoadingMonth(true)
      setLoadingTerritory(true)
      setLoadingStores(true)

      if (props.selectedProject) {
        void getRecordingsByProjectId(props.selectedProject)
          .then(rec => setRecordings(rec))
      }

      void getNetProject([selectedRecording], selectedProject)
        .then(item => setNetIncome(item))

      void getAnalyticsStatement(stores, [selectedRecording], selectedProject)
        .then(item => setAnalyticsMonthlyChart(item))
        .then(() => setLoadingMonth(false))

      void getAnalyticsStatementTerritory(stores, periods, [selectedRecording], selectedProject)
        .then(item => setAnalyticsTerritoriesChart(item))
        .then(() => setLoadingTerritory(false))

      if (stores.length === 0) {
        void getAnalyticsStatementStores(periods, [selectedRecording], selectedProject)
          .then(item => setAnalyticsStoresChart(item))
          .then(() => setLoadingStores(false))
      }
      setTopReleases([])
    } else if (distributionItems && filteredItems && props.project) {
      setLoadingMonth(true)
      setLoadingTerritory(true)
      setLoadingStores(true)

      if (props.selectedProject) {
        void getRecordingsByProjectId(props.selectedProject)
          .then(rec => setRecordings(rec))
      }
      void getNetProject(filteredItems.map(item => item.ISRC), props.project.id)
        .then(item => setNetIncome(item))

      void getAnalyticsStatement(stores, filteredItems.map(item => item.ISRC), props.project.id)
        .then(item => setAnalyticsMonthlyChart(item))
        .then(() => setLoadingMonth(false))

      void getAnalyticsStatementTerritory(stores, periods, filteredItems.map(item => item.ISRC), props.project.id)
        .then(item => setAnalyticsTerritoriesChart(item))
        .then(() => setLoadingTerritory(false))

      if (filteredItems.length > 0) {
        void getAnalyticsStatementTopReleases(stores, periods, filteredItems.map(item => item.ISRC), props.selectedProject)
          .then(item => setTopReleases(item))
      }

      if (stores.length === 0) {
        void getAnalyticsStatementStores(periods, filteredItems.map(item => item.ISRC), props.project.id)
          .then(item => setAnalyticsStoresChart(item))
          .then(() => setLoadingStores(false))
      }
    } else if (props.project) {
      void getRecordingsByProjectId(props.project.id)
        .then(rec => setRecordings(rec))
    } else {
      setLoadingMonth(true)
      setLoadingTerritory(true)
      setLoadingStores(true)

      setRecordings([])
      void getNetOverall()
        .then(item => setNetIncome(item))

      void getAnalyticsStatement(stores)
        .then(item => setAnalyticsMonthlyChart(item))
        .then(() => setLoadingMonth(false))

      void getAnalyticsStatementTerritory(stores, periods)
        .then(item => setAnalyticsTerritoriesChart(item))
        .then(() => setLoadingTerritory(false))

      void getAnalyticsStatementTopReleases(stores, periods)
        .then(item => setTopReleases(item))

      void getTopArtists()
        .then(item => setTopArtists(item))

      if (stores.length === 0) {
        void getAnalyticsStatementStores(periods)
          .then(item => setAnalyticsStoresChart(item))
          .then(() => setLoadingStores(false))
      }
    }
  }

  const text = 'If you have paid out an advance to a collaborator or spent money on this release, you can recoup those amounts using Releese recoups automatically.'
  const [blurb, setBlurb] = useState('')

  const generateBlurb = async (itemString: string) => {
    const textItems = itemString.split(' ')
    for (const textItem of textItems) {
      if (textItem.length > 0) {
        setBlurb(oldValue => `${oldValue} ${textItem}`)
      }
      await timeout(25)
    }
  }

  useEffect(() => {
    void generateBlurb(text)
  }, [text])

  return (
    <>
      <Menu
        anchorEl={anchorElement}
        onClose={handleClose}
        open={open}
      >
        <Stack
          alignItems='flex-start'
          direction='column'
          padding={1.5}
          spacing={4}
          width={1}
        >
          <Typography lineHeight={1.2} textAlign='left' variant='h3'>
            Filters
          </Typography>
          <SelectServices
            destinations={analyticsStoresChart.map(item => item._id)}
            services={stores}
            setServices={setStores}
          />
          <SelectPeriod
            periodOptions={analyticsMonthlyChart.map(item => item._id)}
            periods={periods}
            setPeriods={setPeriods}
          />
        </Stack>
      </Menu>
      <EarningsExportModal
        analyticsMonthlyChart={analyticsMonthlyChart}
        close={() => setExport(false)}
        open={exportItem}
        projectId={props.project?.id}
      />
      <Stack direction='row' height={1} width={1}>
        <Stack
          alignItems='stretch'
          boxSizing='border-box'
          direction='column'
          height={1}
          minWidth={0}
          padding={0}
          width={1}
        >
          <Stack
            alignItems='center'
            height={1}
            overflow='auto'
            sx={{
              scrollBehavior: 'smooth!important',
            }}
            width={1}
          >
            <Stack
              marginLeft='auto'
              marginRight='auto'
              maxWidth={1332}
              padding={SPACING.CONTENT_PADDING}
              width={1}
            >
              {props.project?.organisation &&
              currentOrganisation &&
              props.project.organisation.id ===
              currentOrganisation.id &&
              <Stack marginBottom={2} width={1}>
                <Card
                  elevation={0}
                  sx={{
                    background: theme => theme.palette.background.elevatedCard,
                  }}
                >
                  <Stack padding={2} spacing={1} width={1}>
                    <Stack alignItems='center' direction='row' spacing={0.5} width={1}>
                      <AutoAwesomeRoundedIcon
                        sx={{
                          color: 'rgba(186,120,255,1)',
                        }}
                      />
                      <Typography
                        lineHeight={1.2}
                        sx={{
                          background: 'linear-gradient(90deg, rgba(186,120,255,1) 25%, rgba(255,136,228,1) 75%)',
                          backgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                        }}
                        variant='h4'
                      >
                        Artist Intelligence
                      </Typography>
                    </Stack>
                    <Stack position='relative' width={1}>
                      <Typography
                        color='text.secondary'
                        variant='body1'
                        visibility='hidden'
                      >
                        {text}
                      </Typography>
                      <Typography
                        color='text.secondary'
                        left={0}
                        position='absolute'
                        top={0}
                        variant='body1'
                      >
                        {blurb}
                      </Typography>
                    </Stack>
                    <Button
                      component='a'
                      href='#accounting'
                      rounded
                      size='small'
                      startIcon={<PaidRoundedIcon />}
                      sx={{
                        width: 'fit-content',
                        background: 'linear-gradient(90deg, rgba(186,120,255,1) 25%, rgba(255,136,228,1) 75%, rgba(186,120,255,1) 100%)',
                        backgroundSize: '250% auto',
                        transition: 'all 0.25s ease',
                        '&:hover': {
                          backgroundPosition: '25% center',
                        },
                      }}
                    >
                      Recoup expenses
                    </Button>
                  </Stack>
                </Card>
              </Stack>}
              <Stack paddingBottom={2} width={1}>
                <Grid container spacing={2} sx={{ marginLeft: '-16px!important' }}>
                  <Grid item lg={4} md={12} sm={12} xs={12}>
                    <EarningsDistributionLifetime
                      data={analyticsMonthlyChart}
                      periods={periods}
                      selectedRecording={selectedRecording}
                    />
                  </Grid>
                  <Grid item lg={4} md={12} sm={12} xs={12}>
                    <MyEarningsDistributionLifetime
                      grossData={analyticsMonthlyChart}
                      isOwner={isOwner}
                      netIncome={netIncome}
                      periods={periods}
                      selectedRecording={selectedRecording}
                    />
                  </Grid>
                  <Grid item lg={4} md={12} sm={12} xs={12}>
                    <EarningsDistributionNextPayout />
                  </Grid>
                </Grid>
              </Stack>
              <Stack
                alignItems='flex-start'
                direction={mobile ? 'column' : 'row'}
                justifyContent={mobile ? 'flex-start' : 'space-between'}
                paddingBottom={mobile ? 2 : 0}
                spacing={mobile ? 1 : 2}
                width={1}
              >
                <Stack
                  alignItems='center'
                  direction='row'
                  paddingBottom={mobile ? 0 : 2}
                  spacing={1}
                  width={1}
                >
                  <Button
                    color='inherit'
                    onClick={handleClick}
                    startIcon={<FilterListRoundedIcon />}
                    variant='outlined'
                  >
                    Filters
                  </Button>
                  <Button
                    color='secondary'
                    disabled={periods.length === 0 && stores.length === 0}
                    onClick={() => {
                      setPeriods([])
                      setStores([])
                    }}
                    variant='text'
                  >
                    Clear all
                  </Button>
                </Stack>
                {selectedRecording.length === 0 && !mobile &&
                <Button
                  disabled={analyticsMonthlyChart.length === 0}
                  onClick={() => setExport(true)}
                  startIcon={<FileDownloadRoundedIcon />}
                  sx={{
                    flexShrink: 0,
                  }}
                >
                  Export Earnings
                </Button>}
              </Stack>
              <Stack
                marginLeft='auto'
                marginRight='auto'
                maxWidth={1332}
                paddingBottom={10}
                width={1}
              >
                <Typography variant='h3'>
                  Earnings
                </Typography>

                {loadingMonth
                  ? <Stack spacing={3} width={1}>
                    <Skeleton height={400} variant='rounded' width='100%'>
                      <div />
                    </Skeleton>
                    <Stack paddingY={2} spacing={3} width={1}>
                      <Grid container spacing={2} sx={{ marginLeft: '-16px!important' }}>
                        {Array.from({ length: 3 }, () =>
                          <Grid item key={v4()} lg={4} md={12} sm={12} xs={12}>
                            <Skeleton variant='rounded' width='100%'>
                              <div style={{ paddingTop: '57%' }} />
                            </Skeleton>
                          </Grid>)}
                      </Grid>
                      <Stack spacing={2} width={1}>
                        <Typography variant='h3'>
                          <Skeleton variant='rounded' width='128px' />
                        </Typography>
                        <Skeleton variant='rounded' width='100%' />
                        <Skeleton variant='rounded' width='100%' />
                        <Skeleton variant='rounded' width='100%' />
                        <Skeleton variant='rounded' width='100%' />
                        <Skeleton variant='rounded' width='100%' />
                        <Skeleton variant='rounded' width='100%' />
                        <Skeleton variant='rounded' width='100%' />
                      </Stack>
                    </Stack>
                  </Stack>
                  : <EarningsDistributionOverview
                    data={analyticsMonthlyChart}
                    isOwner={isOwner}
                    netIncome={netIncome}
                    selectedRecording={selectedRecording}
                    topArtists={topArtists}
                    topReleases={topReleases}
                  />}

                {stores.length === 0 &&
                <>
                  {loadingStores
                    ? <Stack paddingY={2} spacing={3} width={1}>
                      <Stack spacing={2} width={1}>
                        <Typography variant='h3'>
                          <Skeleton variant='rounded' width='128px' />
                        </Typography>
                        <Skeleton height={400} variant='rounded' width='100%'>
                          <div />
                        </Skeleton>
                      </Stack>
                      <Stack spacing={2} width={1}>
                        <Typography variant='h3'>
                          <Skeleton variant='rounded' width='128px' />
                        </Typography>
                        <Skeleton variant='rounded' width='100%' />
                        <Skeleton variant='rounded' width='100%' />
                        <Skeleton variant='rounded' width='100%' />
                        <Skeleton variant='rounded' width='100%' />
                        <Skeleton variant='rounded' width='100%' />
                        <Skeleton variant='rounded' width='100%' />
                        <Skeleton variant='rounded' width='100%' />
                      </Stack>
                    </Stack>
                    : <EarningsDistributionStores
                      data={analyticsStoresChart}
                    />}
                </>}

                {loadingTerritory
                  ? <Stack paddingY={2} spacing={3} width={1}>
                    <Stack spacing={2} width={1}>
                      <Typography variant='h3'>
                        <Skeleton variant='rounded' width='128px' />
                      </Typography>
                      <Skeleton height={400} variant='rounded' width='100%'>
                        <div />
                      </Skeleton>
                    </Stack>
                    <Stack spacing={2} width={1}>
                      <Typography variant='h3'>
                        <Skeleton variant='rounded' width='128px' />
                      </Typography>
                      <Skeleton variant='rounded' width='100%' />
                      <Skeleton variant='rounded' width='100%' />
                      <Skeleton variant='rounded' width='100%' />
                      <Skeleton variant='rounded' width='100%' />
                      <Skeleton variant='rounded' width='100%' />
                      <Skeleton variant='rounded' width='100%' />
                      <Skeleton variant='rounded' width='100%' />
                    </Stack>
                  </Stack>
                  : <NewEarningsDistributionLocations
                    data={analyticsTerritoryChart}
                  />}

                {props.selectedRecording && recordingItem &&
                <ViewEarningsAccounting
                  project={project}
                  recordings={recordings}
                  selectedRecording={recordingItem}
                  setProject={setProject}
                  setRecordings={setRecordings}
                />}
                {props.selectedProject && !recordingItem &&
                <ViewEarningsAccounting
                  project={project}
                  recordings={recordings}
                  selectedRecording={undefined}
                  setProject={setProject}
                  setRecordings={setRecordings}
                />}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default EarningsDistribution
