import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded'
import { Avatar, Badge } from '@mui/material'

import type { Organisation } from 'src/models/Organisation'

type Props = {
  readonly organisation?: Organisation | null | undefined
}

const TeamAvatar = (props: Props) =>
  <Badge
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    badgeContent={<VerifiedRoundedIcon
      color='secondary'
      fontSize='small'
      sx={{
        borderRadius: '50%',
        color: theme => `${theme.palette.secondary.main}!important`,
        background: 'radial-gradient(circle, rgba(255,255,255,1) 42%, rgba(255,255,255,0) 43%)',
      }}
    />}
    overlap='circular'
    sx={{
      '.MuiBadge-badge': {
        display: props.organisation?.priority ? undefined : 'none',
      },
    }}
  >
    <Avatar
      src={props.organisation?.logoUrl}
    />
  </Badge>

export default TeamAvatar
