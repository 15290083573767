/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
import { LoadingButton } from '@mui/lab'
import { alpha, Button, Divider, IconButton, Input, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useDropzone } from 'react-dropzone'
import { v4 } from 'uuid'

import { getFiles, getFilesByProjectId, updateFile } from 'src/api/files'
import { downloadProjectFolder, getFolder, getFoldersByProjectId } from 'src/api/folder'
import AddFileUpload from 'src/components/pages/Files/Components/AddFileUpload'
import FileItemView from 'src/components/pages/Files/Components/FileItemView'
import FileTableItem from 'src/components/pages/Files/Components/FileTableItem'
import FolderItem from 'src/components/pages/Files/Components/FolderItem'
import NewFolderItem from 'src/components/pages/Files/Components/NewFolderItem'
import ShareProjectFolderModal from 'src/components/pages/Files/Components/ShareProjectFolderModal'
import useDropzoneFullscreen from 'src/components/pages/Projects/FullscreenDropzone'
import { audioFiles, imageFiles, msDocumentFiles, pdfFiles } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/FilesTab/PreviewFile'
import ShareFilesTour from 'src/components/pages/Tour/Files/ShareFilesTour'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { UploadingItem } from 'src/components/providers/FilesUploadProvider'
import { useFileUploader } from 'src/components/providers/FilesUploadProvider'
import type { FileItem } from 'src/models/File'
import type { Folder } from 'src/models/Folder'
import type Project from 'src/models/Project'
import SPACING from 'src/styles/spacing'

type Props = {
  project?: Project
  display: string
  setDisplay: React.Dispatch<React.SetStateAction<string>>
  setLocation?: React.Dispatch<React.SetStateAction<string>>
  searchQuery: string
  images: boolean
  files: boolean
  music: boolean
  documents: boolean
}

const FolderHome = (props: Props) => {
  const { files, setFiles, setLoading } = useFileUploader()
  const { currentOrganisation } = useAuth()
  const { getRootProps } = useDropzone()
  const isDragActive = useDropzoneFullscreen()
  const [filesItems, setFilesItems] = useState<FileItem[]>([])
  const [folders, setFolders] = useState<Folder[]>([])
  const [page, setPage] = useState(1)
  const themeItem = useTheme()
  const isMobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const [share, setShare] = useState(false)
  const [loadingbutton, setLoadingbutton] = useState(false)

  const uploadQueue = files?.filter(item => props.project?.id
    ? item.projectId === props.project.id
    : item.organisationId === currentOrganisation?.id)

  const regexExtension = /\w{3,4}($|\?)/

  const filterFiles = filesItems
    .filter(item => item.title.toLowerCase().includes(props.searchQuery.toLowerCase()))
    .filter(item => (props.documents && msDocumentFiles.has(regexExtension.exec(item.title)?.[0] ?? '')) ||
    (props.music && audioFiles.has(regexExtension.exec(item.title)?.[0] ?? '')) ||
    (props.files && pdfFiles.has(regexExtension.exec(item.title)?.[0] ?? '')) ||
    (props.images && imageFiles.has(regexExtension.exec(item.title)?.[0] ?? '')) ||
    ((!props.documents && !props.music && !props.files && !props.images)))

  const filterFolders = folders
    .filter(item => item.title.toLowerCase().includes(props.searchQuery.toLowerCase()))

  const currentPageDataFiles = filterFiles.slice(((page * 100) / 5) - 20, (page * 100) / 5)

  const getAllItems = async (projectId?: string) => {
    if (projectId) {
      await getFilesByProjectId(projectId)
        .then(fileItems => setFilesItems(fileItems))
      await getFoldersByProjectId(projectId)
        .then(folderItems => setFolders(folderItems))
    } else {
      await getFiles()
        .then(fileItems => setFilesItems(fileItems))
      await getFolder()
        .then(folderItems => setFolders(folderItems))
    }
  }

  useEffect(() => {
    void getAllItems(props.project?.id)
  }, [currentOrganisation?.id, props.project?.id, files?.length])

  const addAttachmentDropFile = (event: FileList) => {
    const fileArray: File[] = [...event]
    const items = fileArray.map(item => ({
      id: v4(),
      file: item,
      parent: '',
      projectId: props.project?.id,
      organisationId: currentOrganisation?.id,
      taskId: undefined,
      taskboardId: undefined,
      loading: 0,
      total: item.size,
    })) as UploadingItem[]
    setFiles(oldUploads => oldUploads ? [...items, ...oldUploads] : [...items])
    setLoading(old => old
      ? [...old, ...items.map(item => ({ id: item.id, loading: 0, total: item.file.size }))]
      : [...items.map(item => ({ id: item.id, loading: 0, total: item.file.size }))])
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (event.dataTransfer.files.length > 0) {
      addAttachmentDropFile(event.dataTransfer.files)
      event.dataTransfer.clearData()
    }
  }

  const handleAttachmentUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return
    const items = {
      id: v4(),
      file,
      parent: '',
      projectId: props.project?.id,
      organisationId: currentOrganisation?.id,
      taskId: undefined,
      taskboardId: undefined,
      loading: 0,
      total: file.size,
    } as UploadingItem

    setFiles(oldUploads => oldUploads ? [items, ...oldUploads] : [items])
    setLoading(old => old
      ? [...old, { id: items.id, loading: 0, total: items.file.size }]
      : [{ id: items.id, loading: 0, total: items.file.size }])
  }

  const updateCurrentDisplayData = (currentPage: number) => {
    setPage(currentPage)
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    } else if (result.destination.droppableId && result.destination.droppableId !== 'undefined') {
      const newFile = filesItems.find(item => item.id === result.draggableId)
      if (newFile?.id) {
        void updateFile({ ...newFile, parent: result.destination.droppableId }, newFile.id)
          .then(() => {
            setFilesItems(oldItems => oldItems.filter(item => item.id !== newFile.id))
          })
      }
    }
  }

  const downloadFolderFunction = async () => {
    if (props.project) {
      setLoadingbutton(true)
      await downloadProjectFolder(props.project)
        .finally(() => setLoadingbutton(false))
    }
  }

  return (
    <>
      {props.project &&
      <ShareFilesTour />}
      {props.project &&
      <ShareProjectFolderModal
        close={() => setShare(false)}
        open={share}
        projectItem={props.project}
      />}
      <Stack
        {...getRootProps()}
        onDrop={handleDrop}
        sx={{
          border: theme => `dashed ${theme.palette.primary.main} 4px`,
          backgroundColor: theme => alpha(theme.palette.background.paper, 0.9),
          position: 'absolute',
          visibility: isDragActive ? 'visible' : 'hidden',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          animation: 'blinker 2s linear infinite',
          '@keyframes blinker': {
            '50%': {
              opacity: 0.9,
            },
          },
        }}
      >
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            right: 0,
            left: 0,
            textAlign: 'center',
            color: 'grey',
            fontSize: 36,
          }}
        >
          <Typography
            color={themeItem.palette.text.label}
          >
            Drop files here
          </Typography>
        </Stack>
      </Stack>
      <DragDropContext onDragEnd={onDragEnd}>

        <Stack alignItems='center' height={1} overflow='auto' padding={SPACING.CONTENT_PADDING} width={1}>
          <Stack spacing={1} width={1}>
            <Stack flexWrap='wrap' spacing={1} width={1}>
              <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
                <Typography variant='h3'>
                  Folders
                </Typography>
                {props.project &&
                <Stack alignItems='center' direction='row' spacing={1}>
                  <LoadingButton
                    disabled={folders.length === 0 && filesItems.length === 0}
                    id='download-folder'
                    loading={loadingbutton}
                    onClick={() => void downloadFolderFunction()}
                    startIcon={<DownloadRoundedIcon />}
                    variant='subtle'
                  >
                    Download all
                  </LoadingButton>
                  <Button
                    id='share-folder'
                    onClick={() => setShare(true)}
                    startIcon={<ShareRoundedIcon />}
                  >
                    Share files
                  </Button>
                </Stack>}
              </Stack>

              <Stack
                direction='row'
                flexWrap='wrap'
                sx={{
                  alignContent: 'flex-start',
                  alignItems: 'center',
                  width: 1,
                }}
              >
                {filterFolders.map(folder =>
                  <Stack
                    key={folder.id}
                    paddingRight={2}
                    paddingY={1}
                    width={{
                      xl: '12%',
                      lg: '15%',
                      md: '25%',
                      sm: '25%',
                      xs: '50%',
                    }}
                  >
                    <FolderItem
                      folder={folder}
                      setFolders={setFolders}
                      setLocation={props.setLocation}
                    />
                  </Stack>)}
                <Stack
                  key='new'
                  paddingRight={2}
                  paddingY={1}
                  width={{
                    xl: '12%',
                    lg: '15%',
                    md: '25%',
                    sm: '25%',
                    xs: '50%',
                  }}
                >
                  <NewFolderItem
                    location=''
                    project={props.project}
                    setFolders={setFolders}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Divider />

            <Droppable droppableId='undefined' isDropDisabled key='undefined'>
              {provided =>
                <Stack spacing={1} width={1} {...provided.droppableProps} ref={provided.innerRef}>
                  <Stack direction='row' justifyContent='space-between' width={1}>
                    <Typography variant='h3'>
                      Files
                    </Typography>
                    <Stack direction='row' spacing={1}>
                      <IconButton
                        color={props.display === 'list' ? 'primary' : 'default'}
                        onClick={() => props.setDisplay('list')}
                      >
                        <ViewListRoundedIcon />
                      </IconButton>
                      <IconButton
                        color={props.display === 'grid' ? 'primary' : 'default'}
                        onClick={() => props.setDisplay('grid')}
                      >
                        <GridViewRoundedIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                  {filesItems.length > 0 || (uploadQueue ? uploadQueue.length : 0) > 0
                    ? <>
                      <Stack display={props.display === 'grid' ? 'flex' : 'none'} width={1}>
                        <Stack
                          direction='row'
                          flexWrap='wrap'
                          sx={{
                            alignContent: 'flex-start',
                            width: 1,
                          }}
                        >
                          {uploadQueue?.map((file, index) =>
                            <Stack
                              key={file.id}
                              padding={1}
                              width={{
                                xl: '25%',
                                lg: '25%',
                                md: '50%',
                                sm: '50%',
                                xs: '100%',
                              }}
                            >
                              <FileItemView
                                file={file.file}
                                index={index}
                                location=''
                                project={props.project}
                                setFiles={setFilesItems}
                                uploadingId={file.id}
                              />
                            </Stack>)}
                          {props.display === 'grid' && currentPageDataFiles.map((file, index) =>
                            <Stack
                              key={file.id}
                              padding={1}
                              width={{
                                xl: '25%',
                                lg: '50%',
                                md: '50%',
                                sm: '50%',
                                xs: '100%',
                              }}
                            >
                              <FileItemView
                                done
                                fileItem={file}
                                index={index + (uploadQueue ? uploadQueue.length : 0)}
                                link={`https://cdn.releese.io/${file.url}`}
                                location=''
                                setFiles={setFilesItems}
                              />
                            </Stack>)}
                        </Stack>
                      </Stack>
                      <Stack display={props.display === 'list' ? 'flex' : 'none'} width={1}>
                        <TableContainer sx={{ overflowY: 'hidden' }}>
                          <Table
                            aria-label='projects table'
                            size='small'
                            sx={{
                              minWidth: isMobile
                                ? 0
                                : 750,
                            }}
                          >
                            <TableHead sx={{
                              '.MuiTableCell-root':
              {
                borderBottom: 2,
                borderBottomColor: 'divider',
                color: 'text.secondary',
              },
                            }}
                            >
                              <TableRow>
                                <TableCell align='left' />
                                <TableCell align='left'>
                                  Name
                                </TableCell>
                                {!isMobile &&
                                <>
                                  <TableCell align='left'>
                                    Created At
                                  </TableCell>
                                  <TableCell align='left'>
                                    Size
                                  </TableCell>
                                </>}
                                <TableCell align='left'>
                                  Actions
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {uploadQueue?.map((file, index) =>
                                <FileTableItem
                                  file={file.file}
                                  index={index}
                                  key={file.id}
                                  setFiles={setFilesItems}
                                  uploadingId={file.id}
                                />)}
                              {props.display === 'list' && currentPageDataFiles.map((file, index) =>
                                <FileTableItem
                                  fileItem={file}
                                  index={index + (uploadQueue ? uploadQueue.length : 0)}
                                  key={file.id}
                                  link={`https://cdn.releese.io/${file.url}`}
                                  setFiles={setFilesItems}
                                />)}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <AddFileUpload
                          handleAttachmentUpload={handleAttachmentUpload}
                        />
                      </Stack>
                      <Stack>
                        {
                          filterFiles.length > 20 &&
                          <Pagination
                            count={filterFiles.length > 0 ? Math.ceil(filterFiles.length / 20) : 1}
                            onChange={(event, value: number) => {
                              updateCurrentDisplayData(value)
                            }}
                            page={page}
                            sx={{ marginTop: 3, marginBottom: 4, marginLeft: 'auto' }}
                          />
                        }
                      </Stack>
                    </>

                    : <Stack alignItems='center' height={1} justifyContent='center' width={1} >
                      <Paper
                        elevation={0}
                        sx={{
                          backgroundColor: theme => theme.palette.error.main,
                          borderRadius: 16,
                        }}
                      >
                        <Stack
                          alignItems='center'
                          justifyContent='center'
                          padding={2}
                        >
                          <FolderRoundedIcon
                            sx={{
                              color: theme => theme.palette.primary.contrastText,
                              fontSize: '64px',
                            }}
                          />
                        </Stack>
                      </Paper>
                      <Typography
                        color={themeItem.palette.text.secondary}
                        sx={{
                          marginTop: 1,
                        }}
                        variant='h4'
                      >
                        No Files found
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.label}
                        variant='body1'
                      >
                        Drag and drop a new file to store it
                      </Typography>

                      <label htmlFor='upload-attachment'>
                        <Button
                          color='error'
                          component='span'
                          startIcon={<AddRoundedIcon />}
                          sx={{
                            marginTop: 2,
                          }}
                          variant='contained'
                        >
                          Upload a File
                        </Button>
                        <Input
                          id='upload-attachment'
                          onChange={handleAttachmentUpload}
                          sx={{ display: 'none', zIndex: '100000' }}
                          type='file'
                        />
                      </label>
                    </Stack>}
                </Stack>}
            </Droppable>
          </Stack>
        </Stack>
      </DragDropContext>
    </>
  )
}

export default FolderHome
