/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { LinearProgress, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { Avatar, ListItemText, Stack, TableCell, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'

import TopTrackModalSearch from 'src/components/pages/Audience/Components/Modals/TopTrackModalSearch'
import type { NewMusicFridayTopGainersnalytics } from 'src/models/AnalyticsCloud'

type Props = {
  track: NewMusicFridayTopGainersnalytics
}

const LatestTrackItemSearch = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const [openTrackModal, setOpenTrackModal] = useState(false)
  const track = props.track
  return (
    <>
      <TableRow
        hover
        key={track._id}
        onClick={() => setOpenTrackModal(true)}
        sx={{
          '.MuiTableCell-root': { borderBottomColor: 'divider', paddingY: 2 },
        }}
      >
        <TableCell>
          <Stack alignItems='center' direction='row' spacing={2} width={1}>
            <Avatar
              src={track.artwork[0]}
              sx={{
                height: 48,
                width: 48,
              }}
              variant='rounded'
            />
            <ListItemText
              primary={track.name}
              secondary={track.artists?.map(artist => artist.name).join(', ')}
            />
          </Stack>
        </TableCell>
        <TableCell>
          <ListItemText
            primary={`${new Intl.NumberFormat('en-US').format(track.followers)} Reach`}
            secondary={`${track.count} NMF Playlists`}
          />
        </TableCell>
        {!mobile &&
        <>
          <TableCell>
            <ListItemText
              secondary={dayjs(track.releaseDate).format('LL')}
            />
          </TableCell>
          <TableCell sx={{ maxWidth: 120 }}>
            <Stack alignItems='center' direction='row' maxWidth={120}>
              <Tooltip title={`Popularity ${track.popularity}%`}>
                <LinearProgress
                  sx={{
                    height: '16px',
                    borderRadius: 1,
                    width: 120,
                  }}
                  value={track.popularity}
                  variant='determinate'
                />
              </Tooltip>
            </Stack>
          </TableCell>
        </>}
      </TableRow>
      <TopTrackModalSearch
        artists={props.track.artists?.map(item => item.name) ?? []}
        artwork={track.artwork[0] ?? 0}
        close={() => setOpenTrackModal(false)}
        loading={false}
        open={openTrackModal}
        selectedRecording={props.track._id}
        title={props.track.name}
      />
    </>
  )
}

export default LatestTrackItemSearch
