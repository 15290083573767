/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import MicExternalOnRoundedIcon from '@mui/icons-material/MicExternalOnRounded'
import { Avatar, Card, CardActionArea, CardContent, Chip, Grid, Paper, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { v4 } from 'uuid'

import ArtistModalSearch from 'src/components/pages/Audience/Components/Modals/ArtistModalSearch'
import type { ArtistItemSingle } from 'src/models/AnalyticsCloud'

type Props = {
  readonly artistItem: ArtistItemSingle
}

const ViralArtistItem = (props: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {props.artistItem.spotifyId &&
      open &&
      <ArtistModalSearch
        artwork={props.artistItem.images?.[0].url ?? ''}
        close={() => setOpen(false)}
        loading={false}
        open={open}
        selectedArtist={props.artistItem.spotifyId}
        title={props.artistItem.name}
      />}
      <Grid
        item
        lg={3}
        md={4}
        sm={6}
        xl={2.4}
        xs={6}
      >
        <Card
          elevation={0}
          sx={{
            width: 'auto',
            minHeight: 200,
            maxHeight: '100%',
            background: theme => theme.palette.background.elevatedCard,
          }}
        >
          <CardActionArea
            onClick={() => setOpen(true)}
            sx={{
              height: 1,
              width: 1,
            }}
          >
            {props.artistItem.images?.[0]?.url.length > 0
              ? <Avatar
                src={props.artistItem.images?.[0]?.url}
                sx={{
                  objectFit: 'cover',
                  aspectRatio: '1/1',
                  width: '100%',
                  height: 'auto',
                  borderRadius: '0px',
                  background: theme => theme.palette.background.input,
                }}
              >
                <MicExternalOnRoundedIcon
                  sx={{
                    fontSize: 150,
                    color: theme => theme.palette.action.disabled,
                  }}
                />
              </Avatar>
              : <Paper
                elevation={0}
                sx={{
                  height: 'auto',
                  width: 1,
                  borderRadius: 0,
                  backgroundColor: theme => theme.palette.background.input,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  aspectRatio: '1/1',
                }}
              >
                <MicExternalOnRoundedIcon
                  sx={{
                    fontSize: 150,
                    color: theme => theme.palette.action.disabled,
                  }}
                />
              </Paper>}
            <CardContent sx={{ width: 1, padding: 1.5 }}>
              <Stack spacing={1} width={1}>
                <Typography
                  fontSize='1rem'
                  fontWeight={600}
                  lineHeight={1.3}
                  textAlign='left'
                  variant='h4'
                >
                  {props.artistItem.name}
                </Typography>
                {props.artistItem.genres &&
                <Stack alignContent='center' alignItems='center' direction='row' flexWrap='wrap'>
                  {props.artistItem.genres?.map(genre =>
                    <Chip
                      key={v4()}
                      label={genre}
                      size='small'
                      sx={{
                        marginRight: 0.5,
                        marginBottom: 0.5,
                        textTransform: 'capitalize',
                      }}
                    />)}
                </Stack>}
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  )
}

export default ViralArtistItem
