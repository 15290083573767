/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-duplicate-string */
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, Step, StepButton, StepConnector, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@mui/material'
import _ from 'lodash'
import { createContext, useEffect, useState } from 'react'

import { addPaymentMethod, getBankInfo, getCurrencies } from 'src/api/payout'
import OnboardingComplete from 'src/components/pages/Wallet/OnboardingModal/OnboardingComplete'
import OnboardingCurrency from 'src/components/pages/Wallet/OnboardingModal/OnboardingCurrency'
import OnboardingDetails from 'src/components/pages/Wallet/OnboardingModal/OnboardingDetails'
import OnboardingError from 'src/components/pages/Wallet/OnboardingModal/OnboardingError'
import OnboardingSubmit from 'src/components/pages/Wallet/OnboardingModal/OnboardingsSubmit'
import parseObjectProperties from 'src/components/pages/Wallet/OnboardingModal/ParseObjectProperties'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Currency, RequirementsBank } from 'src/models/Payout'
import { PayoutUser } from 'src/models/Payout'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly refresh: () => void
}

export const ErrorContext = createContext({})

const OnboardingModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const { refreshCurrentOrganisation } = useAuth()
  const [recipientDataErrorMap, setRecipientDataErrorMap] = useState<any>()
  const [recipientData, setRecipientData] = useState(new PayoutUser())
  const [currency, setCurrency] = useState<Currency>()
  const [name, setName] = useState('')
  const [currencies, setCurrencies] = useState<Currency[]>([])
  const [requirements, setRequirements] = useState<RequirementsBank[]>()
  const [currentPage, setCurrentPage] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    function fetchCurrencies() {
      setLoading(true)
      getCurrencies()
        .then(setCurrencies)
        .finally(() => setLoading(false))
    }
    fetchCurrencies()
  }, [])

  useEffect(() => {
    function fetchDetails() {
      if (currency) {
        setLoading(true)
        getBankInfo(currency.code)
          .then(setRequirements)
          .catch(() => setCurrentPage(4))
          .finally(() => setLoading(false))
      }
    }
    fetchDetails()
  }, [currency])

  const isButtonDisabled = (page: number) => {
    if (page === 0) {
      if (
        currency?.code.toString().length === 0 || !currency || name.length === 0
      ) {
        return true
      }
    } else if (page === 1) {
      let isAnyFieldIncorrect = false

      parseObjectProperties(recipientData.details, (key: string, value: string) => {
        if (
          !value || value.toString().length === 0
        ) {
          isAnyFieldIncorrect = true
        }
      })

      if (recipientData.accountHolderName.toString().length === 0) {
        isAnyFieldIncorrect = true
      }

      return isAnyFieldIncorrect
    }
  }

  const updateCurrency = (currencyItem: Currency | undefined) => {
    setCurrency(currencyItem)
    setRecipientData({ ...recipientData, currency: currencyItem?.code ?? '' })
  }

  const submitPaymentMethod = () => {
    const newObject = _.cloneDeep(recipientData)
    setLoading(true)
    void addPaymentMethod({ ...newObject }, name)
      .then(() => setCurrentPage(3))
      .catch(() => setCurrentPage(4))
      .then(() => refreshCurrentOrganisation())
      .finally(() => {
        props.refresh()
        setLoading(false)
      })
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        '.MuiDialog-paper': {
          overflow: 'hidden',
        },
      }}
    >
      <Stack height={1} overflow='auto' paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width={1}
        >
          <Typography variant='h3'>
            Create Payout Method
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>

        {currentPage < 3 &&
          <Stepper
            activeStep={currentPage}
            connector={<StepConnector sx={{ padding: 0 }} />}
          >
            <Step sx={{ width: 'fit-content' }}>
              <StepButton
                onClick={() => setCurrentPage(0)}
                sx={{ borderRadius: 1 }}
                value={0}
              >
                <StepLabel error={false}>
                  Currency
                </StepLabel>
              </StepButton>
            </Step>
            <Step sx={{ width: 'fit-content' }}>
              <StepButton
                onClick={() => setCurrentPage(1)}
                sx={{ borderRadius: 1 }}
                value={0}
              >
                <StepLabel error={false}>
                  Bank details
                </StepLabel>
              </StepButton>
            </Step>
            <Step sx={{ width: 'fit-content' }}>
              <StepButton
                onClick={() => setCurrentPage(2)}
                sx={{ borderRadius: 1 }}
                value={0}
              >
                <StepLabel error={false}>
                  Submit
                </StepLabel>
              </StepButton>
            </Step>
          </Stepper>}

        <Stack direction='row' justifyContent='center' spacing={1} width={1} >
          {currentPage === 0 &&
            <OnboardingCurrency
              currencies={currencies}
              currency={currency}
              name={name}
              setCurrency={updateCurrency}
              setName={setName}
            />}
          {currentPage === 1 && requirements &&
            <OnboardingDetails
              recipientData={recipientData}
              recipientDataErrorMap={recipientDataErrorMap}
              requirements={requirements}
              setRecipientData={setRecipientData}
              setRecipientDataErrorMap={setRecipientDataErrorMap}
              setRequirements={setRequirements}
            />}
          {currentPage === 2 && requirements &&
            <OnboardingSubmit
              name={name}
              recipientData={recipientData}
            />}
          {currentPage === 3 &&
            <OnboardingComplete />}
          {currentPage === 4 &&
            <OnboardingError />}
        </Stack>

        <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
          {currentPage < 3 &&
            <Button
              disabled={currentPage === 0}
              onClick={() => setCurrentPage(currentPage - 1)}
              variant='text'
            >
              Previous
            </Button>}
          {currentPage === 2 &&
            <LoadingButton
              disabled={isButtonDisabled(2)}
              loading={loading}
              onClick={submitPaymentMethod}
              variant='contained'
            >
              Submit
            </LoadingButton>}
          {currentPage === 0 &&
            <LoadingButton
              disabled={isButtonDisabled(0)}
              loading={loading}
              onClick={() => setCurrentPage(currentPage + 1)}
              variant='contained'
            >
              Next
            </LoadingButton>}
          {currentPage === 1 &&
            <LoadingButton
              disabled={isButtonDisabled(1)}
              loading={loading}
              onClick={() => setCurrentPage(currentPage + 1)}
              variant='contained'
            >
              Next
            </LoadingButton>}
        </Stack>
      </Stack>
    </Dialog>
  )
}
export default OnboardingModal
