/* eslint-disable no-duplicate-imports */
/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded'
import { Button, Divider, Stack } from '@mui/material'
import { useState } from 'react'

import SearchField from 'src/components/form-elements/SearchField'
import LinksListContent from 'src/components/pages/Links/LinksList/LinksListComponents/LinksListContent'
import MarketingSettingsModal from 'src/components/pages/Links/LinksList/LinksListModal/MarketingCloudSettingsModal'
import NewLinkModal from 'src/components/pages/Links/LinksList/LinksListModal/NewLinkModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'
import type Project from 'src/models/Project'

type Props = {
  readonly project?: Project
}

const ViewProjectMarketingLinks = (props: Props) => {
  const {
    currentAccountPermissions,
  } = useAuth()

  const [newLinkModal, setNewLinkModalOpen] = useState(false)
  const [settings, setSettings] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  return (
    <>
      {newLinkModal &&
      props.project &&
      currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS) &&
      <NewLinkModal
        close={() => setNewLinkModalOpen(false)}
        open={newLinkModal}
        project={props.project}
      />}
      <MarketingSettingsModal
        close={() => setSettings(false)}
        open={settings}
      />
      <Stack height={1}>
        <Stack
          direction='row'
          paddingX={2}
          paddingY={2}
          sx={{
            overflowX: 'auto',
            overflowY: 'clip',
            flexShrink: 0,
          }}
          width={1}
        >
          <Stack alignItems='center' direction='row' spacing={1} width={1}>
            <SearchField
              onChange={event => setSearchQuery(event.target.value)}
              value={searchQuery}
            />
          </Stack>
          <Stack
            alignItems='center'
            direction='row'
            marginLeft='auto'
            paddingLeft={1}
            spacing={1}
            sx={{
              flexShrink: 0,
            }}
          >
            <Button
              color='warning'
              disabled={!currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS)}
              onClick={() => setSettings(true)}
              startIcon={<DevicesRoundedIcon />}
            >
              Settings
            </Button>
            <Button
              disabled={!currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS)}
              onClick={() => setNewLinkModalOpen(true)}
              startIcon={<AddRoundedIcon />}
            >
              Create Link
            </Button>
          </Stack>
        </Stack>
        <Divider sx={{ width: 1 }} />
        <Stack height={1} sx={{ overflow: 'hidden' }} width={1}>
          <Stack height={1} width={1}>
            {props.project &&
              <LinksListContent
                linkType=''
                project={props.project}
                searchQuery={searchQuery}
              />}
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default ViewProjectMarketingLinks
